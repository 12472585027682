/* eslint max-len: 0 */
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let SignalMax = props =>
  <SvgIcon {...props}>
    <path d="M3,21H6V18H3M8,21H11V14H8M13,21H16V9H13M18,21H21V3H18V21Z" />
  </SvgIcon>;

SignalMax = pure(SignalMax);
SignalMax.muiName = 'SvgIcon';

export default SignalMax;
