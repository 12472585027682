"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getApplicationEnvironment = getApplicationEnvironment;
exports.setApplicationEnvironment = setApplicationEnvironment;
exports.runInProduction = runInProduction;
exports.runInDevelopment = runInDevelopment;
var env = 'dev';

function getApplicationEnvironment() {
  return env;
}

function setApplicationEnvironment(desiredEnv) {
  env = desiredEnv;
}

function runInDevelopment(callback) {
  if (getApplicationEnvironment() === 'dev') {
    callback();
  }
}

function runInProduction(callback) {
  if (getApplicationEnvironment() === 'prod') {
    callback();
  }
}