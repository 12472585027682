/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
/* Components Imports */
import ClipboardIcon from '../../style/ClipboardIcon';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Phone from '@material-ui/icons/Phone';
import IconButton from '@material-ui/core/IconButton';
import TooltipMultiLineText from '../TooltipMultiLineText';
import Avatar from '../Avatar';
/* Actions Imports */
/* Other Imports */
import { linkify } from 'holocom-client/lib/utils';
import { capitalize } from 'holocom-client/lib/utils/string';
import { humanizeTime } from 'holocom-client/lib/utils/time';
/* Local Style */
import style from './style';


const messages = defineMessages({
  titlePersonal: { id: 'roomInfoTitlePersonal' },
  titleScheduled: { id: 'roomInfoTitleScheduled' },
  roomName: { id: 'roomInfoName' },
  roomWebAccess: { id: 'roomInfoWebAccess' },
  roomPstnAccess: { id: 'roomInfoPstnAccess' },
  roomPublishStreamUrl: { id: 'roomPublishStreamUrl' },
  roomPstnNotAvailable: { id: 'roomInfoPstnNotAvailable' },
  roomPstnNumber: { id: 'roomInfoPstnNumber' },
  meetingDuration: { id: 'meetingDuration' },
  meetingBriefDuration: { id: 'meetingBriefDuration' },
  meetingCommonDuration: { id: 'meetingCommonDuration' },
  meetingLongDuration: { id: 'meetingLongDuration' },
  meetingVeryLongDuration: { id: 'meetingVeryLongDuration' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
  invitePersonToMeeting: { id: 'invitePersonToMeeting' },
  dialPersonToMeeting: { id: 'dialPersonToMeeting' },
  roomScheduling: { id: 'roomInfoScheduling' },
  from: { id: 'from' },
  to: { id: 'to' },
  unit: {
    day: { id: 'day' },
    days: { id: 'days' },
    hour: { id: 'hour' },
    hours: { id: 'hours' },
    minute: { id: 'minute' },
    minutes: { id: 'minutes' },
    second: { id: 'second' },
    seconds: { id: 'seconds' }
  },
});


class RoomInfo extends Component {
  constructor(props) {
    super(props);
    this.tooltipTimeoutHandler = null;
    this.state = {
      linkCopied: false,
    };
  }

  getMeetingAttendees(room) {
    const atts = room.attendees;
    const classes = this.props.classes;
    if (atts.length === 0) {
      return null;
    }
    return (
      <div>
        <div className={classes.title}>
          Invited participants:
        </div>
        <ul className={classes.participants}>
          {atts.map((val, idx) => {
            return (
              <li key={idx}>
                <div className={classes.content}>
                  {val.email}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  componentWillUnmount() {
    if (this.tooltipTimeoutHandler) {
      clearTimeout(this.tooltipTimeoutHandler);
    }
  }

  onLinkCopied = () => {
    this.setState({ linkCopied: true });
  }

  handleClipboardTooltipClose = () => {
    // ugly, but avoid re-rendering the normal tooltip text just before closing it
    this.tooltipTimeoutHandler = setTimeout(() => {
      this.setState({ linkCopied: false });
      this.tooltipTimeoutHandler = null;
    },
    500);
  }

  meetingExpired(room) {
    if (!(room.dt_end)) {
      return false;
    }
    return moment(new Date()).isAfter(room.dt_end);
  }

  getConfWebAccessComponent(url, rawUrl, room) {
    const classes = this.props.classes;
    const meeting_expired = this.meetingExpired(room);
    let cboardTtipText = this.props.intl.formatMessage(messages.copyToClipboard);
    if (this.state.linkCopied) {
      cboardTtipText = this.props.intl.formatMessage(messages.copiedToClipboard);
    }
    return (
      <div>
        <div className={classes.title}>
          {this.props.intl.formatMessage(messages.roomWebAccess)}
        </div>
        <div className={classes.webAccessContainer}>
          <div className={classNames(classes.content, classes.linkContent)}>
            {url}
          </div>
          <div className={classes.iconContainer}>
            <IconButton>
              <TooltipMultiLineText
                placement="top"
                title={cboardTtipText}
                onClose={this.handleClipboardTooltipClose}
              >
                <CopyToClipboard
                  onCopy={this.onLinkCopied}
                  text={rawUrl}>
                  <ClipboardIcon />
                </CopyToClipboard>
              </TooltipMultiLineText>
            </IconButton>
            {['room_owner', 'room_moderator'].includes(this.props.myRole) ? (
              <div className={classes.iconContainer}>
                <IconButton
                  disabled={meeting_expired}
                  onClick={this.props.onInviteClicked}
                >
                  <TooltipMultiLineText
                    placement="top"
                    title={this.props.intl.formatMessage(messages.invitePersonToMeeting)}
                  >
                    <PersonAdd />
                  </TooltipMultiLineText>
                </IconButton>
                {this.props.dialoutEnabled &&
                  <IconButton
                    disabled={meeting_expired}
                    onClick={this.props.onDialinClicked}
                  >
                    <TooltipMultiLineText
                      placement="top"
                      title={this.props.intl.formatMessage(messages.dialPersonToMeeting)}
                    >
                      <Phone />
                    </TooltipMultiLineText>
                  </IconButton>
                }
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  getConfPstnAccessComponent(room) {
    const classes = this.props.classes;
    let pin = this.getPin(room);
    let msg = this.props.intl.formatMessage(
      messages.roomPstnNumber,
      { number: room.number, pin: pin });
    let value = (
      <div className={classes.content}>
        {msg}
      </div>
    );
    if (!(pin && room.number)) {
      value = (
        <div className={classes.content}>
          {this.props.intl.formatMessage(messages.roomPstnNotAvailable)}
        </div>
      );
    }
    return (
      <div>
        <div className={classes.title}>
          {this.props.intl.formatMessage(messages.roomPstnAccess)}
        </div>
        {value}
      </div>
    );
  }

  getPin(room) {
    let pin = room.pin;
    const ownRoom = (room.organizer || {}).username === this.props.myUsername;
    if (room.room_type === 'personal' && ownRoom) {
      // use pin from session, as it is updated in real time (e.g. when
      // user regenerates pins)
      pin = this.props.roomPin;
    }
    return pin;
  }

  getRoomTitle(room) {
    const dname = { user: room.organizer.display };
    if (room.room_type === 'personal') {
      return this.props.intl.formatMessage(messages.titlePersonal, dname);
    }
    else {
      return this.props.intl.formatMessage(messages.titleScheduled, dname);
    }
  }

  getPublishStreamUrl(room) {
    const classes = this.props.classes;
    const rawUrl = room.publish_stream_url;
    let cboardTtipText = this.props.intl.formatMessage(messages.copyToClipboard);
    if (this.state.linkCopied) {
      cboardTtipText = this.props.intl.formatMessage(messages.copiedToClipboard);
    }
    if (rawUrl && this.props.isStreaming) {
      const url = linkify(rawUrl, '_blank', 25);
      return (
        <div>
          <div className={classes.title}>
            {this.props.intl.formatMessage(messages.roomPublishStreamUrl)}
          </div>
          <div className={classes.webAccessContainer}>
            <div className={classNames(classes.content, classes.webAccessContainer)}>
              {url}
            </div>
            <div className={classes.iconContainer}>
              <IconButton>
                <TooltipMultiLineText
                  placement="top"
                  title={cboardTtipText}
                  onClose={this.handleClipboardTooltipClose}
                >
                  <CopyToClipboard
                    onCopy={this.onLinkCopied}
                    text={rawUrl}>
                    <ClipboardIcon />
                  </CopyToClipboard>
                </TooltipMultiLineText>
              </IconButton>
            </div>
          </div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  formatDate(date) {
    const format = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return this.props.intl.formatDate(date, format);
  }

  getScheduling(room) {
    const classes = this.props.classes;
    if (!(room.dt_start && room.dt_end)) {
      return null;
    }
    let today = new Date();
    let start = this.props.intl.formatDate(room.dt_start, { hour: 'numeric', minute: 'numeric' });
    if (!moment(room.dt_start).isSame(today, 'day')) {
      start = this.props.intl.formatDate(room.dt_start, { weekday: 'long', hour: 'numeric', minute: 'numeric' });
    }
    let end = this.props.intl.formatDate(room.dt_end, { weekday: 'long', hour: 'numeric', minute: 'numeric' });
    if (moment(room.dt_start).isSame(room.dt_end, 'day')) {
      end = this.props.intl.formatDate(room.dt_end, { hour: 'numeric', minute: 'numeric' });
    }
    let msg = `${ this.props.intl.formatMessage(messages.from) } `
      + `${ start } ${ this.props.intl.formatMessage(messages.to) } ${ end }`;
    return (
      <div className={classes.title}>
        {this.props.intl.formatMessage(messages.roomScheduling)}
        <div className={classes.content}>
          {capitalize(msg)}
        </div>
      </div>
    );
  }

  getTimer() {
    const { formatMessage } = this.props.intl;
    const { elapsed } = this.props;
    let string = humanizeTime(elapsed, formatMessage, messages);
    const classes = this.props.classes;
    return (
      <div>
        <div className={classes.title}>
          {this.props.intl.formatMessage(messages.meetingDuration)}
        </div>
        <div className={classes.content}>
          {string}
        </div>
      </div>
    );
  }

  render() {
    const classes = this.props.classes;
    const room = this.props.roomDetails;
    const rawUrl = room.rawUrl;
    const url = linkify(rawUrl, '_blank', 25);
    return (
      <div className={classes.roomInfo}>
        {(room.organizer.display) ?
          <Avatar
            displayName={room.organizer.display}
            uid={room.organizer.uid}
          />
          : null}
        <Typography variant='h5' align='center' color='primary'>
          {this.getRoomTitle(room)}
        </Typography>
        <div className={classes.title}>
          {this.props.intl.formatMessage(messages.roomName)}
        </div>
        <div className={classes.content}>
          {room.title}
        </div>
        {this.getConfWebAccessComponent(url, rawUrl, room)}
        {this.getConfPstnAccessComponent(room)}
        {this.getPublishStreamUrl(room)}
        {this.getScheduling(room)}
        {/* this.getMeetingAttendees(room) */}
        {this.getTimer()}
      </div>
    );
  }
}


RoomInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  roomName: PropTypes.string.isRequired,
  myRole: PropTypes.string,
  roomPin: PropTypes.string,
  isStreaming: PropTypes.bool,
  roomDetails: PropTypes.object.isRequired,
  onInviteClicked: PropTypes.func.isRequired,
  onDialinClicked: PropTypes.func.isRequired,
  elapsed: PropTypes.number.isRequired,
  dialoutEnabled: PropTypes.bool,
  myUsername: PropTypes.string,
};


function mapStateToProps(state) {
  const myself = state.room.roster[state.websocket.uid] || {};
  return {
    myRole: myself.role,
    isStreaming: state.room.isStreaming || false,
    roomPin: state.session.roomNumber,
    myUsername: state.session.username,
    elapsed: state.room.elapsed,
    dialoutEnabled: state.room.dialoutEnabled || false,
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(RoomInfo)));
