/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import style from "./style";
/* UI imports */
import Tooltip from '@material-ui/core/Tooltip';

class TooltipMultiLineText extends Component {

  render() {
    const {title, placement, classes, ...rest} = this.props;
    return (
      <Tooltip
        PopperProps={{ style: { pointerEvents: 'none' } }}
        placement={placement}
        classes={{ tooltip: classes.tooltip }}
        title={title}
        {...rest}>
        {this.props.children}
      </Tooltip>
    );
  }

}


TooltipMultiLineText.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};


export default withStyles(style)(TooltipMultiLineText);
