const style = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      zIndex: 1,
      position: 'fixed',
      left: '6vw',
      right: 0,
      top: 0,
      bottom: 0,
      marginRight: '60px',
      height: '100%'
    },
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      height: '100%',
      width: '99%'
    }
  }
});

export default style;
