/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Cast from '@material-ui/icons/Cast';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Actions Imports */
import {
  startLivestreaming,
  stopLivestreaming,
} from 'holocom-client/lib/actions/room';
/* Local Style */
import style from './style';
import { WithAuthorization } from '../../authorization';


const messages = defineMessages({
  tooltipStopLiveStreaming: { id: 'tooltipStopLiveStreaming' },
  tooltipStartLiveStreaming: { id: 'tooltipStartLiveStreaming' },
  cancel: { id: 'cancel' },
});


class LiveStreaming extends Component {
  constructor(props) {
    super(props);
    this.handleStartLiveStreaming = this.handleStartLiveStreaming.bind(this);
    this.handleStopLiveStreaming = this.handleStopLiveStreaming.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleStartLiveStreaming() {
    this.props.dispatch(startLivestreaming({ base_url: window.location.origin }));
  }

  handleStopLiveStreaming() {
    this.props.dispatch(stopLivestreaming());
  }

  handleClose() {
    this.setState({ open: false });
  }

  getStartLivestreamingIcon(classes) {
    const startLivestreamingIcon = (
      <div>
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(messages.tooltipStartLiveStreaming)}
        >
          <div>
            <ListItem
              button
              className={classes.item}
              disabled={this.props.isRequestingStreamingOp}
              onClick={this.handleStartLiveStreaming}
            >
              <ListItemIcon>
                <Cast />
              </ListItemIcon>
            </ListItem>
          </div>
        </TooltipMultiLineText>
      </div>
    );
    return startLivestreamingIcon;
  }

  getStopLivestreamingIcon(classes) {
    const stopLivestreamingIcon = (
      <div>
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(messages.tooltipStopLiveStreaming)}
        >
          <div>
            <ListItem
              button
              className={classes.item}
              disabled={this.props.isRequestingStreamingOp}
              onClick={this.handleStopLiveStreaming}
            >
              <ListItemIcon>
                <Cast className={classes.pulsating} />
              </ListItemIcon>
            </ListItem>
          </div>
        </TooltipMultiLineText>
      </div>
    );
    return stopLivestreamingIcon;
  }

  render() {
    const classes = this.props.classes;

    if (this.props.isLivestreamingEnabled) {
      return (
        <div>
          {
            this.props.isStreaming ?
              this.getStopLivestreamingIcon(classes) :
              this.getStartLivestreamingIcon(classes)
          }
        </div>
      );
    } else {
      return null;
    }
  }
}


LiveStreaming.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  dispatch: PropTypes.func.isRequired,
  isStreaming: PropTypes.bool,
  isLivestreamingEnabled: PropTypes.bool,
  isRequestingStreamingOp: PropTypes.bool,
};


function mapStateToProps(state) {
  return {
    isStreaming: state.room.isStreaming || false,
    isRequestingStreamingOp: state.room.isRequestingStreamingOp || false,
    isLivestreamingEnabled: state.room.livestreamingEnabled || false,
  };
}


const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, withStyles(style)(injectIntl(connect(mapStateToProps)(LiveStreaming))));
