/**
 * @flow
 * @relayHash 82c8555b68042bf7aaf487fcc5835c3e
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteRecordingInput = {|
  recording?: ?RecordingInputDelete
|};
export type RecordingInputDelete = {|
  domainId: string,
  id: string,
  userId: string,
|};
export type deleteRecordingMutationVariables = {|
  input: DeleteRecordingInput
|};
export type deleteRecordingMutationResponse = {|
  +deleteRecording: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +key: string,
      +message: ?$ReadOnlyArray<?string>,
      +reason: ?$ReadOnlyArray<?string>,
    |}>,
    +recording: ?{|
      +id: string,
      +userId: ?string,
      +domainId: ?string,
    |},
  |}
|};
export type deleteRecordingMutation = {|
  variables: deleteRecordingMutationVariables,
  response: deleteRecordingMutationResponse,
|};
*/

/*
mutation deleteRecordingMutation(
  $input: DeleteRecordingInput!
) {
  deleteRecording(input: $input) {
    errors {
      key
      message
      reason
    }
    recording {
      id
      userId
      domainId
    }
  }
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = [{
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteRecordingInput!",
    "defaultValue": null
  }],
      v1 = [{
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteRecording",
    "storageKey": null,
    "args": [{
      "kind": "Variable",
      "name": "input",
      "variableName": "input",
      "type": "DeleteRecordingInput!"
    }],
    "concreteType": "DeleteRecordingPayload",
    "plural": false,
    "selections": [{
      "kind": "LinkedField",
      "alias": null,
      "name": "errors",
      "storageKey": null,
      "args": null,
      "concreteType": "InputError",
      "plural": true,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "key",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "reason",
        "args": null,
        "storageKey": null
      }]
    }, {
      "kind": "LinkedField",
      "alias": null,
      "name": "recording",
      "storageKey": null,
      "args": null,
      "concreteType": "Recording",
      "plural": false,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "userId",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "domainId",
        "args": null,
        "storageKey": null
      }]
    }]
  }];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "deleteRecordingMutation",
      "type": "RootMutationType",
      "metadata": null,
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": v1
      /*: any*/

    },
    "operation": {
      "kind": "Operation",
      "name": "deleteRecordingMutation",
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": v1
      /*: any*/

    },
    "params": {
      "operationKind": "mutation",
      "name": "deleteRecordingMutation",
      "id": null,
      "text": "mutation deleteRecordingMutation(\n  $input: DeleteRecordingInput!\n) {\n  deleteRecording(input: $input) {\n    errors {\n      key\n      message\n      reason\n    }\n    recording {\n      id\n      userId\n      domainId\n    }\n  }\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = '32854592ffaf4097558dc9ec7ac6174d';
module.exports = node;