"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkBrowserSession = checkBrowserSession;
exports.login = login;
exports.logout = logout;
exports.guestLogin = guestLogin;

var _queryString = _interopRequireDefault(require("query-string"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
/* global fetch */
// FIXME: we should take as input parameter a correlation id to send to server
//       (and generate it if not supplied)


function _fetch(url, request) {
  return new Promise(function (resolve, reject) {
    fetch(url, request).then(function (response) {
      if (response.ok) {
        response.json().then(function (res) {
          resolve(res);
        })["catch"](function (err) {
          reject({
            errorMessage: "error decoding json: " + err,
            errorCode: null
          });
        });
      } else {
        reject({
          errorMessage: response.statusText,
          errorCode: response.status
        });
      }
    })["catch"](function (err) {
      reject({
        errorMessage: err,
        errorCode: null
      });
    });
  });
}

function checkBrowserSession() {
  var url = '/api/session/browser_session';
  var request = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return _fetch(url, request);
}

function login(username, password) {
  var url = '/api/session/create';
  var request = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username,
      password: password
    })
  };
  return _fetch(url, request);
}

function logout(token) {
  var url = '/api/session/delete';
  var request = {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  };
  return _fetch(url, request);
}

function guestLogin(displayName) {
  var params = _queryString["default"].stringify({
    display: displayName
  });

  var url = "/api/session/guest?".concat(params);
  var request = {
    method: 'GET',
    credentials: 'omit'
  };
  return _fetch(url, request);
}