const style = {
  avatar: {
    width: "50%",
    height: "auto",
    paddingBottom: "50%",
  },
  userInfoEntryTitle: {
    paddingTop: '20px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: '1em',
  },
  userInfoEntryContent: {
    color: 'rgba(0, 0, 0, 0.90)',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '2em',
  },
  linkContent: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  userInfoIconContainer: {
    display: 'flex',
  },
  userInfoLinkContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};


export default style;
