const style = theme => ({
  content: {
    overflow: 'unset',
  },

  text: {
    marginBottom: theme.spacing.unit,
  }
});

export default style;
