const style = theme => ({
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxWidth: '300px',
    margin: 'auto',
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  videoConferenceIcon: {
    margin: 'auto',
    display: 'flex',
    fontSize: '64px',
    color: '#084f96',
    paddingBottom: theme.spacing.unit * 3,
  },
  dialogContent: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: 0,
    paddingRight: 0,
  },
  dialogActions: {
    paddingTop: theme.spacing.unit * 3,
  },
  joinButton: {
    margin: 'auto'
  },
  loginButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'none',
    fontWeight: 'unset'
  },
  loginMessage: {
    paddingTop: theme.spacing.unit * 6,
  },
  codeInputField: {
    textAlign: 'center'
  }
});

export default style;
