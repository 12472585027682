/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import style from './style';
/* Components Imports */
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';


class ListAdder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      invalidText: false,
    };
  }

  handleAdd = () => {
    const value = this.state.text;
    if (value && !this.state.invalidText) {
      this.props.onAdd(value);
      this.setState({ text: '', invalidText: false });
    }
  }

  handleRemove = (index) => {
    this.props.onRemove(index);
  }

  handleTextChange = (ev) => {
    const value = ev.target.value;
    this.setState({
      text: value,
      invalidText: this.props.validator ? !this.props.validator(value) : false,
    });
  }

  handleKeyUp = (ev) => {
    if ((ev.key === 'Enter') && (this.state.text)) {
      this.handleAdd();
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}>
        <FormControl error={this.state.invalidText}>
          <InputLabel>{this.props.textFieldLabel}</InputLabel>
          <Input
            value={this.state.text}
            onChange={this.handleTextChange}
            onKeyUp={this.handleKeyUp}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={this.handleAdd}
                >
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          {(this.state.invalidText && this.props.invalidMessage) ?
            <FormHelperText>{this.props.invalidMessage}</FormHelperText>
            : null}
        </FormControl>
        <List dense={false} className={classes.list}>
          {this.props.items.map((el, idx) => {
            return (
              <ListItem className={classes.item} key={idx}>
                <ListItemText primary={el}/>
                <ListItemSecondaryAction>
                  <Icon handleRemove={this.handleRemove} index={idx} />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  }
}


class Icon extends Component {
  handleRemove = () => {
    this.props.handleRemove(this.props.index);
  }

  render() {
    return (
      <IconButton onClick={this.handleRemove}>
        <DeleteIcon/>
      </IconButton>
    );
  }

  static propTypes = {
    handleRemove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
  }
}


ListAdder.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  textFieldLabel: PropTypes.string,
  items: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  validator: PropTypes.func,
  invalidMessage: PropTypes.string,
};


export default withStyles(style)(injectIntl(ListAdder));
