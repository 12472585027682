const style = (theme) => ({
  multiLineList: {
    marginTop: theme.spacing.unit,
    marginLeft: theme.spacing.unit
  },
  actions: {
    margin: theme.spacing.unit,
  }
});

export default style;
