import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Send from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Other Imports */
import { WithAuthorization } from '../../authorization';
/* Local Style */
import style from './style';

class ApplyLayoutButton extends Component {

  render() {
    const { classes } = this.props;
    const layout_changed = !this.props.isLayoutChanged;
    const send_class = layout_changed ? null : classes.pulsating;
    return (<div>
      <TooltipMultiLineText
        placement="left"
        title={this.props.title}
      >
        <div>
          <ListItem
            button
            className={classes.item}
            disabled={this.props.isRequestingApplyLayout || !this.props.isLayoutChanged}
            onClick={this.props.onClick}
          >
            <ListItemIcon>
              <Send className={send_class} />
            </ListItemIcon>
          </ListItem>
        </div>
      </TooltipMultiLineText>
    </div>);
  }
}

ApplyLayoutButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isLayoutChanged: PropTypes.bool,
  isRequestingApplyLayout: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func
};

function mapStateToProps(state) {
  return {
    isLayoutChanged: state.room.isLayoutChanged || false,
    isRequestingApplyLayout: state.room.isRequestingApplyLayout || false,
  };
}

const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, withStyles(style)(connect(mapStateToProps)(ApplyLayoutButton)));
