/* Libraries Imports */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
/* UI Imports */
import Typography from '@material-ui/core/Typography';
import EventAvailable from '@material-ui/icons/EventAvailable';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
/* Other Imports */
import { Router } from 'holocom-client/lib/utils';
import style from './styleDetails';


const messages = defineMessages({
  startMeeting: { id: 'startMeeting' },
  endMeeting: { id: 'endMeeting' },
  notes: { id: 'notes' },
  joinButton: { id: 'joinButton' },
  deleteButton: { id: 'deleteButton' },
});


class Details extends Component {
  constructor(props) {
    super(props);
    this.router = new Router();
  }

  handleJoin = () => {
    const path = this.router.getUrlFor(
      this.router.MEETING,
      { slug: this.props.meetingDetails.slug }
    );
    this.props.history.push(path);
  }

  render() {
    if (this.props.meetingDetails) {
      const { classes } = this.props;
      const { title, dtStart, dtEnd, notes } = this.props.meetingDetails;
      return (
        <div className={classes.detailsContainer}>
          <Paper className={classes.paper}>
            <div className={classes.container}>
              <div className={classes.titleContainer}>
                <Grid container spacing={24}>
                  <Grid item>
                    <EventAvailable />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="h6" gutterBottom>
                      {title}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.dtStartContainer}>
                <Typography variant="body2" gutterBottom>
                  {this.props.intl.formatMessage(messages.startMeeting)}
                </Typography>
                {dtStart ? this.props.formatDate(dtStart) : null}
              </div>
              <div className={classes.dtEndContainer}>
                <Typography variant="body2" gutterBottom>
                  {this.props.intl.formatMessage(messages.endMeeting)}
                </Typography>
                {dtEnd ? this.props.formatDate(dtEnd) : null}
              </div>
              {(notes !== null && notes !== undefined) &&
                <div className={classes.notesContainer}>
                  <Typography variant="body2" gutterBottom>
                    {this.props.intl.formatMessage(messages.notes)}
                  </Typography>
                  <div className={classes.notes}>
                    {notes.split("\n").map((element, index) => {
                      return (
                        <p key={index}> {element} </p>
                      );
                    })}
                  </div>
                </div>
              }
            </div>
            <div>
              <Grid container spacing={24}>
                <Grid item xs={3} sm={3}>
                  <Button
                    variant="contained"
                    className={classes.buttonRight}
                    color='secondary'
                    onClick={this.props.onDelete}
                  >
                    {this.props.intl.formatMessage(messages.deleteButton)}
                  </Button>
                </Grid>
                <Grid className={classes.buttonRight} item xs={9} sm={9}>
                  <Button variant="contained" className={classes.button} onClick={this.handleJoin}>
                    {this.props.intl.formatMessage(messages.joinButton)}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      );
    } else {
      return null;
    }
  }
}


Details.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  meetingDetails: PropTypes.object,
  formatDate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};


export default withStyles(style)(injectIntl(Details));
