/* Libraries Imports */
import { createSelectorCreator, defaultMemoize } from 'reselect';
import lodash from 'lodash';

/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

// import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import QuitIcon from '../QuitIcon';
import TooltipMultiLineText from '../TooltipMultiLineText';
import IconButton from '@material-ui/core/IconButton';
import ChangeRoleIconButton from '../VideoToolbar/ChangeRoleIconButton';
import KickIconButton from '../VideoToolbar/KickIconButton';
import FeaturedIconButton from '../VideoToolbar/FeaturedIconButton';
import style from './style';
import { WithAuthorization } from '../../authorization';

import DesktopControlButton from '../OwnScreenShareToolbar/DesktopControlButton';
import CloseScreenShareButton from '../OwnScreenShareToolbar/CloseScreenShareButton';

const messages = defineMessages({
  microphone: {
    disable: { id: 'microphoneButtonEnableTooltip' },
    enable: { id: 'microphoneButtonDisableTooltip' }
  },
  video: {
    disable: { id: 'videoButtonDisableTooltip' },
    enable: { id: 'videoButtonEnableTooltip' }
  },
  leaveIconButton: { id: 'leave' },
  exitFullScreenButton: { id: 'exitFullScreenButton' },
  enterFullScreenButton: { id: 'enterFullScreenButton' },
  desktopControl: {
    disable: { id: 'disableDesktopControl' },
    enable: { id: 'enableDesktopControl' }
  },
  screenShareButtonExtensionTooltip: {
    disable: { id: 'screenShareButtonExtensionTooltipDisable' }
  },
});

class MenuVideoToolbar extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleToggleVideoMute = () => {
    this.props.onToggleVideoMute(this.props.user, !this.props.isVideoMuted);
  }

  handleToggleAudioMute = () => {
    this.props.onToggleAudioMute(this.props.user, !this.props.isAudioMuted);
  }

  handleEnterFullScreen = () => {
    this.props.enterFullScreen(this.props.user, this.props.idx);
  }

  getVideoIconButton(classes) {
    if (!this.props.onToggleVideoMute) {
      return null;
    }
    const icon = (this.props.isVideoMuted
      ? <Videocam className={classNames(classes.videoButton, classes.videoButtonColor)} />
      : <VideocamOff className={classNames(classes.videoButton, classes.videoButtonColor)} />);
    return (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(this.props.isVideoMuted ?
          messages.video.enable
          : messages.video.disable)}>
        <div>
          <IconButton
            disabled={this.props.isRequestingVideoMute}
            onClick={this.handleToggleVideoMute}
          >
            {icon}
          </IconButton>
        </div>
      </TooltipMultiLineText>
    );
  }

  getFullScreenIconButton(classes) {
    if (!(this.props.exitFullScreen && this.props.enterFullScreen)) {
      return null;
    }
    if (this.props.isFullScreen) {
      return (
        <TooltipMultiLineText
          placement="top"
          title={this.props.intl.formatMessage(messages.exitFullScreenButton)}>
          <IconButton onClick={this.props.exitFullScreen}>
            <FullscreenExitIcon className={classNames(classes.videoButton, classes.videoButtonColor)} />
          </IconButton>
        </TooltipMultiLineText>

      );
    }
    else {
      return (
        <TooltipMultiLineText
          placement="top"
          title={this.props.intl.formatMessage(messages.enterFullScreenButton)}>
          <IconButton
            onClick={this.handleEnterFullScreen}
          >
            <FullscreenIcon className={classNames(classes.videoButton, classes.videoButtonColor)} />
          </IconButton>
        </TooltipMultiLineText>
      );
    }
  }

  getAudioIconButton(props, classes) {
    if (isStreamingAScreen(props)) {
      return null;
    }
    const icon = (this.props.isAudioMuted
      ? <Mic className={classNames(classes.videoButton, classes.videoButtonColor)} />
      : <MicOff className={classNames(classes.videoButton, classes.videoButtonColor)} />);
    const elem = (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(this.props.isAudioMuted ?
          messages.microphone.disable
          : messages.microphone.enable)}>
        <div>
          <IconButton
            disabled={this.props.isRequestingAudioMute}
            onClick={this.handleToggleAudioMute}
          >
            {icon}
          </IconButton>
        </div>
      </TooltipMultiLineText>
    );
    if (props.showAudioButton) {
      // override auth if told to do so
      return elem;
    }
    else {
      const Elem = WithAuthorization(['room_owner', 'room_moderator'], () => elem);
      return <Elem />;
    }
  }



  render() {
    const { someoneHasDesktopControl, classes, screenSourceType } = this.props;
    const { anchorEl } = this.state;
    const audioIcon = this.getAudioIconButton(this.props, classes);

    const videoIcon = this.getVideoIconButton(classes);
    const fullscreenIcon = this.getFullScreenIconButton(classes);
    const videoButton = classes.videoButton;
    const canMute = (
      ['room_owner', 'room_moderator'].includes(this.props.myRole)
      || (this.props.myUserId === this.props.user)
    );
    if (!isStreamingAScreen(this.props) && !videoIcon && !fullscreenIcon && !canMute) {
      return null;
    }
    return (
      <div className={classes.dense}>
        <MoreVertIcon
          aria-owns={anchorEl ? 'menu-video-toolbar' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
        </MoreVertIcon>
        <Menu
          id="menu-video-toolbar"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {videoIcon &&
            <ListItem dense classes={{
              dense: classes.dense
            }} button disableGutters>
              <ListItemIcon>
                {videoIcon}
              </ListItemIcon>
            </ListItem>
          }
          {audioIcon &&
            <ListItem dense classes={{
              dense: classes.dense
            }} button disableGutters>
              <ListItemIcon>
                {audioIcon}
              </ListItemIcon>
            </ListItem>
          }
          {fullscreenIcon &&
            <ListItem dense classes={{
              dense: classes.dense
            }} button disableGutters>
              <ListItemIcon>
                {fullscreenIcon}
              </ListItemIcon>
            </ListItem>
          }


          {this.props.onChangeRole &&
            <ListItem dense classes={{
              dense: classes.dense
            }} button disableGutters>
              <ListItemIcon>
                <ChangeRoleIconButton
                  className={videoButton}
                  user={this.props.user}
                  onClick={this.props.onChangeRole}
                />
              </ListItemIcon>
            </ListItem>
          }
          {this.props.onFeaturedStream && (this.props.onToggleVideoMute || this.props.isLocalScreen) &&
            <ListItem dense classes={{
              dense: classes.dense
            }} button disableGutters>
              <ListItemIcon>
                <FeaturedIconButton
                  className={videoButton}
                  user={this.props.user}
                  onClick={this.props.onFeaturedStream}
                />
              </ListItemIcon>
            </ListItem>
          }
          {this.props.isLocalScreen && <ListItem dense classes={{
            dense: classes.dense
          }} button disableGutters>
            <ListItemIcon>
              <CloseScreenShareButton
                className={videoButton}
                isElectron={this.props.isElectron}
                user={this.props.myUserId}
                handleScreenShare={this.props.handleScreenShare}
                enableDesktopControl={this.props.enableDesktopControl}
              />
            </ListItemIcon>
          </ListItem>}
          {this.props.isLocalScreen && this.props.isElectron
              && !someoneHasDesktopControl && screenSourceType !== 'window' &&
            <ListItem dense classes={{
              dense: classes.dense
            }} button disableGutters>
              <ListItemIcon>
                <DesktopControlButton
                  className={videoButton}
                  isDesktopControlEnabled={this.props.isDesktopControlEnabled}
                  isElectron={this.props.isElectron}
                  screenSourceType={screenSourceType}
                  handleScreenShare={this.props.handleScreenShare}
                  enableDesktopControl={this.props.enableDesktopControl}
                  user={this.props.myUserId}
                />
              </ListItemIcon>
            </ListItem>
          }

          {this.props.onKick &&
            <ListItem dense classes={{
              dense: classes.dense
            }} button disableGutters>
              <ListItemIcon>
                <KickIconButton
                  className={videoButton}
                  user={this.props.user}
                  onClick={this.props.onKick}
                />
              </ListItemIcon>
            </ListItem>
          }
          {this.props.onHangup &&
            <ListItem dense classes={{
              dense: classes.dense
            }} button disableGutters>
              <ListItemIcon>
                <TooltipMultiLineText
                  placement="top"
                  title={this.props.intl.formatMessage(messages.leaveIconButton)}>
                  <IconButton onClick={this.props.onHangup}>
                    <QuitIcon className={classes.videoButton} />
                  </IconButton>
                </TooltipMultiLineText>
              </ListItemIcon>
            </ListItem>
          }
        </Menu>
      </div>
    );
  }
}

MenuVideoToolbar.propTypes = {
  idx: PropTypes.number,
  classes: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  myUserId: PropTypes.string.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  exitFullScreen: PropTypes.func,
  enterFullScreen: PropTypes.func,
  onToggleVideoMute: PropTypes.func,
  onToggleAudioMute: PropTypes.func.isRequired,
  showAudioButton: PropTypes.bool,
  isVideoMuted: PropTypes.bool,
  isRequestingVideoMute: PropTypes.bool,
  isAudioMuted: PropTypes.bool,
  isRequestingAudioMute: PropTypes.bool,
  shouldShowAudioButton: PropTypes.bool,
  isLocalScreen: PropTypes.bool,
  someoneHasDesktopControl: PropTypes.bool,
  onHangup: PropTypes.func,
  onKick: PropTypes.func,
  onFeaturedStream: PropTypes.func,
  onChangeRole: PropTypes.func,
  intl: intlShape.isRequired,
  isElectron: PropTypes.bool,
  screenSourceType: PropTypes.string,
  handleScreenShare: PropTypes.func,
  enableDesktopControl: PropTypes.func,
  isDesktopControlEnabled: PropTypes.bool,
  myRole: PropTypes.string,
};

MenuVideoToolbar.defaultProps = {
  showAudioButton: false,
  isLocalScreen: false,
  idx: -1,
};

function getVideoMutedProperty(state, user) {
  if (!user) {
    return false;
  }
  let prop = 'isVideoMuted';
  if (user.endsWith('_screen')) {
    prop = 'isScreenMuted';
  }
  user = user.replace(/_screen$/, '');
  return (state.room.roster[user] || { prop: false })[prop];
}


function isStreamingAScreen(props) {
  if (!props.user) {
    return false;
  }
  return props.user.endsWith('_screen');
}


const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  lodash.isEqual
);


const getMyInfo = (state) => {
  const me = (state.websocket || {}).uid;
  return state.room.roster[me];
};


const getMyRole = createDeepEqualSelector(getMyInfo, (u) => u.role || '');


function mapStateToProps(state, props) {
  const userId = (props.user || "").replace(/_screen$/, '');
  const user = state.room.roster[userId] || {};
  return {
    myRole: getMyRole(state),
    isVideoMuted: getVideoMutedProperty(state, props.user),
    isAudioMuted: (state.room.roster[props.user] || { isAudioMuted: false }).isAudioMuted,
    isDesktopControlEnabled: (user || { isDesktopControlEnabled: false }).isDesktopControlEnabled,
    isRequestingVideoMute: (state.room.roster[props.user] || { isRequestingVideoMute: false }).isRequestingVideoMute,
    isRequestingAudioMute: (state.room.roster[props.user] || { isRequestingAudioMute: false }).isRequestingAudioMute,

    shouldShowAudioButton: (
      props.showAudioButton || (!isStreamingAScreen(props))
    )
  };
}

export default withStyles(style)(injectIntl(connect(mapStateToProps)(MenuVideoToolbar)));
