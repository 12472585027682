/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { TextField, DialogTitle } from '@material-ui/core';
/* Other Imports */
import { Router } from 'holocom-client/lib/utils';
/* Local Style */
import style from './style';

const messages = defineMessages({
  insertMeetingCode: { id: 'insertMeetingCode', },
  joinButton: { id: 'joinButton' },
  joinByCode: { id: 'joinByCode' },
  cancel: { id: 'cancel' }
});

class MeetingByCodeDialog extends Component {
  constructor(props) {
    super(props);
    this.router = new Router();
    this.state = {
      meeting_id: '',
      submitEnabled: false,
    };
  }

  handleJoinMeeting() {
    const path = this.router.getUrlFor(this.router.MEETING, { slug: this.state.meeting_id });
    this.props.history.push(path);
  }

  handleMeetingIdChange = (ev) => {
    this.setState({
      meeting_id: ev.target.value,
      submitEnabled: ev.target.value.length > 0,
    });
  }

  handleKeyUp = (ev) => {
    if ((ev.key === 'Enter') && (this.state.submitEnabled)) {
      this.handleJoinMeeting();
    }
  }

  handleJoinButton = () => {
    this.handleJoinMeeting();
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.onClose}>
          <DialogTitle>{this.props.intl.formatMessage(messages.joinByCode)}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.intl.formatMessage(messages.insertMeetingCode)}
            </DialogContentText>
            <TextField
              inputProps={{ autoCapitalize: 'off', }}
              autoFocus={true}
              onChange={this.handleMeetingIdChange}
              onKeyUp={this.handleKeyUp}
              fullWidth={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
              {this.props.intl.formatMessage(messages.cancel)}
            </Button>
            <Button
              size="large"
              disabled={!this.state.submitEnabled}
              onClick={this.handleJoinButton}
              variant="contained" color="primary">
              {this.props.intl.formatMessage(messages.joinButton)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MeetingByCodeDialog.propTypes = {
  intl: intlShape.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(style)(injectIntl(MeetingByCodeDialog));
