import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import style from './style';


const messages = defineMessages({
  cancel: {id: 'cancel'},
  ok: {id: 'ok'},
  title: {id: 'screenSharingDialogTitle'},
  content: {id: 'screenSharingDialogContent'},
  screen: {id: 'screenSharingDialogScreen'},
  window: {id: 'screenSharingDialogWindow'},
  errorTitle: {id: 'screenSharingErrorTitle'},
  errorContent: {id: 'screenSharingErrorContent'},
});


class ScreenSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDialogOpen: this.props.deviceError,
    };
  }

  handleShareButton(what) {
    this.setState({errorDialogOpen: false});
    this.props.onShareScreen(what);
  }

  handleWindowShareButton = () => {
    this.handleShareButton('window');
  }

  handleScreenShareButton = () => {
    this.handleShareButton('screen');
  }

  closeErrorDialog = () => {
    this.setState({errorDialogOpen: false});
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.deviceError && this.props.deviceError) {
      this.setState({errorDialogOpen: true});
    }
  }

  renderError() {
    return (
      <Dialog
        open={this.state.errorDialogOpen}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.errorTitle)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.intl.formatMessage(messages.errorContent)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.closeErrorDialog}
            variant="contained"
            color="primary"
          >
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderSelector() {
    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.intl.formatMessage(messages.content)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel}>
            {this.props.intl.formatMessage(messages.cancel)}
          </Button>
          <Button
            onClick={this.handleScreenShareButton}
            variant="contained"
            color="primary"
          >
            {this.props.intl.formatMessage(messages.screen)}
          </Button>
          <Button
            onClick={this.handleWindowShareButton}
            variant="contained"
            color="primary"
          >
            {this.props.intl.formatMessage(messages.window)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    if (this.state.errorDialogOpen) {
      return this.renderError();
    }
    else {
      return this.renderSelector();
    }
  }

}


ScreenSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  onShareScreen: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  deviceError: PropTypes.string,
};


function mapStateToProps(state) {
  return {
    deviceError: state.room.screenError,
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(ScreenSelector)));
