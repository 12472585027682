/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
/* Other Imports */
import { linkify } from 'holocom-client/lib/utils';


export const styles = {
  container: {
    flex: '1 1 auto',
    padding: '0 16px',
    width: 'calc(100% - 64px)',
  },
  displayNameText: {
    fontVariant: 'small-caps',
    textTransform: 'capitalize',
    fontSize: 'small',
  },
  messageText: {
    wordWrap: 'break-word'
  },
};


class ChatMessage extends Component {
  getMessage(text, index, classes) {
    const parsed = linkify(text, '_blank', 25);
    return (
      <Typography
        variant="subtitle1"
        className={classes.messageText}
        key={index}
      >
        {parsed}
      </Typography>
    );
  }

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}>
        <Typography
          color="textSecondary"
          variant="body2"
          className={classes.displayNameText}
        >
          {this.props.displayName}
        </Typography>
        {this.props.messages.map((message, idx) => {
          return this.getMessage(message, idx, classes);
        })}
      </div>
    );
  }
}


ChatMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  displayName: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
};


export default withStyles(styles)(ChatMessage);
