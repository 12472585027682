/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import URI from 'urijs';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PermIdentity from '@material-ui/icons/PermIdentity';
import VpnKey from '@material-ui/icons/VpnKey';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Dialog from '@material-ui/core/Dialog';
import style from './style';
/* Components Imports */
import VideoConferenceIcon from '../../style/VideoConferenceIcon';
/* Actions Imports */
import { loginUser } from 'holocom-client/lib/actions/auth';
/* Other Imports */
import { Router } from 'holocom-client/lib/utils';


const messages = defineMessages({
  ok: { id: 'ok' },
  cancel: { id: 'cancel' },
  username: { id: 'username' },
  password: { id: 'password' },
  disconnectedMessage: { id: 'disconnectedMessage' },
  errorLoginCode401: { id: 'errorLoginCode401' }
});


class StandardLogin extends Component {
  constructor(props) {
    super(props);
    this.router = new Router();
    this.state = {
      username: '',
      password: '',
      submitEnabled: false,
      error: false,
      errorType: null,
      showPassword: false,
    };
  }

  componentDidUpdate(prevProps) {
    const errorCode = this.props.errorCode;
    if (errorCode && errorCode !== prevProps.errorCode) {
      if (errorCode === 401) {
        this.setState({ error: true, password: '', errorCode: errorCode });
      } else {
        this.setState({ error: true, errorCode: errorCode });
      }
    }
  }

  handleLogin = () => {
    const creds = {
      username: this.state.username,
      password: this.state.password
    };
    this.props.dispatch(loginUser(creds, this.props.localStore));
  }

  handleCancel = () => {
    const path = this.router.getUrlFor(this.router.HOME);
    this.props.history.push(path);
  }

  handleUsernameChange = (ev) => {
    this.setState({
      username: ev.target.value,
      submitEnabled: ev.target.value && this.state.password,
    });
  }

  handlePasswordChange = (ev) => {
    this.setState({
      password: ev.target.value,
      submitEnabled: ev.target.value && this.state.username,
    });
  }

  handleKeyUp = (ev) => {
    if ((ev.key === 'Enter') && (this.state.submitEnabled)) {
      this.handleLogin();
    }
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    if (this.props.isAuthenticated) {
      let redirectTo = this.router.getUrlFor(this.router.HOME);
      const qs = new URI(this.props.history.location.search);
      const goTo = qs.search(true).redirectTo;
      if (goTo) {
        redirectTo = this.router.getUrlFor(this.router.MEETING, { slug: goTo });
      }
      return (
        <Redirect to={{
          pathname: redirectTo
        }} />
      );
    }
    const classes = this.props.classes;
    return (
      <Dialog className={classes.holocomDialogForm}
        type='form'
        hideBackdrop
        open
        fullScreen={this.props.fullScreen}>
        <VideoConferenceIcon className={classes.videoConferenceIcon} />
        <form className={classes.container} noValidate>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar><PermIdentity /></Avatar>
              </ListItemAvatar>
              <FormControl className={classes.formControl} fullWidth={true}>
                <Input
                  autoFocus={true}
                  className={classes.textField}
                  placeholder={this.props.intl.formatMessage(messages.username)}
                  onChange={this.handleUsernameChange}
                  onKeyUp={this.handleKeyUp}
                  error={this.state.error}
                />
                {this.state.error && (this.state.errorCode === 401) &&
                  <FormHelperText className={classes.formHelperText} error>
                    {this.props.intl.formatMessage(messages.errorLoginCode401)}
                  </FormHelperText>
                }
              </FormControl>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar><VpnKey /></Avatar>
              </ListItemAvatar>
              <FormControl className={classes.formControl} fullWidth={true}>
                <Input
                  className={classes.textField}
                  type={this.state.showPassword ? 'text' : 'password'}
                  placeholder={this.props.intl.formatMessage(messages.password)}
                  onChange={this.handlePasswordChange}
                  onKeyUp={this.handleKeyUp}
                  error={this.state.error}
                  value={this.state.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.handleClickShowPasssword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </ListItem>
            <ListItem className={classes.loginActions}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={this.handleCancel}
              >
                {this.props.intl.formatMessage(messages.cancel)}
              </Button>
              <Button
                disabled={!this.state.submitEnabled}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleLogin}
              >
                {this.props.intl.formatMessage(messages.ok)}
              </Button>
            </ListItem>
          </List>
        </form>
      </Dialog>
    );
  }
}


StandardLogin.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  localStore: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  errorCode: PropTypes.number,
  fullScreen: PropTypes.bool.isRequired
};


function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    errorCode: state.auth.errorCode
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(
  withMobileDialog({ breakpoint: 'sm' })(StandardLogin))));
