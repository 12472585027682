"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLivestreaming = updateLivestreaming;
exports.updateProfile = updateProfile;

var _reactRelay = require("react-relay");

var mutation = function mutation() {
  var node = require("./__generated__/updateProfileMutation.graphql");

  if (node.hash && node.hash !== "05e7947e8e8ad064ff4915eb7a53a48b") {
    console.error("The definition of 'updateProfileMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/updateProfileMutation.graphql");
};

function updateLivestreaming(environment, _ref) {
  var apiKey = _ref.apiKey,
      apiUrl = _ref.apiUrl,
      publishUrl = _ref.publishUrl;
  var variables = {
    input: {
      profile: {
        meta: {
          livestreaming: {
            apiKey: apiKey,
            apiUrl: apiUrl,
            publishUrl: publishUrl
          }
        }
      }
    }
  };
  return updateProfile(environment, variables);
}

function updateProfile(environment, variables) {
  return new Promise(function (resolve, reject) {
    (0, _reactRelay.commitMutation)(environment, {
      mutation: mutation,
      variables: variables,
      onCompleted: function onCompleted(_ref2, otherErrors) {
        var _ref2$updateProfile = _ref2.updateProfile,
            errors = _ref2$updateProfile.errors,
            pins = _ref2$updateProfile.pins;

        if (otherErrors) {
          return reject(otherErrors);
        } else if (errors) {
          return reject(errors);
        }

        return resolve(pins);
      },
      onError: function onError(err) {
        reject(err);
      }
    });
  });
}