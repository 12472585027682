/**
 * @flow
 * @relayHash 3a6e5a6b16ba53a65dfadafd13fa341f
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteMeetingInput = {|
  meeting?: ?MeetingInputDelete
|};
export type MeetingInputDelete = {|
  doNotify?: ?boolean,
  domainId: string,
  id: string,
  userId: string,
|};
export type deleteMeetingMutationVariables = {|
  input: DeleteMeetingInput
|};
export type deleteMeetingMutationResponse = {|
  +deleteMeeting: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +key: string,
      +message: ?$ReadOnlyArray<?string>,
      +reason: ?$ReadOnlyArray<?string>,
    |}>,
    +meeting: ?{|
      +id: string,
      +userId: ?string,
      +domainId: ?string,
    |},
  |}
|};
export type deleteMeetingMutation = {|
  variables: deleteMeetingMutationVariables,
  response: deleteMeetingMutationResponse,
|};
*/

/*
mutation deleteMeetingMutation(
  $input: DeleteMeetingInput!
) {
  deleteMeeting(input: $input) {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
    }
  }
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = [{
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteMeetingInput!",
    "defaultValue": null
  }],
      v1 = [{
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteMeeting",
    "storageKey": null,
    "args": [{
      "kind": "Variable",
      "name": "input",
      "variableName": "input",
      "type": "DeleteMeetingInput!"
    }],
    "concreteType": "DeleteMeetingPayload",
    "plural": false,
    "selections": [{
      "kind": "LinkedField",
      "alias": null,
      "name": "errors",
      "storageKey": null,
      "args": null,
      "concreteType": "InputError",
      "plural": true,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "key",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "reason",
        "args": null,
        "storageKey": null
      }]
    }, {
      "kind": "LinkedField",
      "alias": null,
      "name": "meeting",
      "storageKey": null,
      "args": null,
      "concreteType": "Meeting",
      "plural": false,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "userId",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "domainId",
        "args": null,
        "storageKey": null
      }]
    }]
  }];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "deleteMeetingMutation",
      "type": "RootMutationType",
      "metadata": null,
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": v1
      /*: any*/

    },
    "operation": {
      "kind": "Operation",
      "name": "deleteMeetingMutation",
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": v1
      /*: any*/

    },
    "params": {
      "operationKind": "mutation",
      "name": "deleteMeetingMutation",
      "id": null,
      "text": "mutation deleteMeetingMutation(\n  $input: DeleteMeetingInput!\n) {\n  deleteMeeting(input: $input) {\n    errors {\n      key\n      message\n      reason\n    }\n    meeting {\n      id\n      userId\n      domainId\n    }\n  }\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = '97e3fd45e6d02d6feefea85ee20a44eb';
module.exports = node;