/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import style from './style';
/* Components Imports */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import ListAdder from '../ListAdder';
/* Other imports */
import { isEmail } from 'holocom-client/lib/utils';


const messages = defineMessages({
  ok: {id: 'ok'},
  cancel: {id: 'cancel'},
  email: {id: 'email'},
  title: {id: 'inviteNewParticipantDialogTitle'},
  content: {id: 'inviteNewParticipantDialogContent'},
  emailError: {id: 'inviteNewParticipantDialogValidationError'},
});


class InviteToMeetingDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  onItemAdd = (item) => {
    const newItems = [item, ...this.state.items];
    this.setState({items: newItems});
  }

  onItemRemove = (index) => {
    const newItems = [...this.state.items];
    newItems.splice(index, 1);
    this.setState({items: newItems});
  }

  emailValidator = (email) => {
    return isEmail(email) || email.length === 0;
  }

  onInvite = () => {
    this.props.onInvite(this.state.items);
  }

  render() {
    const classes = this.props.classes;
    const fullScreen = this.props.fullScreen;
    return (
      <Dialog
        open={this.props.open}
        fullScreen={fullScreen}
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText className={classes.text}>
            {this.props.intl.formatMessage(messages.content)}
          </DialogContentText>
          <ListAdder
            items={this.state.items}
            textFieldLabel={this.props.intl.formatMessage(messages.email)}
            onAdd={this.onItemAdd}
            onRemove={this.onItemRemove}
            invalidMessage={this.props.intl.formatMessage(messages.emailError)}
            validator={this.emailValidator}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            {this.props.intl.formatMessage(messages.cancel)}
          </Button>
          <Button
            variant="contained"
            disabled={this.state.items.length === 0}
            onClick={this.onInvite} color="primary"
          >
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


InviteToMeetingDialog.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};


export default withStyles(style)(injectIntl(withMobileDialog()(InviteToMeetingDialog)));
