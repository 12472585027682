import { getLogger } from 'holocom-client/lib/logger';


class PiP {
  // Picture in Picture implementation
  constructor(videoEl) {
    this.video = videoEl;
    this.logger = getLogger("PiP api");
  }

  static isAvailable() {
    return document.pictureInPictureEnabled;
  }

  static isEnabled() {
    return !!document.pictureInPictureElement;
  }

  set(enabled) {
    if (PiP.isAvailable()) {
      if (enabled) {
        if (PiP.isEnabled) {
          this._exitPip().then(() => this._requestPip());
        }
        else {
          this._requestPip();
        }
      }
      else {
        this._exitPip();
      }
    }
  }

  _requestPip() {
    return this.video.requestPictureInPicture()
      .catch((e) => {
        this.logger.error(`error requesting picture-in-picture: ${e}`);
      });
  }

  _exitPip() {
    if (PiP.isEnabled()) {
      return document.exitPictureInPicture()
        .catch((e) => {
          this.logger.error(`error exiting picture-in-picture: ${e}`);
        });
    }
    else {
      return Promise.resolve(null);
    }
  }

  addListeners(enterFun, leaveFun) {
    this.video.addEventListener('enterpictureinpicture', enterFun);
    this.video.addEventListener('leavepictureinpicture', leaveFun);
  }

  removeListeners(enterFun, leaveFun) {
    this.video.removeEventListener('enterpictureinpicture', enterFun);
    this.video.removeEventListener('leavepictureinpicture', leaveFun);
  }
}


export default PiP;
