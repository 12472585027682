"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.regeneratePins = regeneratePins;
exports.updateLivestreaming = updateLivestreaming;

var _renewMyPins = require("../api/relay/renewMyPins");

var _updateProfile = require("../api/relay/updateProfile");

var _notifications = require("../notifications");

var _sessionDetails = require("../api/relay/sessionDetails");

var _session = require("./session");

var _logger = require("../logger");

function maybeNotifyRegenPinsError() {
  (0, _notifications.newEvent)(_notifications.ERROR, 'regeneratePinsGenError', 'regeneratePinsGenError', "Error while updating pins.");
}

function notifyUpdateProfileError() {
  (0, _notifications.newEvent)(_notifications.ERROR, 'updateProfileError', 'updateProfileError', "Error while updating profile settings.");
}

function notifyUpdateProfileSuccess() {
  (0, _notifications.newEvent)(_notifications.INFO, 'updateProfileSuccess', 'updateProfileSuccess', "Profile saved successfully!");
}

function regeneratePins(relay) {
  return function (dispatch) {
    (0, _renewMyPins.renewMyPins)(relay).then(function (_res) {
      dispatch((0, _session.fetchSessionDetails)(relay, _sessionDetails.sessionDetails));
    })["catch"](function (_err) {
      maybeNotifyRegenPinsError();
    });
  };
}

function updateLivestreaming(relay, params) {
  var logger = (0, _logger.getLogger)('updateLivestreaming');
  return function (_dispatch) {
    (0, _updateProfile.updateLivestreaming)(relay, params).then(function (_res) {
      notifyUpdateProfileSuccess();
    })["catch"](function (err) {
      logger.error("GQL Api Error", err);
      notifyUpdateProfileError();
    });
  };
}