/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import style from './style';
/* Components Imports */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
/* Other imports */
import { updateLivestreaming } from 'holocom-client/lib/actions/user';


const messages = defineMessages({
  cancel: { id: 'cancel' },
  save: { id: 'save' },
  streamTitle: { id: 'liveStreamingCmpTitle' },
  apiKey: { id: 'liveStreamingCmpApiKey' },
  apiUrl: { id: 'liveStreamingCmpApiUrl' },
  pubUrl: { id: 'liveStreamingCmpPubUrl' },
  showApiKey: { id: 'liveStreamingCmpShowApiKey' },
});

class Livestreaming extends Component {


  constructor(props) {
    super(props);

    this.state = Livestreaming.initState(props);
  }

  static getDefaultState() {
    return {
      showKey: false,
      apiUrlValid: false,
      apiKeyValid: false,
      publishUrlValid: false,
      apiUrl: "",
      apiKey: "",
      publishUrl: ""
    };
  }

  static initState(props) {
    if (props.gqlProps === null) {
      return Livestreaming.getDefaultState();
    }

    const { myProfile: { meta } } = props.gqlProps;
    const livestreaming = (meta || {}).livestreaming;
    if (!livestreaming) {
      return Livestreaming.getDefaultState();
    }

    return {
      showKey: false,
      apiUrlValid: Livestreaming.apiUrlIsValid(livestreaming.apiUrl),
      apiKeyValid: Livestreaming.apiKeyIsValid(livestreaming.apiKey),
      publishUrlValid: Livestreaming.publishUrlIsValid(livestreaming.publishUrl),
      apiUrl: livestreaming.apiUrl,
      apiKey: livestreaming.apiKey,
      publishUrl: livestreaming.publishUrl
    };
  }

  static apiKeyIsValid(value) {
    if (value) {
      return true;
    }
    return false;
  }

  static apiUrlIsValid(value) {
    if (value) {
      return true;
    }
    return false;
  }

  static publishUrlIsValid(value) {
    if (value) {
      return true;
    }
    return false;
  }

  static isValid(name, value) {
    switch (name) {
      case 'apiKey':
        return Livestreaming.apiKeyIsValid(value);
      case 'apiUrl':
        return Livestreaming.apiUrlIsValid(value);
      case 'publishUrl':
        return Livestreaming.publishUrlIsValid(value);
      default:
        return false;
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
      [`${ name }Valid`]: Livestreaming.isValid(name, event.target.value)
    });
  };

  handleClickShowKey = () => {
    this.setState(state => ({ showKey: !state.showKey }));
  };

  onLvsSave = () => {
    const vars = {
      apiUrl: this.state.apiUrl,
      apiKey: this.state.apiKey,
      publishUrl: this.state.publishUrl
    };

    this.props.dispatch(updateLivestreaming(this.props.relayEnvironment, vars));
  }

  canSave = () => {
    if (this.state.apiUrlValid && this.state.apiKeyValid && this.state.publishUrlValid) {
      return true;
    }
    return false;
  }

  render() {
    const classes = this.props.classes;

    return (
      <React.Fragment>
        <DialogTitle>
          {this.props.intl.formatMessage(messages.streamTitle)}
        </DialogTitle>
        <DialogContent>
          <form className={classes.container} noValidate autoComplete="off">
            <List>
              <ListItem>
                <TextField
                  error={this.state.apiUrlValid ? false : true}
                  required
                  label={this.props.intl.formatMessage(messages.apiUrl)}
                  className={classes.textField}
                  fullWidth
                  value={this.state.apiUrl}
                  onChange={this.handleChange('apiUrl')}
                />
              </ListItem>
              <ListItem>
                <TextField
                  error={this.state.apiKeyValid ? false : true}
                  required
                  label={this.props.intl.formatMessage(messages.apiKey)}
                  className={classes.textField}
                  type={this.state.showKey ? 'text' : 'password'}
                  fullWidth
                  value={this.state.apiKey}
                  onChange={this.handleChange('apiKey')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant="filled" position="end">
                        <IconButton
                          aria-label={this.props.intl.formatMessage(messages.showApiKey)}
                          onClick={this.handleClickShowKey}
                        >
                          {this.state.showKey ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
              <ListItem>
                <TextField
                  error={this.state.publishUrlValid ? false : true}
                  required
                  label={this.props.intl.formatMessage(messages.pubUrl)}
                  className={classes.textField}
                  fullWidth
                  value={this.state.publishUrl}
                  onChange={this.handleChange('publishUrl')}
                />
              </ListItem>
            </List>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>
            {this.props.intl.formatMessage(messages.cancel)}
          </Button>
          <Button onClick={this.onLvsSave} disabled={!this.canSave()} color="primary">
            {this.props.intl.formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}


function mapStateToProps(_state) {
  return {};
}


Livestreaming.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  relayEnvironment: PropTypes.object.isRequired,
  gqlError: PropTypes.object,
  gqlProps: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};


export default withStyles(style)(injectIntl(connect(mapStateToProps)(Livestreaming)));
