/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import en from 'react-intl/locale-data/en';
import it from 'react-intl/locale-data/it';
import { statsStart } from 'holocom-client/lib/callstats';
/* UI Imports */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import localeData from 'holocom-client-l10n';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
/* Components Imports */
import NavigationBar from './components/NavigationBar';
import NotificationArea from './components/NotificationArea';
import MediaPermissions from './components/MediaPermissions';
import BrowserCheck from './components/BrowserCheck';
import DeviceSelector from './components/DeviceSelector';
import ScreenSelector from './components/ScreenSelector';
import ScreensThumbnails from './components/ScreensThumbnails';
import DeviceError from './components/DeviceError';
import Spinner from './components/Spinner';
/* Actions Imports */
import {
  connectSocket,
  disconnectSocket,
} from 'holocom-client/lib/actions/websocket';
import { checkValidateSession } from 'holocom-client/lib/actions/auth';
import {
  requestScreenSharing,
  stopScreenSharing,
  setShareScreens,
  screenSuccess,
} from 'holocom-client/lib/actions/room';
import { runInProduction } from 'holocom-client/lib/utils/environment';
import { setIsElectron } from 'holocom-client/lib/actions/settings';
import { fetchSessionDetails } from 'holocom-client/lib/actions/session';
import { checkExtension } from 'holocom-client/lib/actions/room';
import { setSyncToken } from 'holocom-client/lib/actions/auth';
/* Other Imports */
import prepareWebRtcProvider from './rtc';
import { getEnvironment } from './relay/';
import { session } from 'holocom-client/lib/api/relay/session';
import { sessionDetails } from 'holocom-client/lib/api/relay/sessionDetails';
import { getLogger } from 'holocom-client/lib/logger';
import * as Logger from 'holocom-client/lib/logger';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faMousePointer } from '@fortawesome/free-solid-svg-icons';
/* Local Style */
import './App.css';

// library.add(faMousePointer);

class App extends Component {
  constructor(props) {
    super(props);

    // install global var for access from the browser's js console
    window.HoloCom = {
      Logger: Logger,
      App: this,
    };

    this.apiLogger = getLogger('Api');

    const isElectron = (typeof navigator === 'object' &&
      typeof navigator.userAgent === 'string' &&
      navigator.userAgent.indexOf('Electron') >= 0) ||
      (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) ||
      (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer');

    this.props.dispatch(setIsElectron(isElectron));
    this.webrtc = prepareWebRtcProvider(isElectron);
    this.state = {
      screenSelectorOpen: false,
      screensThumbnailsOpen: false,
      browser: this.webrtc.adapter.browserDetails.browser,
      browserVersion: this.webrtc.adapter.browserDetails.version,
      parsedUserAgent: this.webrtc.adapter.browserDetails.parsedUserAgent,
      browserChecked: false,
      relayEnvironment: {},
    };

    this.theme = createMuiTheme({
      typography: {
        useNextVariants: true,
      }
    });
    this.messages = this.getMessages();

    this.onBrowserCheckPassed = this.onBrowserCheckPassed.bind(this);
    this.closeScreenSelector = this.closeScreenSelector.bind(this);
    this.startScreenSharing = this.startScreenSharing.bind(this);
    this.stopScreenSharing = this.stopScreenSharing.bind(this);
    this.openScreenSelectorIfNeeded = this.openScreenSelectorIfNeeded.bind(this);

    this._checkAuth(this.state);
  }

  getMessages() {
    addLocaleData([...en, ...it]);
    return localeData[this.props.lang];
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.isAuthenticatedAsGuest && this.props.isAuthenticated) {
      this._disconnectWebsocket(this.props.dispatch);
    }
    if (this._shouldConnectWebsocket(this.props)) {
      this._tryToConnectWebsocket(this.props.dispatch, this.props.authToken);
    }
    else if (this._shouldDisconnectWebsocket(this.props, prevProps)) {
      this._disconnectWebsocket(this.props.dispatch);
    }

    const authenticated = (!prevProps.isAuthenticated && this.props.isAuthenticated);
    const guest = (!prevProps.isAuthenticatedAsGuest && this.props.isAuthenticatedAsGuest);

    if (authenticated || guest) {
      const relay = getEnvironment(this.props.authToken);
      this.setState({ relayEnvironment: relay });
      prevProps.dispatch(fetchSessionDetails(relay, sessionDetails));
    }

    if (this.props.callStatsConfig && this.props.sessionReady && !prevProps.sessionReady) {
      const appId = this.props.callStatsConfig.appId;
      const appSecret = this.props.callStatsConfig.appSecret;
      const endpoint = this.props.callStatsConfig.endpoint;
      if (appId && appSecret && endpoint) {
        this.apiLogger.info('Call stats data ready, starting background reporting');
        statsStart(appId, appSecret, endpoint, this.webrtc.adapter.browserDetails.parsedUserAgent);
      }
    }
  }

  _checkAuth(state) {
    if (this.props.authToken) {
      const relay = getEnvironment(this.props.authToken);
      state.relayEnvironment = relay;
      checkValidateSession(relay, session, this.props.dispatch, this.props.authToken, this.props.localStore);
    }
    window.addEventListener('storage', (event) => {
      if (event.key === 'HoloCom.authToken') {
        if (event.newValue === null) {
          setSyncToken(this.props.dispatch, event.newValue);
        } else {
          const relay = getEnvironment(event.newValue);
          state.relayEnvironment = relay;
          setSyncToken(this.props.dispatch, event.newValue);
          checkValidateSession(relay, session, this.props.dispatch, event.newValue, this.props.localStore);
        }
      }
    });

  }

  componentDidMount() {
    if (this.state.browser === 'chrome') {
      this.checkExtensionisInstalled();
    }

    // attach the beforeunload listener before we instantiate the phoenix
    // websocket, since that library also adds a listener and we need to stop
    // propagation if the user clicks on cancel
    runInProduction(() => { window.addEventListener("beforeunload", this.onUnload); }, true);
    if (this._shouldConnectWebsocket(this.props)) {
      this._tryToConnectWebsocket(this.props.dispatch, this.props.authToken);
    }
    else if (this._shouldDisconnectWebsocket(this.props, null)) {
      this._disconnectWebsocket(this.props.dispatch);
    }
  }

  _shouldConnectWebsocket(props) {
    if (props.socketConnecting || props.socketConnected) {
      return false;
    }

    const authed = props.isAuthenticated && props.authToken;
    const guestAuthed = props.isAuthenticatedAsGuest && props.authToken;
    const go = (authed || guestAuthed) && props.sessionReady;
    return go;
  }

  _shouldDisconnectWebsocket(props, lastProps) {
    if (!lastProps) {
      lastProps = {
        isAuthenticated: true,
        isAuthenticatedAsGuest: true,
      };
    }
    const authed = (!props.isAuthenticated && lastProps.isAuthenticated);
    const guestAuthed = (props.isAuthenticatedAsGuest && !lastProps.isAuthenticatedAsGuest);
    return authed || guestAuthed;
  }

  _tryToConnectWebsocket(dispatch, token) {
    const mouseEvent = window.mouseEvent;
    dispatch(connectSocket(token, this.apiLogger, mouseEvent));
  }

  _disconnectWebsocket(dispatch) {
    dispatch(disconnectSocket());
  }

  onUnload = (event) => {
    if (this.props.roomJoined) {
      event.stopImmediatePropagation();
      const alert = 'alert';
      event.returnValue = alert;
      return alert;
    }
  }

  checkExtensionisInstalled() {
    window.postMessage({ type: 'ping' }, '*');
    window.addEventListener("message", (msg) => {
      if (msg.data.type === 'holocomExtensionLoaded') {
        checkExtension(this.props.dispatch);
      }
    });
  }

  openScreenSelectorIfNeeded() {
    if (this.state.browser === 'firefox' || this.props.isElectron) {
      this.setState({ screenSelectorOpen: true });
    } else if (this.state.browser === 'chrome') {
      this.startScreenSharing();
    }
  }

  closeScreenSelector = () => {
    this.setState({ screenSelectorOpen: false });
  }

  closeScreenThumnailsSelector = () => {
    this.setState({ screensThumbnailsOpen: false });
    setTimeout(() => {
      this.props.dispatch(setShareScreens([], ''));
    }, 100);

  }

  stopScreenSharing() {
    this.props.dispatch(stopScreenSharing(this.webrtc));
  }

  startScreenSharing(what) {

    if (this.props.isElectron) {
      const _this = this;
      this.setState({ screenSelectorOpen: false, screensThumbnailsOpen: true });
      window.desktopCapturer.getSources({
        types: ['window', 'screen'],
        thumbnailSize: { width: 200, height: 200 }
      }, (err, sources) => {

        _this.props.dispatch(setShareScreens(sources, what));

      });


    } else {

      this.props.dispatch(requestScreenSharing(what, this.webrtc));
      this.setState({ screenSelectorOpen: false });
    }

  }

  selectScreen = (screen, screenType) => {
    this.props.dispatch(screenSuccess(screen.id, screenType));
    this.closeScreenThumnailsSelector();
  }

  onBrowserCheckPassed() {
    this.setState({ browserChecked: true });
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider locale={this.props.lang} messages={this.messages}>
          <MuiThemeProvider theme={this.theme}>
            <BrowserCheck
              browser={this.state.browser}
              browserVersion={this.state.browserVersion}
              browserUserAgent={this.state.parsedUserAgent}
              onCheckSuccess={this.onBrowserCheckPassed}
            />
            <BrowserRouter>
              {this.state.browserChecked &&
                <div id='container'>
                  {this.props.isFetching || this.props.relayIsFetching ? <Spinner /> : null}
                  <DeviceSelector
                    dispatch={this.props.dispatch}
                    localStore={this.props.localStore}
                  />
                  <ScreenSelector
                    open={this.state.screenSelectorOpen}
                    onCancel={this.closeScreenSelector}
                    onShareScreen={this.startScreenSharing}
                    onStopScreen={this.stopScreenSharing}
                  />
                  <ScreensThumbnails
                    open={this.state.screensThumbnailsOpen}
                    what={this.props.what}
                    screensToShare={this.props.screensToShare}
                    onCancel={this.closeScreenThumnailsSelector}
                    selectScreen={this.selectScreen}
                  />
                  <DeviceError />
                  <MediaPermissions />
                  <NotificationArea />
                  <NavigationBar
                    isElectron={this.props.isElectron}
                    isAuthenticated={this.props.isAuthenticated}
                    onShareScreen={this.openScreenSelectorIfNeeded}
                    onStopScreen={this.stopScreenSharing}
                    localStore={this.props.localStore}
                    browser={this.state.browser}
                    userAgent={this.state.parsedUserAgent}
                    relayEnvironment={this.state.relayEnvironment}
                    appConfig={this.props.appConfig}
                  />
                </div>
              }
            </BrowserRouter>
          </MuiThemeProvider>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    );
  }
}


App.propTypes = {
  appConfig: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  localStore: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  authToken: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  isAuthenticatedAsGuest: PropTypes.bool,
  isElectron: PropTypes.bool,
  isFetching: PropTypes.bool,
  sessionReady: PropTypes.bool,
  socketConnected: PropTypes.bool,
  socketConnecting: PropTypes.bool,
  callStatsConfig: PropTypes.object,
  relayIsFetching: PropTypes.bool,
  screensToShare: PropTypes.array.isRequired,
  what: PropTypes.string.isRequired,
  roomJoined: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isElectron: state.settings.isElectron,
    isAuthenticatedAsGuest: state.auth.isAuthenticatedAsGuest,
    authToken: state.auth.token,
    what: state.room.what,
    screensToShare: state.room.screensToShare,
    isFetching: state.auth.isFetching,
    relayIsFetching: state.relay.isFetching,
    sessionReady: state.session.sessionReady,
    roomJoined: Boolean(state.websocket.room),
    socketConnected: state.websocket.isConnected,
    socketConnecting: state.websocket.isConnecting,
    callStatsConfig: state.session.callStatsConfig,
  };
}

export default connect(mapStateToProps)(App);
