let baseTabTitle = '';
let tabTitle = '';
let markers = [];

const muteMarker = 'M';

class TabTitle {

  constructor(baseTabTitle) {
    this.constructor.setBaseTabTitle(baseTabTitle);
  }

  static setBaseTabTitle(title) {
    baseTabTitle = title;
    this.renderTitle();
  }

  static getBaseTabTitle() {
    return baseTabTitle;
  }

  static setTabTitle(title) {
    if (title) {
      tabTitle = title;
    }
    this.renderTitle();
  }

  static resetTabTitle() {
    tabTitle = '';
    this.resetMarkers();
    this.renderTitle();
  }

  static renderTitle() {
    let tab = tabTitle
      ? `${tabTitle} - ${baseTabTitle}`
      : `${baseTabTitle}`;
    if (markers.length > 0) {
      tab = `(${markers.join("")}) - ${tab}`;
    }
    window.document.title = tab;
  }

  static addMarker(marker) {
    markers.push(marker);
    this.renderTitle();
  }

  static removeMarker(marker) {
    markers = markers.filter((item) => {
      return item !== marker;
    });
    this.renderTitle();
  }

  static resetMarkers() {
    markers.length = 0;
  }

  static addMuteMarker() {
    this.addMarker(muteMarker);
  }

  static removeMuteMarker() {
    this.removeMarker(muteMarker);
  }
}

export default TabTitle;
