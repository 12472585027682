const style = {
  container: {
    justifyContent: 'center',
    height: 'inherit',
    width: '100%',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
  video: {
    width: '100%',
    height: '100%',
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
  },
  mirrored: {
    transform: 'rotateY(180deg)',
  },
  muted: {
    zIndex: 10,
    background: 'black',
    filter: 'grayscale(100%)',
    opacity: 0.5,
  },
  videoMutedOverlayContainer: {
    zIndex: 20,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  videoMutedOverlay: {
    color: 'rgba(128, 128, 128, 0.6)',
    zIndex: 20,
  },
  videoPipOverlayContainer: {
    zIndex: 20,
    background: 'rgb(0, 0, 0)',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  videoPipOverlay: {
    color: 'rgba(128, 128, 128)',
    zIndex: 20,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  fullHeight: {
    height: '100%',
  },
};

export default style;
