"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rootReducer = rootReducer;

var _auth = _interopRequireWildcard(require("./auth"));

var _websocket = _interopRequireDefault(require("./websocket"));

var _room = _interopRequireDefault(require("./room"));

var _session = _interopRequireDefault(require("./session"));

var _settings = _interopRequireDefault(require("./settings"));

var _invite_participants = _interopRequireDefault(require("./invite_participants"));

var _notifications = _interopRequireDefault(require("./notifications"));

var _relay = _interopRequireDefault(require("./relay"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj["default"] = obj;
    return newObj;
  }
}

function rootReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var authState = Object.assign({}, _auth.defaultState, state.auth);
  return {
    auth: (0, _auth["default"])(authState, action),
    websocket: (0, _websocket["default"])(state.websocket, action),
    room: (0, _room["default"])(state.room, action),
    session: (0, _session["default"])(state.session, action),
    settings: (0, _settings["default"])(state.settings, action),
    invite_participants: (0, _invite_participants["default"])(state.invite_participants, action),
    relay: (0, _relay["default"])(state.relay, action),
    notifications: (0, _notifications["default"])(state.notifications, action)
  };
}