/**
 * @flow
 * @relayHash 8cc19b4cfebde690ccc980a3eb111204
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
export type sessionDetailsQueryVariables = {||};
export type sessionDetailsQueryResponse = {|
  +session: ?{|
    +realm: ?string,
    +displayName: ?string,
    +username: ?string,
    +roles: ?$ReadOnlyArray<?string>,
    +stunServers: ?$ReadOnlyArray<?{|
      +credential: ?string,
      +urls: ?$ReadOnlyArray<?string>,
      +username: ?string,
    |}>,
    +domainId: ?string,
    +email: ?string,
    +userId: ?string,
    +roomNumber: ?string,
    +roomOwnerNumber: ?string,
    +pstnNumber: ?string,
    +callStatsConfig: ?{|
      +appId: ?string,
      +appSecret: ?string,
      +endpoint: ?string,
    |},
    +chromeScreenShareConfig: ?{|
      +url: ?string
    |},
    +recordingEnabled: ?boolean,
    +livestreamingEnabled: ?boolean,
  |}
|};
export type sessionDetailsQuery = {|
  variables: sessionDetailsQueryVariables,
  response: sessionDetailsQueryResponse,
|};
*/

/*
query sessionDetailsQuery {
  session {
    realm
    displayName
    username
    roles
    stunServers {
      credential
      urls
      username
    }
    domainId
    email
    userId
    roomNumber
    roomOwnerNumber
    pstnNumber
    callStatsConfig {
      appId
      appSecret
      endpoint
    }
    chromeScreenShareConfig {
      url
    }
    recordingEnabled
    livestreamingEnabled
  }
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = {
    "kind": "ScalarField",
    "alias": null,
    "name": "username",
    "args": null,
    "storageKey": null
  },
      v1 = [{
    "kind": "LinkedField",
    "alias": null,
    "name": "session",
    "storageKey": null,
    "args": null,
    "concreteType": "Session",
    "plural": false,
    "selections": [{
      "kind": "ScalarField",
      "alias": null,
      "name": "userId",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "realm",
      "args": null,
      "storageKey": null
    }, v0
    /*: any*/
    , {
      "kind": "ScalarField",
      "alias": null,
      "name": "roles",
      "args": null,
      "storageKey": null
    }, {
      "kind": "LinkedField",
      "alias": null,
      "name": "stunServers",
      "storageKey": null,
      "args": null,
      "concreteType": "StunServer",
      "plural": true,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "credential",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "urls",
        "args": null,
        "storageKey": null
      }, v0
      /*: any*/
      ]
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "domainId",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "displayName",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "roomNumber",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "roomOwnerNumber",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "pstnNumber",
      "args": null,
      "storageKey": null
    }, {
      "kind": "LinkedField",
      "alias": null,
      "name": "callStatsConfig",
      "storageKey": null,
      "args": null,
      "concreteType": "CallStats",
      "plural": false,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "appId",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "appSecret",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "endpoint",
        "args": null,
        "storageKey": null
      }]
    }, {
      "kind": "LinkedField",
      "alias": null,
      "name": "chromeScreenShareConfig",
      "storageKey": null,
      "args": null,
      "concreteType": "ChromeScreenShareExtension",
      "plural": false,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      }]
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "recordingEnabled",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "livestreamingEnabled",
      "args": null,
      "storageKey": null
    }]
  }];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "sessionDetailsQuery",
      "type": "RootQueryType",
      "metadata": null,
      "argumentDefinitions": [],
      "selections": v1
      /*: any*/

    },
    "operation": {
      "kind": "Operation",
      "name": "sessionDetailsQuery",
      "argumentDefinitions": [],
      "selections": v1
      /*: any*/

    },
    "params": {
      "operationKind": "query",
      "name": "sessionDetailsQuery",
      "id": null,
      "text": "query sessionDetailsQuery {\n  session {\n    realm\n    displayName\n    username\n    roles\n    stunServers {\n      credential\n      urls\n      username\n    }\n    domainId\n    email\n    userId\n    roomNumber\n    roomOwnerNumber\n    pstnNumber\n    callStatsConfig {\n      appId\n      appSecret\n      endpoint\n    }\n    chromeScreenShareConfig {\n      url\n    }\n    recordingEnabled\n    livestreamingEnabled\n  }\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = '3bea239a04493838397e35080d446318';
module.exports = node;