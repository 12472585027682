/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
/* Other imports */
import { Router } from 'holocom-client/lib/utils';
/* Local Style */
import style from './style';


const messages = defineMessages({
  ok: {id: 'ok'},
  cancel: {id: 'cancel'},
  title: {id: 'guestLoginDialogTitle'},
  clickHereToLogin: {id: 'clickHereToLogin'},
  login: {id: 'login'}
});


class GuestLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitEnabled: false,
      displayName: null
    };
    this.router = new Router();
  }

  handleConfirmButton = () => {
    if (this.state.displayName) {
      this.props.onConfirm(this.state.displayName);
    }
  }

  handleChange = (ev) => {
    const dname = ev.target.value;
    this.setState({
      submitEnabled: Boolean(dname),
      displayName: dname,
    });
  }

  handleKeyUp = (ev) => {
    if ((ev.key === 'Enter') && (this.state.submitEnabled)) {
      this.handleConfirmButton();
    }
  }

  render() {
    const classes = this.props.classes;
    const loginQs = this.props.meetingId ? `redirectTo=${this.props.meetingId}` : null;
    const loginRef = this.router.getUrlFor(this.router.LOGIN, null, loginQs);
    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <Input
            onChange={this.handleChange}
            onKeyUp={this.handleKeyUp}
            autoFocus={true}
            fullWidth
          />
        </DialogContent>
        <DialogActions classes={{action: classes.root}}>
          <Grid container direction='row' alignItems='center' justify='space-between' className={classes.root}>
            <Grid item>
              <a href={loginRef} className={classes.loginLink}>
                <Typography color='primary' variant="caption" gutterBottom align="center">
                  {this.props.intl.formatMessage(messages.clickHereToLogin)}
                </Typography>
              </a>
            </Grid>
            <Grid item>
              <Button className={classes.button} onClick={this.props.onCancel}>
                {this.props.intl.formatMessage(messages.cancel)}
              </Button>
              <Button
                className={classes.button}
                disabled={!this.state.submitEnabled}
                onClick={this.handleConfirmButton}
                variant="contained"
                color="primary"
              >
                {this.props.intl.formatMessage(messages.ok)}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}


GuestLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  meetingId: PropTypes.string.isRequired,
};


export default withStyles(style)(injectIntl(GuestLogin));
