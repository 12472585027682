const style = theme => ({
  webAccessContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  title: {
    paddingTop: '25px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: '1em',
  },

  content: {
    color: 'rgba(0, 0, 0, 0.90)',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '2em',
  },

  linkContent: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  participants: {
    overflowY: 'auto',
    maxHeight: '10vh',
    listStyleType: 'none',
    paddingLeft: '10px',
  },

  iconContainer: {
    display: 'flex',
  },

  roomInfo: {
    [theme.breakpoints.down('sm')]: {
      width: '70vw'
    },
    margin: '10px',
    maxWidth: '300px',
  //   position: 'relative',
  //   background: '#88b7d5',
  //   marginRight: '10px',
  //   border: '4px solid #c2e1f5',
  //   '&:before': {
  //     left: '100%',
  //     top: '50%',
  //     border: 'solid transparent',
  //     content: '" "',
  //     height: 0,
  //     width: 0,
  //     position: 'absolute',
  //     pointerEvents: 'none',
  //     borderColor: 'rgba(194, 225, 245, 0)',
  //     borderLeftColor: '#c2e1f5',
  //     borderWidth: '10px',
  //     marginTop: '-10px',
  //   },
  //   '&:after': {
  //     left: '100%',
  //     top: '50%',
  //     border: 'solid transparent',
  //     content: '" "',
  //     height: 0,
  //     width: 0,
  //     position: 'absolute',
  //     pointerEvents: 'none',
  //     borderColor: 'rgba(136, 183, 213, 0)',
  //     borderLeftColor: '#88b7d5',
  //     borderWidth: '8px',
  //     marginTop: '-8px',
  //   }
  }
});

export default style;
