"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addNotification = addNotification;
exports.removeNotification = removeNotification;
exports.REMOVE_NOTIFICATION = exports.ADD_NOTIFICATION = void 0;
var ADD_NOTIFICATION = 'ADD_NOTIFICATION';
exports.ADD_NOTIFICATION = ADD_NOTIFICATION;
var REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
exports.REMOVE_NOTIFICATION = REMOVE_NOTIFICATION;

function addNotification(reference, data) {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      ref: reference,
      notification: data
    }
  };
}

function removeNotification(reference) {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      ref: reference
    }
  };
}