import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import style from './style';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';



const messages = defineMessages({
  cancel: { id: 'cancel' },
  ok: { id: 'ok' },
  titleScreen: { id: 'screensThumbnailsListDialogTitle' },
  titleWindow: { id: 'windowsThumbnailsListDialogTitle' },
  content: { id: 'screenSharingDialogContent' },
  screen: { id: 'screenSharingDialogScreen' },
  window: { id: 'screenSharingDialogWindow' },
  errorTitle: { id: 'screenSharingErrorTitle' },
  errorContent: { id: 'screenSharingErrorContent' },
});


class ScreensThumbnails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDialogOpen: this.props.deviceError,
    };
  }

  filteredScreen = (screen) => {
    return (
      <ScreenItem
        selectScreen={this.props.selectScreen}
        classes={this.props.classes}
        what={this.props.what}
        screen={screen}
        key={screen.id}
      />
    );
  }

  render() {
    const { what, screensToShare } = this.props;
    const classes = this.props.classes;
    const filteredScreens = _.filter(screensToShare, (screen) => screen.id.includes(what));

    return <Dialog
      open={this.props.open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>
        {what === 'screen' ?
          this.props.intl.formatMessage(messages.titleScreen) : this.props.intl.formatMessage(messages.titleWindow)}
      </DialogTitle>

      {
        screensToShare.length === 0 ? <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.progress} size={50} />
        </div> :
          <div>
            <DialogContent className={classes.thumbnailsContainer}>

              {_.map(filteredScreens, this.filteredScreen)}

            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.onCancel}>
                {this.props.intl.formatMessage(messages.cancel)}
              </Button>
            </DialogActions>
          </div>
      }
    </Dialog>;
  }

}


ScreensThumbnails.propTypes = {
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectScreen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  deviceError: PropTypes.string,
  what: PropTypes.string.isRequired,
  screensToShare: PropTypes.array.isRequired,
};


function mapStateToProps(state) {
  return {
    deviceError: state.room.screenError,
  };
}


class ScreenItem extends Component {
  selectScreen = () => {
    this.props.selectScreen(this.props.screen, this.props.what);
  }

  render() {
    return (<div onClick={this.selectScreen}
      className={this.props.classes.imgContainer} key={this.props.screen.id}>
      <img className={this.props.classes.img} alt={this.props.screen.name}
        src={`${this.props.screen.thumbnail.toDataURL()}`} />
    </div>);
  }

  static propTypes = {
    selectScreen: PropTypes.func.isRequired,
    screen: PropTypes.object.isRequired,
    what: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired
  }
}

export default withStyles(style)(injectIntl(connect(mapStateToProps)(ScreensThumbnails)));
