const style = {
  dense: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  videoButton: {
    color: 'rgba(0,0,0,0.75)',
  },
  redIcon: {
    color: 'rgba(190, 0, 0, 0.8)',
  },
  orangeIcon: {
    color: 'rgba(255, 100, 0, 0.8)',
  },
  greenIcon: {
    color: 'rgba(0, 190, 0, 0.8)',
  },
  grayIcon: {
    color: 'rgba(100, 100, 100, 0.8)',
  },
  icon: {
    color: 'rgba(0,0,0,0.75)',
  }
};

export default style;
