/**
 * @flow
 * @relayHash 817e4b4bcf44b2d4a874d652dc444e9f
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
export type sessionQueryVariables = {||};
export type sessionQueryResponse = {|
  +session: ?{|
    +realm: ?string,
    +username: ?string,
  |}
|};
export type sessionQuery = {|
  variables: sessionQueryVariables,
  response: sessionQueryResponse,
|};
*/

/*
query sessionQuery {
  session {
    realm
    username
  }
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = [{
    "kind": "LinkedField",
    "alias": null,
    "name": "session",
    "storageKey": null,
    "args": null,
    "concreteType": "Session",
    "plural": false,
    "selections": [{
      "kind": "ScalarField",
      "alias": null,
      "name": "realm",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    }]
  }];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "sessionQuery",
      "type": "RootQueryType",
      "metadata": null,
      "argumentDefinitions": [],
      "selections": v0
      /*: any*/

    },
    "operation": {
      "kind": "Operation",
      "name": "sessionQuery",
      "argumentDefinitions": [],
      "selections": v0
      /*: any*/

    },
    "params": {
      "operationKind": "query",
      "name": "sessionQuery",
      "id": null,
      "text": "query sessionQuery {\n  session {\n    realm\n    username\n  }\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = '534b0033eca6dacfda4e8ca7c0fa5043';
module.exports = node;