/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer } from 'react-relay';
/* UI Imports */
/* Components Imports */
import Livestreaming from './liveStreaming';
/* Other imports */
import { myProfileQuery } from 'holocom-client/lib/api/relay/myProfile';


class LivestreamingTab extends Component {

  getLivestreamingCmp = ({ error, props }) => {
    return (
      <Livestreaming
        dispatch={this.props.dispatch}
        relayEnvironment={this.props.relayEnvironment}
        onClose={this.props.onClose}
        gqlError={error}
        gqlProps={props}
        key={props}
      />
    );
  }

  render() {
    return (
      <QueryRenderer
        environment={this.props.relayEnvironment}
        query={myProfileQuery}
        render={this.getLivestreamingCmp}
      />
    );
  }
}


LivestreamingTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  relayEnvironment: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};


export default LivestreamingTab;
