/* eslint max-len: 0 */
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let SignalAnimated = props =>
  <SvgIcon {...props}>
    <path d="M3,21H6V18H3M8,21H11V14H8M13,21H16V9H13M18,21H21V3H18V21Z">
      <animate id="one" attributeName="d" attributeType="XML" from="M3,21H6V18H3M8,21H11V14H8M13,21H16V9H13M18,21H21V3H18V21Z" to="M3,21H6V18H3M8,21H11V14H8M13,21H16V9H13M18" begin="0s; four.end" dur="1s" fill="freeze" />
      <animate id="two" attributeName="d" attributeType="XML" from="M3,21H6V18H3M8,21H11V14H8M13,21H16V9H13M18" to="M3,21H6V18H3M8,21H11V14H8M13" begin="one.end" dur="1s" fill="freeze" />
      <animate id="three" attributeName="d" attributeType="XML" from="M3,21H6V18H3M8,21H11V14H8M13" to="M3,21H6V18H3M8,21H11V14H8M13,21H16V9H13M18" begin="two.end" dur="1s" fill="freeze" />
      <animate id="four" attributeName="d" attributeType="XML" from="M3,21H6V18H3M8,21H11V14H8M13,21H16V9H13M18" to="M3,21H6V18H3M8,21H11V14H8M13,21H16V9H13M18,21H21V3H18V21Z" begin="three.end" dur="1s" fill="freeze" />
    </path>
  </SvgIcon>;

SignalAnimated = pure(SignalAnimated);
SignalAnimated.muiName = 'SvgIcon';

export default SignalAnimated;
