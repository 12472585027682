/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import style from './index.style';
/* Components Imports */
import Dialog from '@material-ui/core/Dialog';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import LivestreamingTab from './liveStreamingTab';
import RegenPinsTab from './regenPinsTab';
import UserInfoTab from './userInfoTab';
/* Other imports */


const messages = defineMessages({
  userTab: { id: 'accountCmpUser' },
  pinsTab: { id: 'accountCmpPins' },
  streamingTab: { id: 'accountCmpStreaming' },
});


class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTab: 0,
    };
  }

  handleTabChange = (_event, value) => {
    this.setState({ openTab: value });
  };

  getLivestreamingTab = () => {
    return (
      <LivestreamingTab
        dispatch={this.props.dispatch}
        relayEnvironment={this.props.relayEnvironment}
        onClose={this.props.onClose}
      />
    );
  }

  getRegenPinsTab = () => {
    return (
      <RegenPinsTab
        dispatch={this.props.dispatch}
        relayEnvironment={this.props.relayEnvironment}
        onClose={this.props.onClose}
      />
    );
  }

  getUserInfoTab = () => {
    return (
      <UserInfoTab
        dispatch={this.props.dispatch}
        relayEnvironment={this.props.relayEnvironment}
        onClose={this.props.onClose}
        defaultUserUid={this.props.defaultUserUid}
      />
    );
  }

  render() {
    const openTab = this.state.openTab;
    const classes = this.props.classes;
    const fullScreen = this.props.fullScreen;
    const dialogPaper = fullScreen ? null : classes.dialogPaper;

    return (
      <Dialog open={this.props.open}
        disableBackdropClick
        onEscapeKeyDown={this.props.onClose}
        maxWidth='sm'
        fullWidth={true}
        fullScreen={fullScreen}
        classes={{paper: dialogPaper}}
      >
        <Tabs value={openTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleTabChange}
          fullWidth
        >
          <Tab label={this.props.intl.formatMessage(messages.userTab)} />
          <Tab label={this.props.intl.formatMessage(messages.pinsTab)} />
          {
            this.props.isLivestreamingEnabled &&
            <Tab label={this.props.intl.formatMessage(messages.streamingTab)} />
          }
        </Tabs>
        {openTab === 0 && this.getUserInfoTab()}
        {openTab === 1 && this.getRegenPinsTab()}
        {openTab === 2 && this.getLivestreamingTab()}
      </Dialog>
    );
  }
}


function mapStateToProps(state) {
  return {
    isLivestreamingEnabled: state.session.livestreamingEnabled || false,
  };
}


Account.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  relayEnvironment: PropTypes.object.isRequired,
  isLivestreamingEnabled: PropTypes.bool,
  fullScreen: PropTypes.bool.isRequired,
  defaultUserUid: PropTypes.string.isRequired,
};


export default withStyles(style)(injectIntl(connect(mapStateToProps)(withMobileDialog()(Account))));
