import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer } from 'react-relay';
import { newEvent, ERROR } from 'holocom-client/lib/notifications';
import { myRecordingsQuery } from 'holocom-client/lib/api/relay/myRecordings';
import MyRecordingList from './MyRecordingList';


class MyRecordings extends Component {
  pageSize = 10;

  renderer = ({error, props}) => {
    if (error) {
      newEvent(ERROR, 'reportUnableLoadRecording', 'reportUnableLoadRecording', "Load recordings Error");
      return null;
    } else if (props) {
      return (
        <MyRecordingList
          onCloseClick={this.props.onCloseClick}
          data={props}
          pageSize={this.pageSize}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <QueryRenderer
        environment={this.props.relayEnvironment}
        query={myRecordingsQuery}
        variables={{
          count: this.pageSize,
          sorting: {
            direction: "DESC",
            sort: "recStart"
          },
          filters: []
        }}
        render={this.renderer}
      />
    );
  }
}

MyRecordings.propTypes = {
  relayEnvironment: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export { default as MyRecording } from './MyRecording';
export { default as MyRecordingList } from './MyRecordingList';
export default MyRecordings;
