/* Libraries Imports */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import InfoIcon from '@material-ui/icons/Info';
import SignalMax from '../../style/SignalMax';
import SignalAnimated from '../../style/SignalAnimated';
import SignalMin from '../../style/SignalMin';
import SignalMed from '../../style/SignalMed';
import PresentationIcon from '../../style/PresentationIcon';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Other imports */
import { isScreen, getUserStream } from '../../state';
/* Local Style */
import style from './style';


const messages = defineMessages({
  screenName: { id: 'screenVideoDisplayName' },
  microphoneButtonDisabledTooltip: { id: 'microphoneButtonDisabledTooltip' },
  presentationIconTooltip: { id: 'presentationIconTooltip' },
  highLinkQuality: { id: 'highLinkQuality' },
  mediumLinkQuality: { id: 'mediumLinkQuality' },
  lowLinkQuality: { id: 'lowLinkQuality' },
  computingLinkQuality: { id: 'computingLinkQuality' },
  linkQualityNotAvailable: { id: 'linkQualityNotAvailable' },
});

class MenuVideoInfoToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { preventLinkQualityFromShowing: true, anchorEl: null };
    this.linkQualityTimer = null;
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {
    const now = Math.floor(Date.now() / 1000);
    let showProgress = false;
    if (now - this.props.videoPublishedTs < 30) {
      showProgress = true;
    }
    if (this.props.videoPublished && !this.linkQualityTimer && showProgress) {
      this._startLinkQualityTimer();
    }
    if (this.props.videoPublished && !this.linkQualityTimer && !showProgress) {
      this.setState({ preventLinkQualityFromShowing: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.videoPublished !== prevProps.videoPublished) {
      this.setState({ preventLinkQualityFromShowing: true });
      if (this.props.videoPublished && !this.linkQualityTimer) {
        this._startLinkQualityTimer();
      }
      else if (this.linkQualityTimer) {
        this._destroyLinkQualityTimer();
      }
    }
  }

  componentWillUnmount() {
    this._destroyLinkQualityTimer();
  }

  _startLinkQualityTimer() {
    this._destroyLinkQualityTimer();
    this.linkQualityTimer = setTimeout(() => {
      this.setState({ preventLinkQualityFromShowing: false });
    }, 30 * 1000);
  }

  _destroyLinkQualityTimer() {
    if (this.linkQualityTimer) {
      clearTimeout(this.linkQualityTimer);
    }
    this.linkQualityTimer = null;
  }

  roleIcon(classes) {
    return (['room_owner', 'room_moderator'].includes(this.props.userRole)
      ? (
        <TooltipMultiLineText
          placement="top"
          title={this.props.intl.formatMessage(messages.presentationIconTooltip)}>
          <PresentationIcon className={classes.icon} />
        </TooltipMultiLineText>)
      : null
    );
  }

  qualityIcon(quality, classes) {
    return this.renderUplinkQuality(quality, classes);
  }

  renderUplinkQuality(quality, classes) {
    if (!quality) {
      return null;
    }
    let color = classes.greenIcon;
    let tooltip = this.props.intl.formatMessage(messages.highLinkQuality);
    let icon = <SignalMax className={color} />;
    if (this.state.preventLinkQualityFromShowing) {
      color = classes.grayIcon;
      tooltip = this.props.intl.formatMessage(messages.computingLinkQuality);
      if (this.props.videoPublished) {
        icon = <SignalAnimated className={color} />;
      }
      else {
        tooltip = this.props.intl.formatMessage(messages.linkQualityNotAvailable);
        icon = <SignalMax className={color} />;
      }
    }
    else if (quality === 'low') {
      color = classes.redIcon;
      tooltip = this.props.intl.formatMessage(messages.lowLinkQuality);
      icon = <SignalMin className={color} />;
    }
    else if (quality === 'medium') {
      color = classes.orangeIcon;
      tooltip = this.props.intl.formatMessage(messages.mediumLinkQuality);
      icon = <SignalMed className={color} />;
    }
    return (
      <TooltipMultiLineText
        placement="top"
        title={tooltip}
      >
        <div>
          {icon}
        </div>
      </TooltipMultiLineText>
    );
  }


  render() {
    const classes = this.props.classes;
    const { anchorEl } = this.state;
    const roleIcon = this.roleIcon(classes);
    const qualityIcon = this.qualityIcon(this.props.uplinkQuality, classes);
    if (!roleIcon && !qualityIcon) {
      return null;
    }
    return (
      <div className={classes.dense}>
        <InfoIcon
          aria-owns={anchorEl ? 'menu-video-info-toolbar' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
        </InfoIcon>
        <Menu
          id="menu-video-info-toolbar"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >

          <ListItem dense classes={{
            dense: classes.dense
          }} button disableGutters>
            <ListItemIcon>
              {qualityIcon}
            </ListItemIcon>
          </ListItem>
          {roleIcon &&
            <ListItem dense classes={{
              dense: classes.dense
            }} button disableGutters>
              <ListItemIcon>
                {roleIcon}
              </ListItemIcon>
            </ListItem>
          }
        </Menu>
      </div>
    );
  }
}

MenuVideoInfoToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isAudioMuted: PropTypes.bool,
  name: PropTypes.string,
  displayName: PropTypes.string,
  user: PropTypes.string,
  myUserId: PropTypes.string,
  type: PropTypes.string,
  userRole: PropTypes.string,
  videoPublishedTs: PropTypes.number,
  uplinkQuality: PropTypes.string,
  videoPublished: PropTypes.bool,
};

MenuVideoInfoToolbar.defaultProps = {
  type: 'user',
};


function mapStateToProps(state, props) {
  let user = props.user;
  if (user) {
    user = user.replace(/_screen$/, '');
  }
  const userInfo = state.room.roster[user];
  const rosterName = (userInfo || { display: 'anonymous' }).display;
  let videoQuality;
  if (isScreen(props)) {
    videoQuality = (userInfo || { screen_uplink_quality: null }).screen_uplink_quality;
  }
  else {
    videoQuality = (userInfo || { video_uplink_quality: null }).video_uplink_quality;
  }
  let userStream = getUserStream(user, props, state);
  return {
    name: props.displayName || rosterName,
    isAudioMuted: (userInfo || { isAudioMuted: false }).isAudioMuted,
    userRole: (userInfo || {}).role,
    uplinkQuality: videoQuality,
    videoPublishedTs: userStream.videoTs,
    videoPublished: userStream.stream && !userStream.muted,
  };
}

export default withStyles(style)(injectIntl(connect(mapStateToProps)(MenuVideoInfoToolbar)));
