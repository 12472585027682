/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import style from './style';
/* Components Imports */
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withMobileDialog from '@material-ui/core/withMobileDialog';
/* Other imports */
import { isPhoneNumber, isSipUri } from 'holocom-client/lib/utils';


const messages = defineMessages({
  ok: { id: 'call' },
  cancel: { id: 'cancel' },
  phone: { id: 'phoneNumber' },
  title: { id: 'dialToMeetingDialogTitle' },
  content: { id: 'dialToMeetingDialogContent' },
  phoneError: { id: 'dialToMeetingValidatioError' },
});


class DialToMeetingDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      invalidPhoneNumber: false,
    };
  }

  onInvite = () => {
    this.props.onInvite(this.state.phoneNumber);
  }

  isPhoneNumberOrURIInvalid = (number) => {
    const matches = isPhoneNumber(number) || isSipUri(number);
    return !matches;
  }

  handlePhoneNumberChange = (ev) => {
    const value = ev.target.value;
    this.setState({
      phoneNumber: value,
      invalidPhoneNumber: this.isPhoneNumberOrURIInvalid(value),
    });
  }

  handleKeyUp = (ev) => {
    if ((ev.key === 'Enter') && (this.state.phoneNumber) &&
      !this.isPhoneNumberOrURIInvalid(this.state.phoneNumber)) {
      this.onInvite();
    }
  }

  render() {
    const classes = this.props.classes;
    const fullScreen = this.props.fullScreen;
    const inputLabel = this.props.intl.formatMessage(messages.phone);
    const invalidMessage = this.props.intl.formatMessage(messages.phoneError);

    return (
      <Dialog
        open={this.props.open}
        fullScreen={fullScreen}
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText className={classes.text}>
            {this.props.intl.formatMessage(messages.content)}
          </DialogContentText>
          <div className={classes.container}>
            <FormControl error={this.state.invalidPhoneNumber}>
              <InputLabel>{inputLabel}</InputLabel>
              <Input
                value={this.state.phoneNumber}
                onChange={this.handlePhoneNumberChange}
                onKeyUp={this.handleKeyUp}
              />
              {this.state.invalidPhoneNumber ?
                <FormHelperText>{invalidMessage}</FormHelperText>
                : null}
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            {this.props.intl.formatMessage(messages.cancel)}
          </Button>
          <Button
            variant="contained"
            disabled={this.state.invalidPhoneNumber === true || this.state.phoneNumber === ""}
            onClick={this.onInvite} color="primary"
          >
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


DialToMeetingDialog.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};


export default withStyles(style)(injectIntl(withMobileDialog()(DialToMeetingDialog)));
