"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onPresenceUpdate = onPresenceUpdate;

var _phoenix = require("phoenix");

function _getUserUid(user) {
  return user.metas[0].user_id;
}

function _remapUser(user) {
  var metas = user.metas[0];
  var display = metas.display;
  var viaPhone = metas.via_phone;
  var role = metas.role;
  var username = metas.username;
  return {
    display: display,
    viaPhone: viaPhone,
    role: role,
    username: username,
    video_uplink_quality: metas.video_uplink_quality,
    screen_uplink_quality: metas.screen_uplink_quality,
    isControllingDesktop: metas.is_controlling_desktop,
    isSharingDesktopControl: metas.is_sharing_desktop_control,
    hasStartedDrawing: metas.has_started_drawing
  };
}

function onPresenceUpdate(channel, onDispatch) {
  var presence = new _phoenix.Presence(channel);

  var updatePresence = function updatePresence() {
    var participants = {};
    presence.list().forEach(function (p) {
      var uid = _getUserUid(p);

      participants[uid] = _remapUser(p);
    });
    onDispatch(participants);
  };

  presence.onSync(updatePresence);
}