"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultState = exports["default"] = void 0;

var _room = require("../actions/room");

var defaultState = {
  isFetching: false,
  error: false,
  errCode: null
};
exports.defaultState = defaultState;

function invite_participants() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _room.INVITE_PARTICIPANTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        error: false,
        errCode: null
      });

    case _room.INVITE_PARTICIPANTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        error: false,
        errCode: null
      });

    case _room.INVITE_PARTICIPANTS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: true,
        errCode: action.payload.errCode
      });

    default:
      return state;
  }
}

var _default = invite_participants;
exports["default"] = _default;