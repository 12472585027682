const style = {
  toolbar: {
    position: 'absolute',
    bottom: '16px',
    zIndex: 20,
    justifyContent: 'center',
    background: 'rgba(255, 255, 255, 0.75)',
    display: 'flex',
    padding: '6px 6px 6px 6px',
    borderRadius: '0 10px 10px 0',
  },
  name: {
    color: 'rgba(0, 0, 0, 0.75)',
    fontVariant: 'small-caps',
    fontWeight: 'bold',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  redIcon: {
    color: 'rgba(190, 0, 0, 0.8)',
  },
  orangeIcon: {
    color: 'rgba(255, 100, 0, 0.8)',
  },
  greenIcon: {
    color: 'rgba(0, 190, 0, 0.8)',
  },
  grayIcon: {
    color: 'rgba(100, 100, 100, 0.8)',
  },
  icon: {
    color: 'rgba(0,0,0,0.75)',
  },
};


export default style;
