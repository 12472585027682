"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteRecording = deleteRecording;

var _reactRelay = require("react-relay");

var _relayRuntime = require("relay-runtime");

var _notifications = require("../../notifications");

var mutation = function mutation() {
  var node = require("./__generated__/deleteRecordingMutation.graphql");

  if (node.hash && node.hash !== "32854592ffaf4097558dc9ec7ac6174d") {
    console.error("The definition of 'deleteRecordingMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/deleteRecordingMutation.graphql");
};

function maybeNotifyError(response) {
  var inError = false;

  if (response && response.deleteRecording.errors !== null) {
    inError = true;
  }

  if (!response) {
    inError = true;
  }

  if (inError) {
    (0, _notifications.newEvent)(_notifications.ERROR, 'deleteRecordingError', 'deleteRecordingError', "Delete recording error");
  }
}

function deleteRecording(environment, id, userId, domainId) {
  return new Promise(function (resolve, reject) {
    var variables = {
      input: {
        recording: {
          userId: userId,
          domainId: domainId,
          id: id
        }
      }
    };
    (0, _reactRelay.commitMutation)(environment, {
      mutation: mutation,
      variables: variables,
      updater: function updater(store) {
        var payload = store.getRootField("deleteRecording");
        var model = payload.getLinkedRecord("recording");
        var rec = {
          id: 'client:root'
        };
        var parent = store.get(rec.id);

        var connection = _relayRuntime.ConnectionHandler.getConnection(parent, "RecordingList_myRecordings");

        _relayRuntime.ConnectionHandler.deleteNode(connection, model.getValue('id'));
      },
      onCompleted: function onCompleted(response, errors) {
        if (errors) {
          maybeNotifyError();
          return reject(errors);
        }

        maybeNotifyError(response);
        return resolve(response);
      },
      onError: function onError(err) {
        maybeNotifyError();
        reject(err);
      }
    });
  });
}