"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteMeeting = deleteMeeting;

var _reactRelay = require("react-relay");

var _notifications = require("../../notifications");

var mutation = function mutation() {
  var node = require("./__generated__/deleteMeetingMutation.graphql");

  if (node.hash && node.hash !== "97e3fd45e6d02d6feefea85ee20a44eb") {
    console.error("The definition of 'deleteMeetingMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/deleteMeetingMutation.graphql");
};

function maybeNotifyError(response) {
  var inError = false;

  if (response && response.deleteMeeting.errors !== null) {
    inError = true;
  }

  if (!response) {
    inError = true;
  }

  if (inError) {
    (0, _notifications.newEvent)(_notifications.ERROR, 'deleteMeetingError', 'deleteMeetingError', "Delete meeting error");
  }
}

function deleteMeeting(environment, id, userId, domainId) {
  return new Promise(function (resolve, reject) {
    var variables = {
      input: {
        meeting: {
          userId: userId,
          domainId: domainId,
          id: id
        }
      }
    };
    (0, _reactRelay.commitMutation)(environment, {
      mutation: mutation,
      variables: variables,
      onCompleted: function onCompleted(response, errors) {
        if (errors) {
          maybeNotifyError();
          return reject(errors);
        }

        maybeNotifyError(response);
        return resolve(response);
      },
      onError: function onError(err) {
        maybeNotifyError();
        reject(err);
      }
    });
  });
}