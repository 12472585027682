const blueMaterial = '#2196F3';

const style = theme => ({
  meetingListGridContainer: {
    flexGrow: 90,
    position: 'relative'
  },
  meetingListElements: {
    display: "flex",
    flexFlow: "column"
  },
  meetingListDetails: {
    display: "flex",
    flexFlow: "column"
  },
  highlightedMeeting: {
    borderLeft: `10px solid ${ blueMaterial }`,
    marginBottom: theme.spacing.unit,
  },
  notHighlightedMeeting: {
    borderLeft: `10px solid #fff`,
    marginBottom: theme.spacing.unit
  },
  nextButton: {
    float: 'right',
    color: blueMaterial
  },
  prevButton: {
    float: 'left',
    color: blueMaterial
  },
  listPrimaryText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  listSecondaryText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

export default style;
