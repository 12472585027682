/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import lodash from 'lodash';
import prepareWebRtcProvider from '../../rtc';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
/* Components Imports */
import MeetingHeader from '../MeetingHeader';
import AudioElement from '../AudioElement';
import NavigationConfirmationDialog from '../NavigationConfirmationDialog';
import NavigationReconnectDialog from '../NavigationReconnectDialog';
import MeetingLeftDialog from '../MeetingLeftDialog';
import GuestLogin from '../GuestLogin';
import BodyLayout from '../../style/bodylayout';
import StandardLayout from './StandardLayout';
import FeaturedLayout from './FeaturedLayout';
/* Other Imports */
import { checkPermissionNotification, sendNotification } from '../../notification';
import { findUsers } from 'holocom-client/lib/utils/';
import TabTitle from '../../tabTitle';
/* Actions Imports */
import {
  createVideoRoom,
  kickUser,
  shareScreen,
  sendMouseEvent,
  startControllingDesktop,
  stopControllingDesktop,
  startDrawing,
  stopDrawing,
  addStream,
  toggleVideoMute,
  toggleAudioMute,
  toggleOwnAudioMute,
  togglePublishVideo,
  changeRole,
  removeStream,
  roomErrorAcked,
} from 'holocom-client/lib/actions/room';
import { joinRoom, leaveChannel } from 'holocom-client/lib/actions/websocket';
import { loginGuest } from 'holocom-client/lib/actions/auth';
/* Local Style */
import style from './style';

const messages = defineMessages({
  meetingHeader: { id: 'meetingHeader' },
  notificationMessage: {
    joinMeeting: { id: 'joinMeeting' },
    exitMeeting: { id: 'exitMeeting' },
    muted: { id: 'muted' },
    unmuted: { id: 'unmuted' },
    promotedToModerator: { id: 'promotedToModerator' },
    demotedModerator: { id: 'demotedModerator' },
    kicked: { id: 'kicked' },
    ended: { id: 'meetingEnded' },
    rosterMultipleChanges: { id: 'rosterMultipleChanges' }
  }
});

class Meeting extends Component {
  constructor(props) {
    super(props);
    this.streamOptions = {video: true};
    this.state = {
      nextLocation: null,
      videoToolBar: false,
      videoSmallToolBar: false,
      fullScreenStream: false,
      navigationConfirmationOpen: false,
      reconnectDialogOpen: Boolean(this.props.hasJoinFailed || this.props.roomError),
      meetingLeftDialogOpen: false,
      guestLoginDialogOpen: !(this.props.isAuthenticated && this.props.isAuthenticatedAsGuest),
    };

    this.rtc = prepareWebRtcProvider();

    this._onKeyUp = this._onKeyUp.bind(this);

    this.acknowledgeRoomError = this.acknowledgeRoomError.bind(this);
    this.hideReconnectDialog = this.hideReconnectDialog.bind(this);
    this.reconnect = this.reconnect.bind(this);
    this.onLeaveRoom = this.onLeaveRoom.bind(this);
    this.hideNavigateAwayConfirmationDialog = this.hideNavigateAwayConfirmationDialog.bind(this);
    this.navigateAway = this.navigateAway.bind(this);
    this.hideMeetingLeftDialog = this.hideMeetingLeftDialog.bind(this);
    this.hideGuestLoginDialog = this.hideGuestLoginDialog.bind(this);
    this.doGuestLogin = this.doGuestLogin.bind(this);

    this.mousemove = this.mousemove.bind(this);
    this.mouseupListener = this.mouseupListener.bind(this);
    this.mousedownListener = this.mousedownListener.bind(this);
    this.keydownListener = this.keydownListener.bind(this);
  }

  shouldShowLeaveRoomConfirmation(nextLocation) {
    if (nextLocation.state && nextLocation.state.doNotConfirm) {
      return false;
    } else if (!nextLocation) {
      return false;
    } else {
      return true;
    }
  }

  componentDidMount() {
    //this.resizeDetector.listenTo(this._getMyViewPort(), this.updateDimensions);
    if (this.props.isSocketConnected) {
      this._joinRoom(this.props);
    }
    this.unblockHistory = this.props.history.block(
      (nextLocation) => {
        if (this.shouldShowLeaveRoomConfirmation(nextLocation)) {
          this.setState({
            nextLocation: nextLocation,
            navigationConfirmationOpen: true,
          });
          return false;
        }
      });
    checkPermissionNotification(this.props.userAgent.os);
    window.addEventListener("keyup", this._onKeyUp, false);
    TabTitle.setTabTitle(this.getMeetingTitle());
  }

  _onKeyUp(ev) {
    const key = ev.key || '';
    if (!this._isTextArea(ev) && key.toLowerCase() === 'm') {
      this.onToggleOwnAudioMute(this.props.myUserId, !this.props.isOwnAudioMuted);
    }
  }

  _isTextArea(ev) {
    const targetType = (ev.target || {}).type;
    return (targetType === 'text' || targetType === 'textarea');
  }

  componentDidUpdate(prevProps) {
    if (this.props.isSocketConnected && !prevProps.isSocketConnected) {
      this._joinRoom(this.props);
    }
    if (this.props.roomJoined && !prevProps.roomJoined) {
      this.onPublish(this.props.meeting_id, this.streamOptions);
    }
    if (this.props.gotScreenToShare && !prevProps.gotScreenToShare) {
      const constraints = this.rtc.getScreenSharingConstraints(this.props.gotScreenToShare);
      this.onShareScreen(this.props.meeting_id, constraints);
    }

    if (this.props.hasJoinFailed && !prevProps.hasJoinFailed) {
      this.setState({ reconnectDialogOpen: true });
    }
    if (this.props.roomError && !prevProps.roomError) {
      this.setState({ reconnectDialogOpen: true });
    }
    if (this.props.meetingLeftReason && !prevProps.meetingLeftReason) {
      this.setState({ meetingLeftDialogOpen: true });
    }
    else if (this.state.meetingLeftDialogOpen && !this.props.meetingLeftReason) {
      this.setState({ meetingLeftDialogOpen: false });
    }
    if (!document.hasFocus()) {
      const currentUsersList = Object.keys(prevProps.users);
      const nextUsersList = Object.keys(this.props.users);
      const users = findUsers(currentUsersList, nextUsersList);
      if (this.props.meetingLeftReason !== 'kicked' && this.props.meetingLeftReason !== 'ended') {
        if (users.length === 1) {
          const user = users[0];
          if (user.status === 'joined') {
            const joinedUser = this.props.users[user.id];
            const title = this.props.intl.formatMessage(
              messages.notificationMessage.joinMeeting,
              { username: joinedUser.displayName }
            );
            sendNotification(title);
          } else if (user.status === 'exited') {
            const exitedUser = prevProps.users[user.id];
            const title = this.props.intl.formatMessage(
              messages.notificationMessage.exitMeeting,
              { username: exitedUser.displayName }
            );
            sendNotification(title);
          }
        } else if (users.length > 1) {
          let exitedUsers = 0;
          let joinedUsers = 0;
          users.forEach((user) => {
            if (user.status === 'exited') {
              exitedUsers++;
            } else if (user.status === 'joined') {
              joinedUsers++;
            }
          });
          const title = this.props.intl.formatMessage(
            messages.notificationMessage.rosterMultipleChanges,
            { joinedUsers: joinedUsers, exitedUsers: exitedUsers }
          );
          sendNotification(title);
        }
      }
    }

    if (prevProps.meetingDetails !== this.props.meetingDetails) {
      TabTitle.setTabTitle(this.getMeetingTitle());
    }

    if ((prevProps.isOwnAudioMuted !== this.props.isOwnAudioMuted)) {
      if (!document.hasFocus()) {
        const title = this.props.isOwnAudioMuted ?
          this.props.intl.formatMessage(messages.notificationMessage.muted)
          : this.props.intl.formatMessage(messages.notificationMessage.unmuted);
        sendNotification(title);
      }
      if (this.props.isOwnAudioMuted) {
        TabTitle.addMuteMarker();
      } else {
        TabTitle.removeMuteMarker();
      }
    }

    if (!document.hasFocus() && (prevProps.myRole !== this.props.myRole)) {
      switch (this.props.myRole) {
        case 'room_moderator': {
          const title = this.props.intl.formatMessage(messages.notificationMessage.promotedToModerator);
          sendNotification(title);
          break;
        }
        case 'room_user': {
          const title = this.props.intl.formatMessage(messages.notificationMessage.demotedModerator);
          sendNotification(title);
          break;
        }
        default: {
          break;
        }
      }
    }

    if (!document.hasFocus() && prevProps.meetingLeftReason !== this.props.meetingLeftReason) {
      if (this.props.meetingLeftReason === 'kicked') {
        const title = this.props.intl.formatMessage(messages.notificationMessage.kicked);
        sendNotification(title);
      }
      else if (this.props.meetingLeftReason === 'ended') {
        const title = this.props.intl.formatMessage(messages.notificationMessage.ended);
        sendNotification(title);
      }
    }

    if (this.props.isElectron) {
      if (!lodash.isEqual(this.props.users, prevProps.users)) {
        window.setRoster({...this.props.users, [this.props.myUserId]: {displayName: this.props.myDisplayName}});
      }
    }

  }

  componentWillUnmount() {
    //this.resizeDetector.removeAllListeners(this._getMyViewPort());
    if (this.props.isSocketConnected) {
      this.onLeave();
    }
    this.unblockHistory();
    window.removeEventListener("keyup", this._onKeyUp, false);
    TabTitle.resetTabTitle();
  }

  navigateAway() {
    this.setState({ navigationConfirmationOpen: false });
    this.unblockHistory();
    if (this.state.nextLocation && this.state.nextLocation.pathname) {
      return this.props.history.push(this.state.nextLocation.pathname);
    }
  }

  hideNavigateAwayConfirmationDialog() {
    this.setState({ navigationConfirmationOpen: false });
  }

  _joinRoom(props) {
    // create video room before join to set up event subscriptions
    // if join fails, the room will be tore down anyway
    props.dispatch(createVideoRoom(props.meeting_id, this.rtc, props.logger));
    props.dispatch(joinRoom(props.meeting_id, props.logger));
  }

  reconnect() {
    this.setState({
      reconnectDialogOpen: false
    });
    this.onLeave();
    this._joinRoom(this.props);
  }

  hideReconnectDialog() {
    this.setState({
      reconnectDialogOpen: false
    });
    this.props.history.push('/', { doNotConfirm: true });
  }

  acknowledgeRoomError() {
    this.onAcknowledgeRoomError();
  }

  hideGuestLoginDialog() {
    this.setState({
      guestLoginDialogOpen: false
    });
    this.props.history.push('/', { doNotConfirm: true });
  }

  hideMeetingLeftDialog() {
    this.setState({
      meetingLeftDialogOpen: false
    });
    this.props.history.push('/', { doNotConfirm: true });
  }

  doGuestLogin(displayName) {
    this.onGuestLogin(displayName);
  }

  getMeetingTitle() {
    const details = this.props.meetingDetails;
    const title = (details && details.title) ? details.title : this.props.meeting_id;
    return `${this.props.intl.formatMessage(messages.meetingHeader)}: ${title}`;
  }

  onLeaveRoom() {
    this.onLeave();
    this.props.history.push('/', { doNotConfirm: true });
  }

  sendMouseEvent(userId, displayName, mouseEventType, mouseEventData) {
    this.props.dispatch(sendMouseEvent(userId, displayName, mouseEventType, mouseEventData));
  }

  mouseupListener(e) {
    var data = this.getMouseData(e);
    this.sendMouseEvent(this.props.myUserId, this.props.myDisplayName, 'mouseUp', data);
  }

  mousedownListener(e) {
    var data = this.getMouseData(e);
    this.sendMouseEvent(this.props.myUserId, this.props.myDisplayName, 'mouseDown', data);
  }

  keydownListener(e) {
    e.preventDefault();

    var data = {
      key: e.key,
      keyCode: e.keyCode,
      shift: e.shiftKey,
      meta: e.metaKey,
      control: e.ctrlKey,
      alt: e.altKey,
    };

    this.sendMouseEvent(this.props.myUserId, this.props.myDisplayName, 'keydown', data);
  }

  mousemove(e) {
    var data = this.getMouseData(e);
    this.sendMouseEvent(this.props.myUserId, this.props.myDisplayName, 'move', data);
  }


  getMouseData(e) {
    var data = {};
    data.clientX = e.clientX;
    data.clientY = e.clientY;

    // var video = document.getElementById('remote-video');
    // if (video) {
    //   // videoSize = video.getBoundingClientRect();
    //   // data.canvasWidth = videoSize.width;
    //   // data.canvasHeight = videoSize.height;

    // }
    data.canvasWidth = window.screen.width;
    data.canvasHeight = window.screen.height;
    // data.canvasWidth = window.screen.availWidth;
    // data.canvasHeight = window.screen.availHeight;

    return data;
  }

  addWindowEventListener = () => {
    window.addEventListener('mousemove', this.mousemove);
    window.addEventListener('mouseup', this.mouseupListener);
    window.addEventListener('mousedown', this.mousedownListener);
    window.addEventListener('keydown', this.keydownListener);
  }

  removeWindowEventListener = () => {
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseupListener);
    window.removeEventListener('mousedown', this.mousedownListener);
    window.removeEventListener('keydown', this.keydownListener);

  }

  startControllingDesktop = () => {
    this.props.dispatch(startControllingDesktop());
  }

  stopControllingDesktop = () => {
    this.props.dispatch(stopControllingDesktop());
  }
  startDrawing = () => {
    this.props.dispatch(startDrawing());
  }

  stopDrawing = () => {
    this.props.dispatch(stopDrawing());
  }

  onKick = (who) => {
    this.props.dispatch(kickUser(who));
  }

  onChangeRole = (who, role) => {
    this.props.dispatch(changeRole(who, role));
  }

  onPublish(room, streamOptions) {
    this.props.dispatch(addStream(room, streamOptions, this.props.logger));
  }

  onShareScreen(room, constraints) {
    this.props.dispatch(shareScreen(room, constraints));
  }

  onToggleVideoMute = (user, muted) => {
    this.props.dispatch(toggleVideoMute(user, muted));
  }

  onToggleAudioMute = (user, muted) => {
    this.props.dispatch(toggleAudioMute(user, muted));
  }

  onToggleOwnAudioMute = (_user, muted) => {
    this.props.dispatch(toggleOwnAudioMute(muted));
  }

  onPublishVideo = (_user, published) => {
    this.props.dispatch(togglePublishVideo(published));
  }

  onLeave = () => {
    this._tearDownMeeting(this.props.dispatch);
  }

  onGuestLogin = (displayName) => {
    this.props.dispatch(loginGuest(displayName, this.props.localStore));
  }

  onAcknowledgeRoomError = () => {
    this.props.dispatch(roomErrorAcked());
  }

  // onClearMousePointers = () => {
  //   this.props.dispatch(clearMousePointers());
  // }

  _tearDownMeeting(dispatch) {
    dispatch(removeStream());
    dispatch(leaveChannel());
  }

  render() {
    const classes = this.props.classes;
    const layout = this.props.layout;
    return (
      <BodyLayout fullHeight={true} fullWidth={true}>
        <NavigationReconnectDialog
          open={this.state.reconnectDialogOpen}
          onCancel={this.hideReconnectDialog}
          onConfirm={this.reconnect}
          onLeave={this.onLeaveRoom}
          reason={this.props.joinErrorMessage}
          errorCode={this.props.joinErrorCode}
          errorPayload={this.props.joinErrorPayload}
          hasJoinFailed={this.props.hasJoinFailed}
          meetingId={this.props.meeting_id}
          isAuthenticated={this.props.isAuthenticated}
          roomError={this.props.roomError}
          onRoomErrorAcked={this.acknowledgeRoomError}
        />
        <NavigationConfirmationDialog
          open={this.state.navigationConfirmationOpen}
          onCancel={this.hideNavigateAwayConfirmationDialog}
          onConfirm={this.navigateAway}
        />
        <MeetingLeftDialog
          open={this.state.meetingLeftDialogOpen}
          reason={this.props.meetingLeftReason}
          onConfirm={this.hideMeetingLeftDialog}
        />
        {(!this.props.isAuthenticated && !this.props.isAuthenticatedAsGuest) &&
          <GuestLogin open={this.state.guestLoginDialogOpen}
            onCancel={this.hideGuestLoginDialog}
            onConfirm={this.doGuestLogin}
            meetingId={this.props.meeting_id}
          />
        }
        <div className={classes.block}>
          <AudioElement src={this.props.audioStream} handleAudioOutputChange={true} />
          <MeetingHeader title={this.getMeetingTitle()} />

          {layout === 'default' &&
            <StandardLayout
              userAgent={this.props.userAgent}
              isElectron={this.props.isElectron}
              removeWindowEventListener={this.removeWindowEventListener}
              addWindowEventListener={this.addWindowEventListener}
              startControllingDesktop={this.startControllingDesktop}
              stopControllingDesktop={this.stopControllingDesktop}
              startDrawing={this.startDrawing}
              stopDrawing={this.stopDrawing}
              handleScreenShare={this.props.handleScreenShare}
              enableDesktopControl={this.props.enableDesktopControl}
              onToggleVideoMute={this.onToggleVideoMute}
              onToggleAudioMute={this.onToggleAudioMute}
              screenSourceType={this.props.screenSourceType}
              onKick={this.onKick}
              isOwnVideoMuted={this.props.isOwnVideoMuted}
              onChangeRole={this.onChangeRole}
              onPublishVideo={this.onPublishVideo}
              localVideoTrack={this.props.localVideoTrack}
              onToggleOwnAudioMute={this.onToggleOwnAudioMute}
              onLeaveRoom={this.onLeaveRoom}
              isRecorder={this.props.isRecorder}
            />
          }
          {layout === 'featured' &&
            <FeaturedLayout
              userAgent={this.props.userAgent}
              handleScreenShare={this.props.handleScreenShare}
              removeWindowEventListener={this.removeWindowEventListener}
              addWindowEventListener={this.addWindowEventListener}
              startControllingDesktop={this.startControllingDesktop}
              stopControllingDesktop={this.stopControllingDesktop}
              startDrawing={this.startDrawing}
              stopDrawing={this.stopDrawing}
              isElectron={this.props.isElectron}
              screenSourceType={this.props.screenSourceType}
              enableDesktopControl={this.props.enableDesktopControl}
              onToggleVideoMute={this.onToggleVideoMute}
              onToggleAudioMute={this.onToggleAudioMute}
              onKick={this.onKick}
              onChangeRole={this.onChangeRole}
              onPublishVideo={this.onPublishVideo}
              isOwnVideoMuted={this.props.isOwnVideoMuted}
              localVideoTrack={this.props.localVideoTrack}
              onToggleOwnAudioMute={this.onToggleOwnAudioMute}
              onLeaveRoom={this.onLeaveRoom}
              isRecorder={this.props.isRecorder}
            />
          }
        </div>
      </BodyLayout>
    );
  }

}


Meeting.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  selectedLayout: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  meeting_id: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  handleScreenShare: PropTypes.func.isRequired,
  enableDesktopControl: PropTypes.func.isRequired,
  myUserId: PropTypes.string,
  layout: PropTypes.string,
  localVideoStream: PropTypes.object,
  audioStream: PropTypes.object,
  roomJoined: PropTypes.bool,
  isSocketConnected: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  hasJoinFailed: PropTypes.bool,
  logger: PropTypes.object.isRequired,
  meetingLeftReason: PropTypes.string,
  screenSourceType: PropTypes.string,
  localStore: PropTypes.object.isRequired,
  joinErrorCode: PropTypes.any,
  joinErrorMessage: PropTypes.string,
  isAuthenticatedAsGuest: PropTypes.bool,
  isElectron: PropTypes.bool,
  gotScreenToShare: PropTypes.string,
  screenStream: PropTypes.object,
  meetingDetails: PropTypes.object,
  joinErrorPayload: PropTypes.object,
  myDisplayName: PropTypes.string,
  localVideoTrack: PropTypes.object,
  isOwnAudioMuted: PropTypes.bool,
  isOwnVideoMuted: PropTypes.bool,
  userAgent: PropTypes.object.isRequired,
  roomError: PropTypes.object,
  myRole: PropTypes.string,
  users: PropTypes.object.isRequired,
  isRecorder: PropTypes.bool
};


const getVideoTrack = (stream) => {
  if (!stream) {
    return null;
  }
  else {
    return stream.getVideoTracks()[0];
  }
};


const getUsers = (state) => {
  const roster = state.room.roster;
  let users = {};
  let keys = Object.keys(roster);
  for (let user of keys) {
    users[user] = {
      displayName: roster[user].display,
    };
  }
  return users;
};

const getMyUserId = (state) => state.websocket.uid;

const createDeepEqualitySelector = createSelectorCreator(
  defaultMemoize,
  lodash.isEqual
);

const memoizedGetUsers = createDeepEqualitySelector([getUsers, getMyUserId], (users, myUid) => {
  if (!myUid) {
    return {};
  }
  let res = { ...users };
  delete res[myUid];
  return res;
});


function mapStateToProps(state) {
  const myUserId = state.websocket.uid;
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticatedAsGuest: state.auth.isAuthenticatedAsGuest,
    isSocketConnected: state.websocket.isConnected,
    screenSourceType: state.room.screenSourceType || "",
    hasJoinFailed: state.websocket.joinFailure,
    meetingLeftReason: state.websocket.leaveReason,
    joinErrorCode: state.websocket.errorCode,
    joinErrorMessage: state.websocket.errorMessage,
    joinErrorPayload: state.websocket.errorPayload,
    audioStream: state.room.audio_stream,
    localVideoStream: state.room.localvideo_stream,
    localVideoTrack: getVideoTrack(state.room.localvideo_stream),
    roomJoined: Boolean(state.websocket.room),
    gotScreenToShare: state.room.screenSourceId,
    screenStream: state.room.screenStream,
    myUserId: myUserId,
    layout: state.room.layout || 'default',
    myDisplayName: state.session.displayName,
    meetingDetails: state.websocket.meeting_details,
    isOwnAudioMuted: (state.room.roster[myUserId] || { isAudioMuted: false }).isAudioMuted,
    isOwnVideoMuted: (state.room.roster[myUserId] || { isVideoMuted: false }).isVideoMuted,
    roomError: state.room.roomError,
    users: memoizedGetUsers(state),
    myRole: (state.room.roster[myUserId] ? state.room.roster[myUserId].role : null),
    selectedLayout: state.room.layout,
    isRecorder: state.session.isRecorder
  };
}

export default withStyles(style)(injectIntl(connect(mapStateToProps)(Meeting)));
