"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capitalize = capitalize;
exports.rightmostSplit = rightmostSplit;

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function rightmostSplit(string, needle) {
  var idx = string.lastIndexOf(needle);

  if (idx >= 0) {
    var rest = string.substring(0, idx);
    var last = string.substring(idx + 1, string.length);
    return [rest, last];
  } else {
    return [string];
  }
}