const style = _theme => ({
  item: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  pulsating: {
    color: "red",
    animation: "pulse 3s infinite",
    "-webkit-animation-name": "pulse",
    "-webkit-animation-duration": "3s",
    "-webkit-animation-iteration-count": "infinite",
  },
  "@keyframes pulse": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.1,
    },
    "100%": {
      opacity: 1,
    },
  }
});

export default style;
