/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
/* Components Imports */
import Avatar from '../Avatar';
/* Other Imports */
import VideoTextOverlay from '../VideoTextOverlay';
/* Local Style */
import style from './style';


const messages = defineMessages({
  notSendingVideo: {id: 'publisherIsNotSendingVideo'},
});


class MutedVideoElement extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}>
        <div className={classes.avatarContainer}>
          <Typography className={classes.text} variant="caption">
            <Avatar
              displayName={this.props.displayName}
              viaPhone={this.props.viaPhone}
              uid={this.props.uid}
            />
            <VideoTextOverlay text={this.props.text} />
          </Typography>
        </div>
      </div>
    );
  }
}


MutedVideoElement.defaultProps = {
  messages: messages,
};


MutedVideoElement.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  displayName: PropTypes.string,
  viaPhone: PropTypes.bool,
  messages: PropTypes.object,
  uid: PropTypes.string,
  text: PropTypes.string,
};


export default withStyles(style)(injectIntl(MutedVideoElement));
