/* eslint max-len: 0 */
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let LogoutIcon = props =>
  <SvgIcon {...props}>
    <path d="M17,17.25V14H10V10H17V6.75L22.25,12L17,17.25M13,2A2,2 0 0,1 15,4V8H13V4H4V20H13V16H15V20A2,2 0 0,1 13,22H4A2,2 0 0,1 2,20V4A2,2 0 0,1 4,2H13Z"/>
  </SvgIcon>;

LogoutIcon = pure(LogoutIcon);
LogoutIcon.muiName = 'SvgIcon';

export default LogoutIcon;
