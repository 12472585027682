import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let PresentationIcon = props =>
  <SvgIcon {...props}>
    <path d="M2,3.4l1,1V16h5.8L7,22h2l1.8-6h2.5l1.8,6h2l-1.5-5.1l4.2,4.2l1.3-1.3L3.3,2.1L2,3.4z M5,14V6.4l7.6,7.6H5z"/>
    <path d="M14,3c0-1.1-0.9-2-2-2s-2,0.9-2,2H6.7l2,2H19v9h-1.3l2,2H21V3H14z"/>
  </SvgIcon>;

PresentationIcon = pure(PresentationIcon);
PresentationIcon.muiName = 'SvgIcon';

export default PresentationIcon;
