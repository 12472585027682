/**
 * @flow
 * @relayHash f000c9338d2112f42207134d1e2064a8
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ScheduleMeetingInput = {|
  meeting?: ?MeetingInput
|};
export type MeetingInput = {|
  attendees?: ?$ReadOnlyArray<?AttendeeInput>,
  doNotify?: ?boolean,
  domainId: string,
  dtEnd: string,
  dtStart: string,
  notes?: ?string,
  title: string,
  userId: string,
|};
export type AttendeeInput = {|
  email: string
|};
export type scheduleMeetingMutationVariables = {|
  input: ScheduleMeetingInput
|};
export type scheduleMeetingMutationResponse = {|
  +scheduleMeeting: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +key: string,
      +message: ?$ReadOnlyArray<?string>,
      +reason: ?$ReadOnlyArray<?string>,
    |}>,
    +meeting: ?{|
      +id: string,
      +userId: ?string,
      +domainId: ?string,
      +title: ?string,
      +url: ?string,
      +slug: ?string,
      +number: ?string,
    |},
  |}
|};
export type scheduleMeetingMutation = {|
  variables: scheduleMeetingMutationVariables,
  response: scheduleMeetingMutationResponse,
|};
*/

/*
mutation scheduleMeetingMutation(
  $input: ScheduleMeetingInput!
) {
  scheduleMeeting(input: $input) {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
      title
      url
      slug
      number
    }
  }
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = [{
    "kind": "LocalArgument",
    "name": "input",
    "type": "ScheduleMeetingInput!",
    "defaultValue": null
  }],
      v1 = [{
    "kind": "LinkedField",
    "alias": null,
    "name": "scheduleMeeting",
    "storageKey": null,
    "args": [{
      "kind": "Variable",
      "name": "input",
      "variableName": "input",
      "type": "ScheduleMeetingInput!"
    }],
    "concreteType": "ScheduleMeetingPayload",
    "plural": false,
    "selections": [{
      "kind": "LinkedField",
      "alias": null,
      "name": "errors",
      "storageKey": null,
      "args": null,
      "concreteType": "InputError",
      "plural": true,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "key",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "reason",
        "args": null,
        "storageKey": null
      }]
    }, {
      "kind": "LinkedField",
      "alias": null,
      "name": "meeting",
      "storageKey": null,
      "args": null,
      "concreteType": "Meeting",
      "plural": false,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "userId",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "domainId",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "title",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "url",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "slug",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "number",
        "args": null,
        "storageKey": null
      }]
    }]
  }];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "scheduleMeetingMutation",
      "type": "RootMutationType",
      "metadata": null,
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": v1
      /*: any*/

    },
    "operation": {
      "kind": "Operation",
      "name": "scheduleMeetingMutation",
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": v1
      /*: any*/

    },
    "params": {
      "operationKind": "mutation",
      "name": "scheduleMeetingMutation",
      "id": null,
      "text": "mutation scheduleMeetingMutation(\n  $input: ScheduleMeetingInput!\n) {\n  scheduleMeeting(input: $input) {\n    errors {\n      key\n      message\n      reason\n    }\n    meeting {\n      id\n      userId\n      domainId\n      title\n      url\n      slug\n      number\n    }\n  }\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = 'd43b4bf15abe6549000834392c351dd7';
module.exports = node;