/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import style from './style';
import VpnKey from '@material-ui/icons/VpnKey';
import Lock from '@material-ui/icons/Lock';
/* Components Imports */
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
/* Other imports */
import { regeneratePins } from 'holocom-client/lib/actions/user';


const messages = defineMessages({
  cancel: { id: 'cancel' },
  save: { id: 'save' },
  title: { id: 'regenPinsTabCmpTitle' },
  regenButton: { id: 'regenPinsTabCmpRegen' },
  publicPin: { id: 'regenPinsTabCmpPubPin' },
  personalPin: { id: 'regenPinsTabCmpPrivPin' },
});


class RegenPinsTab extends Component {

  getPrivPinText = () => {
    const formatMessage = this.props.intl.formatMessage;
    const label = formatMessage(messages.personalPin);
    const pin = this.props.roomOwnerNumber;
    return `${ label }: ${ pin }`;
  }

  getPubPinText = () => {
    const formatMessage = this.props.intl.formatMessage;
    const label = formatMessage(messages.publicPin);
    const pin = this.props.roomNumber;
    return `${ label }: ${ pin }`;
  }

  onRegenClick = () => {
    this.props.dispatch(regeneratePins(this.props.relayEnvironment));
  }

  render() {
    return (
      <React.Fragment>
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <form>
            <List>
              <ListItem>
                <Avatar>
                  <VpnKey />
                </Avatar>
                <ListItemText primary={this.getPubPinText()} >
                </ListItemText>
              </ListItem>
              <ListItem>
                <Avatar>
                  <Lock />
                </Avatar>
                <ListItemText primary={this.getPrivPinText()} />
              </ListItem>
            </List>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>
            {this.props.intl.formatMessage(messages.cancel)}
          </Button>
          <Button onClick={this.onRegenClick} color="primary">
            {this.props.intl.formatMessage(messages.regenButton)}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}


function mapStateToProps(state) {
  return {
    roomNumber: state.session.roomNumber,
    roomOwnerNumber: state.session.roomOwnerNumber,
  };
}


RegenPinsTab.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  relayEnvironment: PropTypes.object.isRequired,
  roomNumber: PropTypes.string,
  roomOwnerNumber: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};


export default withStyles(style)(injectIntl(connect(mapStateToProps)(RegenPinsTab)));

