import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';


const mergeProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
};


const PropsRoute = ({ component, ...rest }) => {
  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Route {...rest} render={routeProps => {
      return mergeProps(component, routeProps, rest);
    }}/>
  );
};


PropsRoute.propTypes = {
  component: PropTypes.func.isRequired,
};


export {PropsRoute};
