import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import style from './style';

class TalkingNotification extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.isTalking ? (
          <div className={classes.root}>
            <LinearProgress classes={{
              barColorPrimary: classes.progressBarFront, colorSecondary: classes.progressBarBack
            }} color="primary" variant="determinate" value={100} />
          </div>
        ) :
          null}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    isTalking: (state.room.roster[props.user] || {}).is_talking,
  };
}

TalkingNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.string,
  isTalking: PropTypes.bool
};

export default withStyles(style)(connect(mapStateToProps)(TalkingNotification));
