import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


const messages = defineMessages({
  ok: {id: 'ok'},
  cancel: {id: 'cancel'},
  title: {id: 'logoutDialogTitle'},
  body: {id: 'logoutDialogMessage'},
});


class Logout extends Component {
  handleCancel = () => {
    this.props.onCancelClick();
  }

  handleLogout = () => {
    this.props.onLogoutClick(this.props.authToken);
    this.props.history.push('/', { doNotConfirm: true });
  }

  render() {
    return (
      <Dialog
        open={true}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.intl.formatMessage(messages.body)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            {this.props.intl.formatMessage(messages.cancel)}
          </Button>
          <Button onClick={this.handleLogout} color="primary">
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


Logout.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  authToken: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    isSocketConnected: state.websocket.isConnected,
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.token,
  };
}

export default withStyles(null)(injectIntl(connect(mapStateToProps)(Logout)));
