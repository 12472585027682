const style = {
  root: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 20,
  },
  progressBarFront: {
    backgroundColor: 'rgba(76, 175, 80, 0.75)'
  },
  progressBarBack: {
    backgroundColor: 'rgba(192, 192, 192, 0.3)'
  }
};

export default style;
