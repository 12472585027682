/* eslint max-len: 0 */
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let VideoConferenceIcon = props =>
  <SvgIcon {...props}>
    <path d="M18,14L14,10.8V14H6V6H14V9.2L18,6M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z"/>
  </SvgIcon>;

VideoConferenceIcon = pure(VideoConferenceIcon);
VideoConferenceIcon.muiName = 'SvgIcon';

export default VideoConferenceIcon;
