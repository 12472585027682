"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultState = exports["default"] = void 0;

var _websocket = require("../actions/websocket");

var defaultState = {
  isConnected: false,
  isConnecting: false,
  token: null,
  errorCode: null,
  errorMessage: null,
  errorPayload: null,
  room: null,
  room_name: null,
  room_roles: [],
  leaveReason: null,
  uid: null,
  room_uid: null,
  joinFailure: false,
  meeting_details: {}
};
exports.defaultState = defaultState;

function websocket() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _websocket.SOCKET_CONNECTING:
      return Object.assign({}, state, {
        isConnected: false,
        isConnecting: true
      });

    case _websocket.SOCKET_CONNECT:
      return Object.assign({}, state, {
        isConnected: true,
        isConnecting: false,
        token: action.payload.token,
        apiserver: action.payload.apiserver,
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        room: null,
        room_name: null,
        room_uid: null
      });

    case _websocket.SOCKET_DISCONNECT:
      return Object.assign({}, state, {
        isConnected: false,
        isConnecting: false,
        token: null,
        apiserver: null,
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        room: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null
      });

    case _websocket.SOCKET_FAILURE:
      return Object.assign({}, state, {
        isConnected: false,
        isConnecting: false,
        token: null,
        apiserver: null,
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage,
        room: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null
      });

    case _websocket.JOIN_SUCCESS:
      return Object.assign({}, state, {
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        room: action.payload.room,
        room_name: action.payload.room_name,
        room_roles: action.payload.room_roles,
        meeting_details: action.payload.meeting_details,
        room_uid: action.payload.room_uid,
        leaveReason: null,
        uid: action.payload.uid
      });

    case _websocket.JOIN_FAILURE:
      return Object.assign({}, state, {
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.errorMessage,
        errorPayload: action.payload.errorPayload,
        room: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null,
        joinFailure: true,
        meeting_details: {}
      });

    case _websocket.LEAVE_SUCCESS:
      return Object.assign({}, state, {
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        room: null,
        room_name: null,
        room_roles: [],
        leaveReason: action.payload.reason,
        meeting_details: {},
        uid: null,
        room_uid: null
      });

    case _websocket.JOIN_REQUEST:
      return Object.assign({}, state, {
        errorCode: null,
        errorMessage: null,
        errorPayload: null,
        room: null,
        room_name: null,
        room_roles: [],
        uid: null,
        room_uid: null,
        leaveReason: null,
        joinFailure: false
      });

    case _websocket.SET_ROOM_ROLE:
      return Object.assign({}, state, {
        room_roles: [action.payload.role]
      });

    default:
      return state;
  }
}

var _default = websocket;
exports["default"] = _default;