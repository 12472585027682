"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.myProfileQuery = void 0;

var _reactRelay = require("react-relay");

var myProfileQuery = function myProfileQuery() {
  var node = require("./__generated__/myProfileQuery.graphql");

  if (node.hash && node.hash !== "f404e506b7c617f36d5b9f4c754da6e4") {
    console.error("The definition of 'myProfileQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/myProfileQuery.graphql");
};

exports.myProfileQuery = myProfileQuery;