export function isScreen(props) {
  const user = props.user || "";
  return props.type === 'screen' || user.endsWith('_screen');
}


export function getUserStream(user, props, state) {
  let stream;
  let muted = false;
  let videoTs = 0;
  if (user === props.myUserId) {
    if (isScreen(props)) {
      videoTs = state.room.localscreen_stream_ts;
      stream = state.room.screenStream;
    }
    else {
      stream = state.room.localvideo_stream;
      videoTs = state.room.localvideo_stream_ts;
      muted = (state.room.roster[user] || { isVideoMuted: true }).isVideoMuted;
    }
  }
  else {
    const defaultUserInfo = {
      screen: null,
      stream: null,
      screen_ts: 0,
      stream_ts: 0,
      isScreenMuted: true,
      isVideoMuted: true,
    };
    const userInfo = state.room.roster[user] || defaultUserInfo;
    if (isScreen(props)) {
      stream = userInfo.screen;
      videoTs = userInfo.screen_ts;
      muted = userInfo.isScreenMuted;
    }
    else {
      stream = userInfo.stream;
      videoTs = userInfo.stream_ts;
      muted = userInfo.isVideoMuted;
    }
  }
  return {stream, muted, videoTs};
}
