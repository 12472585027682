/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

/* UI Imports */
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowExpandIcon from '../../style/ArrowExpandIcon';
import ArrowCollapseIcon from '../../style/ArrowCollapseIcon';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Local Style */
import style from './style';

const messages = defineMessages({
  enlargeIconButtonTooltip: { id: 'enlargeIconButtonTooltip' },
  collapseIconButtonTooltip: { id: 'collapseIconButtonTooltip' }
});


class EnlargeIconButton extends Component {
  getIcon() {
    if (this.props.enlarge) {
      return (<ArrowExpandIcon />);
    }
    else {
      return (<ArrowCollapseIcon />);
    }
  }

  getTooltip() {
    if (this.props.enlarge) {
      return this.props.intl.formatMessage(messages.enlargeIconButtonTooltip);
    }
    else {
      return this.props.intl.formatMessage(messages.collapseIconButtonTooltip);
    }
  }

  render() {
    const classes = this.props.classes;

    return (
      <TooltipMultiLineText
        placement="top"
        title={this.getTooltip()}>
        <IconButton
          className={classNames({
            [classes.enlargeVideoIconButton]: true
          })}
          onClick={this.props.onClick}
        >
          {this.getIcon()}
        </IconButton>
      </TooltipMultiLineText>
    );
  }
}


EnlargeIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  enlarge: PropTypes.bool,
  intl: intlShape.isRequired
};

export default withStyles(style)(injectIntl((EnlargeIconButton)));
