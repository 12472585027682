const style = _theme => ({
  myFullVideo: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  myFullVideoContainer: {
    height: 'calc(100% - 20px)',
    width: '100%',
  },
  // fullScreenDiv: {
  //   top: '0!important',
  //   left: '0!important',
  //   right: '0!important',
  //   bottom: '0!important',
  //   position: 'fixed!important',
  //   width: '100%!important',
  //   height: '100%!important',
  //   zIndex: '100000'
  // },
  fullScreenDivHideCursor: {
    // top: '0!important',
    // left: '0!important',
    // right: '0!important',
    // bottom: '0!important',
    // position: 'fixed!important',
    // width: '100%!important',
    // height: '100%!important',
    // zIndex: '100000',
    // cursor: 'none',
  },

  pointerOverlay: {
    position: 'absolute',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
  },
  myVideoFeatured: {
    width: '75%',
    position: 'relative',
    display: 'table-cell',
  },
  bottomVideoFeatured: {
    position: 'relative',
    display: 'table-cell',
    width: '25%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  myVideoContainer: {
    height: 'calc(100% - 20px)',
    width: '100%',
    display: 'table',
  },
  myVideo: {
    position: 'relative',
    width: '100%',
    height: '80%',
  },
  bottomVideo: {
    position: 'relative',
    width: '100%',
    height: '18%',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  smallToolBar: {
    position: 'absolute',
    top: '2%',
    zIndex: 50,
    color: 'rgba(255, 255, 255, 0.75)',
  },
  enlargeVideoIcon: {
    top: 0,
    position: 'absolute',
    right: 0,
    zIndex: 99,
  },
  enlargeVideoIconButton: {
    color: 'rgba(255, 255, 255, 0.75)',
  },
  smallInfoToolBar: {
    position: 'absolute',
    top: '2%',
    right: 0,
    zIndex: 50,
    color: 'rgba(255, 255, 255, 0.75)',
  },
  audioMutedIcon: {
    position: 'absolute',
    bottom: '2%',
    zIndex: 50,
    left: 0,
  },
  feed: {
    height: '100%',
    backgroundColor: 'black',
  },
  block: {
    display: 'block',
    width: '100%',
    height: '100%',
  }
});

export default style;
