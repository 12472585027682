/**
 * @flow
 * @relayHash c22a67ea8525d3e580f7e5d6ba635dfa
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
type myMeetingsItem$ref = any;
export type FilterMatch = "CONTAINS" | "ENDS" | "EQUALS" | "GT" | "GTE" | "LT" | "LTE" | "STARTS" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type SearchSort = {|
  direction: SortDirection,
  sort: string,
|};
export type SearchFilter = {|
  key: string,
  match?: ?FilterMatch,
  value: string,
|};
export type myMeetingsQueryVariables = {|
  first?: ?number,
  after?: ?string,
  last?: ?number,
  before?: ?string,
  sorting?: ?$ReadOnlyArray<?SearchSort>,
  filters?: ?$ReadOnlyArray<?SearchFilter>,
|};
export type myMeetingsQueryResponse = {|
  +$fragmentRefs: myMeetingsItem$ref
|};
export type myMeetingsQuery = {|
  variables: myMeetingsQueryVariables,
  response: myMeetingsQueryResponse,
|};
*/

/*
query myMeetingsQuery(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $sorting: [SearchSort]
  $filters: [SearchFilter]
) {
  ...myMeetingsItem_3TNRfT
}

fragment myMeetingsItem_3TNRfT on RootQueryType {
  myMeetings(first: $first, after: $after, last: $last, before: $before, sorting: $sorting, filters: $filters) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        title
        url
        slug
        dtStart
        dtEnd
        id
        userId
        domainId
        notes
        attendees {
          email
        }
      }
    }
  }
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = [{
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  }, {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }, {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  }, {
    "kind": "LocalArgument",
    "name": "before",
    "type": "String",
    "defaultValue": null
  }, {
    "kind": "LocalArgument",
    "name": "sorting",
    "type": "[SearchSort]",
    "defaultValue": null
  }, {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "[SearchFilter]",
    "defaultValue": null
  }];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "myMeetingsQuery",
      "type": "RootQueryType",
      "metadata": null,
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": [{
        "kind": "FragmentSpread",
        "name": "myMeetingsItem",
        "args": [{
          "kind": "Variable",
          "name": "after",
          "variableName": "after",
          "type": null
        }, {
          "kind": "Variable",
          "name": "before",
          "variableName": "before",
          "type": null
        }, {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters",
          "type": null
        }, {
          "kind": "Variable",
          "name": "first",
          "variableName": "first",
          "type": null
        }, {
          "kind": "Variable",
          "name": "last",
          "variableName": "last",
          "type": null
        }, {
          "kind": "Variable",
          "name": "sorting",
          "variableName": "sorting",
          "type": null
        }]
      }]
    },
    "operation": {
      "kind": "Operation",
      "name": "myMeetingsQuery",
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": [{
        "kind": "LinkedField",
        "alias": null,
        "name": "myMeetings",
        "storageKey": null,
        "args": [{
          "kind": "Variable",
          "name": "after",
          "variableName": "after",
          "type": "String"
        }, {
          "kind": "Variable",
          "name": "before",
          "variableName": "before",
          "type": "String"
        }, {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters",
          "type": "[SearchFilter]"
        }, {
          "kind": "Variable",
          "name": "first",
          "variableName": "first",
          "type": "Int"
        }, {
          "kind": "Variable",
          "name": "last",
          "variableName": "last",
          "type": "Int"
        }, {
          "kind": "Variable",
          "name": "sorting",
          "variableName": "sorting",
          "type": "[SearchSort]"
        }],
        "concreteType": "MeetingConnection",
        "plural": false,
        "selections": [{
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [{
            "kind": "ScalarField",
            "alias": null,
            "name": "startCursor",
            "args": null,
            "storageKey": null
          }, {
            "kind": "ScalarField",
            "alias": null,
            "name": "endCursor",
            "args": null,
            "storageKey": null
          }, {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasNextPage",
            "args": null,
            "storageKey": null
          }, {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasPreviousPage",
            "args": null,
            "storageKey": null
          }]
        }, {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "MeetingEdge",
          "plural": true,
          "selections": [{
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          }, {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Meeting",
            "plural": false,
            "selections": [{
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "url",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "slug",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "dtStart",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "dtEnd",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "userId",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "domainId",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "notes",
              "args": null,
              "storageKey": null
            }, {
              "kind": "LinkedField",
              "alias": null,
              "name": "attendees",
              "storageKey": null,
              "args": null,
              "concreteType": "Attendee",
              "plural": true,
              "selections": [{
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }]
            }]
          }]
        }]
      }]
    },
    "params": {
      "operationKind": "query",
      "name": "myMeetingsQuery",
      "id": null,
      "text": "query myMeetingsQuery(\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n  $sorting: [SearchSort]\n  $filters: [SearchFilter]\n) {\n  ...myMeetingsItem_3TNRfT\n}\n\nfragment myMeetingsItem_3TNRfT on RootQueryType {\n  myMeetings(first: $first, after: $after, last: $last, before: $before, sorting: $sorting, filters: $filters) {\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n    edges {\n      cursor\n      node {\n        title\n        url\n        slug\n        dtStart\n        dtEnd\n        id\n        userId\n        domainId\n        notes\n        attendees {\n          email\n        }\n      }\n    }\n  }\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = 'eea37058b463fe376dd4875e1b3ece14';
module.exports = node;