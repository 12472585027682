"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.relayOperation = relayOperation;
exports.DELETE_MEETING = exports.SCHEDULE_MEETING = void 0;

var _relay = require("../actions/relay");

var _logger = require("../logger");

var SCHEDULE_MEETING = 'SCHEDULE_MEETING';
exports.SCHEDULE_MEETING = SCHEDULE_MEETING;
var DELETE_MEETING = 'DELETE_MEETING';
exports.DELETE_MEETING = DELETE_MEETING;

function relayOperation(dispatch, callback, reqId, operation, data) {
  var logger = (0, _logger.getLogger)("Relay api");

  if (!reqId) {
    logger.warning("no request ID set");
  }

  dispatch((0, _relay.relayRequest)(reqId, operation, data));
  callback().then(function () {
    return dispatch((0, _relay.relayDone)(reqId, operation, data));
  })["catch"](function (e) {
    dispatch((0, _relay.relayDone)(reqId, data, e));
    throw e;
  });
}