/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
/* Components imports */
import DialogMultiLineText from '../DialogMultiLineText';
/* Other imports */
import { Router } from 'holocom-client/lib/utils';
/* Local Style */
import style from './style';



const messages = defineMessages({
  ok: { id: 'ok' },
  tryAgain: { id: 'tryAgain' },
  leave: { id: 'leave' },
  error: { id: 'error' },
  networkError: { id: 'networkError' },
  roomErrorContent: { id: 'unexpectedRoomError' },
  clickHereToLogin: { id: 'clickHereToLogin' },
  clickHereToLoginToOwnRoom: { id: 'clickHereToLoginToOwnRoom' },
  errorCode: {
    1000: {
      title: { id: 'errorCode1000Title' },
      message: { id: 'errorCode1000Body' },
      secondaryButton: { id: 'errorCode1000SecondaryButton' },
      primaryButton: { id: 'errorCode1000PrimaryButton' }
    },
    1001: {
      title: { id: 'errorCode1001Title' },
      message: { id: 'errorCode1001Body' },
      secondaryButton: { id: 'errorCode1001SecondaryButton' },
      primaryButton: { id: 'errorCode1001PrimaryButton' }
    },
    1002: {
      title: { id: 'errorCode1002Title' },
      message: { id: 'errorCode1002Body' },
      secondaryButton: { id: 'errorCode1002SecondaryButton' },
      primaryButton: { id: 'errorCode1002PrimaryButton' }
    },
    1003: {
      title: { id: 'errorCode1003Title' },
      message: { id: 'errorCode1003Body' },
      secondaryButton: { id: 'errorCode1003SecondaryButton' },
      primaryButton: { id: 'errorCode1003PrimaryButton' }
    },
    1004: {
      title: { id: 'errorCode1004Title' },
      message: { id: 'errorCode1004Body' },
      secondaryButton: { id: 'errorCode1004SecondaryButton' },
      primaryButton: { id: 'errorCode1004PrimaryButton' }
    },
    1005: {
      title: { id: 'errorCode1005Title' },
      message: { id: 'errorCode1005Body' },
      secondaryButton: { id: 'errorCode1005SecondaryButton' },
      primaryButton: { id: 'errorCode1005PrimaryButton' }
    },
    1008: {
      title: { id: 'errorCode1008Title' },
      message: { id: 'errorCode1008Body' },
      secondaryButton: { id: 'errorCode1008SecondaryButton' },
      primaryButton: { id: 'errorCode1008PrimaryButton' }
    },
    1009: {
      title: { id: 'errorCode1009Title' },
      message: { id: 'errorCode1009Body' },
      secondaryButton: { id: 'errorCode1009SecondaryButton' },
      primaryButton: { id: 'errorCode1009PrimaryButton' }
    },
    1010: {
      title: { id: 'errorCode1010Title' },
      message: { id: 'errorCode1010Body' },
      secondaryButton: { id: 'errorCode1010SecondaryButton' },
      primaryButton: { id: 'errorCode1010PrimaryButton' }
    },
    1011: {
      title: { id: 'errorCode1011Title' },
      message: { id: 'errorCode1011Body' },
      secondaryButton: { id: 'errorCode1011SecondaryButton' },
      primaryButton: { id: 'errorCode1011PrimaryButton' }
    },
    1012: {
      title: { id: 'errorCode1012Title' },
      message: { id: 'errorCode1012Body' },
      secondaryButton: { id: 'errorCode1012SecondaryButton' },
      primaryButton: { id: 'errorCode1012PrimaryButton' }
    },
    1013: {
      title: { id: 'errorCode1013Title' },
      message: { id: 'errorCode1013Body' },
      secondaryButton: { id: 'errorCode1013SecondaryButton' },
      primaryButton: { id: 'errorCode1013PrimaryButton' }
    },
    4001: {
      title: { id: 'errorCode4001Title' },
      message: { id: 'errorCode4001Body' },
      secondaryButton: { id: 'errorCodeGenericSecondaryButton' },
      primaryButton: { id: 'errorCode4001PrimaryButton' }
    },
    generic: {
      title: { id: 'errorCodeGenericTitle' },
      message: { id: 'errorCodeGenericBody' },
      secondaryButton: { id: 'errorCodeGenericSecondaryButton' },
      primaryButton: { id: 'errorCodeGenericPrimaryButton' }
    }
  },
  not_found: { id: 'reconnectNotFound' },
  not_running: { id: 'reconnectNotRunning' },
  unit: {
    day: { id: 'day' },
    days: { id: 'days' },
    hour: { id: 'hour' },
    hours: { id: 'hours' },
    minute: { id: 'minute' },
    minutes: { id: 'minutes' }
  },
  listAttendees: { id: 'listAttendees' }
});


class NavigationReconnectDialog extends Component {
  constructor(props) {
    super(props);
    this.joinTimer = null;
    this.router = new Router();
    this.leaveRoom = this.leaveRoom.bind(this);
    this.reconnectToRoom = this.reconnectToRoom.bind(this);
    this.dismissError = this.dismissError.bind(this);
  }

  getErrorMessages(errorCode) {
    let msg = messages.errorCode[errorCode];
    if (!msg) {
      msg = messages.errorCode.generic;
    }
    return msg;
  }

  getActionButton(errorCode) {
    let onSecondaryButton;
    let onPrimaryButton;
    switch (errorCode) {
      case 1001:
      case 1002:
      case 1004:
      case 1005:
      case 1008:
      case 1009:
      case 1010:
      case 1011:
      case 1012:
      case 1013:
        onPrimaryButton = this.props.onConfirm;
        onSecondaryButton = this.props.onCancel;
        return { onPrimaryButton, onSecondaryButton };
      case 1000:
      case 1003:
      case 2000:
      case 4001:
      default:
        onPrimaryButton = this.props.onCancel;
        return { onPrimaryButton };
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.errorCode && (this.props.errorCode !== prevProps.errorCode || !this.joinTimer))) {
      if (this.props.errorCode === 1002 || this.props.errorCode === 1005) {
        this.stopJoinTimer();
        this.joinTimer = window.setTimeout(() => this.joinRoom(), 30000);
      }
    }
  }

  componentWillUnmount() {
    this.stopJoinTimer();
  }

  stopJoinTimer() {
    if (this.joinTimer) {
      window.clearTimeout(this.joinTimer);
      this.joinTimer = null;
    }
  }

  joinRoom() {
    this.props.onConfirm();
    this.stopJoinTimer();
  }

  leaveRoom() {
    this.props.onRoomErrorAcked();
    this.props.onLeave();
  }

  reconnectToRoom() {
    this.props.onRoomErrorAcked();
    this.props.onConfirm();
  }

  dismissError() {
    this.props.onRoomErrorAcked();
  }

  getDate(date) {
    const dateRoom = moment(date);
    const fullDateRoom = this.props.intl.formatDate(dateRoom, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
    const now = moment();
    const days = Math.abs(dateRoom.diff(now, 'days'));
    const hours = Math.abs(dateRoom.diff(now, 'hours') % 24);
    const minutes = Math.abs(dateRoom.diff(now, 'minutes') % 60);
    const unitDays = (days === 1) ? 'day' : 'days';
    const unitHours = (hours === 1) ? 'hour' : 'hours';
    const unitMinutes = (minutes === 1) ? 'minute' : 'minutes';
    return { unitDays, days, unitHours, hours, unitMinutes, minutes, fullDateRoom };
  }

  getLoginLink(classes, errorCode) {
    if (this.props.isAuthenticated) {
      // do not show if already authed
      return null;
    }
    const loginQs = this.props.meetingId ? `redirectTo=${this.props.meetingId}` : null;
    const loginRef = this.router.getUrlFor(this.router.LOGIN, null, loginQs);
    let errorMsg = this.props.intl.formatMessage(messages.clickHereToLogin);
    if (errorCode === 1000) {
      errorMsg = this.props.intl.formatMessage(messages.clickHereToLoginToOwnRoom);
    }
    if (errorCode === 1005 || errorCode === 1000) {
      return (
        <a href={loginRef} className={classes.loginLink}>
          <Typography color='primary' variant="caption" gutterBottom align="center">
            {errorMsg}
          </Typography>
        </a>
      );
    }
    return null;
  }

  getRoomErrorButtons() {
    if (this.props.roomError.recoverable) {
      return (
        <Button onClick={this.dismissError} color="primary">
          {this.props.intl.formatMessage(messages.ok)}
        </Button>
      );
    }
    else {
      return (
        <React.Fragment>
          <Button onClick={this.leaveRoom}>
            {this.props.intl.formatMessage(messages.leave)}
          </Button>
          <Button onClick={this.reconnectToRoom} color="primary">
            {this.props.intl.formatMessage(messages.tryAgain)}
          </Button>
        </React.Fragment>
      );
    }
  }

  renderErrorMessage(error) {
    let msg;
    if (error.errorCode === 408) {
      let networkError = this.props.intl.formatMessage(messages.networkError);
      msg = this.props.intl.formatMessage(messages.roomErrorContent, { errorMessage: networkError });
    }
    else {
      msg = this.props.intl.formatMessage(messages.roomErrorContent, { errorMessage: 'Generic Error' });
    }
    return msg;
  }

  render() {
    if (this.props.roomError) {
      return this.renderRoomError();
    }
    else {
      return this.renderJoinFailure();
    }
  }

  renderRoomError() {
    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.error)}
        </DialogTitle>
        <DialogContent>
          {this.renderErrorMessage(this.props.roomError)}
        </DialogContent>
        <DialogActions>
          {this.getRoomErrorButtons()}
        </DialogActions>
      </Dialog>
    );
  }

  renderJoinFailure() {
    const errorMessages = (this.props.errorCode && this.props.hasJoinFailed)
      ? this.getErrorMessages(this.props.errorCode)
      : null;
    const actionButton = this.getActionButton(this.props.errorCode);
    const { classes } = this.props;
    if (errorMessages) {
      const title = (this.props.errorPayload) ? (this.props.errorPayload.title) : null;
      const slug = (this.props.errorPayload) ? (this.props.errorPayload.slug) : null;
      const notes = (this.props.errorPayload) ? (this.props.errorPayload.notes) : null;
      const dt_start = (this.props.errorPayload) ? (this.props.errorPayload.dt_start) : null;
      const dt_end = (this.props.errorPayload) ? (this.props.errorPayload.dt_end) : null;
      const attendees = (this.props.errorPayload) ? (this.props.errorPayload.attendees) : null;
      const infoDateRoom = dt_start ? this.getDate(dt_start) : null;
      return (
        <Dialog
          open={this.props.open}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="md"
        >
          <DialogTitle>
            {this.props.intl.formatMessage(errorMessages.title, {
              title: title,
              slug: slug,
              notes: notes,
            })}
          </DialogTitle>
          <DialogContent>
            <DialogMultiLineText
              message={this.props.intl.formatMessage(errorMessages.message, {
                title: title,
                slug: slug,
                notes: notes,
                countdown: dt_start ? (this.getDate(dt_start).time) : null,
                dt_start: infoDateRoom ? (infoDateRoom.fullDateRoom) : null,
                days: infoDateRoom ? (infoDateRoom.days) : null,
                unitDays: infoDateRoom ? (this.props.intl.formatMessage(messages.unit[infoDateRoom.unitDays])) : null,
                hours: infoDateRoom ? (infoDateRoom.hours) : null,
                unitHours: infoDateRoom ? (this.props.intl.formatMessage(messages.unit[infoDateRoom.unitHours])) : null,
                minutes: infoDateRoom ? (infoDateRoom.minutes) : null,
                unitMinutes: infoDateRoom
                  ? (this.props.intl.formatMessage(messages.unit[infoDateRoom.unitMinutes]))
                  : null,
                dt_end: dt_end ? (this.getDate(dt_end).fullDateRoom) : null
              })} />
            {attendees && this.props.errorCode === 1002 &&
              <DialogContentText className={classes.attendeedList}>
                {this.props.intl.formatMessage(messages.listAttendees)}
                {attendees.map((item, key) => {
                  return <li key={key}> {item.email}</li>;
                })}
              </DialogContentText>
            }
          </DialogContent>
          <Divider light />
          <DialogActions classes={{ action: classes.root }}>
            <Grid container direction='row' alignItems='center' justify='space-between' className={classes.root}>
              <Grid item>
                {this.getLoginLink(classes, this.props.errorCode)}
              </Grid>
              <Grid item>
                {actionButton.onSecondaryButton &&
                  <Button onClick={actionButton.onSecondaryButton}>
                    {this.props.intl.formatMessage(errorMessages.secondaryButton)}
                  </Button>
                }
                {actionButton.onPrimaryButton &&
                  <Button onClick={actionButton.onPrimaryButton} color="primary">
                    {this.props.intl.formatMessage(errorMessages.primaryButton)}
                  </Button>
                }
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      );
    } else {
      return null;
    }
  }
}


NavigationReconnectDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  reason: PropTypes.string,
  open: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  errorCode: PropTypes.number,
  errorPayload: PropTypes.object,
  classes: PropTypes.object.isRequired,
  hasJoinFailed: PropTypes.bool,
  meetingId: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  roomError: PropTypes.object,
  onRoomErrorAcked: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
};


NavigationReconnectDialog.defaultProps = {
  reason: "generic"
};


export default withStyles(style)(injectIntl(NavigationReconnectDialog));
