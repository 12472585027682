"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.connectSocket = connectSocket;
exports.disconnectSocket = disconnectSocket;
exports.joinRoom = joinRoom;
exports.leaveChannel = leaveChannel;
exports.SET_ROOM_ROLE = exports.SET_USER_ROLE = exports.ROSTER_SET_AUDIO = exports.ROSTER_RESET = exports.ROSTER_CHANGE = exports.LEAVE_SUCCESS = exports.JOIN_REQUEST = exports.JOIN_SUCCESS = exports.JOIN_FAILURE = exports.SOCKET_DISCONNECT = exports.SOCKET_FAILURE = exports.SOCKET_CONNECTING = exports.SOCKET_CONNECT = void 0;

var _videoroom = require("../api/videoroom");

var _room = require("./room");

var _session = require("./session");

var _notifications = require("../notifications");

var SOCKET_CONNECT = 'SOCKET_CONNECT';
exports.SOCKET_CONNECT = SOCKET_CONNECT;
var SOCKET_CONNECTING = 'SOCKET_CONNECTING';
exports.SOCKET_CONNECTING = SOCKET_CONNECTING;
var SOCKET_FAILURE = 'SOCKET_FAILURE';
exports.SOCKET_FAILURE = SOCKET_FAILURE;
var SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
exports.SOCKET_DISCONNECT = SOCKET_DISCONNECT;
var JOIN_FAILURE = 'JOIN_FAILURE';
exports.JOIN_FAILURE = JOIN_FAILURE;
var JOIN_SUCCESS = 'JOIN_SUCCESS';
exports.JOIN_SUCCESS = JOIN_SUCCESS;
var JOIN_REQUEST = 'JOIN_REQUEST';
exports.JOIN_REQUEST = JOIN_REQUEST;
var LEAVE_SUCCESS = 'LEAVE_SUCCESS';
exports.LEAVE_SUCCESS = LEAVE_SUCCESS;
var ROSTER_CHANGE = 'ROSTER_CHANGE';
exports.ROSTER_CHANGE = ROSTER_CHANGE;
var ROSTER_RESET = 'ROSTER_RESET';
exports.ROSTER_RESET = ROSTER_RESET;
var ROSTER_SET_AUDIO = 'ROSTER_SET_AUDIO';
exports.ROSTER_SET_AUDIO = ROSTER_SET_AUDIO;
var SET_USER_ROLE = 'SET_USER_ROLE';
exports.SET_USER_ROLE = SET_USER_ROLE;
var SET_ROOM_ROLE = 'SET_ROOM_ROLE';
exports.SET_ROOM_ROLE = SET_ROOM_ROLE;

function connecting() {
  return {
    type: SOCKET_CONNECTING,
    payload: {}
  };
}

function connectionSuccess(token, apiserver) {
  return {
    type: SOCKET_CONNECT,
    payload: {
      token: token,
      apiserver: apiserver
    }
  };
}

function disconnectSuccess() {
  return {
    type: SOCKET_DISCONNECT,
    payload: {}
  };
}

function connectionError(errmessage, errcode) {
  return {
    type: SOCKET_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode
    },
    error: true
  };
}

function joinFailure(errmessage, errcode, payload) {
  return {
    type: JOIN_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode,
      errorPayload: payload
    },
    error: true
  };
}

function joinSuccess(room, name, uid, room_uid, meeting_details, room_roles) {
  return {
    type: JOIN_SUCCESS,
    payload: {
      room: room,
      room_name: name,
      room_roles: room_roles,
      uid: uid,
      room_uid: room_uid,
      meeting_details: meeting_details
    }
  };
}

function joinRequest() {
  return {
    type: JOIN_REQUEST,
    payload: {}
  };
}

function leaveSuccess(reason) {
  return {
    type: LEAVE_SUCCESS,
    payload: {
      reason: reason
    }
  };
}

function rosterChange(participants) {
  return {
    type: ROSTER_CHANGE,
    payload: {
      participants: participants
    }
  };
}

function rosterReset() {
  return {
    type: ROSTER_RESET,
    payload: {}
  };
}

function setRosterAudioMute(participantsAudioState) {
  return {
    type: ROSTER_SET_AUDIO,
    payload: {
      participants: participantsAudioState
    }
  };
}

function setRoomRole(role) {
  return {
    type: SET_ROOM_ROLE,
    payload: {
      role: role
    }
  };
}

function connect(token, logger, dispatch, getState, mouseEvent) {
  dispatch(connecting());
  var transport = new _videoroom.Transport('/socket', token);
  transport.connect().then(function () {
    var apiserver = new _videoroom.Api(transport, logger);

    var setAudioState = function setAudioState(members) {
      var res = {};
      Object.keys(members).forEach(function (k) {
        var metas = members[k].metas;

        if (metas && metas[0]) {
          res[k] = {
            muted: metas[0].muted
          };
        }
      });
      dispatch(setRosterAudioMute(res));
    };

    var applyLayout = function applyLayout(data) {
      dispatch((0, _room.forceLayout)(data.layout, data.layoutConfig));
    };

    var onLockRoom = function onLockRoom(data) {
      dispatch((0, _room.lockRoomStatus)(data.locked));
    };

    var onRecordingStatus = function onRecordingStatus(d) {
      if (d.is_recording !== undefined) {
        if (d.is_recording) {
          notifyStartRecording();
          dispatch((0, _room.startRecordingSuccess)());
        } else {
          notifyStopRecording();
          dispatch((0, _room.stopRecordingSuccess)());
        }
      }

      if (d.is_livestreaming !== undefined) {
        if (d.is_livestreaming) {
          notifyStartStreaming();
          dispatch((0, _room.startLivestreamingSuccess)());
        } else {
          notifyStopStreaming();
          dispatch((0, _room.stopLivestreamingSuccess)());
        }
      }
    };

    apiserver.subscribe('audio_state', function (d) {
      return setAudioState(d);
    });
    apiserver.subscribe('applyLayout', function (d) {
      return applyLayout(d);
    });
    apiserver.subscribe('lockRoom', function (d) {
      return onLockRoom(d);
    });
    apiserver.subscribe('recordingStatus', function (d) {
      return onRecordingStatus(d);
    });
    apiserver.subscribe('startDeskControl', function (d) {
      return dispatch((0, _room.onEnableDesktopControl)(d.user, true, d.desktopControlType));
    });
    apiserver.subscribe('stopDeskControl', function (d) {
      notifyStopDesktopControl();
      dispatch((0, _room.onEnableDesktopControl)(d.user, false, null));
    });
    apiserver.subscribe('startDrawing', function () {
      var state = (getState() || {}).settings;

      if (state.isElectron) {
        notifyStartDrawing();
      } else {
        notifyStartDrawingBrowser();
      }
    });
    apiserver.subscribe('stopDrawing', function () {
      notifyStopDrawing();
    });
    apiserver.subscribe('mouseEvent', function (d) {
      var state = (getState() || {}).websocket;

      if (state.uid !== d.userId) {
        // if (d.mouseEventType === 'move') {
        //   dispatch(setMousePointer(d));
        // }
        if (typeof mouseEvent !== 'undefined') {
          mouseEvent(d.userId, d.displayName, d.mouseEventType, d.mouseEventData);
        }
      }
    });
    apiserver.subscribe('channelevent', function (d) {
      var state = (getState() || {}).websocket;

      if (d.type === 'leave' && (d.reason === 'kicked' || d.reason === 'ended' || d.reason === 'ice_failed')) {
        var channel = (state || {}).room;

        if (channel) {
          channel.leave();
        }

        dispatch(leaveSuccess(d.reason));
      } else if (d.type === 'myrole_change' && d.role) {
        dispatch(setRoomRole(d.role));
      } else if (d.type === 'join_on_locked' && d.dname) {
        (0, _notifications.newEvent)(_notifications.INFO, 'joinOnLockedRoom', 'joinOnLockedRoom', "".concat(d.dname, " could not enter the room because it is locked"), {
          dname: d.dname
        });
      }
    });
    return dispatch(connectionSuccess(token, apiserver));
  })["catch"](function (reason) {
    dispatch(connectionError(reason, null));
  });
}

function disconnect(dispatch, getState) {
  var state = (getState() || {}).websocket;
  var apiserver = (state || {}).apiserver;

  if (apiserver && apiserver.transport) {
    apiserver.transport.disconnect();
    dispatch(disconnectSuccess());
  }
}

function recordingStatus(is_recording) {
  if (is_recording) {
    notifyStartRecording();
    return (0, _room.startRecordingSuccess)();
  } else {
    return (0, _room.stopRecordingSuccess)();
  }
}

function notifyStopDesktopControl() {
  (0, _notifications.newEvent)(_notifications.INFO, 'stopDesktopControl', 'stopDesktopControl', 'Remote desktop control for this room has been disabled');
}

function notifyStartDrawing() {
  (0, _notifications.newEvent)(_notifications.INFO, 'startDrawing', 'startDrawing', 'Remote drawing for this room has started');
}

function notifyStartDrawingBrowser() {
  (0, _notifications.newEvent)(_notifications.INFO, 'startDrawingBrowser', 'startDrawingBrowser', 'Remote drawing for this room has started. You need to manually start desktop share in fullscreen to use it.');
}

function notifyStopDrawing() {
  (0, _notifications.newEvent)(_notifications.INFO, 'stopDrawing', 'stopDrawing', 'Remote drawing for this room has been disabled');
}

function notifyStartRecording() {
  (0, _notifications.newEvent)(_notifications.INFO, 'roomStartRecording', 'roomStartRecording', 'This room is being recorded.');
}

function notifyStopRecording() {
  (0, _notifications.newEvent)(_notifications.INFO, 'roomStopRecording', 'roomStopRecording', 'Recording has been stopped.');
}

function streamingStatus(is_streaming) {
  if (is_streaming) {
    notifyStartStreaming();
    return (0, _room.startLivestreamingSuccess)();
  } else {
    return (0, _room.stopLivestreamingSuccess)();
  }
}

function notifyStartStreaming() {
  (0, _notifications.newEvent)(_notifications.INFO, 'roomStartStreaming', 'roomStartStreaming', 'This room is being streaming.');
}

function notifyStopStreaming() {
  (0, _notifications.newEvent)(_notifications.INFO, 'roomStopStreaming', 'roomStopStreaming', 'Streaming has been stopped.');
}

function configureRecording(dispatch, record_status) {
  // set the room recording status
  dispatch(recordingStatus(record_status.is_recording)); // set the room recording status

  dispatch(streamingStatus(record_status.is_livestreaming)); // flag the session as beloging to a recorder

  dispatch((0, _session.setRecorder)(record_status.is_recorder)); // flag the room as recordable or not

  dispatch((0, _room.setRecordable)(record_status.enabled)); // flag the room as livestreamable or not

  dispatch((0, _room.setLivestreamable)(record_status.livestreaming_enabled));
}

function configureDeskshare(dispatch, deskshare_status) {
  dispatch((0, _room.toggleDeskshare)(deskshare_status.enabled));
}

function configureDialout(dispatch, dialout_status) {
  dispatch((0, _room.toggleDialout)(dialout_status.enabled));
}

function join(roomname, dispatch, getState, logger) {
  var state = (getState() || {}).websocket;
  var apiserver = (state || {}).apiserver;
  var session = (getState() || {}).session;
  var realm = (session || {}).realm;
  var roomState = (getState() || {}).room;
  var roomObject = roomState.roomObject;
  dispatch(joinRequest());

  if (apiserver) {
    var topic = 'room:' + roomname + '@' + realm;

    var updateRosterCb = function updateRosterCb(users) {
      return dispatch(rosterChange(users));
    };

    apiserver.joinVideoRoom(topic, {}, updateRosterCb).then(function (_ref) {
      var channel = _ref.channel,
          uid = _ref.uid,
          room_uid = _ref.room_uid,
          meeting_details = _ref.meeting_details,
          room_roles = _ref.room_roles,
          layout = _ref.layout,
          locked = _ref.locked,
          record_status = _ref.record_status,
          deskshare_status = _ref.deskshare_status,
          deskcontrol_status = _ref.deskcontrol_status,
          dialout_status = _ref.dialout_status;
      channel.onError(function () {
        channel.leave();
        dispatch(joinFailure('channel_error', 1001));
      });

      if (roomObject) {
        roomObject.setUserId(uid);
        roomObject.setRoomId(room_uid);
      }

      dispatch(joinSuccess(channel, roomname, uid, room_uid, meeting_details, room_roles));
      configureRecording(dispatch, record_status);
      configureDeskshare(dispatch, deskshare_status);
      configureDialout(dispatch, dialout_status);
      dispatch((0, _room.lockRoomStatus)(locked));
      dispatch((0, _room.forceLayout)((layout || {}).layout, (layout || {}).layoutConfig));

      if (typeof deskcontrol_status !== 'undefined' && deskcontrol_status.running === true) {
        setTimeout(function () {
          dispatch((0, _room.onEnableDesktopControl)(deskcontrol_status.payload.user, true, deskcontrol_status.payload.desktopControlType));
        }, 500);
      }
    })["catch"](function (_ref2) {
      var type = _ref2.type,
          reason = _ref2.reason,
          payload = _ref2.payload,
          errorCode = _ref2.errorCode;
      logger.error("failed join", type, reason, errorCode);
      reason = type === 'timeout' ? 'timeout' : reason;
      errorCode = errorCode ? errorCode : 1001;
      dispatch(joinFailure(reason, errorCode, payload));
    });
  }
}

function leave(dispatch, getState) {
  var state = (getState() || {}).websocket;
  var channel = (state || {}).room;

  if (channel) {
    channel.leave();
  }

  dispatch(leaveSuccess('user'));
  dispatch(rosterReset());
}

function connectSocket(token, logger, mouseEvent) {
  return function (dispatch, getState) {
    return connect(token, logger, dispatch, getState, mouseEvent);
  };
}

function disconnectSocket() {
  return function (dispatch, getState) {
    return disconnect(dispatch, getState);
  };
}

function joinRoom(room, logger) {
  return function (dispatch, getState) {
    return join(room, dispatch, getState, logger);
  };
}

function leaveChannel() {
  return function (dispatch, getState) {
    return leave(dispatch, getState);
  };
}