const style = _theme => ({
  toolBar: {
    position: 'absolute',
    bottom: '10px',
    left: '50%',
    transform: 'translate(-50%)',
    borderRadius: '10px',
    zIndex: 99,
    justifyContent: 'center',
    background: 'rgba(255,255,255,0.75)',
    display: 'flex'
  },
  videoButton: {
    width: 36,
    height: 36,
    marginLeft: '3px',
    marginRight: '3px',
    color: 'rgba(0,0,0,0.75)',
    borderRadius: '36px'
  },
  controlButton: {
    width: 36,
    height: 36,
    marginLeft: '3px',
    marginRight: '3px',
    color: 'rgba(0,0,0,0.75)',
  },
  divider: {
    position: 'relative',
    '&:before': {
      content: "''",
      width: '2px',
      position: 'absolute',
      right: 0,
      top: 0,
      height: '80%',
      transform: 'translate(10%, 10%)',
      background: 'rgba(0, 0, 0, 0.2)',
    }
  },
  button: {
    width: '48px',
    height: '48px',
    padding: 0,
  },
  div: {
    display: 'flex',
  }
});

export default style;
