"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultState = exports["default"] = void 0;

var _notifications = require("../actions/notifications");

var defaultState = {
  notifications: []
};
exports.defaultState = defaultState;

function notifications() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _notifications.ADD_NOTIFICATION:
      {
        var new_notifications = [];

        if (action.payload) {
          new_notifications = state.notifications.concat([Object.assign({}, action.payload)]);
        } else {
          new_notifications = state.notifications;
        }

        return Object.assign({}, state, {
          notifications: new_notifications
        });
      }

    case _notifications.REMOVE_NOTIFICATION:
      {
        var payload = action.payload || {};
        return Object.assign({}, state, {
          notifications: state.notifications.filter(function (_ref) {
            var ref = _ref.ref;
            return payload.ref !== ref;
          })
        });
      }

    default:
      return state;
  }
}

var _default = notifications;
exports["default"] = _default;