/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MicOff from '@material-ui/icons/MicOff';
import PresentationIcon from '../../style/PresentationIcon';
import SignalMax from '../../style/SignalMax';
import SignalAnimated from '../../style/SignalAnimated';
import SignalMin from '../../style/SignalMin';
import SignalMed from '../../style/SignalMed';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Other imports */
import { isScreen, getUserStream } from '../../state';
/* Local Style */
import style from './style';


const messages = defineMessages({
  screenName: { id: 'screenVideoDisplayName' },
  microphoneButtonDisabledTooltip: { id: 'microphoneButtonDisabledTooltip' },
  presentationIconTooltip: { id: 'presentationIconTooltip' },
  highLinkQuality: { id: 'highLinkQuality' },
  mediumLinkQuality: { id: 'mediumLinkQuality' },
  lowLinkQuality: { id: 'lowLinkQuality' },
  computingLinkQuality: { id: 'computingLinkQuality' },
  linkQualityNotAvailable: { id: 'linkQualityNotAvailable' },
});


class VideoInfoToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = { preventLinkQualityFromShowing: true };
    this.linkQualityTimer = null;
  }

  componentDidMount() {
    const now = Math.floor(Date.now() / 1000);
    let showProgress = false;
    if (now - this.props.videoPublishedTs < 30) {
      showProgress = true;
    }
    if (this.props.videoPublished && !this.linkQualityTimer && showProgress) {
      this._startLinkQualityTimer();
    }
    if (!this.linkQualityTimer && !showProgress) {
      this.setState({ preventLinkQualityFromShowing: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.videoPublished !== prevProps.videoPublished) {
      this.setState({ preventLinkQualityFromShowing: true });
      if (this.props.videoPublished && !this.linkQualityTimer) {
        this._startLinkQualityTimer();
      }
      else if (this.linkQualityTimer) {
        this._destroyLinkQualityTimer();
      }
    }
  }

  componentWillUnmount() {
    this._destroyLinkQualityTimer();
  }

  _startLinkQualityTimer() {
    this._destroyLinkQualityTimer();
    this.linkQualityTimer = setTimeout(() => {
      this.setState({ preventLinkQualityFromShowing: false });
    }, 30 * 1000);
  }

  _destroyLinkQualityTimer() {
    if (this.linkQualityTimer) {
      clearTimeout(this.linkQualityTimer);
    }
    this.linkQualityTimer = null;
  }

  renderName(what) {
    const cname = what.name.replace(/\b\w/g, w => w.toUpperCase());
    if ((what.type === 'screen') || (what.user && what.user.endsWith('_screen'))) {
      return this.props.intl.formatMessage(messages.screenName, { name: cname });
    }
    else {
      return cname;
    }
  }

  renderUplinkQuality(quality, classes) {
    if (!quality) {
      return null;
    }
    let color = classes.greenIcon;
    let tooltip = this.props.intl.formatMessage(messages.highLinkQuality);
    let icon = <SignalMax className={color} />;
    if (this.state.preventLinkQualityFromShowing) {
      color = classes.grayIcon;
      tooltip = this.props.intl.formatMessage(messages.computingLinkQuality);
      if (this.props.videoPublished) {
        icon = <SignalAnimated className={color} />;
      }
      else {
        tooltip = this.props.intl.formatMessage(messages.linkQualityNotAvailable);
        icon = <SignalMax className={color} />;
      }
    }
    else if (quality === 'low') {
      color = classes.redIcon;
      tooltip = this.props.intl.formatMessage(messages.lowLinkQuality);
      icon = <SignalMin className={color} />;
    }
    else if (quality === 'medium') {
      color = classes.orangeIcon;
      tooltip = this.props.intl.formatMessage(messages.mediumLinkQuality);
      icon = <SignalMed className={color} />;
    }
    return (
      <TooltipMultiLineText
        placement="top"
        title={tooltip}
      >
        <div>
          {icon}
        </div>
      </TooltipMultiLineText>
    );
  }



  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.toolbar}>
        {this.props.isAudioMuted ? (
          <TooltipMultiLineText
            placement="top"
            title={this.props.intl.formatMessage(messages.microphoneButtonDisabledTooltip)}
          >
            <MicOff className={classes.redIcon} />
          </TooltipMultiLineText>
        ) : null}
        {this.renderUplinkQuality(this.props.uplinkQuality, classes)}
        {['room_owner', 'room_moderator'].includes(this.props.userRole)
          ? (
            <TooltipMultiLineText
              placement="top"
              title={this.props.intl.formatMessage(messages.presentationIconTooltip)}
            >
              <PresentationIcon className={classes.icon} />
            </TooltipMultiLineText>)
          : null
        }
        <Typography className={classes.name} variant="subtitle1">
          {this.renderName(this.props)}
        </Typography>
      </div>
    );
  }
}

VideoInfoToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isAudioMuted: PropTypes.bool,
  name: PropTypes.string,
  displayName: PropTypes.string,
  user: PropTypes.string,
  myUserId: PropTypes.string,
  type: PropTypes.string,
  userRole: PropTypes.string,
  uplinkQuality: PropTypes.string,
  videoPublishedTs: PropTypes.number,
  videoPublished: PropTypes.bool,
};

VideoInfoToolbar.defaultProps = {
  type: 'user',
};


function mapStateToProps(state, props) {
  let user = props.user;
  if (user) {
    user = user.replace(/_screen$/, '');
  }
  const userInfo = state.room.roster[user];
  const rosterName = (userInfo || { display: 'anonymous' }).display;
  let videoQuality;
  if (isScreen(props)) {
    videoQuality = (userInfo || { screen_uplink_quality: null }).screen_uplink_quality;
  }
  else {
    videoQuality = (userInfo || { video_uplink_quality: null }).video_uplink_quality;
  }
  let userStream = getUserStream(user, props, state);
  return {
    name: props.displayName || rosterName,
    isAudioMuted: (userInfo || { isAudioMuted: false }).isAudioMuted,
    userRole: (userInfo || {}).role,
    uplinkQuality: videoQuality,
    videoPublishedTs: userStream.videoTs,
    videoPublished: userStream.stream && !userStream.muted,
  };
}

export default withStyles(style)(injectIntl(connect(mapStateToProps)(VideoInfoToolbar)));
