/* eslint max-len: 0 */
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let SignalMax = props =>
  <SvgIcon {...props}>
    <path d="M3,21H6V18H3m5,3h3V14H8" />
  </SvgIcon>;

SignalMax = pure(SignalMax);
SignalMax.muiName = 'SvgIcon';

export default SignalMax;
