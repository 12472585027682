/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import lodash from 'lodash';
import { withRouter } from 'react-router';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Divider from '@material-ui/core/Divider';
import Shush from '../../style/Shush';
import ShushOff from '../../style/ShushOff';
import QuitIcon from '../QuitIcon';
import CloseOutline from '../../style/CloseOutline';
/* Actions Imports */
import {
  muteAll,
  unMuteAll,
  removeStream,
  toggleVideoMute,
} from 'holocom-client/lib/actions/room';
import { leaveChannel } from 'holocom-client/lib/actions/websocket';


const messages = defineMessages({
  muteAllButton: { id: 'muteAllButton' },
  unMuteAllButton: { id: 'unMuteAllButton' },
  muteAllVideos: {id: 'muteAllVideos'},
  endMeeting: {id: 'endMeetingMsg'},
  leaveButton: {id: 'leave'},
});


const style = {
  red: {
    color: '#d21818',
  }
};


class RoomActionsMenu extends Component {

  constructor(props) {
    super(props);
    this.roles = ['room_owner', 'room_moderator'];

    this.muteAll = this.muteAll.bind(this);
    this.unMuteAll = this.unMuteAll.bind(this);
    this.muteAllVideos = this.muteAllVideos.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
    this.endMeeting = this.endMeeting.bind(this);
  }

  muteAll() {
    this.props.dispatch(muteAll());
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  unMuteAll() {
    this.props.dispatch(unMuteAll());
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  leaveRoom() {
    this.props.dispatch(removeStream());
    this.props.dispatch(leaveChannel());
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.props.history.push('/', { doNotConfirm: true });
  }

  muteAllVideos() {
    this.props.others.forEach(
      (user) => this.props.dispatch(toggleVideoMute(user, true))
    );
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  endMeeting() {
    this.props.onEndMeeting();
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const classes = this.props.classes;
    const authorized = this.roles.includes(this.props.myRole);
    const muteDisabled = this.props.isRequestingAllAudioMute || !authorized;
    const endMeetingDisabled = !authorized;
    return (
      <List dense>
        <ListItem
          button
          disabled={muteDisabled}
          onClick={this.muteAll}
        >
          <ListItemIcon>
            <Shush />
          </ListItemIcon>
          <ListItemText primary={this.props.intl.formatMessage(messages.muteAllButton)} />
        </ListItem>
        <ListItem
          button
          disabled={muteDisabled}
          onClick={this.unMuteAll}
        >
          <ListItemIcon>
            <ShushOff />
          </ListItemIcon>
          <ListItemText primary={this.props.intl.formatMessage(messages.unMuteAllButton)} />
        </ListItem>
        <ListItem
          button
          onClick={this.muteAllVideos}
        >
          <ListItemIcon>
            <VideocamOff />
          </ListItemIcon>
          <ListItemText primary={this.props.intl.formatMessage(messages.muteAllVideos)} />
        </ListItem>
        <Divider />
        <ListItem
          button
          disabled={endMeetingDisabled}
          onClick={this.endMeeting}
        >
          <ListItemIcon>
            <CloseOutline className={classes.red}/>
          </ListItemIcon>
          <ListItemText primary={this.props.intl.formatMessage(messages.endMeeting)} />
        </ListItem>
        <Divider />
        <ListItem
          button
          onClick={this.leaveRoom}
        >
          <ListItemIcon>
            <QuitIcon />
          </ListItemIcon>
          <ListItemText primary={this.props.intl.formatMessage(messages.leaveButton)} />
        </ListItem>
      </List>
    );
  }
}


const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  lodash.isEqual
);


const getMyInfo = (state) => {
  const me = (state.websocket || {}).uid;
  return state.room.roster[me];
};


const getUsers = (state) => {
  const roster = state.room.roster;
  return Object.keys(roster);
};


const getMyUid = (state) => {
  return (state.websocket || {}).uid;
};


const getAudioMute = createDeepEqualSelector(getMyInfo, (u) => u.isRequestingAllAudioMute || false);


const getMyRole = createDeepEqualSelector(getMyInfo, (u) => u.role || '');


const getOtherMembers = createDeepEqualSelector(
  getUsers,
  getMyUid,
  (users, me) => users.filter(u => u !== me)
);


function mapStateToProps(state, _props) {
  return {
    myRole: getMyRole(state),
    isRequestingAllAudioMute: getAudioMute(state),
    users: getUsers(state),
    others: getOtherMembers(state),
  };
}


RoomActionsMenu.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  myRole: PropTypes.string,
  others: PropTypes.array,
  isRequestingAllAudioMute: PropTypes.bool,
  onEndMeeting: PropTypes.func.isRequired,
};

export default withStyles(style)(injectIntl(withRouter(connect(mapStateToProps)(RoomActionsMenu))));
