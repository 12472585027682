"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultState = exports["default"] = void 0;

var _relay = require("../actions/relay");

var defaultState = {
  isFetching: false,
  lastRequestId: null,
  operation: null,
  data: null,
  error: null
};
exports.defaultState = defaultState;

function relay() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _relay.RELAY_REQUEST:
      return Object.assign({}, state, {
        type: _relay.RELAY_REQUEST,
        isFetching: true,
        lastRequestId: action.payload.lastRequestId,
        operation: action.payload.operation,
        data: action.payload.data
      });

    case _relay.RELAY_DONE:
      return Object.assign({}, state, {
        type: _relay.RELAY_DONE,
        isFetching: false,
        lastRequestId: action.payload.lastRequestId,
        operation: action.payload.operation,
        data: action.payload.data,
        error: action.payload.error
      });

    default:
      return state;
  }
}

var _default = relay;
exports["default"] = _default;