/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
/* Components Imports */
import MeetingSelector from '../MeetingSelector';
import Login from '../Login';
import Logout from '../Logout';
import About from '../About/';
import Meeting from '../Meeting';
import NotFound from '../NotFound';
import { logoutUser } from 'holocom-client/lib/actions/auth';
/* Other Imports */
import { PropsRoute } from '../propsroute';
import { getLogger } from 'holocom-client/lib/logger';
import { Router } from 'holocom-client/lib/utils';
/* Local Style */
import style from './style';


function getLoginComp(props) {
  const { appConfig, history, localStore } = props;
  return (
    <Login appConfig={appConfig} history={history} localStore={localStore} />
  );
}


getLoginComp.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  localStore: PropTypes.object.isRequired,
  appConfig: PropTypes.object.isRequired
};

function getLogoutComp(props) {
  const { history, localStore, onLogout, onCancel } = props;
  return (
    <Logout
      history={history}
      localStore={localStore}
      onLogoutClick={onLogout}
      onCancelClick={onCancel}
    />
  );
}


getLogoutComp.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  localStore: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


function getMeetingSelectorComp(props) {
  const { history, relayEnvironment } = props;
  return (
    <MeetingSelector
      history={history}
      relayEnvironment={relayEnvironment}
    />
  );
}


getMeetingSelectorComp.propTypes = {
  history: PropTypes.object.isRequired,
  relayEnvironment: PropTypes.object.isRequired
};


function getMeetingComp(props) {
  let logger = getLogger(`Meeting ${props.match.params.id}`);
  return (
    <Meeting
      history={props.history}
      dispatch={props.dispatch}
      isElectron={props.isElectron}
      handleScreenShare={props.handleScreenShare}
      enableDesktopControl={props.enableDesktopControl}
      logger={logger}
      localStore={props.localStore}
      meeting_id={props.match.params.id}
      userAgent={props.userAgent}
    />
  );
}


getMeetingComp.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleScreenShare: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isElectron: PropTypes.bool.isRequired,
  enableDesktopControl: PropTypes.func.isRequired,
  localStore: PropTypes.object.isRequired,
  userAgent: PropTypes.object.isRequired,
};


class NavigationMain extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  onLogoutCancel = () => {
    this.props.history.push('/');
  }

  onLogoutClick = (token) => {
    this.props.dispatch(logoutUser(token, this.props.localStore));
  }

  render() {
    const router = new Router();
    const classes = this.props.classes;
    return (
      <main className={classes.content}>
        <Switch>
          <PropsRoute
            exact
            path={router.getUrlFor(router.HOME)}
            relayEnvironment={this.props.relayEnvironment}
            component={getMeetingSelectorComp}
          />
          <Route exact path={router.getUrlFor(router.ABOUT)} component={About} />
          <PropsRoute
            exact
            path={router.getUrlFor(router.LOGIN)}
            dispatch={this.props.dispatch}
            localStore={this.props.localStore}
            appConfig={this.props.appConfig}
            component={getLoginComp}
          />
          {this.props.authToken &&
            <PropsRoute
              exact
              path={router.getUrlFor(router.LOGOUT)}
              dispatch={this.props.dispatch}
              localStore={this.props.localStore}
              onLogout={this.onLogoutClick}
              onCancel={this.onLogoutCancel}
              component={getLogoutComp}
            />
          }
          <PropsRoute
            exact
            path={router.getUrlFor(router.MEETING, { slug: ':id' })}
            dispatch={this.props.dispatch}
            isElectron={this.props.isElectron}
            localStore={this.props.localStore}
            enableDesktopControl={this.props.enableDesktopControl}
            handleScreenShare={this.props.handleScreenShare}
            component={getMeetingComp}
            userAgent={this.props.userAgent}
          />
          <Route component={NotFound} />
        </Switch>
      </main>
    );
  }
}


NavigationMain.propTypes = {
  appConfig: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  localStore: PropTypes.object.isRequired,
  userAgent: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isElectron: PropTypes.bool.isRequired,
  enableDesktopControl: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleScreenShare: PropTypes.func.isRequired,
  authToken: PropTypes.string,
  relayEnvironment: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.token
  };
}


export default withStyles(style)(withRouter(connect(mapStateToProps)(NavigationMain)));
