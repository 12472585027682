/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import gridElements from 'holocom-client/lib/grid';
/* Other Imports */
import style from './style';
import * as featuredDimension from './FeaturedDimensionLayout.js';
import PiP from '../../pip';
/* Component Imports */
import TalkingNotification from '../TalkingNotification';
import OwnMutedVideoElement from '../OwnMutedVideoElement';
import OwnVideoToolbar from '../OwnVideoToolbar';
import VideoElement from '../VideoElement';
import MutedVideoElement from '../MutedVideoElement';
import MenuVideoToolbar from '../MenuVideoToolbar';
import FullScreen from '../Fullscreen';
import VideoToolbar from '../VideoToolbar';
import VideoInfoToolbar from '../VideoInfoToolbar';
import MenuVideoInfoToolbar from '../MenuVideoInfoToolbar';
import AudioMutedIcon from '../MenuVideoInfoToolbar/AudioMutedIcon';
import NoVideoElement from '../NoVideoElement';
import EnlargeIconButton from './EnlargeIconButton';
// import DesktopControlPointer from '../DesktopControlPointer';
import OwnScreenShareToolbar from '../OwnScreenShareToolbar';
/* Other imports */
import elementResizeDetectorMaker from 'element-resize-detector';
import { expandStreamVideo, enlargeStreamVideo } from 'holocom-client/lib/actions/room';
import { newEvent, INFO } from 'holocom-client/lib/notifications';
import { getMemoizedDeskControlledUser, getDrawingHasStarted } from './reduxSelectors';

const messages = defineMessages({
  screenName: { id: 'screenVideoDisplayName' },
});

class FeaturedLayout extends Component {

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.updateBottomDimensions = this.updateBottomDimensions.bind(this);
    this.onEnlargeVideo = this.onEnlargeVideo.bind(this);
    this.onFeaturedStream = this.onFeaturedStream.bind(this);
    this.onToggleVideoMute = props.onToggleVideoMute.bind(this);
    this.onToggleAudioMute = props.onToggleAudioMute.bind(this);
    this.onToggleOwnAudioMute = props.onToggleOwnAudioMute.bind(this);
    this.resizeDetector = elementResizeDetectorMaker({ strategy: "scroll" });
    this.leaveFullScreen = this.leaveFullScreen.bind(this);
    this.startDrawing = this.startDrawing.bind(this);
    this.stopDrawing = this.stopDrawing.bind(this);
    this.exitFullScreenDesktopControl = this.exitFullScreenDesktopControl.bind(this);

    this.state = {
      videoToolBar: false,
      videoSmallToolBar: false,
      fullScreenStream: false,
      fullScreenIdx: null,
      enlargedVideo: false,
      gridBoxes: [],
      bottomBoxes: [],
      controlledUserInFullScreen: null,
      pipUser: null, // stream with picture-in-picture enabled
    };
    const os = (props.userAgent.os || '').toLowerCase();
    if (os === 'ios') {
      // fullscreen is not suppoerted on ios
      this.onExitFullScreen = null;
      this.onEnterFullScreen = null;
    }
    else {
      this.onExitFullScreen = this.onExitFullScreen.bind(this);
      this.onEnterFullScreen = this.onEnterFullScreen.bind(this);
    }

    this.pip = new PiP(this.video);
  }

  _getMyViewPort() {
    return ReactDOM.findDOMNode(this.videoArea);// eslint-disable-line react/no-find-dom-node
  }

  _getMyBottomViewPort() {
    return ReactDOM.findDOMNode(this.videoBottomArea);// eslint-disable-line react/no-find-dom-node
  }

  videoToolbarIsOnFor(user) {
    if (user && this.state.videoToolBar === user) {
      return true;
    }
    return false;
  }

  videoSmallToolbarIsOnFor(user) {
    if (user && this.state.videoSmallToolBar === user) {
      return true;
    }
    return false;
  }

  onPublishVideo = (user, published) => {
    this.disablePip();
    this.props.onPublishVideo(user, published);
  }

  onLeaveRoom = () => {
    this.disablePip();
    this.pip.set(false);
    this.props.onLeaveRoom();
  }

  onKick = (user) => {
    this.props.onKick(user);
  }

  onChangeRole = (user, role) => {
    this.props.onChangeRole(user, role);
  }

  showOwnToolbar = () => {
    this.setState({ videoToolBar: this.props.myUserId });
  }

  hideOwnToolbar = () => {
    this.setState({ videoToolBar: false });
  }

  onEnlargeVideo() {
    //this.setState({ enlargedVideo: !this.state.enlargedVideo });
    this.props.dispatch(enlargeStreamVideo(!this.props.featuredEnlarged));
  }

  onExitFullScreen() {
    this.setState({ fullScreenStream: null, fullScreenIdx: null });
    this.removeWindowEventListener();
  }

  onEnterFullScreen(user, idx) {
    if (user.endsWith('_screen')) {
      if (user.replace(/_screen$/, '') === this.props.desktopControlledUser) {
        this.addWindowEventListener();
        this.props.startControllingDesktop();
      }
    }

    this.setState({ fullScreenStream: user, fullScreenIdx: idx });
  }

  fullScreenIsOnFor(user, idx) {
    if (this.state.fullScreenStream === user && this.state.fullScreenIdx === idx) {
      return true;
    }
    return false;
  }

  onFullScreenChangeFor = (newStatus, user, idx) => {
    if (newStatus) {
      return;
    }
    this.removeWindowEventListener();
    this.props.stopControllingDesktop();
    if (this.state.fullScreenStream === user && this.state.fullScreenIdx === idx) {
      this.setState({ controlledUserInFullScreen: null, fullScreenStream: null, fullScreenIdx: null});
    }
  }

  onFeaturedStream(user, stream_type) {
    this.props.dispatch(expandStreamVideo(user, stream_type));
  }

  _countBottomStreams(props) {
    let nrOfStreams = Object.keys(props.bottomVideoStreams).length;
    if (!this.isLocalVideoFeatured()) {
      nrOfStreams = nrOfStreams + 1;
    }
    if (!this.isLocalScreenFeatured() && props.screenStream) {
      nrOfStreams = nrOfStreams + 1;
    }
    return nrOfStreams;
  }

  updateBottomDimensions() {
    const me = this._getMyBottomViewPort();
    const W = me.clientWidth;
    const H = me.clientHeight;
    const nrOfStreams = this._countBottomStreams(this.props);
    const boxes = gridElements(W, H, nrOfStreams);
    this.setState({ bottomBoxes: boxes });
  }

  updateDimensions() {
    const me = this._getMyViewPort();
    const W = me.clientWidth;
    const H = me.clientHeight;
    /* there is always a stream */
    const nrOfStreams = 1;
    const boxes = gridElements(W, H, nrOfStreams);

    if (!this.props.featuredEnlarged && this._countBottomStreams(this.props) > 0) {
      this.updateBottomDimensions();
    }
    this.setState({ gridBoxes: boxes });
  }

  isLocalVideoFeatured() {
    return this.props.featuredIdStream === null || this.props.isRecorder === true ||
      (this.props.featuredIdStream === this.props.myUserId && this.props.featuredStreamType !== "screen");
  }

  isLocalScreenFeatured() {
    return this.props.featuredIdStream === this.props.myUserId && this.props.featuredStreamType === "screen";
  }

  isPipEnabledFor = (user) => {
    return !!this.state.pipUser && (this.state.pipUser === user);
  }

  enablePip = (user) => {
    // request to enable PiP
    this.setState({pipUser: user});
  }

  disablePip = (_user) => {
    // request to disable PiP
    this.setState({pipUser: null});
  }

  onPipEnabled = (user) => {
    // called when PiP is effectively enabled
    this.setState({pipUser: user});
  }

  onPipDisabled = (_user) => {
    // called when PiP is effectively disabled
    this.setState({pipUser: null});
  }

  bottomVideoDisplayName(key) {
    const displayName = this.props.bottomVideoStreams[key].displayName;
    if (key.endsWith('_screen')) {
      return this.props.intl.formatMessage(messages.screenName, { name: displayName });
    }
    return displayName;
  }

  getLocalScreenElement(classes, style, isBottomStream) {
    const localScreen = this.props.screenStream;
    if (!localScreen) {
      return null;
    }
    let textVideo = null;
    if (isBottomStream) {
      textVideo = this.props.intl.formatMessage(messages.screenName, { name: this.props.myDisplayName });
    }
    return (
      <div style={style}
        // eslint-disable-next-line react/jsx-no-bind
        onMouseEnter={() => this.setState({ videoSmallToolBar: this.props.myUserId })}
        // eslint-disable-next-line react/jsx-no-bind
        onMouseLeave={() => this.setState({ videoSmallToolBar: false })}
        // eslint-disable-next-line react/jsx-no-bind
        onTouchStart={() => this.setState({ videoSmallToolBar: this.props.myUserId })}>
        {(isBottomStream) ?
          <div className={classes.audioMutedIcon} >
            <AudioMutedIcon user={this.props.myUserId} />
          </div>
          :
          <React.Fragment>
            {this.state.videoSmallToolBar === this.props.myUserId ?
              <OwnScreenShareToolbar
                isElectron={this.props.isElectron}
                screenSourceType={this.props.screenSourceType}
                handleScreenShare={this.props.handleScreenShare}
                enableDesktopControl={this.props.enableDesktopControl}
                user={this.props.myUserId}
                someoneHasDesktopControl={Boolean(this.props.desktopControlledUser)}
              />
              : null}
            <VideoInfoToolbar
              myUserId={this.props.myUserId}
              user={this.props.myUserId}
              displayName={this.props.myDisplayName}
              type='screen'
            />
          </React.Fragment>
        }
        {!isBottomStream ? this.enlargeVideoIcon(classes) : null}

        <div className={classes.feed}>
          <VideoElement
            user={this.props.myUserId + '_screen'}
            onDoubleClick={isBottomStream ? this.onFeaturedStream : null}
            src={this.props.screenStream}
            text={textVideo}
          />
        </div>
        {(isBottomStream && this.videoSmallToolbarIsOnFor(this.props.myUserId)) ?
          <div>

            <div className={classes.smallToolBar} >
              <MenuVideoToolbar className={classes.smallToolBar}
                user={this.props.myUserId + '_screen'}
                isFullScreen={false}
                isLocalScreen={true}
                onFeaturedStream={this.onFeaturedStream}
                onToggleAudioMute={this.onToggleAudioMute}

                someoneHasDesktopControl={typeof fullScreenStream !== 'undefined'}
                myUserId={this.props.myUserId}
                screenSourceType={this.props.screenSourceType}
                handleScreenShare={this.props.handleScreenShare}
                isElectron={this.props.isElectron}
                enableDesktopControl={this.props.enableDesktopControl}
              />
            </div>
            <div className={classes.smallInfoToolBar}>
              <MenuVideoInfoToolbar className={classes.smallInfoToolBar}
                myUserId={this.props.myUserId}
                user={this.props.myUserId}
                displayName={this.props.myDisplayName}
              />
            </div>
          </div>
          : null
        }
      </div>
    );
  }

  getBottomLocalScreenElement(classes) {
    let offset = 0;
    if (!this.isLocalVideoFeatured()) offset += 1;
    return this.getLocalScreenElement(classes, this.state.bottomBoxes[offset], true);
  }

  getToolbar(classes, idx, key, hasStream, onRemoteVideoMute, isFullScreenDesktopControl) {
    let isFullScreen = false;
    let exitFullScreen = null;
    let enterFullScreen = null;
    if (hasStream) {
      isFullScreen = this.fullScreenIsOnFor(key, idx) || isFullScreenDesktopControl;
      exitFullScreen = isFullScreenDesktopControl ? this.exitFullScreenDesktopControl : this.onExitFullScreen;
      enterFullScreen = hasStream ? this.onEnterFullScreen : null;
    }
    if (this.fullScreenIsOnFor(key, idx) || isFullScreenDesktopControl) {
      return (
        <div>
          <VideoInfoToolbar myUserId={this.props.myUserId} user={key} />
          <VideoToolbar
            idx={idx}
            user={key}
            isFullScreen={isFullScreen}
            exitFullScreen={exitFullScreen}
            enterFullScreen={enterFullScreen}
            onToggleVideoMute={onRemoteVideoMute}
            onToggleAudioMute={this.onToggleAudioMute}
            onKick={this.onKick}
            onChangeRole={this.onChangeRole}
          />
        </div>);

    } else {
      return (<div>
        <div className={classes.smallToolBar} >
          <MenuVideoToolbar className={classes.smallToolBar}
            idx={idx}
            user={key}
            myUserId={this.props.myUserId}
            isFullScreen={isFullScreen}
            exitFullScreen={exitFullScreen}
            enterFullScreen={enterFullScreen}
            onToggleVideoMute={onRemoteVideoMute}
            onToggleAudioMute={this.onToggleAudioMute}
            onFeaturedStream={this.onFeaturedStream}
            onKick={this.onKick}
            onChangeRole={this.onChangeRole}
          />
        </div>
        <div className={classes.smallInfoToolBar}>
          <MenuVideoInfoToolbar className={classes.smallInfoToolBar}
            myUserId={this.props.myUserId}
            user={key}
            displayName={this.props.bottomVideoStreams[key].displayName}
          />
        </div>
      </div>);
    }
  }

  getLocalToolbar(isBottomVideo, classes) {
    if (this.videoToolbarIsOnFor(this.props.myUserId)) {
      if (!isBottomVideo) {
        const isPublishing = this.props.localVideoTrack && !this.props.isOwnVideoMuted;
        return (<OwnVideoToolbar
          user={this.props.myUserId}
          onToggleAudioMute={this.onToggleOwnAudioMute}
          onToggleVideoMute={this.props.localVideoTrack ? this.onPublishVideo : null}
          onHangup={this.onLeaveRoom}
          pipEnabled={this.isPipEnabledFor(this.props.myUserId)}
          pipEnabledOnOther={this.state.pipUser && (this.state.pipUser !== this.props.myUserId)}
          onEnablePip={isPublishing ? this.enablePip : null}
          onDisablePip={isPublishing ? this.disablePip : null}
        />);
      }
      else {
        return (
          <React.Fragment>
            <div className={classes.smallToolBar} >
              <MenuVideoToolbar className={classes.smallToolBar}
                user={this.props.myUserId}
                myUserId={this.props.myUserId}
                isFullScreen={false}
                showAudioButton={true}
                isElectron={this.props.isElectron}
                onToggleVideoMute={this.props.localVideoTrack ? this.onPublishVideo : null}
                onToggleAudioMute={this.onToggleOwnAudioMute}
                onFeaturedStream={this.onFeaturedStream}
                onHangup={this.onLeaveRoom}
              />
            </div>
            <div className={classes.smallInfoToolBar}>
              <MenuVideoInfoToolbar className={classes.smallInfoToolBar}
                myUserId={this.props.myUserId}
                user={this.props.myUserId}
                displayName={this.props.myDisplayName}

              />
            </div>
          </React.Fragment>
        );
      }
    }
    else {
      return null;
    }
  }

  getLocalVideoElement(classes, style, isBottomVideo) {
    if (this.props.isRecorder) {
      return null;
    }

    const localVideoStream = this.props.localVideoStream;
    if (!localVideoStream) {
      return null;
    }
    let videoText = null;
    if (isBottomVideo) {
      videoText = this.props.myDisplayName;
    }
    return (
      <div
        style={style}
        onMouseEnter={this.showOwnToolbar}
        onMouseLeave={this.hideOwnToolbar}
        onTouchStart={this.showOwnToolbar}
      >
        <div>
          <TalkingNotification user={this.props.myUserId} />
        </div>
        {!isBottomVideo ? this.enlargeVideoIcon(classes) : null}
        {!isBottomVideo && <VideoInfoToolbar
          myUserId={this.props.myUserId}
          user={this.props.myUserId}
          displayName={this.props.myDisplayName}
        />
        }
        <div className={classes.feed}>
          {this.props.localVideoTrack ?
            <VideoElement
              user={this.props.myUserId}
              mirrored={true}
              addVideoMutedIconOverlay={true}
              src={this.props.localVideoStream}
              onDoubleClick={isBottomVideo ? this.onFeaturedStream : null}
              text={videoText}
              pipEnabled={this.isPipEnabledFor(this.props.myUserId)}
              onPipEnabled={this.onPipEnabled}
              onPipDisabled={this.onPipDisabled}
            />
            :
            <OwnMutedVideoElement
              displayName={this.props.myDisplayName}
              uid={this.props.myUserId}
            />
          }
        </div>
        {isBottomVideo && <div className={classes.audioMutedIcon} >
          <AudioMutedIcon user={this.props.myUserId} />
        </div>
        }
        {this.getLocalToolbar(isBottomVideo, classes)}
      </div>
    );
  }

  enlargeVideoIcon(classes) {
    return (
      <div className={classes.enlargeVideoIcon}>
        <EnlargeIconButton
          enlarge={!this.props.featuredEnlarged}
          onClick={this.onEnlargeVideo} />
      </div>
    );
  }

  noFeaturedVideo(style, classes, remoteStreams) {
    if ((this.isLocalVideoFeatured() && this.props.isRecorder !== true)
      || (this.isLocalScreenFeatured() && this.props.screenStream) ||
      Object.keys(remoteStreams).length > 0) {
      return null;
    }
    return (
      <div style={style}>
        <div className={classes.feed}>
          {this.enlargeVideoIcon(classes)}
          <NoVideoElement />
        </div>
      </div>
    );
  }

  shouldHideBottomVideos() {
    return this._countBottomStreams(this.props) === 0 || this.props.featuredEnlarged;
  }

  maybeDisablePip(props) {
    const pipUser = this.state.pipUser;
    if (pipUser === props.myUserId) {
      // no need to check on own stream, this is done when leaving room
      return;
    }
    const users = Object.keys(props.remoteVideoStreams);
    if (pipUser && !users.includes(pipUser)) {
      // user disappeared, disable PiP
      this.disablePip();
      this.pip.set(false);
    }
  }

  componentWillUnmount() {
    this.resizeDetector.removeAllListeners(this._getMyViewPort());
    this.removeWindowEventListener();
    this.props.stopControllingDesktop();
  }

  componentDidMount() {
    if (this.props.isElectron) {
      window.ipcRenderer.on('startDrawing', this.startDrawing);
      window.ipcRenderer.on('stopDrawing', this.stopDrawing);
    }
    this.resizeDetector.listenTo(this._getMyViewPort(), this.updateDimensions);
  }

  componentDidUpdate(prevProps, _prevState) {
    this.maybeDisablePip(this.props, prevProps);
    if (this.props.featuredIdStream !== prevProps.featuredIdStream) {
      this.updateDimensions();
    }

    if (this._countBottomStreams(this.props) !== this._countBottomStreams(prevProps))
      this.updateDimensions();

    if (this.state.controlledUserInFullScreen === null) {
      if (this.props.desktopControlledUser) {
        const prev_dce = prevProps.desktopControlledUser;
        const current_dce = this.props.desktopControlledUser;
        if (prev_dce !== current_dce
          && Object.keys(this.props.bottomVideoStreams).includes(`${this.props.desktopControlledUser}_screen`)) {

          if (this.props.isElectron) {
            this.setState({ controlledUserInFullScreen: this.props.desktopControlledUser });
            window.goFullScreen(`${this.props.desktopControlledUser}_screen`);
            this.addWindowEventListener();
          } else {
            if (this.props.hasStartedDrawing
              && Object.keys(this.props.remoteVideoStreams).includes(`${this.props.desktopControlledUser}_screen`)) {
              newEvent(INFO, 'startDrawingBrowser', 'startDrawingBrowser',
                'Remote drawing for this room has started. You need to ' +
                'manually start desktop share in fullscreen to use it.');
            } else {
              newEvent(INFO, 'needFullscreenForControl', 'not_used_reason', "needFullscreenForControl");
            }
          }
        }

      }
    }
    else {
      if (!this.props.desktopControlledUser && prevProps.desktopControlledUser) {
        this.leaveFullScreen();
      }
    }
  }

  removeWindowEventListener() {
    if (this.props.isElectron) {
      window.ipcRenderer.removeListener('leaveFullScreen', this.leaveFullScreen);
      window.ipcRenderer.removeListener('startDrawing', this.startDrawing);
      window.ipcRenderer.removeListener('stopDrawing', this.stopDrawing);
    }
    this.props.removeWindowEventListener();
  }

  addWindowEventListener() {
    if (this.props.isElectron) {
      window.ipcRenderer.on('leaveFullScreen', this.leaveFullScreen);
    }
    this.props.addWindowEventListener();
  }

  startDrawing() {
    this.props.startDrawing();
  }
  stopDrawing() {
    this.props.stopDrawing();
  }

  leaveFullScreen() {
    if (this.props.isElectron) {
      window.exitFullScreen();
    }

    // this.setState({ controlledUserInFullScreen: null, pointersColor: [], fullScreenStream: null });
    this.setState({ controlledUserInFullScreen: null, fullScreenStream: null, fullScreenIdx: null });

    this.removeWindowEventListener();

    // this.props.clearMousePointers();

  }

  renderBottomVideos(classes) {
    return (
      <div className={classes.bottomVideoFeatured} ref={(ref) => { this.videoBottomArea = ref; }}>
        {(Object.keys(this.props.bottomVideoStreams).length !== 0) ?
          <div>
            {Object.keys(this.props.bottomVideoStreams).map((key, idx) => {
              let offset = 0;
              if (!this.isLocalVideoFeatured()) offset += 1;
              if (!this.isLocalScreenFeatured() && this.props.screenStream) offset += 1;
              let onRemoteVideoMute = (this.props.bottomVideoStreams[key].stream
                ? this.onToggleVideoMute
                : null);

              const isDesktopControlRunning = key === `${this.state.controlledUserInFullScreen}_screen`;

              return (
                <div key={idx} style={this.state.bottomBoxes[idx + offset]}
                  className={isDesktopControlRunning ?
                    classes.fullScreenDivHideCursor : null}
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseEnter={() => this.setState({ videoSmallToolBar: key })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseLeave={() => this.setState({ videoSmallToolBar: false })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onTouchStart={() => this.setState({ videoSmallToolBar: key })}>
                  <FullScreen
                    idx={idx}
                    user={key}
                    enabled={this.fullScreenIsOnFor(key, idx)}
                    onChange={this.onFullScreenChangeFor}
                  >

                    <div>
                      <TalkingNotification
                        user={key}
                      />
                    </div>
                    <div className={classes.feed}>
                      {(this.props.bottomVideoStreams[key].stream) ?
                        <VideoElement
                          user={key}
                          onDoubleClick={this.onFeaturedStream}
                          src={this.props.bottomVideoStreams[key].stream}
                          text={this.fullScreenIsOnFor(key, idx) ||
                            isDesktopControlRunning ? null : this.bottomVideoDisplayName(key)}
                        />
                        :
                        <MutedVideoElement
                          displayName={this.props.bottomVideoStreams[key].displayName}
                          viaPhone={this.props.bottomVideoStreams[key].viaPhone}
                          text={this.fullScreenIsOnFor(key, idx) ||
                            isDesktopControlRunning ? null : this.bottomVideoDisplayName(key)}
                          uid={key}
                        />
                      }
                    </div>
                    <div className={classes.audioMutedIcon} >
                      <AudioMutedIcon user={key} />
                    </div>
                    {this.videoSmallToolbarIsOnFor(key) ?
                      this.getToolbar(classes, idx, key,
                        Boolean(this.props.bottomVideoStreams[key].stream),
                        onRemoteVideoMute, isDesktopControlRunning)
                      : null}
                  </FullScreen>
                </div>
              );
            })}
          </div>
          : null
        }
        {!this.isLocalVideoFeatured() ? this.getLocalVideoElement(classes, this.state.bottomBoxes[0], true) : null}
        {(!this.isLocalScreenFeatured() && this.props.screenStream) ?
          this.getBottomLocalScreenElement(classes) : null}
      </div>);
  }

  exitFullScreenDesktopControl() {
    this.leaveFullScreen();
  }

  render() {
    const classes = this.props.classes;
    const myVideoClass = this.shouldHideBottomVideos() ? classes.myFullVideo : classes.myVideoFeatured;
    const myVideoContainerClass = this.shouldHideBottomVideos() ?
      classes.myFullVideoContainer : classes.myVideoContainer;
    return (
      <React.Fragment>
        <div className={myVideoContainerClass}>
          <div className={myVideoClass} ref={(ref) => { this.videoArea = ref; }}>
            {Object.keys(this.props.remoteVideoStreams).map((key, idx) => {
              let offset = 0;
              let onRemoteVideoMute = (this.props.remoteVideoStreams[key].stream
                ? this.onToggleVideoMute
                : null);

              const fullscreen_id = (this.state.controlledUserInFullScreen)
                ? `${this.state.controlledUserInFullScreen}_screen`
                : '';
              const isDesktopControlRunning = key === fullscreen_id;
              return (
                <div key={idx} style={this.state.gridBoxes[idx + offset]}
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseEnter={() => this.setState({ videoToolBar: key })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onTouchStart={() => this.setState({ videoToolBar: key })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseLeave={() => this.setState({ videoToolBar: false })}>
                  <FullScreen
                    idx={idx}
                    fullscreen_id={isDesktopControlRunning ? fullscreen_id : ''}
                    user={key}
                    enabled={this.fullScreenIsOnFor(key, idx)}
                    onChange={this.onFullScreenChangeFor}
                  >

                    <div>
                      <TalkingNotification
                        user={key}
                      />
                    </div>
                    <div className={classes.feed}>
                      {(this.props.remoteVideoStreams[key].stream) ?
                        <VideoElement
                          user={key}
                          src={this.props.remoteVideoStreams[key].stream}
                          pipEnabled={this.isPipEnabledFor(key)}
                          onPipEnabled={this.onPipEnabled}
                          onPipDisabled={this.onPipDisabled}
                        />
                        :
                        <MutedVideoElement
                          displayName={this.props.remoteVideoStreams[key].displayName}
                          viaPhone={this.props.remoteVideoStreams[key].viaPhone}
                          uid={key}
                        />
                      }
                    </div>
                    {this.enlargeVideoIcon(classes)}
                    <VideoInfoToolbar myUserId={this.props.myUserId} user={key} />
                    {this.videoToolbarIsOnFor(key) ?
                      <VideoToolbar
                        idx={idx}
                        user={key}
                        isFullScreen={this.fullScreenIsOnFor(key, idx)}
                        exitFullScreen={this.onExitFullScreen}
                        enterFullScreen={this.onEnterFullScreen}
                        onToggleVideoMute={onRemoteVideoMute}
                        onToggleAudioMute={this.onToggleAudioMute}
                        pipEnabled={this.isPipEnabledFor(key)}
                        pipEnabledOnOther={this.state.pipUser && (this.state.pipUser !== key)}
                        onEnablePip={this.enablePip}
                        onDisablePip={this.disablePip}
                        onKick={this.onKick}
                        onChangeRole={this.onChangeRole}
                      />
                      : null}
                  </FullScreen>
                </div>
              );
            })}
            {this.isLocalVideoFeatured() ? this.getLocalVideoElement(classes, this.state.gridBoxes[0]) : null}
            {this.isLocalScreenFeatured() && this.props.screenStream ?
              this.getLocalScreenElement(classes, this.state.gridBoxes[0]) : null}
            {this.noFeaturedVideo(this.state.gridBoxes[0], classes, this.props.remoteVideoStreams)}
          </div>
          {this.shouldHideBottomVideos() ? null : this.renderBottomVideos(classes)}
        </div>
      </React.Fragment>
    );
  }
}


function mapStateToProps(state) {
  const myUserId = state.websocket.uid;
  const featured_id = (state.room.layoutConfig || {}).featured_id || null;
  const featured_enlarged = (state.room.layoutConfig || {}).enlarge_video || false;
  const featured_stream_type = (state.room.layoutConfig || {}).featured_type;
  return {
    myUserId: myUserId,
    myDisplayName: state.session.displayName,
    screenStream: state.room.screenStream,
    localVideoStream: state.room.localvideo_stream,
    featuredIdStream: featured_id,
    featuredEnlarged: featured_enlarged,
    featuredStreamType: featured_stream_type,
    // mousePointers: state.room.mousePointers,
    desktopControlledUser: getMemoizedDeskControlledUser(state),
    hasStartedDrawing: getDrawingHasStarted(state),
    remoteVideoStreams: featuredDimension.getStreams(state),
    bottomVideoStreams: featuredDimension.getBottomStreams(state),
  };
}


FeaturedLayout.defaultProps = {
  isOwnVideoMuted: false,
};


FeaturedLayout.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  myUserId: PropTypes.string,
  myDisplayName: PropTypes.string,
  screenStream: PropTypes.object,
  localVideoStream: PropTypes.object,
  featuredIdStream: PropTypes.string,
  featuredStreamType: PropTypes.string,
  featuredEnlarged: PropTypes.bool,
  remoteVideoStreams: PropTypes.object.isRequired,
  bottomVideoStreams: PropTypes.object.isRequired,
  /* pass from parent */
  userAgent: PropTypes.object.isRequired,
  onToggleVideoMute: PropTypes.func.isRequired,
  onToggleAudioMute: PropTypes.func.isRequired,
  onKick: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  handleScreenShare: PropTypes.func.isRequired,
  onPublishVideo: PropTypes.func.isRequired,
  localVideoTrack: PropTypes.object,
  isOwnVideoMuted: PropTypes.bool,
  onToggleOwnAudioMute: PropTypes.func.isRequired,
  enableDesktopControl: PropTypes.func.isRequired,
  onLeaveRoom: PropTypes.func.isRequired,
  isRecorder: PropTypes.bool.isRequired,
  isElectron: PropTypes.bool.isRequired,
  desktopControlledUser: PropTypes.string,
  hasStartedDrawing: PropTypes.bool,
  screenSourceType: PropTypes.string.isRequired,
  removeWindowEventListener: PropTypes.func.isRequired,
  addWindowEventListener: PropTypes.func.isRequired,
  startControllingDesktop: PropTypes.func.isRequired,
  stopControllingDesktop: PropTypes.func.isRequired,
  startDrawing: PropTypes.func.isRequired,
  stopDrawing: PropTypes.func.isRequired,
};

export default withStyles(style)(injectIntl(connect(mapStateToProps)(FeaturedLayout)));
