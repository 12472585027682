/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';
import { Router } from 'holocom-client/lib/utils';
import { linkify } from 'holocom-client/lib/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import style from './style';
/* Components Imports */
import Avatar from '../Avatar';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TooltipMultiLineText from '../TooltipMultiLineText';
import IconButton from '@material-ui/core/IconButton';
import ClipboardIcon from '../../style/ClipboardIcon';
/* Other imports */


const messages = defineMessages({
  cancel: { id: 'cancel' },
  title: { id: 'userTabCmpTitle' },
  email: { id: 'email' },
  username: { id: 'username' },
  name: { id: 'name' },
  personalRoom: { id: 'personalRoom' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});


class UserInfoTab extends Component {
  constructor(props) {
    super(props);
    const router = new Router();
    this.personalRoomRawUrl = router.getUrlFor(
      router.MEETING,
      { slug: this.props.username },
      null,
      window.location.origin
    );
    this.personalRoomUrl = linkify(this.personalRoomRawUrl, '_blank', 35);

    this.timeoutHandler = null;
    this.state = {
      linkCopied: false,
    };
  }

  componentWillUnmount() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
    }
  }

  onLinkCopied = () => {
    this.setState({ linkCopied: true });
  }

  handleClipboardTooltipClose = () => {
    // ugly, but avoid re-rendering the normal tooltip text just before closing it
    this.timeoutHandler = setTimeout(() => {
      this.setState({ linkCopied: false });
      this.timeoutHandler = null;
    },
    500);
  }

  render() {
    const classes = this.props.classes;
    let uid = null;
    if (this.props.uid && this.props.uid.length) {
      uid = this.props.uid;
    }
    else {
      uid = this.props.defaultUserUid;
    }

    let cboardTtipText = this.props.intl.formatMessage(messages.copyToClipboard);
    if (this.state.linkCopied) {
      cboardTtipText = this.props.intl.formatMessage(messages.copiedToClipboard);
    }

    return (
      <React.Fragment>
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={24}>
            <Grid item xs={3} sm={3}>
              <Avatar
                displayName={this.props.displayName}
                className={classes.avatar}
                uid={uid}
              />
            </Grid>
            <Grid item xs={9} sm={9}>
              <form>
                <div className={classes.userInfoEntryTitle}>
                  {this.props.intl.formatMessage(messages.name)}
                  <div className={classes.userInfoEntryContent}>
                    {this.props.displayName}
                  </div>
                </div>
                <div className={classes.userInfoEntryTitle}>
                  {this.props.intl.formatMessage(messages.email)}
                  <div className={classes.userInfoEntryContent}>
                    {this.props.email}
                  </div>
                </div>
                <div className={classes.userInfoEntryTitle}>
                  {this.props.intl.formatMessage(messages.username)}
                  <div className={classes.userInfoEntryContent}>
                    {this.props.username}
                  </div>
                </div>
                <div className={classes.userInfoEntryTitle}>
                  {this.props.intl.formatMessage(messages.personalRoom)}
                  <div className={classes.userInfoLinkContainer}>
                    <div className={classNames(classes.userInfoEntryContent, classes.linkContent)}>
                      {this.personalRoomUrl}
                    </div>
                    <div className={classes.iconContainer}>
                      <IconButton>
                        <TooltipMultiLineText
                          placement="top"
                          title={cboardTtipText}
                          onClose={this.handleClipboardTooltipClose}
                        >
                          <CopyToClipboard
                            onCopy={this.onLinkCopied}
                            text={this.personalRoomRawUrl}>
                            <ClipboardIcon />
                          </CopyToClipboard>
                        </TooltipMultiLineText>
                      </IconButton>
                    </div>
                  </div>
                </div>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            {this.props.intl.formatMessage(messages.cancel)}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}


function mapStateToProps(state) {
  return {
    email: state.session.email,
    username: state.session.username,
    displayName: state.session.displayName,
    uid: state.websocket.uid,
  };
}


UserInfoTab.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  relayEnvironment: PropTypes.object.isRequired,
  email: PropTypes.string,
  username: PropTypes.string,
  displayName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  uid: PropTypes.string,
  defaultUserUid: PropTypes.string.isRequired,
};


export default withStyles(style)(injectIntl(connect(mapStateToProps)(UserInfoTab)));
