"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.myMeetingsFragment = exports.myMeetings = void 0;

var _reactRelay = require("react-relay");

var myMeetings = function myMeetings() {
  var node = require("./__generated__/myMeetingsQuery.graphql");

  if (node.hash && node.hash !== "eea37058b463fe376dd4875e1b3ece14") {
    console.error("The definition of 'myMeetingsQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/myMeetingsQuery.graphql");
};

exports.myMeetings = myMeetings;
var myMeetingsFragment = {
  data: function data() {
    var node = require("./__generated__/myMeetingsItem.graphql");

    if (node.hash && node.hash !== "062f757e713d87e5b6d01bf2fdfb940d") {
      console.error("The definition of 'myMeetingsItem' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
    }

    return require("./__generated__/myMeetingsItem.graphql");
  }
};
exports.myMeetingsFragment = myMeetingsFragment;