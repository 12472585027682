import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import lodash from 'lodash';


export const createDeepEqualitySelector = createSelectorCreator(
  defaultMemoize,
  lodash.isEqual
);


// filter out unwanted elements from roster to only observe the feature we want
// to avoid useless re-renders
export const getRoster = (state) => {
  const wanted_keys = ['stream', 'screen', 'display', 'viaPhone'];
  const roster = state.room.roster;
  const users = Object.keys(roster);
  let observed_roster = {};
  for (let user of users) {
    observed_roster[user] = lodash.pick(roster[user], wanted_keys);
  }
  return observed_roster;
};


export const getMyUserId = (state) => state.websocket.uid;


export const getDeskControlledUser = (state) => {
  const roster = state.room.roster;
  let keys = Object.keys(roster);
  for (let user of keys) {
    if (roster[user].isDesktopControlEnabled) {
      return {user: user, hasStartedDrawing: roster[user].hasStartedDrawing};
    }
  }
  return undefined;
};


export const getDrawingHasStarted = createSelector([getDeskControlledUser], (dCUser) => {
  return (dCUser && dCUser.hasStartedDrawing);
});


export const getMemoizedDeskControlledUser = createSelector([getDeskControlledUser, getMyUserId], (dCUser, myUid) => {
  if (dCUser && dCUser.user !== myUid) {
    return dCUser.user;
  }
  else {
    return undefined;
  }
});
