/* Libraries Imports */
import React from 'react';
import PropTypes from 'prop-types';
/* UI Imports */
import ic_screen_share_download from '../../assets/icons/ic_screen_share_download.svg';

const ScreenShareDownloadIcon = (props) => {
  props.setTooltipExtensionMessage("download");
  return (
    <img style={{width: 24, height: 24}} src={ic_screen_share_download} alt='' />
  );
};
ScreenShareDownloadIcon.propTypes = {
  setTooltipExtensionMessage: PropTypes.func.isRequired
};
export default ScreenShareDownloadIcon;
