import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
/* Other Imports */
import prepareWebRtcProvider from '../../rtc';
import { RtcDevices } from 'holocom-client/lib/api/rtcDevices';
import { getLogger } from 'holocom-client/lib/logger';
/* Local Style */
import style from './style';


class AudioElement extends Component {
  componentDidMount() {
    if (this.props.src) {
      this.video.srcObject = this.props.src;
    }
    if (this.props.sink) {
      this.setOutputDevice(null, this.props.sink);
    }
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.src && this.props.src !== prevProps.src) {
      this.video.srcObject = this.props.src;
    }
    if (this.props.handleAudioOutputChange) {
      this.setOutputDevice(prevProps.sink, this.props.sink);
    }
  }

  setOutputDevice(oldDevice, newDevice) {
    const logger = getLogger('AudioElement Set Output');
    const webrtc = prepareWebRtcProvider();
    const rtc = new RtcDevices(webrtc, logger);

    if (newDevice && newDevice !== oldDevice) {
      rtc.setAudioOutputOn(this.video, newDevice).then(() => {
        logger.debug("Changed audio output to ", newDevice);
      }).catch((e) => logger.error(e));
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <video
        className={classes.video}
        autoPlay playsInline
        ref={(ref) => { this.video = ref; }}
      />
    );
  }
}


AudioElement.propTypes = {
  src: PropTypes.object,
  classes: PropTypes.object.isRequired,
  sink: PropTypes.object,
  handleAudioOutputChange: PropTypes.bool,
};


AudioElement.defaultProps = {
  handleAudioOutputChange: false
};


function mapStateToProps(state) {
  return {
    sink: state.settings.audioOutDevice,
  };
}


export default withStyles(style)(connect(mapStateToProps)(AudioElement));
