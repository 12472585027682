import React, { Component } from 'react';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Other Imports */
import { WithAuthorization } from '../../authorization';
/* Local Style */
import style from './lockRoomButtonStyle';

const messages = defineMessages({
  lockRoom: { id: 'lockRoom' },
  unLockRoom: { id: 'unLockRoom' }
});

class LockRoomButton extends Component {

  getLockedIcon(classes) {
    const lockedIcon = (
      <div>
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(messages.unLockRoom)}
        >
          <div>
            <ListItem
              button
              className={classes.item}
              disabled={this.props.isRequestingLock}
              onClick={this.props.onClick}
            >
              <ListItemIcon>
                <Lock className={classes.locked} />
              </ListItemIcon>
            </ListItem>
          </div>
        </TooltipMultiLineText>
      </div>
    );
    return lockedIcon;
  }

  getUnlockedIcon(classes) {
    const send_class = null;
    const unLockedIcon = (
      <div>
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(messages.lockRoom)}
        >
          <div>
            <ListItem
              button
              className={classes.item}
              disabled={this.props.isRequestingLock}
              onClick={this.props.onClick}
            >
              <ListItemIcon>
                <LockOpen className={send_class} />
              </ListItemIcon>
            </ListItem>
          </div>
        </TooltipMultiLineText>
      </div>
    );
    return unLockedIcon;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {
          this.props.isLocked ?
            this.getLockedIcon(classes) :
            this.getUnlockedIcon(classes)
        }
      </div>
    );
  }
}

LockRoomButton.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  isLocked: PropTypes.bool,
  isRequestingLock: PropTypes.bool,
  onClick: PropTypes.func
};

function mapStateToProps(state) {
  return {
    isLocked: state.room.isLocked || false,
    isRequestingLock: state.room.isRequestingLock || false,
  };
}

const roles = ['room_owner', 'room_moderator'];

export default WithAuthorization(roles, withStyles(style)(injectIntl(connect(mapStateToProps)(LockRoomButton))));
