/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import VideocamOff from '@material-ui/icons/VideocamOff';
/* Local Style */
import style from './style';


class NoVideoElement extends Component {
  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}>
        <div className={classes.containerIcon}>
          <div className={classes.icon}>
            <VideocamOff style={{ height: '100%', width: '100%' }} />
          </div>
        </div>
      </div>
    );
  }
}


NoVideoElement.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
};


export default withStyles(style)(injectIntl(NoVideoElement));
