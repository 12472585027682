import _defineProperty from "/builddir/build/BUILD/holocom-client-0.13.0/packages/holocom-client-web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

var _tooltip;

var generic = {
  ok: 'Ok',
  cancel: 'Cancel',
  reset: 'Reset',
  close: 'Close',
  yes: 'Yes',
  no: 'No',
  pageNotFound: 'Page not found',
  hour: 'hour',
  hours: 'hours',
  minute: 'minute',
  minutes: 'minutes',
  second: 'second',
  seconds: 'seconds',
  day: 'day',
  days: 'days',
  email: 'Email',
  phoneNumber: 'Phone number',
  call: 'Call',
  login: 'login',
  copyToClipboard: 'Copy to clipboard',
  copiedToClipboard: 'Copied!',
  from: 'from',
  to: 'to',
  next: 'next',
  previous: 'previous',
  dateFormat: 'MMMM Do H:mm',
  fullDateFormat: 'MMMM Do YYYY H:mm',
  tryAgain: 'Try again',
  leave: 'Leave',
  error: 'Error',
  networkError: 'Network connection error',
  endMeetingMsg: 'End meeting',
  save: 'Save',
  name: 'Name',
  allDone: 'No new events, all done!',
  allDoneSchedule: 'Click here to schedule a new meeting.'
};
var deviceConfiguration = {
  deviceSelectorTitle: 'Select preferred camera and microphone',
  deviceSelectorVideoError: 'Something is wrong with your webcam, it may be in use or too high quality is set. Check your settings!',
  deviceSelectorAudioInput: 'Audio input',
  deviceSelectorVideoInput: 'Webcam',
  deviceSelectorAudioOutput: 'Audio output',
  deviceSelectorNoAudioOutput: 'Audio output not supported',
  deviceSelectorVideoQuality: 'Video quality',
  deviceSelectorLoadingCam: 'Refreshing webcam, please hold on...'
};
var login = {
  username: 'Username',
  password: 'Password',
  loginHaveAnAccount: 'Have an account? Click here to login.',
  disconnectedMessage: 'Failed to connect to server',
  genericOAuth: 'Logon using OAuth/2.0',
  errorLoginCode5xx: 'The server encountered an error, please try again or contact the administrator if the problem persists',
  errorLoginCode4xx: 'The request is not valid',
  errorLoginCode401: 'Incorrect username or password',
  errorLoginGeneric: 'An unexpected error occurred. Contact the administrator if the problem persists'
};
var logout = {
  logoutDialogTitle: 'Logout',
  logoutDialogMessage: 'Are you sure you want to logout?'
};
var mediaPermissions = {
  mediaPermissionsMessage: 'We need access to your camera and microphone so that other participants can see and hear you.\n' + 'We will ask you to confirm this decision on each browser and computer you use.\n' + 'If this takes too long, you may be experiencing hardware problems. Try reloading the page or checking your devices.',
  mediaPermissionsTitle: 'Allow to use your camera and microphone',
  mediaPermissionsBlockedTitle: 'Camera and microphone are blocked',
  mediaPermissionsPermsErr: 'We require access to your camera and microphone.\n' + "Click the camera blocked icon in your browser's address bar.\n" + 'You may need to reload this page for setttings to take effect',
  mediaPermissionsGenErr: 'Something is terribly wrong with your hardware.\n' + 'Try to restart your browser or ask for help. Sorry :('
};
var meeting = {
  meetingHeader: 'Meeting',
  meetingWelcome: 'Hello {user}!',
  noVideoDeviceFound: 'No video device found. Using audio only.',
  insertMeetingCode: 'Insert meeting code',
  navigationConfirmationTitle: 'Leave the room?',
  navigationConfirmationMessage: 'Are you sure you want to leave this room?',
  errorCodeGenericTitle: 'Error joining room.',
  errorCodeGenericBody: 'An error occurred.',
  errorCodeGenericPrimaryButton: 'Cancel',
  reconnectNotFound: 'Room not found, or not yet started.\nDo you want to rejoin this room?',
  reconnectNotRunning: 'Owner not connected yet.\nDo you want to retry?',
  errorCode1000Title: 'Cannot access the room',
  errorCode1000Body: 'Because the owner is not there',
  errorCode1000PrimaryButton: 'Ok',
  errorCode1001Title: "There was a problem",
  errorCode1001Body: 'The server encountered an error, please try again or contact the administrator if the problem persists',
  errorCode1001PrimaryButton: 'Try Again',
  errorCode1001SecondaryButton: 'Cancel',
  errorCode1002Title: "Cannot connect to the meeting",
  errorCode1002Body: "Meeting {title} is not available yet \n" + "Start: {dt_start} \n" + "End: {dt_end} \n" + "The meeting will be available in {days} {unitDays} {hours} {unitHours} {minutes} {unitMinutes} ",
  errorCode1002SecondaryButton: 'Cancel',
  errorCode1002PrimaryButton: 'Try again',
  errorCode1003Title: "Cannot enter the room  {title}",
  errorCode1003Body: 'The meeting ended on {dt_end} ',
  errorCode1003PrimaryButton: 'Ok',
  errorCode1004Title: "Meeting not found or expired",
  errorCode1004Body: 'No meeting was found',
  errorCode1004SecondaryButton: 'Cancel',
  errorCode1004PrimaryButton: 'Try again',
  errorCode1005Title: "The owner is not present yet",
  errorCode1005Body: 'Please wait, access to the room will be done automatically',
  errorCode1005SecondaryButton: 'Cancel',
  errorCode1005PrimaryButton: 'Try again',
  errorCode1008Title: "Domain resources exhausted",
  errorCode1008Body: 'Cannot allow more participants in this domain.',
  errorCode1008SecondaryButton: 'Cancel',
  errorCode1008PrimaryButton: 'Try again',
  errorCode1009Title: "Domain resources exhausted",
  errorCode1009Body: 'Cannot start more rooms in this domain.',
  errorCode1009SecondaryButton: 'Cancel',
  errorCode1009PrimaryButton: 'Try again',
  errorCode1010Title: "User resources exhausted",
  errorCode1010Body: 'Your user cannot start more rooms.',
  errorCode1010SecondaryButton: 'Cancel',
  errorCode1010PrimaryButton: 'Try again',
  errorCode1011Title: "Room resources exhausted",
  errorCode1011Body: 'This room cannot accept more participants.',
  errorCode1011SecondaryButton: 'Cancel',
  errorCode1011PrimaryButton: 'Try again',
  errorCode1012Title: "Generic resources error",
  errorCode1012Body: 'The resources controlled experimented an error,  please try again or contact the administrator if the problem persists.',
  errorCode1012SecondaryButton: 'Cancel',
  errorCode1012PrimaryButton: 'Try again',
  errorCode1013Title: "Cannot enter the room",
  errorCode1013Body: 'This room is locked. This room cannot accept more participants.',
  errorCode1013SecondaryButton: 'Cancel',
  errorCode1013PrimaryButton: 'Try again',
  errorCode4001Title: 'Unauthorized',
  errorCode4001Body: 'You are not authorized to perform this action.',
  errorCode4001SecondaryButton: 'Cancel',
  errorCode4001PrimaryButton: 'Ok',
  guestLoginDialogTitle: 'Insert your name',
  clickHereToLogin: 'Meeting organizer? Click here to login',
  clickHereToLoginToOwnRoom: 'Room owner? Click here to login',
  streamErrorTitle: 'Error accessing device',
  streamErrorContent: 'Please check you device or permissions',
  joinButton: 'Join',
  joinByCode: 'Join using a meeting id',
  screenVideoDisplayName: "{name}'s screen",
  publisherIsNotSendingVideo: 'This user is not streaming video',
  youAreNotSendingVideo: 'You are not streaming video',
  listAttendees: 'Participants list:',
  meetingLeftDialogTitle: 'Room left',
  meetingLeftDialogContent: 'You have now left the room',
  meetingLeftDialogContentWhenKicked: 'You have been kicked from the room',
  meetingLeftDialogContentWhenEnded: 'The meeting has ended',
  meetingLeftDialogContentWhenIceFailed: 'Failed connecting to the audio/video server, probably because of restrictive firewall rules. Please contact your network administrator.',
  meetingAvailable: 'Next meetings',
  meetingStartsAt: 'On {dtStart}',
  attendeesErrorEmail: 'The email is not valid',
  attendeesErrorEmpty: 'The field is empty',
  attendeesErrorRequired: 'At least an attendee is required',
  attendeesErrorAlreadyEntered: 'The email is already entered',
  dtErrorTooBrief: 'Interval too brief',
  dtErrorTooLong: 'Interval too long',
  scheduleMeeting: 'Schedule a meeting',
  scheduleStartMeeting: 'Enter the meeting start date',
  scheduleEndMeeting: 'Enter the meeting end date',
  titleMeeting: 'Meeting title',
  titleMeetingError: 'The field is empty',
  notes: 'Notes',
  sendInvite: 'Send invite',
  invitePersonToMeeting: 'Invite new participant',
  roomInfoTitlePersonal: "Start your personal video room",
  roomHowToInvitePersonal: "To invite someone to your personal room, send the following details:",
  roomHowInvitePersonalClipBoardHeader: "Join my personal video room using the following details:",
  roomHowInvitePersonalClipBoardWeb: "Web access (Chrome, Firefox o Safari)",
  roomInfoTitleScheduled: "{user}'s scheduled meeting",
  roomInfoName: 'Room name',
  roomInfoWebAccess: 'Web access',
  roomPublishStreamUrl: 'Live streaming access',
  roomInfoPstnAccess: 'Dial in access number',
  roomInfoPstnNotAvailable: 'Not available',
  roomInfoPstnNumber: '{number} (pin is {pin})',
  roomInfoScheduling: 'Schedule',
  inviteNewParticipantDialogTitle: 'Invite new participant to current meeting',
  inviteNewParticipantDialogContent: 'Insert a valid email for every participant you whish to invite.',
  inviteNewParticipantDialogValidationError: 'Invalid email',
  dialPersonToMeeting: 'Add someone by phone',
  dialToMeetingDialogTitle: 'Add someone by phone to current meeting',
  dialToMeetingDialogContent: 'Insert a valid phone number to call.',
  dialToMeetingValidatioError: 'Invalid phone number',
  dialParticipantSuccess: 'Call started successfully.',
  startMeeting: 'Meeting start date',
  endMeeting: 'Meeting end date',
  deleteButton: "Delete",
  highLinkQuality: 'High upload speed',
  mediumLinkQuality: 'Average upload speed',
  lowLinkQuality: 'Low upload speed',
  computingLinkQuality: 'Computing upload speed',
  linkQualityNotAvailable: 'Upload speed not available',
  6005: 'Service unavailable, probably because of dialout limits.',
  6004: 'Unable to dial participant. Number may be wrong or not allowed.',
  6003: 'Unable to invite participants, please try again or contact the administrator if the problem persists',
  6000: 'Unable to invite participants, meeting has not started yet.',
  6001: 'Unable to invite participants, meeting expired.',
  6002: 'Unable to invite participants, meeting does not exists.',
  deleteMeetingError: 'Unable to delete meeting, please try again or contact the administrator if the problem persists',
  unexpectedRoomError: 'An error occurred: {errorMessage}.',
  meetingDuration: 'Meeting duration',
  meetingBriefDuration: '{time} {time, plural, one {second} other {seconds} }',
  meetingCommonDuration: '{time1} {time1, plural, one {minute} other {minutes}} {time2, plural, =0 {} other {and {time2 } {time2, plural, one {second} other {seconds}}}}',
  meetingLongDuration: "{time1} {time1, plural, one {hour} other {hours}} {time2, plural, =0 {} other {and {time2 } {time2, plural, one {minute} other {minutes}}}}",
  meetingVeryLongDuration: "{time1} {time1, plural, one {day} other {days}}{time2, plural, =0 {} other {, {time2 } {time2, plural, one {hour} other {hours}}}} {time3, plural, =0 {} other {and {time3 } {time3, plural, one {minute} other {minutes}}}}",
  muteAllButton: 'Mute audience',
  unMuteAllButton: 'Unmute audience',
  muteAllVideos: 'Stop receiving other videos',
  endMeetingDialogTitle: 'End meeting',
  endMeetingDialogContent: 'Are you sure you want to end the current meeting? All participants will be kicked.'
};
var screenShare = {
  screenSharingDialogTitle: 'Screen sharing',
  screensThumbnailsListDialogTitle: 'Select screen',
  windowsThumbnailsListDialogTitle: 'Select window',
  screenSharingDialogContent: 'Select what to share',
  screenSharingDialogScreen: 'Screen',
  screenSharingDialogWindow: 'Window',
  screenSharingErrorTitle: 'Error sharing the screen',
  screenSharingErrorContent: 'Please be sure to allow browser permission when asked to',
  extensionErrorTitle: 'Extension not installed',
  extensionErrorContent: "An extension is required to enable desktop sharing\n" + "To install VoiSmart Screen Share extension:\n",
  extensionErrorContentList: "1. click on the install button\n" + "2. add the extension to your browser\n" + "3. reload the page.",
  extensionErrorButton: 'Cancel',
  extensionInstallButton: 'Install',
  extensionReloadButton: 'Reload',
  screenSharingUnsupportedTitle: 'Desktop sharing not supported',
  screenSharingUnsupportedMessage: "Your browser does not support Desktop Sharing.\n" + "Please try with Chrome, Firefox or Opera."
};
var browserCheck = {
  unsupportedBrowserTitle: 'Unsupported web browser',
  unsupportedBrowserVersionTitle: 'Unsupported web browser',
  supportedExperimentalTitle: 'Web browser not fully supported',
  unsupportedBrowserContent: 'Your web browser is not supported.',
  unsupportedBrowserVersionContent: 'Your browser is supported starting from version {version}. Update it and try reconnecting.',
  supportedExperimentalContent: 'Your browser support is still experimental. Some features will not work.',
  supportedBrowsers: 'Suggested browsers are Google Chrome (at least version {minChromeVersion}) and Mozilla Firefox (at least version {minFirefoxVersion}).',
  iosSupportedBrowsers: 'Please use Safari (at least version {minSafariVersion}).'
};
var tooltip = (_tooltip = {
  meetingIconButtonTooltip: "Homepage",
  loginIconButtonTooltip: 'Login',
  logoutIconButtonTooltip: 'Logout',
  settingsIconButtonTooltip: 'Settings',
  accountIconButtonTooltip: 'Account',
  changeUrlButtonTooltip: 'Change URL',
  screenShareButtonExtensionTooltipDownload: 'Download screen sharing extension',
  screenShareButtonExtensionTooltipIsNotSupported: 'Screen sharing is not supported',
  screenShareButtonExtensionTooltipIsSupported: 'Share screen',
  screenShareButtonExtensionTooltipDisable: 'Disable screen sharing',
  microphoneButtonEnableTooltip: 'Enable Microphone',
  microphoneButtonDisableTooltip: 'Disable Microphone',
  videoButtonEnableTooltip: 'Enable Video',
  videoButtonDisableTooltip: 'Disable Video',
  hangupIconButton: 'Hang up',
  microphoneButtonDisabledTooltip: 'Muted',
  exitFullScreenButton: 'Exit Fullscreen',
  enterFullScreenButton: 'Enter Fullscreen'
}, _defineProperty(_tooltip, "enterFullScreenButton", 'Enter Fullscreen'), _defineProperty(_tooltip, "enablePipButton", 'Enable mini video'), _defineProperty(_tooltip, "disablePipButton", 'Disable mini video'), _defineProperty(_tooltip, "enableDesktopControl", 'Enable Desktop Control'), _defineProperty(_tooltip, "disableDesktopControl", 'Disable Desktop Control'), _defineProperty(_tooltip, "chatIconButtonTooltip", 'Chat'), _defineProperty(_tooltip, "ejectIconButtonTooltip", 'Kick'), _defineProperty(_tooltip, "enlargeIconButtonTooltip", 'Enlarge'), _defineProperty(_tooltip, "collapseIconButtonTooltip", 'Collapse'), _defineProperty(_tooltip, "promoteRoleIconButtonTooltip", 'Promote to moderator'), _defineProperty(_tooltip, "demoteRoleIconButtonTooltip", 'Demote moderator'), _defineProperty(_tooltip, "presentationIconTooltip", 'Moderator'), _defineProperty(_tooltip, "roomInfoTooltip", 'Room info'), _defineProperty(_tooltip, "scheduleButtonTooltip", 'Schedule a meeting'), _defineProperty(_tooltip, "joinTooltip", 'Join'), _defineProperty(_tooltip, "editTooltip", 'Edit'), _defineProperty(_tooltip, "roomActionsMenuTooltip", 'Room actions'), _defineProperty(_tooltip, "lockRoom", 'Lock room'), _defineProperty(_tooltip, "unLockRoom", 'Unlock room'), _tooltip);
var notificationMessage = {
  joinMeeting: "{username} has joined the meeting",
  exitMeeting: "{username} has left the meeting",
  muted: 'You have been muted',
  unmuted: 'Your microphone has been activated',
  promotedToModerator: 'You have been promoted to moderator',
  demotedModerator: 'You are no longer a moderator',
  kicked: 'You have been kicked from the room',
  rosterMultipleChanges: "{joinedUsers} users have joined the meeting and {exitedUsers} users have left the meeting",
  needFullscreenForControl: 'You need to manually start desktop share in fullscreen to enable desktop control',
  stopDesktopControl: 'Remote desktop control for this room has been disabled',
  startDrawing: 'Remote drawing for this room has started',
  startDrawingBrowser: 'Remote drawing for this room has started. You need to manually start desktop share in fullscreen to use it.',
  stopDrawing: 'Remote drawing for this room has been disabled',
  meetingEnded: 'The meeting has ended',
  joinOnLockedRoom: '{dname} could not enter the meeting because it is locked'
};
var layout = {
  standardLayout: 'Standard layout',
  featuredLayout: 'Presentation',
  chooseLayout: 'Choose your layout',
  applyLayout: 'Apply current layout to all participants'
};
var recording = {
  stopRecording: 'Stop recording',
  startRecording: 'Start recording',
  reportRecording: 'Report recordings',
  reportTitleRecording: 'Title',
  reportStartRecording: 'Start',
  reportEndRecording: 'End',
  reportNoteRecording: 'Notes',
  reportSlugRecording: 'Room',
  reportPlayRecording: 'Play',
  reportDeleteRecording: 'Delete',
  reportDurationRecording: "Duration",
  reportFilterTemporalRangeRecording: "Temporal Range",
  reportFilterApplyRecording: "Apply Filter",
  reportFilterAppliedRecording: "Filter Applied",
  reportLoadRecording: 'Load more recordings',
  reportUnablePlayRecording: 'Unable to play recording',
  reportUnableLoadRecording: 'Unable to load recordings',
  roomStartRecording: 'This room is being recorded.',
  roomStopRecording: 'Recording has been stopped.',
  reportDownloadRecording: 'Download file.',
  7001: 'All recorders are busy at the moment.',
  7002: 'Unauthorized method called.',
  7003: 'Generic error, check serverlogs.',
  deleteRecordingError: 'Unable to delete recording, please try again or contact the administrator if the problem persists.',
  deleteRecordingSuccess: 'Recording deleted successfully',
  5004: 'Server timeout',
  defaultNotificationError: 'Unknown Error'
};
var liveStreaming = {
  roomStartStreaming: 'This room is streaming live',
  roomStopStreaming: 'Live streaming has been stopped',
  tooltipStartLiveStreaming: 'Start live streaming',
  tooltipStopLiveStreaming: 'Stop live streaming'
};
var accountCmp = {
  accountCmpUser: 'User',
  accountCmpPins: 'Pins',
  accountCmpStreaming: 'Streaming'
};
var userTabCmp = {
  userTabCmpTitle: 'User info'
};
var regenPinsTabCmp = {
  regenPinsTabCmpRegen: 'Renew pins',
  regenPinsTabCmpTitle: 'Personal Room Pins',
  regenPinsTabCmpPubPin: 'Public',
  regenPinsTabCmpPrivPin: 'Personal'
};
var liveStreamingCmp = {
  liveStreamingCmpTitle: 'Live streaming',
  liveStreamingCmpApiKey: 'Stream key',
  liveStreamingCmpShowApiKey: 'Show stream key',
  liveStreamingCmpApiUrl: 'Server URL',
  liveStreamingCmpPubUrl: 'Public live stream URL'
};
var errors = {
  regeneratePinsGenError: 'Error while updating pins.',
  getMyProfileError: 'Error while loading user profile.',
  updateProfileError: 'Error while updating profile settings.'
};
var success = {
  updateProfileSuccess: 'Profile saved successfully!',
  personalRoom: 'Personal room'
};
export { generic, deviceConfiguration, login, logout, mediaPermissions, meeting, screenShare, browserCheck, liveStreaming, tooltip, notificationMessage, layout, recording, accountCmp, userTabCmp, regenPinsTabCmp, liveStreamingCmp, errors, success };