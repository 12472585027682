import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import { withStyles } from '@material-ui/core/styles';

import TooltipMultiLineText from '../../TooltipMultiLineText';
import style from '../DesktopControlButton/style';

const messages = defineMessages({
  screenShareButtonExtensionTooltip: {
    disable: { id: 'screenShareButtonExtensionTooltipDisable' }
  },
});

class CloseScreenShareButton extends Component {

  handleScreenShare = () => {
    this.props.handleScreenShare();
  }

  render() {
    const { classes, className } = this.props;
    return (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(messages.screenShareButtonExtensionTooltip.disable)}>
        <div>
          <IconButton
            onClick={this.handleScreenShare}
            className={classes.button}
          >
            <StopScreenShare className={classNames(className, classes.videoButton)} />
          </IconButton>
        </div>
      </TooltipMultiLineText>
    );
  }
}

CloseScreenShareButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isDesktopControlEnabled: PropTypes.bool,
  isElectron: PropTypes.bool,
  user: PropTypes.string.isRequired,
  screenSourceType: PropTypes.string,
  className: PropTypes.string,
  handleScreenShare: PropTypes.func,
  enableDesktopControl: PropTypes.func,
  intl: intlShape.isRequired
};


export default withStyles(style)(injectIntl((CloseScreenShareButton)));
