/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PresentationIcon from '../../style/PresentationIcon';
import PresentationOffIcon from '../../style/PresentationOffIcon';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Other Imports */
import { WithAuthorization } from '../../authorization';
/* Local Style */
import style from './style';

const messages = defineMessages({
  promoteRoleIconButtonTooltip: {id: 'promoteRoleIconButtonTooltip' },
  demoteRoleIconButtonTooltip: {id: 'demoteRoleIconButtonTooltip'}
});

class ChangeRoleIconButton extends Component {
  onChangeRole = () => {
    let user = this.props.user;
    if (user) {
      user = user.replace(/_screen$/, '');
      const newRole = (this.props.userRole === 'room_user') ? 'room_moderator' : 'room_user';
      this.props.onClick(user, newRole);
    }
  }

  getIcon(props, classes, className) {
    const presentator_roles = ['room_owner', 'room_moderator'];
    if (presentator_roles.includes(props.userRole)) {
      return <PresentationOffIcon className={classNames(classes.videoButton, className)} />;
    }
    else {
      return <PresentationIcon className={classNames(classes.videoButton, className)} />;
    }
  }

  render() {
    const {
      classes,
      className,
    } = this.props;
    const presentator_roles = ['room_owner', 'room_moderator'];
    if (this.props.userRole === 'room_owner') {
      return null;
    }
    if (this.props.viaPhone) {
      return null;
    }
    return (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(presentator_roles.includes(this.props.userRole)
          ? messages.demoteRoleIconButtonTooltip
          : messages.promoteRoleIconButtonTooltip)}>
        <IconButton
          disabled={this.props.isRequestingRoleChange}
          onClick={this.onChangeRole}
          className={classes.button}
        >
          {this.getIcon(this.props, classes, className)}
        </IconButton>
      </TooltipMultiLineText>
    );
  }
}


ChangeRoleIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  viaPhone: PropTypes.bool,
  isRequestingRoleChange: PropTypes.bool,
  intl: intlShape.isRequired
};


function mapStateToProps(state, props) {
  const userId = (props.user || "").replace(/_screen$/, '');
  const user = state.room.roster[userId] || {};
  return {
    userRole: user.role,
    viaPhone: user.viaPhone,
    isRequestingRoleChange: user.isRequestingRoleChange,
  };
}


const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, withStyles(style)(injectIntl(connect(mapStateToProps)(ChangeRoleIconButton))));
