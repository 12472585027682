/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

/* UI Imports */
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowExpandIcon from '../../style/ArrowExpandIcon';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Local Style */
import style from './style';

const messages = defineMessages({
  enlargeIconButtonTooltip: { id: 'enlargeIconButtonTooltip' }
});


class FeaturedIconButton extends Component {
  onFeatured = () => {
    let user = this.props.user;
    if (user) {
      let stream_type = 'stream';
      if (user.endsWith('_screen')) {
        stream_type = 'screen';
      }
      user = user.replace(/_screen$/, '');

      this.props.onClick(user, stream_type);
    }
  }

  render() {
    const {
      classes,
      className,
    } = this.props;

    return (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(messages.enlargeIconButtonTooltip)}>
        <IconButton
          onClick={this.onFeatured}
        >
          <ArrowExpandIcon className={classNames(classes.videoButton, className)}
          />
        </IconButton>
      </TooltipMultiLineText>
    );
  }
}


FeaturedIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  className: PropTypes.string,
  viaPhone: PropTypes.bool,
  intl: intlShape.isRequired
};


function mapStateToProps(state, props) {
  const user = state.room.roster[props.user] || {};
  return {
    viaPhone: user.viaPhone,
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(FeaturedIconButton)));
