const style = {
  container: {
    justifyContent: 'center',
    height: 'inherit',
    width: '100%',
    display: 'table',
  },
  containerIcon: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    background: 'black',
    filter: 'grayscale(100%)',
    opacity: 0.5,
  },
  icon: {
    zIndex: 20,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    color: 'rgba(128, 128, 128, 0.6)',
  },
};

export default style;
