const style = _theme => ({
  item: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  locked: {
    color: "red",
  },
});

export default style;
