/* global RTCPeerConnection */

import adapter from 'webrtc-adapter';
import detectBrowser from 'detect-browser';


export default function prepareWebRtcProvider(isElectron) {
  navigator.adapter = adapter;
  navigator.adapter.browserDetails.parsedUserAgent = detectBrowser.detect();

  if (!navigator.RTCPeerConnection) {
    navigator.RTCPeerConnection = window.RTCPeerConnection;
  }
  if (!navigator.requestScreen) {
    navigator.requestScreen = (what, onSuccess) => {
      const browser = adapter.browserDetails.browser;

      if ((browser === 'firefox' || isElectron) && what) {
        onSuccess({ type: 'gotScreen', sourceId: what });
      } else if (browser === 'chrome') {
        if (!navigator.screenSharingCallbackInstalled) {
          window.addEventListener('message', (ev) => {
            if (ev.origin !== window.location.origin) {
              return;
            }
            onSuccess(ev.data);
          });
          navigator.screenSharingCallbackInstalled = true;
        }
        window.postMessage({ type: 'getScreen' }, '*');
      }
    };
  }
  if (!navigator.getScreenSharingConstraints) {
    const browser = adapter.browserDetails.browser;
    navigator.getScreenSharingConstraints = (what) => {
      if (browser === 'chrome' && what) {
        return {
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: what,
              minFrameRate: 3,
              maxFrameRate: 3,
              maxWidth: window.screen.width,
              maxHeight: window.screen.height,
            },
            optional: [
              {googTemporalLayeredScreencast: true},
              {googNoiseReduction: true},
            ]
          },
          audio: false
        };
      }
      else if (browser === 'firefox' && what)
        return {
          video: {
            mediaSource: what,
            frameRate: {min: '3', max: '3'},
            width: {max: window.screen.width},
            height: {max: window.screen.height},
          },
          audio: false
        };
    };
  }

  return navigator;
}
