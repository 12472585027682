const style = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 2,
    maxWidth: '100%',
    minWidth: '60%',
    flexGrow: 10,
  }),
  container: {
    margin: theme.spacing.unit * 3,
  },
  detailsContainer: {
    paddingTop: theme.spacing.unit
  },
  titleContainer: {
    margin: theme.spacing.unit * 3,
    width: '100%',
    wordWrap: 'break-word',
  },
  dtStartContainer: {
    margin: theme.spacing.unit * 4,
    width: '100%',
  },
  dtEndContainer: {
    margin: theme.spacing.unit * 4,
    width: '100%',
  },
  notesContainer: {
    margin: theme.spacing.unit * 4,
    width: '100%',
  },
  notes: {
    width: '100%',
    maxHeight: '15vh',
    overflow: 'auto',
    wordWrap: 'break-word',
  },
  buttonRight: {
    textAlign: 'right',
  }
});

export default style;
