"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.session = void 0;

var _reactRelay = require("react-relay");

var session = function session() {
  var node = require("./__generated__/sessionQuery.graphql");

  if (node.hash && node.hash !== "534b0033eca6dacfda4e8ca7c0fa5043") {
    console.error("The definition of 'sessionQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/sessionQuery.graphql");
};

exports.session = session;