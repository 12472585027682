const style = theme => ({
  paper: {
    padding: theme.spacing.unit,
    margin: 'auto',
  },
  dialogPaper: {
    minHeight: '50vh',
    maxHeight: '80vh',
  },
});


export default style;
