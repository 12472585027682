/**
 * @flow
 * @relayHash 4908973e29d7821b8f43eb01a7c87bfd
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
type myRecordings$ref = any;
export type FilterMatch = "CONTAINS" | "ENDS" | "EQUALS" | "GT" | "GTE" | "LT" | "LTE" | "STARTS" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type SearchSort = {|
  direction: SortDirection,
  sort: string,
|};
export type SearchFilter = {|
  key: string,
  match?: ?FilterMatch,
  value: string,
|};
export type myRecordingsForwardQueryVariables = {|
  count: number,
  after?: ?string,
  sorting?: ?$ReadOnlyArray<?SearchSort>,
  filters?: ?$ReadOnlyArray<?SearchFilter>,
|};
export type myRecordingsForwardQueryResponse = {|
  +$fragmentRefs: myRecordings$ref
|};
export type myRecordingsForwardQuery = {|
  variables: myRecordingsForwardQueryVariables,
  response: myRecordingsForwardQueryResponse,
|};
*/

/*
query myRecordingsForwardQuery(
  $count: Int!
  $after: String
  $sorting: [SearchSort]
  $filters: [SearchFilter]
) {
  ...myRecordings
}

fragment myRecordings on RootQueryType {
  myRecordings(first: $count, after: $after, sorting: $sorting, filters: $filters) {
    edges {
      node {
        ...myRecordings_recording
        id
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}

fragment myRecordings_recording on Recording {
  id
  status
  recStart
  recEnd
  slug
  title
  notes
  number
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = [{
    "kind": "LocalArgument",
    "name": "count",
    "type": "Int!",
    "defaultValue": null
  }, {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  }, {
    "kind": "LocalArgument",
    "name": "sorting",
    "type": "[SearchSort]",
    "defaultValue": null
  }, {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "[SearchFilter]",
    "defaultValue": null
  }],
      v1 = [{
    "kind": "Variable",
    "name": "after",
    "variableName": "after",
    "type": "String"
  }, {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters",
    "type": "[SearchFilter]"
  }, {
    "kind": "Variable",
    "name": "first",
    "variableName": "count",
    "type": "Int"
  }, {
    "kind": "Variable",
    "name": "sorting",
    "variableName": "sorting",
    "type": "[SearchSort]"
  }];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "myRecordingsForwardQuery",
      "type": "RootQueryType",
      "metadata": null,
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": [{
        "kind": "FragmentSpread",
        "name": "myRecordings",
        "args": null
      }]
    },
    "operation": {
      "kind": "Operation",
      "name": "myRecordingsForwardQuery",
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": [{
        "kind": "LinkedField",
        "alias": null,
        "name": "myRecordings",
        "storageKey": null,
        "args": v1
        /*: any*/
        ,
        "concreteType": "RecordingConnection",
        "plural": false,
        "selections": [{
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "RecordingEdge",
          "plural": true,
          "selections": [{
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "Recording",
            "plural": false,
            "selections": [{
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "status",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "recStart",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "recEnd",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "slug",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "notes",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "number",
              "args": null,
              "storageKey": null
            }, {
              "kind": "ScalarField",
              "alias": null,
              "name": "__typename",
              "args": null,
              "storageKey": null
            }]
          }, {
            "kind": "ScalarField",
            "alias": null,
            "name": "cursor",
            "args": null,
            "storageKey": null
          }]
        }, {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [{
            "kind": "ScalarField",
            "alias": null,
            "name": "hasNextPage",
            "args": null,
            "storageKey": null
          }, {
            "kind": "ScalarField",
            "alias": null,
            "name": "endCursor",
            "args": null,
            "storageKey": null
          }]
        }]
      }, {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "myRecordings",
        "args": v1
        /*: any*/
        ,
        "handle": "connection",
        "key": "RecordingList_myRecordings",
        "filters": ["SearchFilter", "SearchSort"]
      }]
    },
    "params": {
      "operationKind": "query",
      "name": "myRecordingsForwardQuery",
      "id": null,
      "text": "query myRecordingsForwardQuery(\n  $count: Int!\n  $after: String\n  $sorting: [SearchSort]\n  $filters: [SearchFilter]\n) {\n  ...myRecordings\n}\n\nfragment myRecordings on RootQueryType {\n  myRecordings(first: $count, after: $after, sorting: $sorting, filters: $filters) {\n    edges {\n      node {\n        ...myRecordings_recording\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment myRecordings_recording on Recording {\n  id\n  status\n  recStart\n  recEnd\n  slug\n  title\n  notes\n  number\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = 'e0c94b01e9588bba24230f58126f0566';
module.exports = node;