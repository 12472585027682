"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scheduleMeeting = scheduleMeeting;

var _reactRelay = require("react-relay");

var _notifications = require("../../notifications");

var mutation = function mutation() {
  var node = require("./__generated__/scheduleMeetingMutation.graphql");

  if (node.hash && node.hash !== "d43b4bf15abe6549000834392c351dd7") {
    console.error("The definition of 'scheduleMeetingMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/scheduleMeetingMutation.graphql");
};

function maybeNotifyError(response) {
  var inError = false;

  if (response && response.scheduleMeeting.errors !== null) {
    inError = true;
  }

  if (!response) {
    inError = true;
  }

  if (inError) {
    (0, _notifications.newEvent)(_notifications.ERROR, 'errorCodeGenericBody', 'errorCodeGenericBody', "Schedule meeting error");
  }
}

function scheduleMeeting(environment, _ref) {
  var title = _ref.title,
      userId = _ref.userId,
      domainId = _ref.domainId,
      dtStart = _ref.dtStart,
      dtEnd = _ref.dtEnd,
      users = _ref.users,
      notes = _ref.notes;
  var attendees = users.value.map(function (el) {
    return {
      email: el
    };
  });
  return new Promise(function (resolve, reject) {
    var variables = {
      input: {
        meeting: {
          title: title,
          userId: userId,
          domainId: domainId,
          dtStart: dtStart,
          dtEnd: dtEnd,
          attendees: attendees,
          notes: notes
        }
      }
    };
    (0, _reactRelay.commitMutation)(environment, {
      mutation: mutation,
      variables: variables,
      onCompleted: function onCompleted(response, errors) {
        if (errors) {
          maybeNotifyError();
          return reject(errors);
        }

        maybeNotifyError(response);
        return resolve(response);
      },
      onError: function onError(err) {
        maybeNotifyError();
        reject(err);
      }
    });
  });
}