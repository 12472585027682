/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* UI Imports */
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Pip from '@material-ui/icons/PictureInPictureAlt';
/* Other Imports */
import prepareWebRtcProvider from '../../rtc';
import { RtcDevices } from 'holocom-client/lib/api/rtcDevices';
import { getLogger } from 'holocom-client/lib/logger';
import VideoTextOverlay from '../VideoTextOverlay';
import PiP from '../../pip';
/* Local Style */
import style from './style';


class VideoElement extends Component {
  onPipDisabled = () => {
    this.props.onPipDisabled(this.props.user);
  }

  onPipEnabled = () => {
    this.props.onPipEnabled(this.props.user);
  }

  componentDidMount() {
    this.pip = new PiP(this.video);
    if (this.props.src) {
      this.video.srcObject = this.props.src;
    }
    this.video.muted = !!this.props.muted;

    if (this.props.onPipEnabled && this.props.onPipDisabled) {
      this.pip.addListeners(this.onPipEnabled, this.onPipDisabled);
    }
  }

  componentWillUnmount() {
    this.pip.removeListeners(this.onPipEnabled, this.onPipDisabled);
  }

  componentDidUpdate(prevProps) {
    if (!!this.props.src && this.props.src !== prevProps.src) {
      this.video.srcObject = this.props.src;
    }
    if (this.props.muted !== prevProps.muted) {
      this.video.muted = !!this.props.muted;
    }
    if (this.props.handleAudioOutputChange) {
      this.setOutputDevice(prevProps.sink, this.props.sink);
    }
    if (this.props.pipEnabled !== prevProps.pipEnabled) {
      this.pip.set(this.props.pipEnabled);
    }
  }

  setOutputDevice(oldDevice, newDevice) {
    const logger = getLogger('VideoElement Set Output');
    const webrtc = prepareWebRtcProvider();
    const rtc = new RtcDevices(webrtc, logger);

    if (newDevice && newDevice !== oldDevice) {
      rtc.setAudioOutputOn(this.video, newDevice).then(() => {
        logger.debug("Changed audio output to ", newDevice);
      }).catch((e) => logger.error(e));
    }
  }

  doNothing() {
    // This is ugly, but to allow hovering on ios, the container element must
    // be clickable, so for now just add an empty click callback
    // cfr. https://developer.apple.com/library/content/documentation/AppleApplications/Reference/SafariWebContent/HandlingEvents/HandlingEvents.html
  }

  onDoubleClick = () => {
    let user = this.props.user;
    if (user) {
      let stream_type = 'stream';
      if (user.endsWith('_screen')) {
        stream_type = 'screen';
      }
      user = user.replace(/_screen$/, '');

      if (this.props.onDoubleClick) {
        this.props.onDoubleClick(user, stream_type);
      }
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.container}
        onDoubleClick={this.onDoubleClick}>
        <div className={classes.fullHeight}>
          <video className={classNames({
            [classes.video]: true,
            [classes.mirrored]: this.props.mirrored,
            [classes.muted]: this.props.isVideoMuted,
            [classes.fullHeight]: this.props.fullHeight,
          })}
          autoPlay
          playsInline
          ref={(ref) => { this.video = ref; }} onClick={this.doNothing}
          />
          <VideoTextOverlay text={this.props.text} />
        </div>
        {this.props.isVideoMuted && this.props.addVideoMutedIconOverlay &&
          <div className={classes.videoMutedOverlayContainer}>
            <VideocamOff style={{ height: '100%', width: '100%' }}
              className={classes.videoMutedOverlay} />
          </div>
        }
        {this.props.pipEnabled &&
          <div className={classes.videoPipOverlayContainer}>
            <Pip style={{ height: '50%', width: '50%' }}
              className={classes.videoPipOverlay} />
          </div>
        }
      </div>
    );
  }
}


VideoElement.propTypes = {
  src: PropTypes.object.isRequired,
  mirrored: PropTypes.bool,
  muted: PropTypes.bool,
  handleAudioOutputChange: PropTypes.bool,
  sink: PropTypes.object,
  fullHeight: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  isVideoMuted: PropTypes.bool,
  text: PropTypes.string,
  user: PropTypes.string,
  onDoubleClick: PropTypes.func,
  addVideoMutedIconOverlay: PropTypes.bool,
  pipEnabled: PropTypes.bool,
  onPipEnabled: PropTypes.func,
  onPipDisabled: PropTypes.func,
};


VideoElement.defaultProps = {
  muted: true,
  fullHeight: true,
  handleAudioOutputChange: false,
  addVideoMutedIconOverlay: false,
  pipEnabled: false,
};


function getVideoMutedProperty(state, user) {
  if (!user) {
    return false;
  }
  let prop = 'isVideoMuted';
  if (user.endsWith('_screen')) {
    prop = 'isScreenMuted';
  }
  user = user.replace(/_screen$/, '');
  return (state.room.roster[user] || { prop: false })[prop];
}


function mapStateToProps(state, props) {
  return {
    isVideoMuted: getVideoMutedProperty(state, props.user),
    sink: state.settings.audioOutDevice,
  };
}


export default withStyles(style)(connect(mapStateToProps)(VideoElement));
