"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sessionDetails = void 0;

var _reactRelay = require("react-relay");

var sessionDetails = function sessionDetails() {
  var node = require("./__generated__/sessionDetailsQuery.graphql");

  if (node.hash && node.hash !== "3bea239a04493838397e35080d446318") {
    console.error("The definition of 'sessionDetailsQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/sessionDetailsQuery.graphql");
};

exports.sessionDetails = sessionDetails;