/* Libraries Imports */
import React from 'react';
import PropTypes from 'prop-types';
/* UI Imports */
import ic_screen_share_error from '../../assets/icons/ic_screen_share_error.svg';

const ScreenShareErrorIcon = (props) => {
  props.setTooltipExtensionMessage('isNotSupported');
  return (
    <img style={{width: 24, height: 24}} src={ic_screen_share_error} alt='' />
  );
};

ScreenShareErrorIcon.propTypes = {
  setTooltipExtensionMessage: PropTypes.func.isRequired
};

export default ScreenShareErrorIcon;
