/* Libraries Imports */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import TooltipMultiLineText from '../TooltipMultiLineText';
import { WithAuthorization } from '../../authorization';
/* Components Imports */
/* Actions Imports */
import {
  startRecording,
  stopRecording
} from 'holocom-client/lib/actions/room';
/* Local Style */
import style from './recordButtonStyle';

const messages = defineMessages({
  startRecording: { id: 'startRecording' },
  stopRecording: { id: 'stopRecording' }
});

class RecordButton extends Component {

  constructor(props) {
    super(props);
    this.handleStartRecording = this.handleStartRecording.bind(this);
    this.handleStopRecording = this.handleStopRecording.bind(this);
  }

  handleStartRecording() {
    this.props.dispatch(startRecording({ base_url: window.location.origin }));
  }

  handleStopRecording() {
    this.props.dispatch(stopRecording());
  }

  getStartRecordingIcon(classes) {
    const startRecordingIcon = (
      <div>
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(messages.startRecording)}
        >
          <div>
            <ListItem
              button
              className={classes.item}
              disabled={this.props.isRequestingRecordingOp}
              onClick={this.handleStartRecording}
            >
              <ListItemIcon>
                <FiberManualRecord />
              </ListItemIcon>
            </ListItem>
          </div>
        </TooltipMultiLineText>
      </div>
    );
    const Elem = WithAuthorization(['room_owner', 'room_moderator'], () => startRecordingIcon);
    return <Elem />;
  }

  getStopRecordingIcon(classes) {
    const stopRecordingIcon = (
      <div>
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(messages.stopRecording)}
        >
          <div>
            <ListItem
              button
              className={classes.item}
              disabled={this.props.isRequestingRecordingOp}
              onClick={this.handleStopRecording}
            >
              <ListItemIcon>
                <FiberManualRecord className={classes.pulsating}/>
              </ListItemIcon>
            </ListItem>
          </div>
        </TooltipMultiLineText>
      </div>
    );
    const Elem = WithAuthorization(['room_owner', 'room_moderator'], () => stopRecordingIcon);
    return <Elem />;
  }

  render() {
    const classes = this.props.classes;

    if (this.props.isRecordingEnabled) {
      return (
        <div>
          {
            this.props.isRecording ?
              this.getStopRecordingIcon(classes) :
              this.getStartRecordingIcon(classes)
          }
        </div>
      );
    } else {
      return null;
    }
  }
}

RecordButton.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isRecording: PropTypes.bool,
  isRecordingEnabled: PropTypes.bool,
  isRequestingRecordingOp: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isRecording: state.room.isRecording || false,
    isRecorder: state.session.isRecorder || false,
    isRecordingEnabled: state.room.recordingEnabled || false,
    isRequestingRecordingOp: state.room.isRequestingRecordingOp || false,
  };
}

export default withStyles(style)(injectIntl(withRouter(connect(mapStateToProps)(RecordButton))));
