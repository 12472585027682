const style = theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 24,
    overflowX: 'hidden',
    //marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      //marginTop: 64,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      zIndex: 1
    },
  },
});

export default style;
