/* eslint max-len: 0 */
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let ArrowExpandIcon = props =>
  <SvgIcon {...props}>
    <path d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z" />
  </SvgIcon>;

ArrowExpandIcon = pure(ArrowExpandIcon);
ArrowExpandIcon.muiName = 'SvgIcon';

export default ArrowExpandIcon;
