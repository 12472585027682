"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMeeting = updateMeeting;

var _reactRelay = require("react-relay");

var _relayRuntime = require("relay-runtime");

var _notifications = require("../../notifications");

var mutation = function mutation() {
  var node = require("./__generated__/updateMeetingMutation.graphql");

  if (node.hash && node.hash !== "0b5fd214eb8abc07fe6a24e4598e603a") {
    console.error("The definition of 'updateMeetingMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/updateMeetingMutation.graphql");
};

function maybeNotifyError(response) {
  var inError = false;

  if (response && response.updateMeeting.errors !== null) {
    inError = true;
  }

  if (!response) {
    inError = true;
  }

  if (inError) {
    (0, _notifications.newEvent)(_notifications.ERROR, 'errorCodeGenericBody', 'errorCodeGenericBody', "Update meeting error");
  }
}

function updateMeeting(environment, _ref) {
  var id = _ref.id,
      title = _ref.title,
      userId = _ref.userId,
      domainId = _ref.domainId,
      dtStart = _ref.dtStart,
      dtEnd = _ref.dtEnd,
      users = _ref.users,
      notes = _ref.notes;
  var attendees = users.value.map(function (el) {
    return {
      email: el
    };
  });
  return new Promise(function (resolve, reject) {
    var variables = {
      input: {
        meeting: {
          id: id,
          title: title,
          userId: userId,
          domainId: domainId,
          dtStart: dtStart,
          dtEnd: dtEnd,
          attendees: attendees,
          notes: notes
        }
      }
    };
    /*const optimisticResponse = {
      updateMeeting: variables.input,
    };*/

    (0, _reactRelay.commitMutation)(environment, {
      mutation: mutation,
      variables: variables,
      updater: function updater(store) {
        var payload = store.getRootField("updateMeeting");
        var model = payload.getLinkedRecord("meeting");
        var meet = {
          id: 'client:root'
        };
        var parent = store.get(meet.id);

        var connection = _relayRuntime.ConnectionHandler.getConnection(parent, "data_myMeetings");

        if (connection) {
          // a date can be updated, need to refresh connection from scratch to preserve order...
          _relayRuntime.ConnectionHandler.insertEdgeAfter(connection, model);
        }
      },
      //optimisticResponse,
      onCompleted: function onCompleted(response, errors) {
        if (errors) {
          maybeNotifyError();
          return reject(errors);
        }

        maybeNotifyError(response);
        return resolve(response);
      },
      onError: function onError(err) {
        maybeNotifyError();
        reject(err);
      }
    });
  });
}