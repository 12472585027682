/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* UI Imports */
import Grid from '@material-ui/core/Grid';
/* Components Imports */
/* Actions Imports */
/* Other Imports */
/* Local Style */

class MeetingHeader extends Component {
  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container align="flex-start" justify="center" spacing={16}>
            <p>{this.props.title}</p>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

MeetingHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default MeetingHeader;
