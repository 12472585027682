"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultState = exports["default"] = void 0;

var _immutabilityHelper = _interopRequireDefault(require("immutability-helper"));

var _room = require("../actions/room");

var _websocket = require("../actions/websocket");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    var ownKeys = Object.keys(source);

    if (typeof Object.getOwnPropertySymbols === 'function') {
      ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) {
        return Object.getOwnPropertyDescriptor(source, sym).enumerable;
      }));
    }

    ownKeys.forEach(function (key) {
      _defineProperty(target, key, source[key]);
    });
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var defaultState = {
  localvideo_stream: null,
  localvideo_stream_ts: 0,
  audio_stream: null,
  roomObject: null,
  roomAudio: null,
  chatRoom: null,
  roomError: null,
  screenSourceId: null,
  screenSourceType: '',
  screenStream: null,
  localscreen_stream_ts: 0,
  screenError: null,
  streamError: null,
  extensionIsInstalled: false,
  roster: {},
  chatMessages: [],
  unreadChatMessages: 0,
  layout: "default",
  layoutConfig: {},
  isRequestingApplyLayout: false,
  isRequestingLockRoom: false,
  isLocked: false,
  isLayoutChanged: false,
  isRecording: false,
  isRequestingRecordingOp: false,
  isStreaming: false,
  livestreamingEnabled: false,
  isRequestingStreamingOp: false,
  recordingEnabled: false,
  screensToShare: [],
  what: '',
  deskShareEnabled: false,
  dialoutEnabled: false,
  elapsed: 0 // mousePointers: []

};
exports.defaultState = defaultState;

function room() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _room.ROOM_CREATED:
      return Object.assign({}, state, {
        roomObject: action.payload.roomObject,
        roomAudio: action.payload.roomAudio,
        chatRoom: action.payload.chatRoom
      });

    case _room.LOCALSTREAM_VIDEO_ADD:
      return Object.assign({}, state, {
        localvideo_stream: action.payload.stream,
        localvideo_stream_ts: Math.floor(Date.now() / 1000),
        streamError: null
      });

    case _room.LOCALSTREAM_VIDEO_REMOVE:
      return Object.assign({}, state, {
        localvideo_stream: null,
        localvideo_stream_ts: 0,
        roomObject: null,
        roomAudio: null,
        chatRoom: null,
        streamError: null,
        chatMessages: [],
        unreadChatMessages: 0,
        layout: "default",
        layoutConfig: {},
        isLayoutChanged: false,
        isRecording: false,
        isStreaming: false
      });

    case _room.LOCALSTREAM_ERROR:
      return Object.assign({}, state, {
        streamError: action.payload.errorMessage
      });
    // case CLEAR_MOUSE_POINTERS:
    //   return Object.assign({}, state, {
    //     mousePointers: [],
    //   });
    // case SET_MOUSE_POINTER: {
    //   const findPointerIndex = _.findIndex(state.mousePointers,
    //     (pointer) => action.payload.mousePointer.userId === pointer.userId);
    //   let newMousePointers = [...state.mousePointers, action.payload.mousePointer];
    //   if (findPointerIndex !== -1) {
    //     newMousePointers = update(state.mousePointers, {
    //       [findPointerIndex]: {
    //         $set: action.payload.mousePointer,
    //       },
    //     });
    //   }
    //   return Object.assign({}, state, {
    //     mousePointers: newMousePointers,
    //   });
    // }

    case _room.LOCALSTREAM_AUDIO_ADD:
      return Object.assign({}, state, {
        audio_stream: action.payload.audio_stream
      });

    case _room.LOCALSTREAM_AUDIO_REMOVE:
      return Object.assign({}, state, {
        audio_stream: null
      });

    case _room.REMOTESTREAM_VIDEO_ADD:
      {
        var _username2;

        var _username = action.payload.user_id;

        if (!state.roster[_username]) {
          // user is not there? ignore stream
          // TODO: this may happen if the presence_diff event arrives from server
          //       after the new stream. It may happens when the server is
          //       clustered and presence delta broadcast interval is too big.
          //       Should we add the stream anyway and update the rest of user
          //       info later?
          //       See #holocom-stack/170
          return state;
        }

        var what = action.payload.type === 'remotescreenevent' ? 'screen' : 'stream';
        var ts_key = 'stream_ts';
        var stream_ts = Math.floor(Date.now() / 1000);

        if (what === 'screen') {
          ts_key = 'screen_ts';
        }

        var _newState = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username, (_username2 = {}, _defineProperty(_username2, what, {
            $set: action.payload.stream
          }), _defineProperty(_username2, ts_key, {
            $set: stream_ts
          }), _username2))
        });

        return _newState;
      }

    case _room.REMOTESTREAM_VIDEO_REMOVE:
      {
        var _username4;

        var _username3 = action.payload.user_id;

        if (!state.roster[_username3]) {
          // user has gone away, e.g. the phoenix channel disconnected
          return state;
        }

        var _what = action.payload.type === 'remotescreenevent' ? 'screen' : 'stream';

        var _ts_key = 'stream_ts';

        if (_what === 'screen') {
          _ts_key = 'screen_ts';
        }

        var _newState2 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username3, (_username4 = {}, _defineProperty(_username4, _what, {
            $set: null
          }), _defineProperty(_username4, _ts_key, {
            $set: 0
          }), _username4))
        });

        return _newState2;
      }

    case _room.REMOTESTREAM_VIDEO_REMOVE_ALL:
      {
        var newRoster = {};
        Object.keys(state.roster).forEach(function (key) {
          var userState = _objectSpread({}, state.roster[key], {
            stream: null,
            screen: null
          });

          newRoster[key] = userState;
        });
        return _objectSpread({}, state, {
          roster: newRoster
        });
      }

    case _room.SCREEN_REQUEST:
      return Object.assign({}, state, {
        screenError: null,
        errorCode: null,
        screenSourceId: null,
        screenSourceType: ''
      });

    case _room.SET_SHARE_SCREENS:
      return Object.assign({}, state, {
        screensToShare: action.payload.screens,
        what: action.payload.what
      });

    case _room.SCREEN_SUCCESS:
      return Object.assign({}, state, {
        screenError: null,
        errorCode: null,
        screenSourceId: action.payload.sourceId,
        screenSourceType: action.payload.sourceType
      });

    case _room.SCREEN_ERROR:
      return Object.assign({}, state, {
        screenError: action.payload.errorMessage,
        screenSourceId: null,
        screenSourceType: ''
      });

    case _room.SCREEN_VIDEO_ADD:
      return Object.assign({}, state, {
        screenStream: action.payload.stream,
        localscreen_stream_ts: Math.floor(Date.now() / 1000)
      });

    case _room.SCREEN_VIDEO_REMOVE:
      return Object.assign({}, state, {
        screenStream: null,
        localscreen_stream_ts: 0,
        screenSourceId: null,
        screenSourceType: ''
      });

    case _room.ROOM_ERROR:
      return Object.assign({}, state, {
        roomError: _objectSpread({}, action.payload.error, {
          recoverable: action.payload.recoverable
        })
      });

    case _room.ROOM_ERROR_ACKED:
      return Object.assign({}, state, {
        roomError: null
      });

    case _room.SET_EXTENSION_IS_INSTALLED:
      return Object.assign({}, state, {
        extensionIsInstalled: true
      });

    case _websocket.ROSTER_CHANGE:
      var newparts = {};
      Object.entries(action.payload.participants).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        var partState;

        if (state.roster[key]) {
          partState = Object.assign({}, state.roster[key]);
        } else {
          partState = {
            is_talking: false,
            stream: null,
            screen: null,
            isVideoMuted: false,
            isScreenMuted: false,
            isRequestingVideoMute: false,
            isRequestingAudioMute: false,
            isRequestingAllAudioMute: false,
            isRequestingRoleChange: false,
            isRequestingUserKick: false,
            isDesktopControlEnabled: false,
            isAudioMuted: false,
            video_uplink_quality: null,
            screen_uplink_quality: null,
            isControllingDesktop: false,
            isSharingDesktopControl: false,
            hasStartedDrawing: false
          };
        }

        partState.display = value.display;
        partState.viaPhone = value.viaPhone;
        partState.role = value.role;
        partState.username = value.username;
        partState.video_uplink_quality = value.video_uplink_quality;
        partState.screen_uplink_quality = value.screen_uplink_quality;
        partState.isControllingDesktop = value.isControllingDesktop;
        partState.hasStartedDrawing = value.hasStartedDrawing;
        partState.isSharingDesktopControl = value.isSharingDesktopControl;
        newparts[key] = partState;
      });
      return Object.assign({}, state, {
        roster: newparts
      });

    case _websocket.ROSTER_RESET:
      return Object.assign({}, state, {
        roster: {}
      });

    case _websocket.ROSTER_SET_AUDIO:
      {
        var _newparts = {};
        Object.entries(state.roster).forEach(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
              key = _ref4[0],
              value = _ref4[1];

          var stateToSet = action.payload.participants[key];

          if (stateToSet) {
            var newvalue = Object.assign({}, value);
            newvalue.isAudioMuted = stateToSet.muted;
            _newparts[key] = newvalue;
          } else {
            _newparts[key] = value;
          }
        });
        return Object.assign({}, state, {
          roster: _newparts
        });
      }

    case _websocket.SET_USER_ROLE:
      {
        var _username5 = action.payload.user;
        var currentState = state.roster[_username5];

        if (!currentState) {
          return state;
        }

        var _newState3 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username5, {
            role: {
              $set: action.payload.role
            }
          })
        });

        return _newState3;
      }

    case _room.TALKING_CHANGE:
      var username = action.payload.username;
      var talking = action.payload.is_talking;

      if (!state.roster[username]) {
        return state;
      }

      var newState = (0, _immutabilityHelper["default"])(state, {
        roster: _defineProperty({}, username, {
          is_talking: {
            $set: talking
          }
        })
      });
      return newState;

    case _room.MUTEVIDEO:
      {
        var _username7;

        var _username6 = action.payload.user_id;
        var _currentState = state.roster[_username6];

        if (!_currentState) {
          return state;
        }

        var _what2 = action.payload.type === 'remotescreenevent' ? 'isScreenMuted' : 'isVideoMuted';

        var _newState4 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username6, (_username7 = {}, _defineProperty(_username7, _what2, {
            $set: action.payload.muted
          }), _defineProperty(_username7, "isRequestingVideoMute", {
            $set: false
          }), _username7))
        });

        return _newState4;
      }

    case _room.MUTEVIDEO_REQUEST:
      {
        var _username8 = action.payload.user_id;
        var _currentState2 = state.roster[_username8];

        if (!_currentState2) {
          return state;
        }

        var _newState5 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username8, {
            isRequestingVideoMute: {
              $set: true
            }
          })
        });

        return _newState5;
      }

    case _room.MUTEVIDEO_REQUEST_ERROR:
      {
        var _username9 = action.payload.user_id;
        var _currentState3 = state.roster[_username9];

        if (!_currentState3) {
          return state;
        }

        var _newState6 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username9, {
            isRequestingVideoMute: {
              $set: false
            }
          })
        });

        return _newState6;
      }

    case _room.MUTEAUDIO:
      {
        var _username10 = action.payload.user_id;
        var _currentState4 = state.roster[_username10];

        if (!_currentState4) {
          return state;
        }

        var desiredState = {
          isAudioMuted: {
            $set: action.payload.muted
          },
          isRequestingAudioMute: {
            $set: false
          }
        };

        if (action.payload.muted && _currentState4.is_talking) {
          desiredState["is_talking"] = {
            $set: false
          };
        }

        var _newState7 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username10, desiredState)
        });

        return _newState7;
      }

    case _room.ENABLE_DESKTOP_CONTROL:
      {
        var _username11 = action.payload.userId;
        var _currentState5 = state.roster[_username11];

        if (!_currentState5) {
          return state;
        }

        return (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username11, {
            isDesktopControlEnabled: {
              $set: action.payload.desktopControlEnabled
            },
            desktopControlType: {
              $set: action.payload.desktopControlType
            }
          })
        });
      }

    case _room.MUTEAUDIO_REQUEST:
      {
        var _username12 = action.payload.user_id;

        var _newState8 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username12, {
            isRequestingAudioMute: {
              $set: true
            }
          })
        });

        return _newState8;
      }

    case _room.MUTEAUDIO_REQUEST_ERROR:
      {
        var _username13 = action.payload.user_id;
        var _currentState6 = state.roster[_username13];

        if (!_currentState6) {
          return state;
        }

        var _newState9 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username13, {
            isRequestingAudioMute: {
              $set: false
            }
          })
        });

        return _newState9;
      }

    case _room.MUTEALLAUDIO_REQUEST:
      {
        var _username14 = action.payload.user_id;

        var _newState10 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username14, {
            isRequestingAllAudioMute: {
              $set: true
            }
          })
        });

        return _newState10;
      }

    case _room.MUTEALLAUDIO_DONE:
      {
        var _username15 = action.payload.user_id;
        var _currentState7 = state.roster[_username15];

        if (!_currentState7) {
          return state;
        }

        var _newState11 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username15, {
            isRequestingAllAudioMute: {
              $set: false
            }
          })
        });

        return _newState11;
      }

    case _room.CHANGE_USER_ROLE_REQUEST:
      {
        var _username16 = action.payload.user_id;
        var _currentState8 = state.roster[_username16];

        if (!_currentState8) {
          return state;
        }

        var _newState12 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username16, {
            isRequestingRoleChange: {
              $set: true
            }
          })
        });

        return _newState12;
      }

    case _room.CHANGE_USER_ROLE_SUCCESS:
      {
        var _username17 = action.payload.user_id;
        var _currentState9 = state.roster[_username17];

        if (!_currentState9) {
          return state;
        }

        var _newState13 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username17, {
            isRequestingRoleChange: {
              $set: false
            }
          })
        });

        return _newState13;
      }

    case _room.CHANGE_USER_ROLE_ERROR:
      {
        var _username18 = action.payload.user_id;
        var _currentState10 = state.roster[_username18];

        if (!_currentState10) {
          return state;
        }

        var _newState14 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username18, {
            isRequestingRoleChange: {
              $set: false
            }
          })
        });

        return _newState14;
      }

    case _room.APPLY_LAYOUT_REQUEST:
      {
        var _newState15 = (0, _immutabilityHelper["default"])(state, {
          isRequestingApplyLayout: {
            $set: true
          }
        });

        return _newState15;
      }

    case _room.APPLY_LAYOUT_SUCCESS:
      {
        var _newState16 = (0, _immutabilityHelper["default"])(state, {
          isRequestingApplyLayout: {
            $set: false
          },
          isLayoutChanged: {
            $set: false
          }
        });

        return _newState16;
      }

    case _room.APPLY_LAYOUT_ERROR:
      {
        var _newState17 = (0, _immutabilityHelper["default"])(state, {
          isRequestingApplyLayout: {
            $set: false
          }
        });

        return _newState17;
      }

    case _room.LOCK_ROOM_REQUEST:
      {
        var _newState18 = (0, _immutabilityHelper["default"])(state, {
          isRequestingLockRoom: {
            $set: true
          }
        });

        return _newState18;
      }

    case _room.LOCK_ROOM_SUCCESS:
      {
        var locked = action.payload.is_locked;

        var _newState19 = (0, _immutabilityHelper["default"])(state, {
          isRequestingLockRoom: {
            $set: false
          },
          isLocked: {
            $set: locked
          }
        });

        return _newState19;
      }

    case _room.LOCK_ROOM_ERROR:
      {
        var _newState20 = (0, _immutabilityHelper["default"])(state, {
          isRequestingLockRoom: {
            $set: false
          }
        });

        return _newState20;
      }

    case _room.FORCE_LAYOUT:
      {
        var layout = action.payload.layout_type;
        var layout_config = action.payload.layout_config;

        if (layout && layout_config) {
          var _newState21 = (0, _immutabilityHelper["default"])(state, {
            layout: {
              $set: layout
            },
            layoutConfig: {
              $set: layout_config
            },
            isLayoutChanged: {
              $set: false
            }
          });

          return _newState21;
        } else {
          return state;
        }
      }

    case _room.CHANGE_LAYOUT:
      {
        var _newState22 = (0, _immutabilityHelper["default"])(state, {
          layout: {
            $set: action.payload.layout_type
          },
          layoutConfig: {
            $set: action.payload.layout_config || {}
          },
          isLayoutChanged: {
            $set: true
          }
        });

        return _newState22;
      }

    case _room.EXPAND_STREAMVIDEO:
      {
        var _newState23 = (0, _immutabilityHelper["default"])(state, {
          layoutConfig: {
            featured_id: {
              $set: action.payload.featured_id
            },
            featured_type: {
              $set: action.payload.stream_type
            }
          },
          isLayoutChanged: {
            $set: true
          }
        });

        return _newState23;
      }

    case _room.ENLARGE_STREAMVIDEO:
      {
        var _newState24 = (0, _immutabilityHelper["default"])(state, {
          layoutConfig: {
            enlarge_video: {
              $set: action.payload.enlarge
            }
          },
          isLayoutChanged: {
            $set: true
          }
        });

        return _newState24;
      }

    case _room.KICK_USER_REQUEST:
      {
        var _username19 = action.payload.user_id;
        var _currentState11 = state.roster[_username19];

        if (!_currentState11) {
          return state;
        }

        var _newState25 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username19, {
            isRequestingUserKick: {
              $set: true
            }
          })
        });

        return _newState25;
      }

    case _room.KICK_USER_SUCCESS:
      {
        var _username20 = action.payload.user_id;
        var _currentState12 = state.roster[_username20];

        if (!_currentState12) {
          return state;
        }

        var _newState26 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username20, {
            isRequestingUserKick: {
              $set: false
            }
          })
        });

        return _newState26;
      }

    case _room.KICK_USER_ERROR:
      {
        var _username21 = action.payload.user_id;
        var _currentState13 = state.roster[_username21];

        if (!_currentState13) {
          return state;
        }

        var _newState27 = (0, _immutabilityHelper["default"])(state, {
          roster: _defineProperty({}, _username21, {
            isRequestingUserKick: {
              $set: false
            }
          })
        });

        return _newState27;
      }

    case _room.CHATMESSAGE:
      {
        var dname = (state.roster[action.payload.from] || {
          display: ''
        }).display;
        var color = (state.roster[action.payload.from] || {}).color;
        var message = {
          from: dname,
          message: action.payload.message,
          userId: action.payload.from,
          color: color
        };
        return Object.assign({}, state, {
          chatMessages: [].concat(_toConsumableArray(state.chatMessages), [message]),
          unreadChatMessages: ++state.unreadChatMessages
        });
      }

    case _room.CHATMESSAGES_RESET_UNREAD:
      {
        return Object.assign({}, state, {
          unreadChatMessages: 0
        });
      }

    case _room.START_RECORDING_SUCCESS:
      {
        return Object.assign({}, state, {
          isRecording: true,
          isRequestingRecordingOp: false
        });
      }

    case _room.START_RECORDING_ERROR:
      {
        return Object.assign({}, state, {
          isRecording: false,
          isRequestingRecordingOp: false
        });
      }

    case _room.STOP_RECORDING_SUCCESS:
      {
        return Object.assign({}, state, {
          isRecording: false,
          isRequestingRecordingOp: false
        });
      }

    case _room.STOP_RECORDING_ERROR:
      {
        return Object.assign({}, state, {
          isRequestingRecordingOp: false // isRecording: false,
          // TODO: log something here? what to do?

        });
      }

    case _room.START_RECORDING_REQUEST:
      {
        var _newState28 = (0, _immutabilityHelper["default"])(state, {
          isRequestingRecordingOp: {
            $set: true
          }
        });

        return _newState28;
      }

    case _room.STOP_RECORDING_REQUEST:
      {
        var _newState29 = (0, _immutabilityHelper["default"])(state, {
          isRequestingRecordingOp: {
            $set: true
          }
        });

        return _newState29;
      }

    case _room.START_LIVESTREAMING_SUCCESS:
      {
        return Object.assign({}, state, {
          isStreaming: true,
          isRequestingStreamingOp: false
        });
      }

    case _room.START_LIVESTREAMING_ERROR:
      {
        return Object.assign({}, state, {
          isStreaming: false,
          isRequestingStreamingOp: false
        });
      }

    case _room.STOP_LIVESTREAMING_SUCCESS:
      {
        return Object.assign({}, state, {
          isStreaming: false,
          isRequestingStreamingOp: false
        });
      }

    case _room.STOP_LIVESTREAMING_ERROR:
      {
        return Object.assign({}, state, {
          isRequestingStreamingOp: false
        });
      }

    case _room.START_LIVESTREAMING_REQUEST:
      {
        var _newState30 = (0, _immutabilityHelper["default"])(state, {
          isRequestingStreamingOp: {
            $set: true
          }
        });

        return _newState30;
      }

    case _room.STOP_LIVESTREAMING_REQUEST:
      {
        var _newState31 = (0, _immutabilityHelper["default"])(state, {
          isRequestingStreamingOp: {
            $set: true
          }
        });

        return _newState31;
      }

    case _room.SET_LIVESTREAMABLE:
      {
        return (0, _immutabilityHelper["default"])(state, {
          livestreamingEnabled: {
            $set: action.payload.streamable
          }
        });
      }

    case _room.SET_RECORDABLE:
      {
        return (0, _immutabilityHelper["default"])(state, {
          recordingEnabled: {
            $set: action.payload.recordable
          }
        });
      }

    case _room.DURATION_UPDATE:
      {
        return (0, _immutabilityHelper["default"])(state, {
          elapsed: {
            $set: action.payload.elapsed
          }
        });
      }

    case _room.TOGGLE_DESKSHARE:
      {
        return (0, _immutabilityHelper["default"])(state, {
          deskShareEnabled: {
            $set: action.payload.is_enabled
          }
        });
      }

    case _room.TOGGLE_DIALOUT:
      {
        return (0, _immutabilityHelper["default"])(state, {
          dialoutEnabled: {
            $set: action.payload.is_enabled
          }
        });
      }

    default:
      return state;
  }
}

var _default = room;
exports["default"] = _default;