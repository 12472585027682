/* Libraries Imports */
import React, { Component, Fragment } from 'react';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DateTimePicker } from 'material-ui-pickers';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DateRange from '@material-ui/icons/DateRange';
import AccessTime from '@material-ui/icons/AccessTime';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
/* Components Imports */
import ListAdder from '../ListAdder';
import Spinner from '../Spinner';
/* Other Imports */
import { scheduleMeeting } from 'holocom-client/lib/api/relay/scheduleMeeting';
import { updateMeeting } from 'holocom-client/lib/api/relay/updateMeeting';
import { isEmail } from 'holocom-client/lib/utils';
import { relayOperation, SCHEDULE_MEETING } from 'holocom-client/lib/utils/relayOperation';
import { generateRequestId } from 'holocom-client/lib/utils/requestId';
/* Local Style */
import style from './style';

const messages = defineMessages({
  ok: { id: 'ok' },
  close: { id: 'close' },
  dateFormat: { id: 'dateFormat' },
  scheduleMeeting: { id: 'scheduleMeeting' },
  startMeeting: { id: 'startMeeting' },
  endMeeting: { id: 'endMeeting' },
  email: { id: 'email' },
  titleMeeting: { id: 'titleMeeting' },
  titleMeetingError: { id: 'titleMeetingError' },
  notes: { id: 'notes' },
  sendInvite: { id: 'sendInvite' },
  attendeesError: {
    email: {
      id: 'attendeesErrorEmail'
    },
    required: {
      id: 'attendeesErrorRequired'
    },
  },
  dtError: {
    too_long: {
      id: 'dtErrorTooLong'
    },
    too_brief: {
      id: 'dtErrorTooBrief'
    }
  },
});

class ScheduleMeeting extends Component {
  constructor(props) {
    super(props);
    const now = moment();
    this.state = {
      users: { error: false, errorType: null, value: props.meetingDetails.users },
      title: { error: false, errorType: null, value: props.meetingDetails.title },
      dt_start: { error: false, errorType: null, value: props.meetingDetails.dt_start || now },
      dt_end: { error: false, errorType: null, value: props.meetingDetails.dt_end || this.addMinutes(now, 60) },
      notes: props.meetingDetails.notes,
      meeting_id: props.meetingDetails.meeting_id,
      openSpinner: false
    };

    this.handleChangedtStart = this.handleChangedtStart.bind(this);
    this.handleChangedtEnd = this.handleChangedtEnd.bind(this);
    this.handleChangeTitleMeeting = this.handleChangeTitleMeeting.bind(this);
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
    this.addUser = this.addUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  handleChangeTitleMeeting(event) {
    this.setState({
      title: update(this.state.title, { error: { $set: false }, value: { $set: event.target.value } })
    });
  }

  handleChangedtStart(event) {
    const new_start = event.valueOf();
    let dt_end = this.state.dt_end.value;
    if (!event.isBefore(dt_end)) {
      dt_end = this.addMinutes(new_start, 60);
    }
    this.setState({
      dt_start: update(this.state.dt_start, { error: { $set: false }, value: { $set: new_start } }),
      dt_end: update(this.state.dt_end, {
        error: { $set: false }, value: { $set: dt_end }
      })
    });
  }

  handleChangedtEnd(event) {
    const new_end = event.valueOf();
    let dt_start = this.state.dt_start.value;
    if (event.isBefore(dt_start)) {
      dt_start = this.delMinutes(new_end, 60);
    }
    this.setState({
      dt_start: update(this.state.dt_start, { error: { $set: false }, value: { $set: dt_start } }),
      dt_end: update(this.state.dt_end, { error: { $set: false }, value: { $set: new_end } })
    });
  }

  handleChangeNotes(event) {
    this.setState({
      notes: update(this.state.notes, { $set: event.target.value })
    });
  }

  addMinutes(date, minutes) {
    return moment(date + (minutes * 60 * 1000));
  }

  delMinutes(date, minutes) {
    return moment(date - (minutes * 60 * 1000));
  }

  addUser(item) {
    this.setState({
      users: update(this.state.users, { error: { $set: false }, value: { $push: [item] } })
    });
  }

  deleteUser(index) {
    this.setState({
      users: update(this.state.users, { value: { $splice: [[index, 1]] } })
    });
  }

  isDuplicateEmail(v) {
    let found = false;
    if (this.state.users.value.length > 0) {
      this.state.users.value.forEach((users) => {
        if (v === users) {
          found = true;
        }
      });
    }
    return found;
  }

  validateTitleMeetingForm() {
    if (this.state.title.value.length > 0) {
      return true;
    } else {
      this.setState({
        title: update(this.state.title, { error: { $set: true }, errorType: { $set: "empty" } })
      });
      return false;
    }
  }

  validateUsers() {
    if (this.state.users.value.length > 0) {
      return true;
    } else {
      this.setState({
        users: update(this.state.users, { error: { $set: true }, errorType: { $set: "required" } })
      });
      return false;
    }
  }


  validateForm() {
    const isTitleMeetingFormValid = this.validateTitleMeetingForm();
    const areUsersValid = this.validateUsers();
    if (isTitleMeetingFormValid && areUsersValid) {
      return true;
    } else {
      return false;
    }
  }

  handleCloseSpinner() {
    this.setState({ openSpinner: false });
  }

  handleOpenSpinner() {
    this.setState({ openSpinner: true });
  }

  getMeetingParams() {
    const { title, dt_start, dt_end, users, notes, meeting_id } = this.state;
    const { userId, domainId } = this.props;
    return {
      title: title.value,
      dtStart: moment(dt_start.value),
      dtEnd: moment(dt_end.value),
      users: users,
      userId: userId,
      domainId: domainId,
      notes: notes ? notes : null,
      id: meeting_id
    };
  }

  sendInvite() {
    const meetingParams = this.getMeetingParams();
    const { relayEnvironment } = this.props;

    if (meetingParams.id === null) {
      return scheduleMeeting(relayEnvironment, meetingParams)
        .then((res) => {
          const { errors } = res.scheduleMeeting;
          if (errors) {
            errors.forEach((form) => {
              this.setState({
                [form.key]: update(
                  this.state[form.key],
                  { error: { $set: true }, errorType: { $set: form.reason[0] } }
                )
              });
            });
          } else {
            this.props.onCloseClick();
          }
        })
        .catch(() => { });
    } else {
      return updateMeeting(relayEnvironment, meetingParams)
        .then((res) => {
          const { errors } = res.updateMeeting;
          if (errors) {
            errors.forEach((form) => {
              this.setState({
                [form.key]: update(
                  this.state[form.key],
                  { error: { $set: true }, errorType: { $set: form.reason[0] } }
                )
              });
            });
          } else {
            this.props.onCloseClick();
          }
        })
        .catch(() => { });
    }
  }

  handleSendInvite = () => {
    if (this.validateForm()) {
      return relayOperation(this.props.dispatch, () => this.sendInvite(), generateRequestId(), SCHEDULE_MEETING);
    }

  }

  emailValidator = (email) => {
    return (!this.isDuplicateEmail(email) && isEmail(email)) || email.length === 0;
  }

  render() {
    const classes = this.props.classes;
    const fullScreen = this.props.fullScreen;
    return (
      <div>
        <Fragment>
          <Dialog
            open={true}
            disableBackdropClick
            maxWidth='xs'
            fullWidth={true}
            onEscapeKeyDown={this.props.onCloseClick}
            fullScreen={fullScreen}
          >
            {this.state.openSpinner &&
              <Spinner />
            }
            <DialogTitle>
              {this.props.intl.formatMessage(messages.scheduleMeeting)}
            </DialogTitle>
            <DialogContent>
              <div>
                <form noValidate>
                  <div>
                    <FormControl className={classes.formControl} error={this.state.title.error}>
                      <InputLabel>{this.props.intl.formatMessage(messages.titleMeeting)}</InputLabel>
                      <Input
                        id="title"
                        variant="text"
                        defaultValue={this.state.title.value}
                        onChange={this.handleChangeTitleMeeting}
                        className={classes.textField}
                      />
                      {this.state.title.error &&
                        <FormHelperText>
                          {this.props.intl.formatMessage(messages.titleMeetingError)}
                        </FormHelperText>
                      }
                    </FormControl>
                  </div>
                  <div>
                    <DateTimePicker
                      value={this.state.dt_start.value}
                      onChange={this.handleChangedtStart}
                      leftArrowIcon={<KeyboardArrowLeft />}
                      rightArrowIcon={<KeyboardArrowRight />}
                      dateRangeIcon={<DateRange />}
                      emptyLabel={this.props.intl.formatMessage(messages.startMeeting)}
                      timeIcon={<AccessTime />}
                      ampm={false}
                      format={this.props.intl.formatMessage(messages.dateFormat)}
                      className={classes.formControl}
                    />
                    {this.state.dt_start.error &&
                      <div>
                        <FormHelperText className={classes.formError}>
                          {this.props.intl.formatMessage(messages.dtError[this.state.dt_start.errorType])}
                        </FormHelperText>
                      </div>
                    }
                  </div>
                  <div>
                    <DateTimePicker
                      value={this.state.dt_end.value}
                      onChange={this.handleChangedtEnd}
                      leftArrowIcon={<KeyboardArrowLeft />}
                      rightArrowIcon={<KeyboardArrowRight />}
                      dateRangeIcon={<DateRange />}
                      emptyLabel={this.props.intl.formatMessage(messages.endMeeting)}
                      timeIcon={<AccessTime />}
                      ampm={false}
                      format={this.props.intl.formatMessage(messages.dateFormat)}
                      className={classes.formControl}
                    />
                    {this.state.dt_end.error &&
                      <div>
                        <FormHelperText className={classes.formError}>
                          {this.props.intl.formatMessage(messages.dtError[this.state.dt_end.errorType])}
                        </FormHelperText>
                      </div>
                    }
                  </div>
                  <div>
                    <FormControl className={classes.formControl}>
                      <InputLabel>{this.props.intl.formatMessage(messages.notes)}</InputLabel>
                      <Input
                        id="text"
                        variant="text"
                        multiline
                        rows="2"
                        rowsMax="4"
                        defaultValue={this.state.notes}
                        onChange={this.handleChangeNotes}
                        className={classes.textField}
                      />
                    </FormControl>
                  </div>
                  <FormControl className={classes.formControl} error={this.state.users.error}>
                    <ListAdder
                      items={this.state.users.value}
                      textFieldLabel={this.props.intl.formatMessage(messages.email)}
                      onAdd={this.addUser}
                      onRemove={this.deleteUser}
                      invalidMessage={this.props.intl.formatMessage(messages.attendeesError.email)}
                      validator={this.emailValidator}
                    />
                    {this.state.users.error &&
                      <Typography className={classes.formError} variant="body2" gutterBottom align="center">
                        {this.props.intl.formatMessage(messages.attendeesError.required)}
                      </Typography>
                    }
                  </FormControl>
                </form>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.onCloseClick} color="primary">
                {this.props.intl.formatMessage(messages.close)}
              </Button>
              <Button
                color="primary" variant="contained"
                onClick={this.handleSendInvite}>
                {this.props.intl.formatMessage(messages.sendInvite)}
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      </div>
    );
  }
}


ScheduleMeeting.defaultProps = {
  meetingDetails: {
    users: [],
    title: '',
    notes: '',
    dt_start: null,
    dt_end: null,
    meeting_id: null
  }
};

ScheduleMeeting.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  userId: PropTypes.string,
  domainId: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  meetingDetails: PropTypes.object,
  relayEnvironment: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
};


function mapStateToProps(state) {
  return {
    domainId: state.session.domainId,
    userId: state.session.userId
  };
}

export { ScheduleMeeting };
export default withStyles(style)(injectIntl(connect(mapStateToProps)(withMobileDialog()(ScheduleMeeting))));
