/**
 * @flow
 */

/* eslint-disable */
'use strict';
/*::
import type { ReaderFragment } from 'relay-runtime';
type myRecordings_recording$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type myRecordings$ref: FragmentReference;
export type myRecordings = {|
  +myRecordings: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: myRecordings_recording$ref
      |}
    |}>,
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?string,
    |},
  |},
  +$refType: myRecordings$ref,
|};
*/

var node
/*: ReaderFragment*/
= {
  "kind": "Fragment",
  "name": "myRecordings",
  "type": "RootQueryType",
  "metadata": {
    "connection": [{
      "count": "count",
      "cursor": "after",
      "direction": "forward",
      "path": ["myRecordings"]
    }]
  },
  "argumentDefinitions": [{
    "kind": "RootArgument",
    "name": "count",
    "type": "Int"
  }, {
    "kind": "RootArgument",
    "name": "after",
    "type": "String"
  }, {
    "kind": "RootArgument",
    "name": "sorting",
    "type": "[SearchSort]"
  }, {
    "kind": "RootArgument",
    "name": "filters",
    "type": "[SearchFilter]"
  }],
  "selections": [{
    "kind": "LinkedField",
    "alias": "myRecordings",
    "name": "__RecordingList_myRecordings_connection",
    "storageKey": null,
    "args": null,
    "concreteType": "RecordingConnection",
    "plural": false,
    "selections": [{
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "RecordingEdge",
      "plural": true,
      "selections": [{
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Recording",
        "plural": false,
        "selections": [{
          "kind": "FragmentSpread",
          "name": "myRecordings_recording",
          "args": null
        }, {
          "kind": "ScalarField",
          "alias": null,
          "name": "__typename",
          "args": null,
          "storageKey": null
        }]
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }]
    }, {
      "kind": "LinkedField",
      "alias": null,
      "name": "pageInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "PageInfo",
      "plural": false,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      }]
    }]
  }]
}; // prettier-ignore

node
/*: any*/
.hash = 'b27288da87fc303b783e13e2897006ab';
module.exports = node;