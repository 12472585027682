"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loginUser = loginUser;
exports.logoutUser = logoutUser;
exports.loginGuest = loginGuest;
exports.checkValidateSession = checkValidateSession;
exports.setSyncToken = setSyncToken;
exports.SYNC_TOKEN_LOGOUT = exports.SYNC_TOKEN_LOGIN = exports.GUESTLOGIN_FAILURE = exports.GUESTLOGIN_SUCCESS = exports.GUESTLOGIN_REQUEST = exports.LOGOUT_SUCCESS = exports.LOGIN_FAILURE = exports.LOGIN_SUCCESS = exports.LOGIN_REQUEST = void 0;

var _session = require("../api/session");

var _relayRuntime = require("relay-runtime");

var _session2 = require("../actions/session");

var _logger = require("../logger");

var _sentry = require("../sentry");

var _notifications = require("../notifications");

var LOGIN_REQUEST = 'LOGIN_REQUEST';
exports.LOGIN_REQUEST = LOGIN_REQUEST;
var LOGIN_SUCCESS = 'LOGIN_SUCCESS';
exports.LOGIN_SUCCESS = LOGIN_SUCCESS;
var LOGIN_FAILURE = 'LOGIN_FAILURE';
exports.LOGIN_FAILURE = LOGIN_FAILURE;
var LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
exports.LOGOUT_SUCCESS = LOGOUT_SUCCESS;
var GUESTLOGIN_REQUEST = 'GUESTLOGIN_REQUEST';
exports.GUESTLOGIN_REQUEST = GUESTLOGIN_REQUEST;
var GUESTLOGIN_SUCCESS = 'GUESTLOGIN_SUCCESS';
exports.GUESTLOGIN_SUCCESS = GUESTLOGIN_SUCCESS;
var GUESTLOGIN_FAILURE = 'GUESTLOGIN_FAILURE';
exports.GUESTLOGIN_FAILURE = GUESTLOGIN_FAILURE;
var SYNC_TOKEN_LOGIN = 'SYNC_TOKEN_LOGIN';
exports.SYNC_TOKEN_LOGIN = SYNC_TOKEN_LOGIN;
var SYNC_TOKEN_LOGOUT = 'SYNC_TOKEN_LOGOUT';
exports.SYNC_TOKEN_LOGOUT = SYNC_TOKEN_LOGOUT;

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    payload: {
      username: creds.username
    }
  };
}

function receiveLogin(user, token) {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      token: token,
      username: user
    }
  };
}

function loginError(errmessage, errcode) {
  return {
    type: LOGIN_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode
    },
    error: true
  };
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    payload: {}
  };
}

function requestGuestAuth() {
  return {
    type: GUESTLOGIN_REQUEST,
    payload: {}
  };
}

function receiveGuestAuth(token, displayName) {
  return {
    type: GUESTLOGIN_SUCCESS,
    payload: {
      token: token,
      displayName: displayName
    }
  };
}

function guestAuthError(errmessage, errcode) {
  return {
    type: GUESTLOGIN_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode
    },
    error: true
  };
}

function syncTokenLogin(token) {
  return {
    type: SYNC_TOKEN_LOGIN,
    payload: {
      token: token
    }
  };
}

function syncTokenLogout() {
  return {
    type: SYNC_TOKEN_LOGOUT
  };
}

function safeClearToken(localStore) {
  try {
    localStore.clearToken();
  } catch (_e) {
    return;
  }
}

function clearSessionDetails() {
  return {
    type: _session2.SESSIONDETAILS_CLEAR,
    payload: {}
  };
}

function safeSaveToken(localStore, token) {
  try {
    localStore.saveToken(token);
  } catch (_e) {
    return;
  }
}

function deauthenticate(token, dispatch, localStore) {
  (0, _sentry.unsetTracedUser)();
  dispatch(receiveLogout());
  safeClearToken(localStore);
  dispatch(clearSessionDetails());
  var logger = (0, _logger.getLogger)('Logout');
  (0, _session.logout)(token).then(function () {
    logger.debug("session closed on server");
  })["catch"](function (err) {
    logger.error("There was an error while closing the session", err.errorMessage, err.errorCode);
  });
}

function authenticate(creds, dispatch, localStore) {
  (0, _session.login)(creds.username, creds.password).then(function (res) {
    if (res.token) {
      safeSaveToken(localStore, res.token);
      dispatch(receiveLogin(creds.username, res.token));
    } else {
      safeClearToken(localStore);
      var err = (res.errors || {
        detail: 'unknown error'
      }).detail;
      onLoginError(err, null);
      dispatch(loginError(err, null));
    }
  })["catch"](function (err) {
    safeClearToken(localStore);
    onLoginError(err, err.errorCode);
    dispatch(loginError(err.errorMessage, err.errorCode));
  });
}

function onLoginError(errorMessage, errorCode) {
  var messageID = 'errorLoginGeneric';

  if (errorCode >= 600) {
    messageID = 'errorLoginGeneric';
  } else if (errorCode >= 500) {
    messageID = 'errorLoginCode5xx';
  } else if (errorCode === 401) {
    messageID = 'errorLoginCode401';
  } else if (errorCode >= 400) {
    messageID = 'errorLoginCode4xx';
  }

  (0, _notifications.newEvent)(_notifications.ERROR, messageID, messageID, errorMessage);
}

function authenticateGuest(displayName, dispatch, _localStore) {
  // ignoring token store, since we do not want to cache the guest token
  (0, _session.guestLogin)(displayName).then(function (res) {
    if (res.token) {
      // safeSaveToken(localStore, res.token);
      dispatch(receiveGuestAuth(res.token, displayName));
    } else {
      // safeClearToken(localStore);
      var err = (res.errors || {
        detail: 'unknown error'
      }).detail;
      dispatch(guestAuthError(err, null));
    }
  })["catch"](function (e) {
    // safeClearToken(localStore);
    dispatch(guestAuthError(e.errorMessage, e.errorCode));
  });
}

function loginUser(creds, localStore) {
  return function (dispatch) {
    dispatch(requestLogin(creds));
    return authenticate(creds, dispatch, localStore);
  };
}

function logoutUser(token, localStore) {
  return function (dispatch) {
    return deauthenticate(token, dispatch, localStore);
  };
}

function loginGuest(displayName, localStore) {
  return function (dispatch) {
    dispatch(requestGuestAuth());
    return authenticateGuest(displayName, dispatch, localStore);
  };
}

function checkValidateSession(relay, session, dispatch, token, localStore) {
  (0, _relayRuntime.fetchQuery)(relay, session, dispatch).then(function (res) {
    dispatch(receiveLogin("".concat(res.session.username, "@").concat(res.session.realm), token));
  })["catch"](function () {
    safeClearToken(localStore);
    dispatch(receiveLogout());
  });
}

function setSyncToken(dispatch, token) {
  if (token) {
    dispatch(syncTokenLogin(token));
  } else {
    dispatch(syncTokenLogout());
  }
}