import React from 'react';
import VideoToolbar from '../VideoToolbar';


export default function OwnVideoToolbar(extraProps) {
  return (
    <VideoToolbar
      isFullScreen={false}
      showAudioButton={true}
      {...extraProps}
    />
  );
}
