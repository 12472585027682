import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BodyLayout from '../../style/bodylayout';
import style from './style';


class About extends Component {
  getLogo() {
    return '/voismart_big.png';
  }

  render() {
    return (
      <BodyLayout>
        <img className={this.props.classes.logo} alt="" src={this.getLogo()}/>
      </BodyLayout>
    );
  }
}


About.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(style)(About);
