import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let PresentationIcon = props =>
  <SvgIcon {...props}>
    <path d="M3,3h7c0-1.1,0.9-2,2-2s2,0.9,2,2h7v13h-5.8l1.8,6h-2l-1.8-6h-2.5L9,22H7l1.8-6H3 M5,5v9h14V5H5z"/>
  </SvgIcon>;

PresentationIcon = pure(PresentationIcon);
PresentationIcon.muiName = 'SvgIcon';

export default PresentationIcon;
