/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { createSelector } from 'reselect';
import classNames from 'classnames';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import Send from '@material-ui/icons/Send';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/List';
/* Componentes Imports */
import Avatar from '../Avatar';
import ChatMessage from '../Chat/ChatMessage';

const style = theme => ({
  panel: {
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
      minHeight: 0,
      height: '100%'
    },
    width: '20vw',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '96vh',
  },
  list: {
    [theme.breakpoints.down('sm')]: {
      height: '95%',
      width: '95%'
    },
    overflowY: 'auto',
    maxHeight: '90vh',
    paddingRight: '8px',
  },
  listItem: {
    alignItems: 'flex-start',
    borderRadius: '10px',
    marginTop: '10px',
    display: 'flex',
  },
  myBubble: {
    background: '#f5f2f2',
  },
  otherBubble: {
    background: '#d0d0d0',
  },
  messagesContainer: {
    [theme.breakpoints.down('sm')]: {
      height: '40%',
      flex: 'auto',
    },
    flex: 'auto',
  },
  inputRow: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      marginBottom: 10,
      bottom: 0,
      width: '95%',

    },
    width: '100%',
    // marginBottom: '1em',
  },
  input: {
    flex: 'auto',
    width: '100%'
  },
  avatar: {
    fontSize: '15px',
    margin: '5px',
  },
  text: {
  }
});


class ChatPanel extends Component {
  constructor(props) {
    super(props);
    this.needToScroll = true;
    this.state = {
      messageToSend: '',
      submitEnabled: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  handleSend() {
    const message = this.state.messageToSend;
    if (message) {
      this.props.onSend(message);
    }
    this.setState({ messageToSend: '', submitEnabled: false });
  }

  handleInputChange(ev) {
    this.setState({
      messageToSend: ev.target.value,
      submitEnabled: ev.target.value.length > 0,
    });
  }

  handleInputKeyUp(ev) {
    if ((ev.key === 'Enter') && (this.state.submitEnabled)) {
      this.handleSend();
    }
  }

  getSnapshotBeforeUpdate() {
    const { scrollTop, clientHeight, scrollHeight } = this.messageList;
    if (scrollTop + clientHeight === scrollHeight) {
      this.needToScroll = true;
    }
    return null;
  }

  componentDidUpdate() {
    this.scrollToLastMessage();
  }

  componentDidMount() {
    this.scrollToLastMessage();
  }

  scrollToLastMessage() {
    if (this.messageList && this.needToScroll) {
      this.messageList.scrollTop = this.messageList.scrollHeight;
      this.needToScroll = false;
    }
  }

  getMessage(idx, messages, from, userId) {
    if (userId === this.props.myself) {
      return (
        <ListItem
          key={idx}
          className={classNames(this.props.classes.listItem, this.props.classes.myBubble)}
        >
          <Avatar
            displayName={from}
            className={this.props.classes.avatar}
            uid={userId}
          />
          <ChatMessage
            messages={messages}
            displayName={from}
          />
        </ListItem>
      );
    }
    else {
      return (
        <ListItem
          key={idx}
          className={classNames(this.props.classes.listItem, this.props.classes.otherBubble)}
        >
          <ChatMessage
            messages={messages}
            displayName={from}
          />
          <Avatar
            displayName={from}
            className={this.props.classes.avatar}
            uid={userId}
          />
        </ListItem>
      );
    }
  }

  getMessageList() {
    const classes = this.props.classes;
    /* eslint-disable react/no-find-dom-node */
    // findDomNode here sucks, but it seems to be recommended by material-ui
    // devs since the removal of rootRef from List components
    return (
      <List className={classes.list} ref={el => { this.messageList = ReactDOM.findDOMNode(el); }}>
        {this.props.chatMessages.map((value, idx) => (
          this.getMessage(idx, value.messages, value.from, value.userId)
        ))}
      </List>
    );
    /* eslint-enable react/no-find-dom-node */
  }

  render() {
    const {
      classes
    } = this.props;
    return (
      <div className={classes.panel}>
        <div className={classes.messagesContainer}>
          {this.getMessageList()}
        </div>
        <FormGroup row className={classes.inputRow}>
          <Input
            autoFocus
            placeholder="Type a message"
            value={this.state.messageToSend}
            className={classes.input}
            onChange={this.handleInputChange}
            onKeyUp={this.handleInputKeyUp}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  className={classes.button}
                  disabled={!this.state.submitEnabled}
                  onClick={this.handleSend}
                  color="primary"
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormGroup>
      </div>
    );
  }
}


ChatPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired,
  chatMessages: PropTypes.array,
  myself: PropTypes.string,
};


export function groupByAuthor(messages) {
  const res = messages.reduce((acc, el) => {
    if (!acc.lastEl) {
      const lastEl = { ...el, messages: [el.message] };
      delete lastEl.message;
      return { result: acc.result, lastEl: lastEl };
    }
    else if (el.userId === acc.lastEl.userId) {
      acc.lastEl.messages.push(el.message);
      return { result: acc.result, lastEl: acc.lastEl };
    }
    else {
      const lastEl = { ...el, messages: [el.message] };
      delete lastEl.message;
      return { result: [...acc.result, acc.lastEl], lastEl: lastEl };
    }
  }, { result: [], lastEl: null });
  if (res.lastEl) {
    res.result.push(res.lastEl);
  }
  return res.result;
}


const getMessages = createSelector((state) => state.room.chatMessages, groupByAuthor);


function mapStateToProps(state) {
  return {
    chatMessages: getMessages(state),
    myself: state.websocket.uid,
  };
}


export default withStyles(style)(connect(mapStateToProps)(ChatPanel));
