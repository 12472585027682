"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultState = exports["default"] = void 0;

var _settings = require("../actions/settings");

var defaultState = {
  device_settings: false,
  isElectron: false
};
exports.defaultState = defaultState;

function settings() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _settings.TOGGLE_DEVICE_SETTINGS:
      return Object.assign({}, state, {
        device_settings: !state.device_settings
      });

    case _settings.SET_IS_ELECTRON:
      return Object.assign({}, state, {
        isElectron: action.payload.isElectron
      });

    case _settings.VIDEO_DEVICE_SETTINGS:
      return Object.assign({}, state, {
        videoDevice: action.payload.config
      });

    case _settings.VIDEO_QUALITY_SETTINGS:
      return Object.assign({}, state, {
        videoQuality: action.payload.config
      });

    case _settings.AUDIOIN_DEVICE_SETTINGS:
      return Object.assign({}, state, {
        audioInDevice: action.payload.config
      });

    case _settings.AUDIOOUT_DEVICE_SETTINGS:
      return Object.assign({}, state, {
        audioOutDevice: action.payload.config
      });

    default:
      return state;
  }
}

var _default = settings;
exports["default"] = _default;