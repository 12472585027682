const style = {
  avatar: {
    paddingBottom: '15%',
    width: '15%',
    height: 0,
    margin: 'auto auto 10px auto',
    fontSize: '30px',
    position: 'relative',
  },
  avatarIcon: {
    paddingTop: '100%',
    fontSize: '20px',
    position: 'absolute',
  },
};

export default style;
