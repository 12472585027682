const style = _theme => ({
  img: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto'
  },
  imgContainer: {
    marginRight: '10px',
    alignItems: 'center',
    marginBottom: '10px',
    '&:hover': {
      opacity: 0.4,
      cursor: 'pointer',
    },
    '&:last-child': {
      marginRight: 0,
    }

  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center', paddingBottom: '20px',
  },
  thumbnailsContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px',
    marginTop: '0',
    flexWrap: 'wrap'

  }
});

export default style;
