import { createDeepEqualitySelector, getRoster, getMyUserId } from './reduxSelectors';


const getFeaturedId = (state) => (state.room.layoutConfig || {}).featured_id || null;


const getFeaturedStreamType = (state) => (state.room.layoutConfig || {}).featured_type;


export const getBottomStreams = createDeepEqualitySelector(
  [getRoster, getMyUserId, getFeaturedId, getFeaturedStreamType],
  (roster, myself, featured_id, featured_type) => {
    let videos = {};
    if (!myself) {
      return videos;
    }

    let keys = Object.keys(roster);
    for (let user of keys) {
      if (user === myself) {
        continue;
      }

      let remote_v = roster[user].stream;
      /* Featured video is muted */
      /*if (featured_id === user && !remote_v) {
        videos[user] = {
          stream: remote_v,
          displayName: roster[user].display,
          viaPhone: roster[user].viaPhone,
        };
      }*/
      if (featured_id !== user || (featured_id === user && featured_type !== "stream")) {
        videos[user] = {
          stream: remote_v,
          displayName: roster[user].display,
          viaPhone: roster[user].viaPhone,
        };
      }
      if (featured_id !== user || (featured_id === user && featured_type !== "screen")) {
        let v = roster[user].screen;
        if (v) {
          videos[user + '_screen'] = {
            stream: v,
            displayName: roster[user].display,
            viaPhone: false,
          };
        }
      }
    }
    return videos;
  }
);


export const getStreams = createDeepEqualitySelector(
  [getRoster, getMyUserId, getFeaturedId, getFeaturedStreamType],
  (roster, myself, featured_id, featured_type) => {
    let videos = {};
    if (!myself || featured_id === null) {
      return videos;
    }
    let keys = Object.keys(roster);
    for (let user of keys) {
      if (user === myself) {
        continue;
      }

      let remote_v = roster[user].stream;
      /* show featured video even if it is muted */
      if (featured_id === user && featured_type === "stream") {
        videos[user] = {
          stream: remote_v,
          displayName: roster[user].display,
          viaPhone: roster[user].viaPhone,
        };
      }
      let v = roster[user].screen;
      if (v && featured_id === user && featured_type === "screen") {
        videos[user + '_screen'] = {
          stream: v,
          displayName: roster[user].display,
          viaPhone: false,
        };
      }
    }
    return videos;
  }
);
