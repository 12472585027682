const style = {
  dense: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  videoButtonColor: {
    color: 'rgba(0,0,0,0.75)',
  },

  videoButton: {
    width: 24,
    height: 24,
    marginLeft: '3px',
    marginRight: '3px',
    borderRadius: '36px'
  },

};

export default style;
