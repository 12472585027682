/**
 * @flow
 * @relayHash b6747a3e2bc0d155dea45a315f4a83b2
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProfileInput = {|
  profile?: ?ProfileUpdate
|};
export type ProfileUpdate = {|
  email?: ?string,
  meta?: ?UserMetaInput,
  middlename?: ?string,
  name?: ?string,
  password?: ?string,
  surname?: ?string,
|};
export type UserMetaInput = {|
  livestreaming?: ?LivestreamingConfigInput
|};
export type LivestreamingConfigInput = {|
  apiKey?: ?string,
  apiUrl?: ?string,
  publishUrl?: ?string,
|};
export type updateProfileMutationVariables = {|
  input: UpdateProfileInput
|};
export type updateProfileMutationResponse = {|
  +updateProfile: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +key: string,
      +message: ?$ReadOnlyArray<?string>,
      +reason: ?$ReadOnlyArray<?string>,
    |}>,
    +user: ?{|
      +meta: ?{|
        +livestreaming: ?{|
          +apiKey: ?string,
          +apiUrl: ?string,
          +publishUrl: ?string,
        |}
      |}
    |},
  |}
|};
export type updateProfileMutation = {|
  variables: updateProfileMutationVariables,
  response: updateProfileMutationResponse,
|};
*/

/*
mutation updateProfileMutation(
  $input: UpdateProfileInput!
) {
  updateProfile(input: $input) {
    errors {
      key
      message
      reason
    }
    user {
      meta {
        livestreaming {
          apiKey
          apiUrl
          publishUrl
        }
      }
      id
    }
  }
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = [{
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProfileInput!",
    "defaultValue": null
  }],
      v1 = [{
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateProfileInput!"
  }],
      v2 = {
    "kind": "LinkedField",
    "alias": null,
    "name": "errors",
    "storageKey": null,
    "args": null,
    "concreteType": "InputError",
    "plural": true,
    "selections": [{
      "kind": "ScalarField",
      "alias": null,
      "name": "key",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "reason",
      "args": null,
      "storageKey": null
    }]
  },
      v3 = {
    "kind": "LinkedField",
    "alias": null,
    "name": "meta",
    "storageKey": null,
    "args": null,
    "concreteType": "UserMeta",
    "plural": false,
    "selections": [{
      "kind": "LinkedField",
      "alias": null,
      "name": "livestreaming",
      "storageKey": null,
      "args": null,
      "concreteType": "LivestreamingConfig",
      "plural": false,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "apiKey",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "apiUrl",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "publishUrl",
        "args": null,
        "storageKey": null
      }]
    }]
  };
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "updateProfileMutation",
      "type": "RootMutationType",
      "metadata": null,
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": [{
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProfile",
        "storageKey": null,
        "args": v1
        /*: any*/
        ,
        "concreteType": "UpdateProfilePayload",
        "plural": false,
        "selections": [v2
        /*: any*/
        , {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [v3
          /*: any*/
          ]
        }]
      }]
    },
    "operation": {
      "kind": "Operation",
      "name": "updateProfileMutation",
      "argumentDefinitions": v0
      /*: any*/
      ,
      "selections": [{
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProfile",
        "storageKey": null,
        "args": v1
        /*: any*/
        ,
        "concreteType": "UpdateProfilePayload",
        "plural": false,
        "selections": [v2
        /*: any*/
        , {
          "kind": "LinkedField",
          "alias": null,
          "name": "user",
          "storageKey": null,
          "args": null,
          "concreteType": "User",
          "plural": false,
          "selections": [v3
          /*: any*/
          , {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }]
        }]
      }]
    },
    "params": {
      "operationKind": "mutation",
      "name": "updateProfileMutation",
      "id": null,
      "text": "mutation updateProfileMutation(\n  $input: UpdateProfileInput!\n) {\n  updateProfile(input: $input) {\n    errors {\n      key\n      message\n      reason\n    }\n    user {\n      meta {\n        livestreaming {\n          apiKey\n          apiUrl\n          publishUrl\n        }\n      }\n      id\n    }\n  }\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = '05e7947e8e8ad064ff4915eb7a53a48b';
module.exports = node;