"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleDeviceSettings = toggleDeviceSettings;
exports.saveVideoConfig = saveVideoConfig;
exports.saveVideoQuality = saveVideoQuality;
exports.saveAudioInConfig = saveAudioInConfig;
exports.saveAudioOutConfig = saveAudioOutConfig;
exports.setIsElectron = setIsElectron;
exports.SET_IS_ELECTRON = exports.AUDIOOUT_DEVICE_SETTINGS = exports.AUDIOIN_DEVICE_SETTINGS = exports.VIDEO_QUALITY_SETTINGS = exports.VIDEO_DEVICE_SETTINGS = exports.TOGGLE_DEVICE_SETTINGS = void 0;
var TOGGLE_DEVICE_SETTINGS = 'SETTINGS::TOGGLE_DEVICES';
exports.TOGGLE_DEVICE_SETTINGS = TOGGLE_DEVICE_SETTINGS;
var VIDEO_DEVICE_SETTINGS = 'SETTINGS::VIDEO_DEVICE';
exports.VIDEO_DEVICE_SETTINGS = VIDEO_DEVICE_SETTINGS;
var VIDEO_QUALITY_SETTINGS = 'SETTINGS::VIDEO_QUALITY';
exports.VIDEO_QUALITY_SETTINGS = VIDEO_QUALITY_SETTINGS;
var AUDIOIN_DEVICE_SETTINGS = 'SETTINGS::AUDIOIN_DEVICE';
exports.AUDIOIN_DEVICE_SETTINGS = AUDIOIN_DEVICE_SETTINGS;
var AUDIOOUT_DEVICE_SETTINGS = 'SETTINGS::AUDIOOUT_DEVICE';
exports.AUDIOOUT_DEVICE_SETTINGS = AUDIOOUT_DEVICE_SETTINGS;
var SET_IS_ELECTRON = 'SET_IS_ELECTRON';
exports.SET_IS_ELECTRON = SET_IS_ELECTRON;

function mediaConfigUpdate(config, which) {
  return {
    type: which,
    payload: {
      config: config
    }
  };
}

function _saveVideoConfig(dispatch, config, localStorage) {
  _persistVideoConfig(config, localStorage);

  dispatch(mediaConfigUpdate(config, VIDEO_DEVICE_SETTINGS));
}

function _saveVideoQuality(dispatch, config, localStorage) {
  _persistVideoQualityConfig(config, localStorage);

  dispatch(mediaConfigUpdate(config, VIDEO_QUALITY_SETTINGS));
}

function _saveAudioInConfig(dispatch, config, localStorage) {
  _persistAudioInConfig(config, localStorage);

  dispatch(mediaConfigUpdate(config, AUDIOIN_DEVICE_SETTINGS));
}

function _saveAudioOutConfig(dispatch, config, localStorage) {
  _persistAudioOutConfig(config, localStorage);

  dispatch(mediaConfigUpdate(config, AUDIOOUT_DEVICE_SETTINGS));
}

function _persistVideoConfig(config, storage) {
  var oldconfig = storage.loadMediaConfig();
  var newconfig = Object.assign(oldconfig, {
    videoDevice: config
  });
  storage.saveMediaConfig(newconfig);
}

function _persistAudioInConfig(config, storage) {
  var oldconfig = storage.loadMediaConfig();
  var newconfig = Object.assign(oldconfig, {
    audioInDevice: config
  });
  storage.saveMediaConfig(newconfig);
}

function _persistAudioOutConfig(config, storage) {
  var oldconfig = storage.loadMediaConfig();
  var newconfig = Object.assign(oldconfig, {
    audioOutDevice: config
  });
  storage.saveMediaConfig(newconfig);
}

function _persistVideoQualityConfig(config, storage) {
  var oldconfig = storage.loadMediaConfig();
  var newconfig = Object.assign(oldconfig, {
    videoQuality: config
  });
  storage.saveMediaConfig(newconfig);
}

function toggleDeviceSettings() {
  return function (dispatch) {
    dispatch({
      type: TOGGLE_DEVICE_SETTINGS,
      payload: {}
    });
  };
}

function saveVideoConfig(config, configStorage) {
  return function (dispatch) {
    return _saveVideoConfig(dispatch, config, configStorage);
  };
}

function saveVideoQuality(config, configStorage) {
  return function (dispatch) {
    return _saveVideoQuality(dispatch, config, configStorage);
  };
}

function saveAudioInConfig(config, configStorage) {
  return function (dispatch) {
    return _saveAudioInConfig(dispatch, config, configStorage);
  };
}

function saveAudioOutConfig(config, configStorage) {
  return function (dispatch) {
    return _saveAudioOutConfig(dispatch, config, configStorage);
  };
}

function setIsElectron(isElectron) {
  return {
    type: SET_IS_ELECTRON,
    payload: {
      isElectron: isElectron
    }
  };
}