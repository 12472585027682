const drawerWidth = 55;

const style = theme => ({
  root: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    MsOverflowStyle: 'none'
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  item: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  appBar: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
  },
  iconSelected: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    width: 'max-content'
  },
  pulsating: {
    color: "red",
    animation: "pulse 3s 5",
    "-webkit-animation-name": "pulse",
    "-webkit-animation-duration": "3s",
    "-webkit-animation-iteration-count": "5",
  },
  "@keyframes pulse": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.1,
    },
    "100%": {
      opacity: 1,
    },
  }
});

export default style;
