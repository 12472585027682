"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.humanizeTime = humanizeTime;
exports.callWhen = callWhen;

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function humanizeTime(elapsed, formatMessage, messages) {
  var momentObject = _moment["default"].duration(elapsed, 'seconds');

  var days = momentObject.days();
  var hours = momentObject.hours();
  var minutes = momentObject.minutes();
  var seconds = momentObject.seconds();
  var string;

  if (elapsed >= 60 * 60 * 24) {
    string = formatMessage(messages.meetingVeryLongDuration, {
      time1: days,
      time2: hours,
      time3: minutes
    });
  } else if (elapsed >= 60 * 60) {
    string = formatMessage(messages.meetingLongDuration, {
      time1: hours,
      time2: minutes
    });
  } else if (elapsed >= 60) {
    string = formatMessage(messages.meetingCommonDuration, {
      time1: minutes,
      time2: seconds
    });
  } else {
    string = formatMessage(messages.meetingBriefDuration, {
      time: seconds
    });
  }

  return string;
}

function callWhen(condition, f) {
  var interval = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
  var maxTries = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 3;

  if (maxTries <= 0) {
    throw Error("invalid argument ".concat(maxTries, " for maxTries argument"));
  }

  if (interval <= 0) {
    throw Error("invalid argument ".concat(interval, " for interval argument"));
  }

  return _callWhen(condition, f, 0, interval, maxTries);
}

function _callWhen(condition, f, tryNum, interval, maxTries) {
  if (condition()) {
    return f();
  } else {
    if (tryNum < maxTries) {
      setTimeout(function () {
        return _callWhen(condition, f, tryNum + 1, interval, maxTries);
      }, interval);
    }
  }
}