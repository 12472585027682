const style = theme => ({
  content: {
    overflow: 'unset',
  },
  text: {
    marginBottom: theme.spacing.unit,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
});

export default style;
