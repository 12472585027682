"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.retriveStoredSettings = retriveStoredSettings;

var _settings = require("../reducers/settings");

function _setAudioInState(state, config) {
  return Object.assign(state, {
    audioInDevice: config.audioInDevice
  });
}

function _setVideoDeviceState(state, config) {
  return Object.assign(state, {
    videoDevice: config.videoDevice
  });
}

function _setAudioOutState(state, config) {
  return Object.assign(state, {
    audioOutDevice: config.audioOutDevice
  });
}

function _setVideoQualityState(state, config) {
  return Object.assign(state, {
    videoQuality: config.videoQuality
  });
}

function retriveStoredSettings(localStorage) {
  var storedConf = localStorage.loadMediaConfig();

  var state = _setAudioInState(_settings.defaultState, storedConf);

  state = _setAudioOutState(state, storedConf);
  state = _setVideoDeviceState(state, storedConf);
  return _setVideoQualityState(state, storedConf);
}