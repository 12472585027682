const style = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  loginActions: {
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  oauthButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  formHelperText: {
    marginLeft: "7px"
  },
  videoConferenceIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    fontSize: '64px',
    color: '#084f96',
    padding: theme.spacing.unit * 2,
  },
});

export default style;
