var generic = {
  ok: 'Ok',
  cancel: 'Annulla',
  close: 'Chiudi',
  reset: 'Reset',
  yes: 'Sì',
  no: 'No',
  pageNotFound: 'Pagina non trovata',
  hour: 'ora',
  hours: 'ore',
  minute: 'minuto',
  minutes: 'minuti',
  second: 'secondo',
  seconds: 'secondi',
  day: 'giorno',
  days: 'giorni',
  login: 'accedi',
  email: 'Email',
  phoneNumber: 'Numero di telefono',
  call: 'Chiama',
  copyToClipboard: 'Copia negli appunti',
  copiedToClipboard: 'Copiato!',
  from: 'da',
  to: 'a',
  next: 'successivo',
  previous: 'precedente',
  dateFormat: 'DD MMMM H:mm',
  fullDateFormat: 'DD MMMM YYYY HH:mm',
  tryAgain: 'Try again',
  leave: 'Esci',
  error: 'Errore',
  networkError: 'Errore di connessione alla rete',
  endMeetingMsg: 'Termina meeting',
  save: 'Salva',
  name: 'Nome',
  allDone: 'Nessun evento, tutto fatto!',
  allDoneSchedule: 'Clicca qui per organizzare un appuntamento.'
};
var deviceConfiguration = {
  deviceSelectorTitle: 'Seleziona i dispositivi audio e video',
  deviceSelectorVideoError: "Rilevato errore nell'accendere la telecamera, potrebbe esser in uso o con impostazioni di qualit\xE0 troppa alta.",
  deviceSelectorAudioInput: 'Ingresso audio',
  deviceSelectorVideoInput: 'Telecamera',
  deviceSelectorAudioOutput: 'Uscita audio',
  deviceSelectorNoAudioOutput: 'Uscita audio non supportata',
  deviceSelectorVideoQuality: 'Qualità video',
  deviceSelectorLoadingCam: 'Riconfigurazione telecamera, attendere....'
};
var login = {
  username: 'Nome utente',
  password: 'Password',
  loginHaveAnAccount: 'Hai un account? Fai login qui.',
  disconnectedMessage: 'Connessione al server non riuscita',
  genericOAuth: 'Accedi con OAuth/2.0',
  errorLoginCode5xx: "Il server ha riscontrato un errore, si prega di riprovare o contattare l'amministratore se il problema persiste",
  errorLoginCode4xx: 'La richiesta non è valida',
  errorLoginCode401: 'Username o password errata',
  errorLoginGeneric: "Errore inaspettato. Contattare l'amministratore se il problema persiste"
};
var logout = {
  logoutDialogTitle: 'Logout',
  logoutDialogMessage: 'Sei sicuro di voler uscire?'
};
var mediaPermissions = {
  mediaPermissionsMessage: "Perch\xE9 gli altri partecipanti possano sentirti, \xE8 richiesto l'accesso al microfono.\n" + "Ti verr\xE0 chiesto di confermare questa scelta per ogni browser e computer in uso.\n" + "Se questa operazione richiede troppo tempo, potresti avere problemi hardware. Prova a ricaricare la pagina o controllare i tuoi dispositivi.",
  mediaPermissionsTitle: 'Consenti di utilizzare la videocamera e il microfono',
  mediaPermissionsBlockedTitle: 'Fotocamera e microfono bloccati',
  mediaPermissionsPermsErr: "Dobbiamo accedere alla videocamera e al microfono.\n" + "Fai clic sull'icona della videocamera bloccata nella barra degli indirizzi del browser.\n" + "Potresti aver bisogno di ricaricare questa pagina dopo aver cambiato le impostazioni.",
  mediaPermissionsGenErr: "Qualcosa \xE8 andato storto nell'avvio delle periferiche.\n" + "Prova a riavviare il browser o chiedi aiuto. Scusa :("
};
var meeting = {
  meetingHeader: 'Appuntamento',
  meetingWelcome: 'Ciao {user}, benvenuto.',
  noVideoDeviceFound: 'Nessun dispositivo video trovato. Accesso in modalità solo audio.',
  insertMeetingCode: "Inserisci il codice dell'appuntamento",
  navigationConfirmationTitle: 'Lasciare la stanza?',
  navigationConfirmationMessage: 'Sei sicuro di voler lasciare la stanza?',
  errorCodeGenericTitle: 'Errore di connessione alla stanza.',
  errorCodeGenericBody: "Si \xE8 verificato un'errore.",
  errorCodeGenericPrimaryButton: 'Annulla',
  reconnectNotFound: 'Stanza non trovata o non ancora avviata.\nVuoi ricollegarti?',
  reconnectNotRunning: 'Proprietario non ancora collegato.\nVuoi riprovare?',
  errorCode1000Title: "Impossibile accedere alla stanza",
  errorCode1000Body: 'Stanza privata non disponibile',
  errorCode1000PrimaryButton: 'Ok',
  errorCode1001Title: "C'\xE8 stato un errore",
  errorCode1001Body: "Se il problema persiste contattare l'amministratore del server",
  errorCode1001PrimaryButton: 'Riprova',
  errorCode1001SecondaryButton: 'Annulla',
  errorCode1002Title: "Impossibile connettersi alla stanza",
  errorCode1002Body: "La stanza {title} non \xE8 ancora disponibile. \n" + "Inizia: {dt_start} \n" + "Finisce: {dt_end} \n" + "La stanza sar\xE0 disponibile tra {days} {unitDays} {hours} {unitHours} {minutes} {unitMinutes} ",
  errorCode1002SecondaryButton: 'Annulla',
  errorCode1002PrimaryButton: 'Riprova',
  errorCode1003Title: "Impossibile entrare nella stanza {title}",
  errorCode1003Body: 'La stanza è stata chiusa {dt_end} ',
  errorCode1003PrimaryButton: 'Ok',
  errorCode1004Title: "Appuntamento non trovato oppure scaduto",
  errorCode1004Body: 'Nessun appuntamento trovato',
  errorCode1004SecondaryButton: 'Annulla',
  errorCode1004PrimaryButton: 'Riprova',
  errorCode1005Title: "Il proprietario non \xE8 presente",
  errorCode1005Body: 'Si prega di attendere, l\'accesso alla stanza verrà effettuato in automatico',
  errorCode1005SecondaryButton: 'Annulla',
  errorCode1005PrimaryButton: 'Riprova',
  errorCode1008Title: "Risorse del dominio esaurite",
  errorCode1008Body: 'Non è possibile ammettere ulteriori ospiti in questo dominio.',
  errorCode1008SecondaryButton: 'Annulla',
  errorCode1008PrimaryButton: 'Riprova',
  errorCode1009Title: "Risorse del dominio esaurite",
  errorCode1009Body: 'Non è possibile allocare ulteriori stanze in questo dominio.',
  errorCode1009SecondaryButton: 'Annulla',
  errorCode1009PrimaryButton: 'Riprova',
  errorCode1010Title: "Risorse utente esaurite",
  errorCode1010Body: 'Spiacenti, il tuo utente non può avviare ulteriori stanze.',
  errorCode1010SecondaryButton: 'Annulla',
  errorCode1010PrimaryButton: 'Riprova',
  errorCode1011Title: "Risorse della stanza esaurite",
  errorCode1011Body: 'Questa stanza non può ammettere ulteriori ospiti.',
  errorCode1011SecondaryButton: 'Annulla',
  errorCode1011PrimaryButton: 'Riprova',
  errorCode1012Title: "Errore generico risorse",
  errorCode1012Body: 'Errore generico nel controllare le risorse. Se il problema persiste contattare l\'amministratore del server.',
  errorCode1012SecondaryButton: 'Annulla',
  errorCode1012PrimaryButton: 'Riprova',
  errorCode1013Title: 'Impossibile entrare nella stanza',
  errorCode1013Body: 'Questa stanza è bloccata, non sono ammessi altri partecipanti.',
  errorCode1013SecondaryButton: 'Annulla',
  errorCode1013PrimaryButton: 'Riprova',
  errorCode4001Title: 'Non autorizzato',
  errorCode4001Body: 'Non hai permessi sufficienti per completare questa azione.',
  errorCode4001SecondaryButton: 'Annulla',
  errorCode4001PrimaryButton: 'Ok',
  guestLoginDialogTitle: 'Inserisci il tuo nome',
  clickHereToLogin: "Organizzatore dell'appuntamento? Clicca qui per fare login",
  clickHereToLoginToOwnRoom: 'Proprietario della stanza? Clicca qui per fare login',
  streamErrorTitle: 'Errore di accesso alla telecamera',
  streamErrorContent: 'Controllare il dispositivo hardware o i permessi di accesso.',
  joinButton: 'Entra',
  joinByCode: 'Accedi tramite codice',
  screenVideoDisplayName: "Schermo di {name}",
  publisherIsNotSendingVideo: 'Questo utente non sta inviando video',
  youAreNotSendingVideo: 'Non stai inviando video',
  listAttendees: 'Lista partecipanti:',
  meetingLeftDialogTitle: 'Stanza lasciata',
  meetingLeftDialogContent: 'Hai lasciato la stanza',
  meetingLeftDialogContentWhenKicked: 'Sei stato espulso dalla stanza',
  meetingLeftDialogContentWhenEnded: 'Il meeting è terminato',
  meetingLeftDialogContentWhenIceFailed: 'Errore nella connessione con il server audio/video, probabilmente a causa di restrizioni firewall. Contattare l\'amministratore di rete',
  meetingAvailable: 'I prossimi appuntamenti',
  meetingStartsAt: '{dtStart}',
  attendeesErrorEmail: "L'email non è valida",
  attendeesErrorEmpty: 'Il campo è vuoto',
  attendeesErrorRequired: 'Almeno un partecipante è richiesto',
  attendeesErrorAlreadyEntered: "L'email è già stata inserita",
  dtErrorTooBrief: 'Intervallo troppo breve',
  dtErrorTooLong: 'Intervallo troppo lungo',
  scheduleMeeting: 'Organizza un appuntamento',
  scheduleStartMeeting: 'Inserisci la data di inizio appuntamento',
  scheduleEndMeeting: 'Inserisci la data di fine appuntamento',
  titleMeeting: "Titolo dell'appuntamento",
  titleMeetingError: 'Il campo è vuoto',
  notes: 'Note',
  sendInvite: 'Invita',
  invitePersonToMeeting: 'Invita nuovo partecipante',
  roomInfoTitlePersonal: "Avvia la tua stanza personale",
  roomHowToInvitePersonal: "Per invitare qualcuno alla tua stanza personale, inviagli i seguenti riferimenti:",
  roomHowInvitePersonalClipBoardHeader: "Collegati alla mia conferenza personale, di seguito i dettagli:",
  roomHowInvitePersonalClipBoardWeb: "Accesso tramite browser (Chrome, Firefox o Safari)",
  roomInfoTitleScheduled: "Appuntamento organizzato da {user}",
  roomInfoName: 'Nome stanza',
  roomInfoWebAccess: 'Accesso web',
  roomPublishStreamUrl: 'Accesso diretta video',
  roomInfoPstnAccess: 'Numero per accesso telefonico',
  roomInfoPstnNotAvailable: 'Non disponibile',
  roomInfoPstnNumber: '{number} (il pin è {pin})',
  roomInfoScheduling: 'Pianificata per',
  inviteNewParticipantDialogTitle: 'Nuovo invito per la stanza corrente',
  inviteNewParticipantDialogContent: "Inserisci l'email per i nuovi partecipanti da invitare.",
  inviteNewParticipantDialogValidationError: 'Email non valida',
  dialPersonToMeeting: 'Chiama qualcuno via telefono',
  dialToMeetingDialogTitle: 'Aggiungi qualcuno alla stanza via telefono',
  dialToMeetingDialogContent: 'Inserisci un numero di telefono valido',
  dialToMeetingValidatioError: 'Numero di telefono non valido',
  dialParticipantSuccess: 'Chiamata avviata con successo.',
  startMeeting: 'Data di inizio appuntamento',
  endMeeting: 'Data di fine appuntamento',
  deleteButton: 'Elimina',
  highLinkQuality: 'Buona velocità in upload',
  mediumLinkQuality: 'Discreta velocità in upload',
  lowLinkQuality: 'Bassa velocità in upload',
  computingLinkQuality: 'Calcolo velocità di upload in corso',
  linkQualityNotAvailable: 'Velocità di upload non disponibile',
  6005: 'Servizio non disponibile, potrebbero esser stati raggiunti i limiti di chiamate.',
  6004: 'Numero non raggiungibile, potrebbe esser errato o non permesso.',
  6003: "Non è stato possibile invitare i partecipanti, si prega di riprovare o contattare l'amministratore se il problema persiste",
  6000: "Non è stato possibile invitare i partecipanti, l'appuntamento non è ancora iniziato.",
  6001: "Non è stato possibile invitare i partecipanti, l'appuntamento è scaduto.",
  6002: "Non è stato possibile invitare i partecipanti, l'appuntamento non esiste.",
  deleteMeetingError: "Non è stato possibile cancellare l'appuntamento, si prega di riprovare o contattare l'amministratore se il problema persiste",
  unexpectedRoomError: 'Si è verificato un errore: {errorMessage}.',
  meetingDuration: "Durata dell'appuntamento",
  meetingBriefDuration: '{time} {time, plural, one {secondo} other {secondi} }',
  meetingCommonDuration: '{time1} {time1, plural, one {minuto} other {minuti}} {time2, plural, =0 {} other {e {time2 } {time2, plural, one {secondo} other {secondi}}}}',
  meetingLongDuration: "{time1} {time1, plural, one {ora} other {ore}} {time2, plural, =0 {} other {e {time2 } {time2, plural, one {minuto} other {minuti}}}}",
  meetingVeryLongDuration: "{time1} {time1, plural, one {giorno} other {giorni}}{time2, plural, =0 {} other {, {time2 } {time2, plural, one {ora} other {ore}}}} {time3, plural, =0 {} other {e {time3 } {time3, plural, one {minuto} other {minuti}}}}",
  muteAllButton: 'Disattiva audio partecipanti',
  unMuteAllButton: 'Attiva audio partecipanti',
  muteAllVideos: 'Non ricevere video dai partecipanti',
  endMeetingDialogTitle: 'Termina meeting',
  endMeetingDialogContent: 'Sei sicuro di voler terminare il meeting? Tutti i partecipanti verranno espulsi.'
};
var screenShare = {
  screenSharingDialogTitle: 'Condividi schermo',
  screensThumbnailsListDialogTitle: 'Seleziona schermo',
  windowsThumbnailsListDialogTitle: 'Seleziona finestra',
  screenSharingDialogContent: 'Seleziona cosa condividere',
  screenSharingDialogScreen: 'Schermo',
  screenSharingDialogWindow: 'Finestra',
  screenSharingErrorTitle: 'Errore nella condivisione dello schermo',
  screenSharingErrorContent: 'Accertarsi di consentire il permesso alla condivisione quando richiesto dal browser',
  extensionErrorTitle: 'Estensione non installata',
  extensionErrorContent: "Per abilitare la condivisione dello schermo \xE8 necessaria un'estensione.\n" + "Per installare l'estensione VoiSmart Screen Share:\n",
  extensionErrorContentList: "1. clicca sul buttone installa\n" + "2. aggiungi l'estensione al tuo browser\n" + "3. ricarica la pagina",
  extensionErrorButton: 'Annulla',
  extensionInstallButton: 'Installa',
  extensionReloadButton: "Ricarica",
  screenSharingUnsupportedTitle: 'Condivisione dello schermo non supportata',
  screenSharingUnsupportedMessage: "Il tuo browser non supporta la condivisione dello schermo.\n" + "Prova con Chrome, Firefox o Opera."
};
var browserCheck = {
  unsupportedBrowserTitle: 'Web browser non supportato',
  unsupportedBrowserVersionTitle: 'Web browser non supportato',
  supportedExperimentalTitle: 'Web browser non pienamente supportato',
  unsupportedBrowserContent: 'Il tuo browser non è supportato.',
  unsupportedBrowserVersionContent: 'Il tuo browser è supportato a partire dalla versione {version}. Aggiornalo e riprova.',
  supportedExperimentalContent: 'Il tuo browser è supportato in maniera sperimentale, alcune funzionalità non saranno disponibili.',
  supportedBrowsers: 'I browser consigliati sono Google Chrome (dalla versione {minChromeVersion}) e Mozilla Firefox (dalla versione {minFirefoxVersion}).',
  iosSupportedBrowsers: 'Si consiglia di usare Safari (dalla versione {minSafariVersion}).'
};
var tooltip = {
  meetingIconButtonTooltip: "Homepage",
  loginIconButtonTooltip: 'Login',
  logoutIconButtonTooltip: 'Logout',
  settingsIconButtonTooltip: 'Impostazioni',
  accountIconButtonTooltip: 'Utente',
  changeUrlButtonTooltip: 'Cambia indirizzo',
  screenShareButtonExtensionTooltipDownload: "Scarica l'estensione per la condivisione dello schermo",
  screenShareButtonExtensionTooltipIsNotSupported: 'Condivisione dello schermo non supportata',
  screenShareButtonExtensionTooltipIsSupported: 'Condividi schermo',
  screenShareButtonExtensionTooltipDisable: 'Disattiva condivisione schermo',
  microphoneButtonEnableTooltip: 'Attiva Microfono',
  microphoneButtonDisableTooltip: 'Disattiva Microfono',
  videoButtonEnableTooltip: 'Attiva Video',
  videoButtonDisableTooltip: 'Disattiva Video',
  hangupIconButton: 'Termina chiamata',
  microphoneButtonDisabledTooltip: 'Muto',
  exitFullScreenButton: 'Esci dalla modalità schermo intero',
  enterFullScreenButton: 'Schermo intero',
  enablePipButton: 'Abilita mini video',
  disablePipButton: 'Disabilita mini video',
  enableDesktopControl: 'Abilita Controllo Desktop',
  disableDesktopControl: 'Disabilita Controllo Desktop',
  chatIconButtonTooltip: 'Chat',
  ejectIconButtonTooltip: 'Espelli',
  enlargeIconButtonTooltip: 'Ingrandisci',
  collapseIconButtonTooltip: 'Riduci',
  promoteRoleIconButtonTooltip: 'Rendi moderatore',
  demoteRoleIconButtonTooltip: 'Rimuovi dal ruolo di moderatore',
  presentationIconTooltip: 'Moderatore',
  roomInfoTooltip: 'Informazioni sulla stanza',
  scheduleButtonTooltip: 'Organizza un appuntamento',
  joinTooltip: 'Entra',
  editTooltip: 'Modifica',
  roomActionsMenuTooltip: 'Azioni stanza',
  lockRoom: 'Blocca la stanza',
  unLockRoom: 'Sblocca la stanza'
};
var notificationMessage = {
  joinMeeting: "{username} \xE8 entrato nella stanza",
  exitMeeting: "{username} \xE8 uscito dalla stanza",
  muted: 'Il tuo microfono è stato disattivato',
  unmuted: 'Il tuo microfono è stato attivato',
  promotedToModerator: 'Sei stato promosso a moderatore',
  demotedModerator: 'Non sei più moderatore',
  kicked: 'Sei stato espulso dalla stanza',
  rosterMultipleChanges: "{joinedUsers} utenti sono entrati nella stanza e {exitedUsers} utenti sono usciti dalla stanza",
  needFullscreenForControl: 'Devi avviare manualmente lo share del desktop in fullscreen per avviare il controllo desktop',
  stopDesktopControl: 'Il controllo del desktop remoto per questa stanza è stato disabilitato',
  startDrawing: 'Il disegno desktop per questa stanza è stato abilitato',
  startDrawingBrowser: 'Il disegno desktop per questa stanza è stato abilitato. Devi avviare manualmente lo share del desktop in fullscreen per per usarlo.',
  stopDrawing: 'Il disegno desktop per questa stanza è stato disabilitato',
  meetingEnded: 'Meeting terminato',
  joinOnLockedRoom: '{dname} non ha potuto accedere al meeting perché la stanza è bloccata'
};
var layout = {
  standardLayout: 'Layout base',
  featuredLayout: 'Presentazione',
  chooseLayout: 'Scegli il tuo layout',
  applyLayout: 'Applica il layout corrente a tutti i partecipanti'
};
var recording = {
  stopRecording: 'Ferma registrazione',
  startRecording: 'Avvia registrazione',
  reportRecording: 'Lista registrazioni',
  reportTitleRecording: 'Titolo',
  reportStartRecording: 'Inizio',
  reportEndRecording: 'Fine',
  reportNoteRecording: 'Note',
  reportSlugRecording: 'Stanza',
  reportPlayRecording: 'Riproduci',
  reportDeleteRecording: 'Elimina',
  reportDurationRecording: "Durata",
  reportFilterTemporalRangeRecording: "Intervallo Temporale",
  reportFilterApplyRecording: "Applica Filtro",
  reportFilterAppliedRecording: "Filtro Applicato",
  reportLoadRecording: 'Carica altre registrazioni',
  reportUnablePlayRecording: 'Impossibile riprodurre la registrazione',
  reportUnableLoadRecording: 'Impossibile caricare la lista delle registrazioni',
  roomStartRecording: 'Questa stanza è in registrazione.',
  roomStopRecording: 'La registrazione è stata interrotta.',
  reportDownloadRecording: 'Scarica registrazione.',
  7001: 'Tutti i registratori sono occupati.',
  7002: 'Metodo non autorizzato.',
  7003: 'Errore interno, contattare amministratore.',
  deleteRecordingError: 'Non è stato possibile cancellare la registrazione, si prega di riprovare o contattare l\'amministratore se il problema persiste.',
  deleteRecordingSuccess: 'La registrazione è stata cancellata con successo.',
  5004: 'Server timeout',
  defaultNotificationError: 'Unknown Error'
};
var liveStreaming = {
  roomStartStreaming: 'La conferenza è trasmessa in diretta video',
  roomStopStreaming: 'La trasmissione in diretta video è terminata',
  tooltipStartLiveStreaming: 'Avvia la diretta video',
  tooltipStopLiveStreaming: 'Ferma la diretta video'
};
var accountCmp = {
  accountCmpUser: 'Utente',
  accountCmpPins: 'Pins',
  accountCmpStreaming: 'Streaming'
};
var userTabCmp = {
  userTabCmpTitle: 'Informazioni utente',
  personalRoom: 'Stanza personale'
};
var regenPinsTabCmp = {
  regenPinsTabCmpRegen: 'Rigenera codici',
  regenPinsTabCmpTitle: 'Codici accesso stanza personale',
  regenPinsTabCmpPubPin: 'Pubblico',
  regenPinsTabCmpPrivPin: 'Personale'
};
var liveStreamingCmp = {
  liveStreamingCmpTitle: 'Diretta video',
  liveStreamingCmpApiKey: 'Chiave/nome flusso',
  liveStreamingCmpShowApiKey: 'Mostra chiave',
  liveStreamingCmpApiUrl: 'Server URL',
  liveStreamingCmpPubUrl: 'URL pubblico diretta video'
};
var errors = {
  regeneratePinsGenError: 'Errore nel rinnovo dei pin.',
  getMyProfileError: 'Errore nel recupero del profilo utente.',
  updateProfileError: "Errore nell'aggiornamento del profilo utente"
};
var success = {
  updateProfileSuccess: 'Profilo salvato correttamente.'
};
export { generic, deviceConfiguration, login, logout, mediaPermissions, meeting, screenShare, browserCheck, liveStreaming, tooltip, notificationMessage, layout, recording, accountCmp, userTabCmp, regenPinsTabCmp, liveStreamingCmp, errors, success };