/**
 * @flow
 * @relayHash a358edc7a8e43f4b1f648affe6b0b132
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
export type myProfileQueryVariables = {||};
export type myProfileQueryResponse = {|
  +myProfile: ?{|
    +email: ?string,
    +middlename: ?string,
    +name: ?string,
    +username: ?string,
    +surname: ?string,
    +meta: ?{|
      +livestreaming: ?{|
        +apiKey: ?string,
        +apiUrl: ?string,
        +publishUrl: ?string,
      |}
    |},
  |}
|};
export type myProfileQuery = {|
  variables: myProfileQueryVariables,
  response: myProfileQueryResponse,
|};
*/

/*
query myProfileQuery {
  myProfile {
    email
    middlename
    name
    username
    surname
    meta {
      livestreaming {
        apiKey
        apiUrl
        publishUrl
      }
    }
  }
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = [{
    "kind": "LinkedField",
    "alias": null,
    "name": "myProfile",
    "storageKey": null,
    "args": null,
    "concreteType": "UserProfile",
    "plural": false,
    "selections": [{
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "middlename",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "username",
      "args": null,
      "storageKey": null
    }, {
      "kind": "ScalarField",
      "alias": null,
      "name": "surname",
      "args": null,
      "storageKey": null
    }, {
      "kind": "LinkedField",
      "alias": null,
      "name": "meta",
      "storageKey": null,
      "args": null,
      "concreteType": "UserMeta",
      "plural": false,
      "selections": [{
        "kind": "LinkedField",
        "alias": null,
        "name": "livestreaming",
        "storageKey": null,
        "args": null,
        "concreteType": "LivestreamingConfig",
        "plural": false,
        "selections": [{
          "kind": "ScalarField",
          "alias": null,
          "name": "apiKey",
          "args": null,
          "storageKey": null
        }, {
          "kind": "ScalarField",
          "alias": null,
          "name": "apiUrl",
          "args": null,
          "storageKey": null
        }, {
          "kind": "ScalarField",
          "alias": null,
          "name": "publishUrl",
          "args": null,
          "storageKey": null
        }]
      }]
    }]
  }];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "myProfileQuery",
      "type": "RootQueryType",
      "metadata": null,
      "argumentDefinitions": [],
      "selections": v0
      /*: any*/

    },
    "operation": {
      "kind": "Operation",
      "name": "myProfileQuery",
      "argumentDefinitions": [],
      "selections": v0
      /*: any*/

    },
    "params": {
      "operationKind": "query",
      "name": "myProfileQuery",
      "id": null,
      "text": "query myProfileQuery {\n  myProfile {\n    email\n    middlename\n    name\n    username\n    surname\n    meta {\n      livestreaming {\n        apiKey\n        apiUrl\n        publishUrl\n      }\n    }\n  }\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = 'f404e506b7c617f36d5b9f4c754da6e4';
module.exports = node;