/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl } from 'react-intl';

/* UI Imports */
import { withStyles } from '@material-ui/core/styles';



import DesktopControlButton from './DesktopControlButton';
import CloseScreenShareButton from './CloseScreenShareButton';

/* Local Style */
import style from './style';


class OwnScreenShareToolbar extends Component {

  render() {
    const { someoneHasDesktopControl, classes, screenSourceType, user, isElectron } = this.props;
    return (
      <div className={classes.toolBar}>

        <CloseScreenShareButton
          isElectron={isElectron}
          isDesktopControlEnabled={this.props.isDesktopControlEnabled}
          user={user}
          screenSourceType={screenSourceType}
          handleScreenShare={this.props.handleScreenShare}
          enableDesktopControl={this.props.enableDesktopControl}
        />
        {isElectron && !someoneHasDesktopControl && screenSourceType !== 'window' && <DesktopControlButton
          isElectron={isElectron}
          isDesktopControlEnabled={this.props.isDesktopControlEnabled}
          screenSourceType={screenSourceType}
          handleScreenShare={this.props.handleScreenShare}
          enableDesktopControl={this.props.enableDesktopControl}
          user={user}
        />}

      </div>
    );
  }
}


OwnScreenShareToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  isElectron: PropTypes.bool,
  handleScreenShare: PropTypes.func,
  enableDesktopControl: PropTypes.func,
  isDesktopControlEnabled: PropTypes.bool,
  someoneHasDesktopControl: PropTypes.bool,
  screenSourceType: PropTypes.string,
  intl: intlShape.isRequired
};

function mapStateToProps(state, props) {
  return {
    isDesktopControlEnabled: (state.room.roster[props.user] ||
      { isDesktopControlEnabled: false }).isDesktopControlEnabled,
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(OwnScreenShareToolbar)));
