import React from 'react';
import { defineMessages } from 'react-intl';
import MutedVideoElement from '../MutedVideoElement';


const messages = defineMessages({
  notSendingVideo: {id: 'youAreNotSendingVideo'},
});


export default function OwnMutedVideoElement(extraProps) {
  return (
    <MutedVideoElement {...extraProps} messages={messages} />
  );
}
