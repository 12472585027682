"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.myRecordingsQueryForward = exports.myRecordingsFragment = exports.myRecordingsFragmentList = exports.myRecordingsQuery = void 0;

var _reactRelay = require("react-relay");

var myRecordingsQuery = function myRecordingsQuery() {
  var node = require("./__generated__/myRecordingsQuery.graphql");

  if (node.hash && node.hash !== "a73c7bccc54753ebd476119094d9757b") {
    console.error("The definition of 'myRecordingsQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/myRecordingsQuery.graphql");
};

exports.myRecordingsQuery = myRecordingsQuery;

var myRecordingsQueryForward = function myRecordingsQueryForward() {
  var node = require("./__generated__/myRecordingsForwardQuery.graphql");

  if (node.hash && node.hash !== "e0c94b01e9588bba24230f58126f0566") {
    console.error("The definition of 'myRecordingsForwardQuery' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/myRecordingsForwardQuery.graphql");
};

exports.myRecordingsQueryForward = myRecordingsQueryForward;
var myRecordingsFragment = {
  recording: function recording() {
    var node = require("./__generated__/myRecordings_recording.graphql");

    if (node.hash && node.hash !== "c2f0a99eb1d65104781f82d3fc48a8d2") {
      console.error("The definition of 'myRecordings_recording' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
    }

    return require("./__generated__/myRecordings_recording.graphql");
  }
};
exports.myRecordingsFragment = myRecordingsFragment;
var myRecordingsFragmentList = {
  data: function data() {
    var node = require("./__generated__/myRecordings.graphql");

    if (node.hash && node.hash !== "b27288da87fc303b783e13e2897006ab") {
      console.error("The definition of 'myRecordings' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
    }

    return require("./__generated__/myRecordings.graphql");
  }
};
exports.myRecordingsFragmentList = myRecordingsFragmentList;