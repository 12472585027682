import deepOrange from '@material-ui/core/colors/deepOrange';


const style = {
  container: {
    display: 'table',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    webkitUserSelect: 'none',
    mozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  },
  avatarContainer: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: 'black',
  },
  avatar: {
    width: '60px',
    height: '60px',
    margin: 'auto auto 10px auto',
    backgroundColor: deepOrange[500],
    fontSize: '30px',
  },
  avatarIcon: {
    width: '40px',
    height: '40px',
  },
  text: {
    //padding: '15px',
  },
};

export default style;
