const style = theme => ({
  root: {
    height: '100%',
    display: "flex",
    flexFlow: "column",
  },
  flexMeetingContainer: {
  },
  flexMeetingPersonal: {
    flexGrow: 10,
  },
  meetingListRoot: {
    flexGrow: 70,
    paddingTop: theme.spacing.unit * 2,
    display: "flex",
    flexFlow: "column",
  },
  flexMeetingSelector: {
    flexGrow: 10,
    marginTop: 12,
    marginBottom: 12,
  },
  guestFlexMeetingSelector: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  meetingListHeader: {
    flexGrow: 10,
  },
  meetingListGridContainer: {
    flexGrow: 90,
    position: 'relative'
  },
  meetingListElements: {
    display: "flex",
    flexFlow: "column"
  },
  meetingListDetails: {
    marginTop: "8px",
    height: '90%'
  }
});

export default style;
