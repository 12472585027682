/* Libraries Imports */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect';
import lodash from 'lodash';
import uuid from 'uuid';
import { Router } from 'holocom-client/lib/utils';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LoginIcon from '../../style/LoginIcon';
import Home from '@material-ui/icons/Home';
import MeetingIcon from '../../style/MeetingIcon';
import LogoutIcon from '../../style/LogoutIcon';
import Schedule from '@material-ui/icons/Schedule';
import SettingsBackupRestore from '@material-ui/icons/SettingsBackupRestore';
import Reorder from '@material-ui/icons/Reorder';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import BorderAll from '@material-ui/icons/BorderAll';
import Divider from '@material-ui/core/Divider';
import TooltipMultiLineText from '../TooltipMultiLineText';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Popover from '@material-ui/core/Popover';
/* Components Imports */
import Account from '../Account';
import ExtensionError from '../ExtensionError';
import ScreenShareUnsupported from '../ScreenShareUnsupported';
import Logout from '../Logout';
import NavigationMain from '../NavigationMain';
import NavigationSlide from '../NavigationSlide';
import { ChatPanel } from '../Chat';
import { ChatIcon } from '../Chat';
import ScreenShareIcon from '../../style/ScreenShareIcon';
import ScheduleMeeting from '../ScheduleMeeting';
import MyRecordings from '../MyRecordings';
import RoomInformation from '../RoomInformation';
import LiveStreaming from '../LiveStreaming';
import InviteToMeetingDialog from '../InviteToMeetingDialog';
import DialToMeetingDialog from '../DialToMeetingDialog';
import ChangeLayout from '../ChangeLayout';
import RecordButton from './RecordButton';
import LockRoomButton from './LockRoomButton';
import ApplyLayoutButton from './ApplyLayoutButton';
import RoomActionsMenu from './RoomActionsMenu';
import EndMeetingDialog from '../EndMeetingDialog';
/* Actions Imports */
import { logoutUser } from 'holocom-client/lib/actions/auth';
import { toggleDeviceSettings } from 'holocom-client/lib/actions/settings';
import {
  sendChatMessage,
  resetUnreadChatMessages,
  inviteParticipants,
  dialParticipant,
  lockRoom,
  changeLayout,
  applyLayout,
  enableDesktopControl,
  endMeeting,
} from 'holocom-client/lib/actions/room';
/* Local Style */
import style from './style';


const messages = defineMessages({
  meetingIconButtonTooltip: { id: 'meetingIconButtonTooltip' },
  loginIconButtonTooltip: { id: 'loginIconButtonTooltip' },
  logoutIconButtonTooltip: { id: 'logoutIconButtonTooltip' },
  settingsIconButtonTooltip: { id: 'settingsIconButtonTooltip' },
  changeUrlButtonTooltip: { id: 'changeUrlButtonTooltip' },
  roomActionsMenuTooltip: { id: 'roomActionsMenuTooltip' },
  screenShareButtonExtensionTooltip: {
    download: { id: 'screenShareButtonExtensionTooltipDownload' },
    isNotSupported: { id: 'screenShareButtonExtensionTooltipIsNotSupported' },
    isSupported: { id: 'screenShareButtonExtensionTooltipIsSupported' },
    disable: { id: 'screenShareButtonExtensionTooltipDisable' }
  },
  chatIconButtonTooltip: { id: 'chatIconButtonTooltip' },
  roomInfoTooltip: { id: 'roomInfoTooltip' },
  scheduleButtonTooltip: { id: 'scheduleButtonTooltip' },
  chooseLayout: { id: 'chooseLayout' },
  applyLayout: { id: 'applyLayout' },
  reportRecording: { id: 'reportRecording' },
  accountIconButtonTooltip: { id: 'accountIconButtonTooltip' },
});


function startDesktopControl(dispatch, user, desktopControlEnabled, desktopControlType, disableDesktopControlListener) {

  if (desktopControlEnabled) {
    window.ipcRenderer.on('disableDesktopControl', disableDesktopControlListener);
    window.enableDesktopControl();
  } else {
    window.ipcRenderer.removeListener('disableDesktopControl', disableDesktopControlListener);
    window.disableDesktopControl();
  }

  dispatch(enableDesktopControl(user, desktopControlEnabled, desktopControlType));
}

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultUserUid: uuid.v4(),
      openLogoutDialog: false,
      openExtensionErrorDialog: false,
      openScreenShareUnsupportedDialog: false,
      openScheduleMeetingDialog: false,
      openRecordingListDialog: false,
      reloadEnabled: false,
      navigationSlideOpen: false,
      tooltipMessageExtensionDesktopSharing: 'isSupported',
      roomInfoOpen: false,
      changeLayoutOpen: false,
      roomActionsMenuOpen: false,
      inviteToMeetingDialogOpen: false,
      dialToMeetingDialogOpen: false,
      endMeetingDialogOpen: false,
      openAccountDialog: false,
    };

    this.handleOpenLogoutDialog = this.handleOpenLogoutDialog.bind(this);
    this.handleOpenEndMeetingDialog = this.handleOpenEndMeetingDialog.bind(this);
    this.handleEndMeetingDialogClose = this.handleEndMeetingDialogClose.bind(this);
    this.handleEndMeeting = this.handleEndMeeting.bind(this);
    this.handleCloseLogoutDialog = this.handleCloseLogoutDialog.bind(this);
    this.handleOpenExtensionDialog = this.handleOpenExtensionDialog.bind(this);
    this.handleCloseExtensionDialog = this.handleCloseExtensionDialog.bind(this);
    this.handleOpenScreenShareUnsupportedDialog = this.handleOpenScreenShareUnsupportedDialog.bind(this);
    this.handleCloseScreenShareUnsupportedDialog = this.handleCloseScreenShareUnsupportedDialog.bind(this);
    this.handleOpenScheduleMeetingDialog = this.handleOpenScheduleMeetingDialog.bind(this);
    this.handleOpenRecordingListDialog = this.handleOpenRecordingListDialog.bind(this);
    this.handleCloseScheduleMeetingDialog = this.handleCloseScheduleMeetingDialog.bind(this);
    this.handleCloseRecordingListDialog = this.handleCloseRecordingListDialog.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleHome = this.handleHome.bind(this);
    this.installExtension = this.installExtension.bind(this);
    this.setTooltipExtensionMessage = this.setTooltipExtensionMessage.bind(this);
    this.handleScreenShare = this.handleScreenShare.bind(this);
    this.handleToggleChat = this.handleToggleChat.bind(this);
    this.handleRoomInfoButton = this.handleRoomInfoButton.bind(this);
    this.handleChangeLayoutButton = this.handleChangeLayoutButton.bind(this);
    this.toggleRoomActionsMenu = this.toggleRoomActionsMenu.bind(this);
    this.handleApplyLayoutButton = this.handleApplyLayoutButton.bind(this);
    this.handleSelectedLayout = this.handleSelectedLayout.bind(this);
    this.openInviteToMeetingDialog = this.openInviteToMeetingDialog.bind(this);
    this.handleSendChatMessage = this.handleSendChatMessage.bind(this);
    this.handleResetUnreadChatMessages = this.handleResetUnreadChatMessages.bind(this);
    this.handleCloseInviteToMeetingDialog = this.handleCloseInviteToMeetingDialog.bind(this);
    this.handleInviteToMeeting = this.handleInviteToMeeting.bind(this);
    this.handleSettings = this.handleSettings.bind(this);
    this.disableDesktopControlListener = this.disableDesktopControlListener.bind(this);
  }

  disableDesktopControlListener() {
    this.props.dispatch(enableDesktopControl(this.props.myUserId, false, null));
  }

  componentWillUnmount = () => {
    if (this.props.isElectron) {
      window.ipcRenderer.removeListener('disableDesktopControl', this.disableDesktopControlListener);
    }
  }

  handleOpenLogoutDialog() {
    this.setState({ openLogoutDialog: true });
  }

  handleCloseLogoutDialog() {
    this.setState({ openLogoutDialog: false });
  }

  handleOpenEndMeetingDialog() {
    this.setState({ endMeetingDialogOpen: true });
  }

  handleEndMeetingDialogClose() {
    this.setState({ endMeetingDialogOpen: false });
  }

  handleEndMeeting() {
    this.setState({ endMeetingDialogOpen: false });
    this.props.dispatch(endMeeting());
  }

  handleOpenExtensionDialog() {
    this.setState({ openExtensionErrorDialog: true });
  }

  handleCloseExtensionDialog() {
    this.setState({ openExtensionErrorDialog: false });
  }

  handleOpenScreenShareUnsupportedDialog() {
    this.setState({ openScreenShareUnsupportedDialog: true });
  }

  handleCloseScreenShareUnsupportedDialog() {
    this.setState({ openScreenShareUnsupportedDialog: false });
  }

  handleOpenScheduleMeetingDialog() {
    this.setState({ openScheduleMeetingDialog: true });
  }

  handleOpenRecordingListDialog() {
    this.setState({ openRecordingListDialog: true });
  }

  handleCloseScheduleMeetingDialog() {
    this.setState({ openScheduleMeetingDialog: false });
  }

  handleCloseRecordingListDialog() {
    this.setState({ openRecordingListDialog: false });
  }

  installExtension() {
    const defUrl = 'detail/voismart-screen-share/ipglkdcdgpagcjcockkhdpcojokgigbg?hl=en-US&gl=IT';
    const config = this.props.chromeScreenShareConfig || {};
    const urlExtension = config.url || defUrl;
    const chromeWebStoreUrl = 'https://chrome.google.com/webstore/';
    window.open(`${ chromeWebStoreUrl }${ urlExtension }`, "_blank");
    this.setState({ reloadEnabled: true });
  }

  handleReloadClick() {
    window.location.reload();
  }

  handleLogin() {
    this.props.history.push("/login");
  }

  handleHome() {
    this.props.history.push("/");
  }

  handleSettings() {
    this.props.dispatch(toggleDeviceSettings());
  }

  handleAccountClick = () => {
    this.setState({ accountDialogOpen: true });
  }

  handleScreenShare() {

    if (this.props.isSharingScreen) {
      if (this.props.isElectron && this.props.isDesktopControlEnabled) {
        startDesktopControl(this.props.dispatch, this.props.myUserId,
          false, this.props.screenSourceType, this.disableDesktopControlListener);
      }
      this.props.onStopScreen();
    }
    else {
      const browser = this.props.browser;
      if (!this.props.extensionIsInstalled && browser !== 'firefox' && !this.props.isElectron) {
        if (browser === 'chrome') {
          this.handleOpenExtensionDialog();
          return;
        } else {
          this.handleOpenScreenShareUnsupportedDialog();
          return;
        }
      } else {
        this.props.onShareScreen();
      }
    }
  }

  setTooltipExtensionMessage(status) {
    this.setState({ tooltipMessageExtensionDesktopSharing: status });
  }

  handleToggleChat() {
    this.setState({ navigationSlideOpen: !this.state.navigationSlideOpen });
  }

  handleRoomInfoButton() {
    this.setState({ roomInfoOpen: !this.state.roomInfoOpen });
  }

  handleChangeLayoutButton() {
    this.setState({ changeLayoutOpen: !this.state.changeLayoutOpen });
  }

  toggleRoomActionsMenu() {
    this.setState({ roomActionsMenuOpen: !this.state.roomActionsMenuOpen });
  }

  handleApplyLayoutButton() {
    this.props.dispatch(applyLayout());
  }

  handleLockRoomButton = () => {
    this.props.dispatch(lockRoom());
  }

  handleSelectedLayout(layout, layout_config) {
    this.setState({
      changeLayoutOpen: false,
    });
    this.props.dispatch(changeLayout(layout, layout_config));
  }

  handleSendChatMessage(message) {
    this.props.dispatch(sendChatMessage(message));
  }

  handleResetUnreadChatMessages() {
    this.props.dispatch(resetUnreadChatMessages());
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.navigationSlideOpen && !prevState.navigationSlideOpen) {
      // chat panel has been opened
      if (!this.state.resetUnreadMessagesTaskId) {
        this.handleResetUnreadChatMessages();
      }
    }
    else if (!this.state.navigationSlideOpen && prevState.navigationSlideOpen) {
      // chat panel has been closed
      this.handleResetUnreadChatMessages();
    }

    if (this.props.isSharingScreen && !prevProps.isSharingScreen) {
      this.setTooltipExtensionMessage('disable');
    } else if (!this.props.isSharingScreen && prevProps.isSharingScreen) {
      this.setTooltipExtensionMessage('isSupported');
    }

    if ((Object.keys(this.props.meetingDetails).length > 0) && this.props.meetingDetails !== prevProps.meetingDetails) {
      let room_details = this.getRoomDetails(this.props.meetingDetails);
      this.setState({ roomDetails: room_details });
    }
  }

  componentWillUnmount() {
    this.handleResetUnreadChatMessages();
  }

  openInviteToMeetingDialog() {
    this.setState({
      inviteToMeetingDialogOpen: true,
      roomInfoOpen: false,
    });
  }

  openDialToMeetingDialog = () => {
    this.setState({
      dialToMeetingDialogOpen: true,
      roomInfoOpen: false,
    });
  }

  handleCloseInviteToMeetingDialog() {
    this.setState({ inviteToMeetingDialogOpen: false });
  }

  handleCloseDialToMeetingDialog = () => {
    this.setState({ dialToMeetingDialogOpen: false });
  }

  handleCloseAccountDialog = () => {
    this.setState({ accountDialogOpen: false });
  }

  handleInviteToMeeting(who) {
    this.props.dispatch(inviteParticipants(who, { base_url: window.location.origin }));
    this.handleCloseInviteToMeetingDialog();
  }

  handleDialToMeeting = (destination) => {
    this.props.dispatch(dialParticipant(destination));
    this.handleCloseDialToMeetingDialog();
  }

  getRoomDetails(meeting_details) {
    const getUidFromRoster = (username) => {
      if (!username || (username === "guest")) {
        return null;
      }
      const keys = Object.keys(this.props.users);
      for (let uid of keys) {
        if (this.props.users[uid] === username) {
          return uid;
        }
      }
      return null;
    };

    const isMyRoom = () => {
      return meeting_details.slug === this.props.myUsername;
    };

    let organizer_username = meeting_details.organizer.username;
    if (!organizer_username && isMyRoom()) {
      organizer_username = this.props.myUsername;
    }
    const router = new Router();
    const rawUrl = router.getUrlFor(router.MEETING, { slug: meeting_details.slug }, null, window.location.origin);
    const organizer_uid = getUidFromRoster(organizer_username) || '';
    let res = {
      ...meeting_details,
      organizer: { ...meeting_details.organizer, uid: organizer_uid },
      number: this.props.roomNumber, rawUrl: rawUrl,
    };
    res.pin = meeting_details.number;
    if (!res.organizer.display && isMyRoom()) {
      res.organizer.display = this.props.myDisplayName;
    }
    return res;
  }

  showLoginLogout() {
    const config = this.props.appConfig || {};
    if (config.login_enabled) { return true; }
    return false;
  }

  getScreenShareItem() {
    if (!this.props.inMeeting) {
      return null;
    }

    const classes = this.props.classes;
    return (
      <div>
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(
            messages.screenShareButtonExtensionTooltip[this.state.tooltipMessageExtensionDesktopSharing]
          )}>
          <ListItem className={classes.item} button onClick={this.handleScreenShare}>
            <ListItemIcon>
              {(this.props.isSharingScreen)
                ? <StopScreenShare />
                : <ScreenShareIcon browser={this.props.browser}
                  isElectron={this.props.isElectron}
                  setTooltipExtensionMessage={this.setTooltipExtensionMessage} />
              }
            </ListItemIcon>
          </ListItem>
        </TooltipMultiLineText>
        {(!this.props.extensionIsInstalled && !this.props.isElectron) && (
          <ExtensionError
            open={this.state.openExtensionErrorDialog}
            onInstallClick={this.installExtension}
            onCloseButtonClick={this.handleCloseExtensionDialog}
            reloadEnabled={this.state.reloadEnabled}
            onReloadClick={this.handleReloadClick}
          />
        )}
        {(!this.props.extensionIsInstalled && !this.props.isElectron) && (
          <ScreenShareUnsupported
            open={this.state.openScreenShareUnsupportedDialog}
            onCloseButtonClick={this.handleCloseScreenShareUnsupportedDialog}
          />
        )}
      </div>
    );
  }

  getActionMenuItems() {
    const classes = this.props.classes;
    return (
      <div>
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(
            messages.roomActionsMenuTooltip
          )}
        >
          <div ref={el => { this.roomActionsAnchorEl = el; }} >
            <ListItem
              className={classes.item}
              button
              onClick={this.toggleRoomActionsMenu}>
              <ListItemIcon>
                <MeetingIcon
                  className={this.state.roomActionsMenuOpen ? classes.iconSelected : null}
                />
              </ListItemIcon>
            </ListItem>
          </div>
        </TooltipMultiLineText>
        <Popover
          open={this.state.roomActionsMenuOpen}
          anchorEl={this.roomActionsAnchorEl}
          onClose={this.toggleRoomActionsMenu}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <RoomActionsMenu
            open={this.state.roomActionsMenuOpen}
            anchorEl={this.roomActionsAnchorEl}
            onClose={this.toggleRoomActionsMenu}
            onEndMeeting={this.handleOpenEndMeetingDialog}
          />
        </Popover>
      </div>
    );
  }

  getInMeetingItems() {
    if (!this.props.inMeeting) {
      return null;
    }
    const classes = this.props.classes;
    return (
      <div>
        <Divider />
        {this.getActionMenuItems()}
        {this.props.deskShareEnabled && this.getScreenShareItem()}
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(
            messages.chatIconButtonTooltip
          )}>
          <ListItem className={classes.item} button onClick={this.handleToggleChat} aria-selected={true}>
            <ChatIcon
              chatPanelOpen={this.state.navigationSlideOpen}
              unreadChatMessages={this.props.unreadChatMessages}
              userAgent={this.props.userAgent}
            />
          </ListItem>
        </TooltipMultiLineText>
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(messages.roomInfoTooltip)}
        >
          <div ref={el => { this.roomInfoAnchorEl = el; }} >
            <ListItem
              button
              className={classes.item}
              onClick={this.handleRoomInfoButton}
            >
              <ListItemIcon>
                <InfoOutlined className={this.state.roomInfoOpen ? classes.iconSelected : null} />
              </ListItemIcon>
            </ListItem>
          </div>
        </TooltipMultiLineText>
        <Popover
          open={this.state.roomInfoOpen}
          anchorEl={this.roomInfoAnchorEl}
          onClose={this.handleRoomInfoButton}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          {<RoomInformation
            roomDetails={this.state.roomDetails}
            roomName={this.props.roomName}
            onInviteClicked={this.openInviteToMeetingDialog}
            onDialinClicked={this.openDialToMeetingDialog}
          />
          }
        </Popover>
        {this.getLayoutItems()}
        <Divider />
        <RecordButton />
        <LiveStreaming />
      </div>
    );
  }

  getLockRoomIcon() {
    const lockRoomIcon = (
      <LockRoomButton
        onClick={this.handleLockRoomButton} />
    );
    return lockRoomIcon;
  }

  getApplyLayoutIcon() {
    const applyLayoutIcon = (
      <ApplyLayoutButton
        title={this.props.intl.formatMessage(messages.applyLayout)}
        onClick={this.handleApplyLayoutButton} />
    );
    return applyLayoutIcon;
  }

  getLayoutItems() {
    const classes = this.props.classes;
    return (
      <div>
        <Divider />
        <TooltipMultiLineText
          placement="left"
          title={this.props.intl.formatMessage(messages.chooseLayout)}
        >
          <div ref={el => { this.changeLayoutAnchorEl = el; }} >
            <ListItem
              button
              className={classes.item}
              onClick={this.handleChangeLayoutButton}
            >
              <ListItemIcon>
                <BorderAll className={this.state.changeLayoutOpen ? classes.iconSelected : null} />
              </ListItemIcon>
            </ListItem>
          </div>
        </TooltipMultiLineText>
        <Popover
          open={this.state.changeLayoutOpen}
          anchorEl={this.changeLayoutAnchorEl}
          onClose={this.handleChangeLayoutButton}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <ChangeLayout
            open={this.state.changeLayoutOpen}
            anchorEl={this.changeLayoutAnchorEl}
            onSelectedLayout={this.handleSelectedLayout}
          />
        </Popover>
        {this.getApplyLayoutIcon(classes)}
        {this.getLockRoomIcon(classes)}
      </div>
    );
  }

  goToLanding = () => {
    window.goToLanding();
  }

  enableDesktopControl = (user, desktopControlEnabled, desktopControlType) => {
    startDesktopControl(this.props.dispatch, user, desktopControlEnabled,
      desktopControlType, this.disableDesktopControlListener);
  }

  handleLogoutClick = (token) => {
    this.props.dispatch(logoutUser(token, this.props.localStore));
    this.handleCloseLogoutDialog();
  }

  render() {
    const classes = this.props.classes;
    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <NavigationMain
            appConfig={this.props.appConfig}
            handleScreenShare={this.handleScreenShare}
            isElectron={this.props.isElectron}
            enableDesktopControl={this.enableDesktopControl}
            relayEnvironment={this.props.relayEnvironment}
            userAgent={this.props.userAgent}
            localStore={this.props.localStore}
          />
          <NavigationSlide open={this.state.navigationSlideOpen && this.props.inMeeting}>
            <ChatPanel
              onSend={this.handleSendChatMessage}
              onResetUnreadChatMessages={this.handleResetUnreadChatMessages}
            />
          </NavigationSlide>
          {!this.props.isRecorder &&
            <div className={classes.appBar}>
              <List>
                <TooltipMultiLineText
                  placement="left"
                  title={this.props.intl.formatMessage(messages.meetingIconButtonTooltip)}>
                  <ListItem className={classes.item} button onClick={this.handleHome}>
                    <ListItemIcon>
                      <Home />
                    </ListItemIcon>
                  </ListItem>
                </TooltipMultiLineText>
                {!this.props.isAuthenticated && this.showLoginLogout() &&
                  <TooltipMultiLineText
                    placement="left"
                    title={this.props.intl.formatMessage(messages.loginIconButtonTooltip)}>
                    <ListItem className={classes.item} button onClick={this.handleLogin}>
                      <ListItemIcon>
                        <LoginIcon />
                      </ListItemIcon>
                    </ListItem>
                  </TooltipMultiLineText>
                }
                <Divider />
                {this.props.isAuthenticated &&
                  <div>
                    {this.showLoginLogout() &&
                      <TooltipMultiLineText
                        placement="left"
                        title={this.props.intl.formatMessage(messages.logoutIconButtonTooltip)}>
                        <ListItem className={classes.item} button onClick={this.handleOpenLogoutDialog}>
                          <ListItemIcon>
                            <LogoutIcon />
                          </ListItemIcon>
                        </ListItem>
                      </TooltipMultiLineText>
                    }
                    <TooltipMultiLineText
                      placement="left"
                      title={this.props.intl.formatMessage(messages.accountIconButtonTooltip)}>
                      <ListItem className={classes.item} button onClick={this.handleAccountClick}>
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                      </ListItem>
                    </TooltipMultiLineText>
                    <TooltipMultiLineText
                      placement="left"
                      title={this.props.intl.formatMessage(messages.scheduleButtonTooltip)}>
                      <ListItem className={classes.item} button onClick={this.handleOpenScheduleMeetingDialog}>
                        <ListItemIcon>
                          <Schedule />
                        </ListItemIcon>
                      </ListItem>
                    </TooltipMultiLineText>
                    <TooltipMultiLineText
                      placement="left"
                      title={this.props.intl.formatMessage(messages.reportRecording)}>
                      <ListItem className={classes.item} button onClick={this.handleOpenRecordingListDialog}>
                        <ListItemIcon>
                          <Reorder />
                        </ListItemIcon>
                      </ListItem>
                    </TooltipMultiLineText>
                  </div>
                }
                {this.state.openLogoutDialog === true &&
                  <Logout
                    onLogoutClick={this.handleLogoutClick}
                    onCancelClick={this.handleCloseLogoutDialog}
                    history={this.props.history}
                  />
                }
                {this.state.openScheduleMeetingDialog === true &&
                  <ScheduleMeeting
                    onCloseClick={this.handleCloseScheduleMeetingDialog}
                    relayEnvironment={this.props.relayEnvironment}
                  />
                }
                {this.state.openRecordingListDialog === true &&
                  <MyRecordings
                    onCloseClick={this.handleCloseRecordingListDialog}
                    relayEnvironment={this.props.relayEnvironment}
                  />
                }
                {this.state.inviteToMeetingDialogOpen &&
                  <InviteToMeetingDialog
                    open={this.state.inviteToMeetingDialogOpen}
                    onClose={this.handleCloseInviteToMeetingDialog}
                    onInvite={this.handleInviteToMeeting}
                  />
                }
                {this.state.dialToMeetingDialogOpen &&
                  <DialToMeetingDialog
                    open={this.state.dialToMeetingDialogOpen}
                    onClose={this.handleCloseDialToMeetingDialog}
                    onInvite={this.handleDialToMeeting}
                  />
                }
                {this.state.accountDialogOpen === true &&
                  <Account
                    open={this.state.accountDialogOpen}
                    onClose={this.handleCloseAccountDialog}
                    dispatch={this.props.dispatch}
                    relayEnvironment={this.props.relayEnvironment}
                    defaultUserUid={this.state.defaultUserUid}
                  />
                }
                <TooltipMultiLineText
                  placement="left"
                  title={this.props.intl.formatMessage(messages.settingsIconButtonTooltip)}>
                  <ListItem className={classes.item} button onClick={this.handleSettings}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                  </ListItem>
                </TooltipMultiLineText>
                {this.props.isElectron && (
                  <TooltipMultiLineText
                    placement="left"
                    title={this.props.intl.formatMessage(messages.changeUrlButtonTooltip)}
                  >
                    <ListItem className={classes.item} button onClick={this.goToLanding}>
                      <ListItemIcon>
                        <SettingsBackupRestore />
                      </ListItemIcon>
                    </ListItem>
                  </TooltipMultiLineText>
                )}

                {this.getInMeetingItems()}
              </List>
              <EndMeetingDialog
                open={this.state.endMeetingDialogOpen}
                onConfirm={this.handleEndMeeting}
                onCancel={this.handleEndMeetingDialogClose}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}

NavigationBar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  localStore: PropTypes.object.isRequired,
  onShareScreen: PropTypes.func.isRequired,
  onStopScreen: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  inMeeting: PropTypes.bool,
  isSharingScreen: PropTypes.bool,
  extensionIsInstalled: PropTypes.bool.isRequired,
  browser: PropTypes.string.isRequired,
  userAgent: PropTypes.object.isRequired,
  chatMessages: PropTypes.array,
  unreadChatMessages: PropTypes.number,
  intl: intlShape.isRequired,
  roomName: PropTypes.string,
  roomPin: PropTypes.string,
  roomNumber: PropTypes.string,
  meetingDetails: PropTypes.object,
  users: PropTypes.object,
  myUsername: PropTypes.string,
  myUserId: PropTypes.string,
  myDisplayName: PropTypes.string,
  chromeScreenShareConfig: PropTypes.object,
  relayEnvironment: PropTypes.object.isRequired,
  isRecorder: PropTypes.bool,
  deskShareEnabled: PropTypes.bool,
  appConfig: PropTypes.object.isRequired,
  isElectron: PropTypes.bool,
  screenSourceType: PropTypes.string,
  isRequestingAllAudioMute: PropTypes.bool,
  isDesktopControlEnabled: PropTypes.bool,
};


const getUsers = (state) => {
  const roster = state.room.roster;
  const users = {};
  let keys = Object.keys(roster);
  for (let user of keys) {
    users[user] = roster[user].username;
  }
  return users;
};


const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  lodash.isEqual
);


const mapper = createDeepEqualSelector(getUsers, (u) => u || {});


const getMyUid = (state) => {
  const my_uid = (state.websocket || {}).uid;
  return (state.room.roster[my_uid] ||
    { isDesktopControlEnabled: false }).isDesktopControlEnabled;
};


const getMyDesktopControlEnabled = createSelector(getMyUid, (x) => x);


function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    inMeeting: Boolean(state.websocket.room),
    isSharingScreen: Boolean(state.room.screenStream),
    extensionIsInstalled: state.room.extensionIsInstalled,
    chatMessages: state.room.chatMessages,
    unreadChatMessages: state.room.unreadChatMessages,
    roomName: state.websocket.room_name || '',
    roomPin: state.session.roomNumber,
    roomNumber: state.session.pstnNumber,
    meetingDetails: state.websocket.meeting_details || {},
    users: mapper(state),
    myUsername: state.auth.username,
    myUserId: state.websocket.uid,
    myDisplayName: state.session.displayName,
    chromeScreenShareConfig: state.session.chromeScreenShareConfig,
    isRecorder: state.session.isRecorder || false,
    isDesktopControlEnabled: getMyDesktopControlEnabled(state),
    deskShareEnabled: state.room.deskShareEnabled || false,
    screenSourceType: state.room.screenSourceType || "",
  };
}


export default withStyles(style)(injectIntl(withRouter(connect(mapStateToProps)(NavigationBar))));
