/* eslint max-len: 0 */
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let MicAllIcon = props =>
  <SvgIcon {...props}>
    <path d="M2.8,5.1c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3S3.5,5.1,2.8,5.1z M2.1,20.8c0.1-0.1,0.2-0.2,0.2-0.2
        c0.2,0,0.7,0,1,0c0.6,0.1,1.2,0.1,1.7,0c1-0.3,2.4-1.5,2.9-2.4c0.2-0.5,0.2-1.3,0.1-2c0.3-0.3,0.7-0.7,1-1c0.2-0.3,0.3-0.6,0.3-1
        c-0.1-0.3-0.3-0.7-0.5-1c0.5-0.6,0.5-0.9,0.5-1.1c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.3-0.4-0.5-0.6c0.5-0.2,1-0.5,1.4-0.7
        c0.4-0.2,0.7-0.5,0.8-1c0.2-0.7,0.1-1.6-0.4-2.4C9.7,5.5,7.9,2.3,6.8,0H4.6c1.2,2.8,3.6,6.9,4.1,7.8C8.8,8,8.9,8.2,8.9,8.4
        c-1,0.4-2.3,1.2-2.4,1.2l-1.1,0.6l0.8,1c0,0,0.4,0.5,0.9,1c-0.1,0.1-0.3,0.3-0.5,0.4l-1.1,1l1.5,0.8c0,0,0,0,0,0
        c-0.3,0.3-0.6,0.5-0.7,0.6l-0.4,0.4L6,15.9c0.1,0.6,0.1,1.3,0.1,1.4c-0.2,0.4-1.2,1.2-1.6,1.4c-0.1,0-0.6,0-0.9,0
        c-0.7-0.1-1.4-0.1-1.9,0.1c-0.5,0.2-1.5,1-1.5,1.9c0,1.1,0,3.3,0,3.4l2,0C2.1,24,2.1,21.9,2.1,20.8z M13.4,15.8c0.6-0.7,1-1.6,1-2.5
        c0-1-0.4-1.9-1.1-2.6c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4c0.3,0.3,0.5,0.7,0.5,1.1c0,0.4-0.2,0.8-0.4,1.1
        c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3C13,16.1,13.2,16,13.4,15.8z M16,18.4c1.3-1.4,2.1-3.2,2.1-5.1c0-2-0.8-3.8-2.1-5.2
        c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4c1,1,1.5,2.3,1.5,3.7c0,1.4-0.5,2.7-1.5,3.7c-0.4,0.4-0.4,1,0,1.4
        c0.2,0.2,0.5,0.3,0.7,0.3C15.6,18.7,15.8,18.6,16,18.4z M18.6,21c2-2.1,3.2-4.8,3.2-7.7c0-2.9-1.1-5.7-3.2-7.8c-0.4-0.4-1-0.4-1.4,0
        c-0.4,0.4-0.4,1,0,1.4c1.7,1.7,2.6,4,2.6,6.4c0,2.4-0.9,4.6-2.6,6.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3
        C18.2,21.3,18.4,21.2,18.6,21z"/>
  </SvgIcon>;

MicAllIcon = pure(MicAllIcon);
MicAllIcon.muiName = 'SvgIcon';

export default MicAllIcon;
