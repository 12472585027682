/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import IconButton from '@material-ui/core/IconButton';
import Videocam from '@material-ui/icons/Videocam';
import VideocamOff from '@material-ui/icons/VideocamOff';
import Mic from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import Pip from '@material-ui/icons/PictureInPictureAlt';
import QuitIcon from '../QuitIcon';
import { WithAuthorization } from '../../authorization';
import KickIconButton from './KickIconButton';
import ChangeRoleIconButton from './ChangeRoleIconButton';
import TooltipMultiLineText from '../TooltipMultiLineText';
import classNames from 'classnames';
/* Other Imports */
import PiP from '../../pip';

/* Local Style */
import style from './style';

const messages = defineMessages({
  microphone: {
    disable: { id: 'microphoneButtonEnableTooltip' },
    enable: { id: 'microphoneButtonDisableTooltip' }
  },
  video: {
    disable: { id: 'videoButtonDisableTooltip' },
    enable: { id: 'videoButtonEnableTooltip' }
  },
  leaveIconButton: { id: 'leave' },
  exitFullScreenButton: {id: 'exitFullScreenButton'},
  enterFullScreenButton: {id: 'enterFullScreenButton'},
  enablePip: {id: 'enablePipButton'},
  disablePip: {id: 'disablePipButton'},
});

class VideoToolbar extends Component {
  handleToggleAudioMute = () => {
    this.props.onToggleAudioMute(this.props.user, !this.props.isAudioMuted);
  }

  handleToggleVideoMute = () => {
    this.props.onToggleVideoMute(this.props.user, !this.props.isVideoMuted);
  }

  handleEnterFullScreen = () => {
    this.props.enterFullScreen(this.props.user, this.props.idx);
  }

  onEnablePip = () => {
    this.props.onEnablePip(this.props.user);
  }

  onDisablePip = () => {
    this.props.onDisablePip(this.props.user);
  }

  getAudioIconButton(props, classes) {
    if (isStreamingAScreen(props)) {
      return null;
    }
    const icon = (this.props.isAudioMuted
      ? <Mic className={classes.videoButton} />
      : <MicOff className={classes.videoButton} />);
    const elem = (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(this.props.isAudioMuted ?
          messages.microphone.disable
          : messages.microphone.enable)}>
        <div>
          <IconButton
            disabled={this.props.isRequestingAudioMute}
            onClick={this.handleToggleAudioMute}
            className={classes.button}
          >
            {icon}
          </IconButton>
        </div>
      </TooltipMultiLineText>
    );
    if (props.showAudioButton) {
      // override auth if told to do so
      return elem;
    }
    else {
      const Elem = WithAuthorization(['room_owner', 'room_moderator'], () => elem);
      return <Elem />;
    }
  }

  getVideoIconButton(classes) {
    if (!this.props.onToggleVideoMute) {
      return null;
    }
    const icon = (this.props.isVideoMuted
      ? <Videocam className={classes.videoButton} />
      : <VideocamOff className={classes.videoButton} />);
    return (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(this.props.isVideoMuted ?
          messages.video.enable
          : messages.video.disable)}>
        <div>
          <IconButton
            disabled={this.props.isRequestingVideoMute}
            onClick={this.handleToggleVideoMute}
            className={classes.button}
          >
            {icon}
          </IconButton>
        </div>
      </TooltipMultiLineText>
    );
  }

  getFullScreenIconButton(classes) {
    if (!(this.props.enterFullScreen && this.props.exitFullScreen)) {
      return null;
    }
    if (this.props.isFullScreen) {
      return (
        <div className={classes.div}>
          <div className={classes.divider} />
          <TooltipMultiLineText
            placement="top"
            title={this.props.intl.formatMessage(messages.exitFullScreenButton)}>
            <IconButton
              onClick={this.props.exitFullScreen}
              className={classes.button}
            >
              <FullscreenExitIcon className={classes.videoButton}/>
            </IconButton>
          </TooltipMultiLineText>
        </div>
      );
    }
    else {
      return (
        <div className={classes.div}>
          <div className={classes.divider} />
          <TooltipMultiLineText
            placement="top"
            title={this.props.intl.formatMessage(messages.enterFullScreenButton)}>
            <IconButton
              onClick={this.handleEnterFullScreen}
              className={classes.button}
            >
              <FullscreenIcon className={classes.videoButton}/>
            </IconButton>
          </TooltipMultiLineText>
        </div>
      );
    }
  }

  getPipIconButton(addDividers, classes) {
    if (!PiP.isAvailable()) {
      return null;
    }
    if (!(this.props.onEnablePip && this.props.onDisablePip)) {
      return null;
    }
    if (this.props.pipEnabledOnOther) {
      return null;
    }
    if (this.props.pipEnabled) {
      return (
        <div className={classes.div}>
          {addDividers && <div className={classes.divider} />}
          <TooltipMultiLineText
            placement="top"
            title={this.props.intl.formatMessage(messages.disablePip)}>
            <IconButton
              onClick={this.onDisablePip}
              className={classes.button}
            >
              <Pip className={classNames(classes.green, classes.videoButton)}/>
            </IconButton>
          </TooltipMultiLineText>
        </div>
      );
    }
    else {
      return (
        <div className={classes.div}>
          {addDividers && <div className={classes.divider} />}
          <TooltipMultiLineText
            placement="top"
            title={this.props.intl.formatMessage(messages.enablePip)}>
            <IconButton
              onClick={this.onEnablePip}
              className={classes.button}
            >
              <Pip className={classes.videoButton}/>
            </IconButton>
          </TooltipMultiLineText>
        </div>
      );
    }
  }

  render() {
    const classes = this.props.classes;
    const fullscreenAvail = (this.props.enterFullScreen && this.props.exitFullScreen);
    return (
      <div className={classes.videoToolBar}>
        {this.getVideoIconButton(classes)}
        {this.getAudioIconButton(this.props, classes)}
        {this.getFullScreenIconButton(classes)}
        {this.getPipIconButton(!fullscreenAvail, classes)}
        {ChangeRoleIconButton && this.props.onChangeRole &&
          <div className={classes.div}>
            <div className={classes.divider} />
            <ChangeRoleIconButton
              user={this.props.user}
              onClick={this.props.onChangeRole}
            />
          </div>
        }
        {this.props.onHangup &&
          <div className={classes.div}>
            <div className={classes.divider} />
            <TooltipMultiLineText
              placement="top"
              title={this.props.intl.formatMessage(messages.leaveIconButton)}>
              <IconButton
                onClick={this.props.onHangup}
                className={classes.button}
              >
                <QuitIcon className={classes.videoButton}/>
              </IconButton>
            </TooltipMultiLineText>
          </div>
        }

        {KickIconButton && this.props.onKick &&
          <div className={classes.div}>
            <div className={classes.divider} />
            <KickIconButton
              user={this.props.user}
              onClick={this.props.onKick}
            />
          </div>
        }
      </div>
    );
  }
}


VideoToolbar.propTypes = {
  idx: PropTypes.number,
  classes: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  exitFullScreen: PropTypes.func,
  enterFullScreen: PropTypes.func,
  onToggleVideoMute: PropTypes.func,
  onToggleAudioMute: PropTypes.func.isRequired,
  showAudioButton: PropTypes.bool,
  isVideoMuted: PropTypes.bool,
  isStreamingScreen: PropTypes.object,
  isRequestingVideoMute: PropTypes.bool,
  isAudioMuted: PropTypes.bool,
  isRequestingAudioMute: PropTypes.bool,
  shouldShowAudioButton: PropTypes.bool,
  onHangup: PropTypes.func,
  onKick: PropTypes.func,
  onChangeRole: PropTypes.func,
  intl: intlShape.isRequired,
  onEnablePip: PropTypes.func,
  onDisablePip: PropTypes.func,
  pipEnabled: PropTypes.bool,
  pipEnabledOnOther: PropTypes.bool,
};


VideoToolbar.defaultProps = {
  showAudioButton: false,
  idx: -1,
  pipEnabled: false,
  pipEnabledOnOther: false,
};


function getVideoMutedProperty(state, user) {
  if (!user) {
    return false;
  }
  let prop = 'isVideoMuted';
  if (user.endsWith('_screen')) {
    prop = 'isScreenMuted';
  }
  user = user.replace(/_screen$/, '');
  return (state.room.roster[user] || { prop: false })[prop];
}


function isStreamingAScreen(props) {
  if (!props.user) {
    return false;
  }
  return props.user.endsWith('_screen');
}


function mapStateToProps(state, props) {
  return {
    isVideoMuted: getVideoMutedProperty(state, props.user),
    isAudioMuted: (state.room.roster[props.user] || { isAudioMuted: false }).isAudioMuted,
    isRequestingVideoMute: (state.room.roster[props.user] || { isRequestingVideoMute: false }).isRequestingVideoMute,
    isRequestingAudioMute: (state.room.roster[props.user] || { isRequestingAudioMute: false }).isRequestingAudioMute,

    shouldShowAudioButton: (
      props.showAudioButton || (!isStreamingAScreen(props))
    ),
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(VideoToolbar)));
