/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
/* UI Imports */
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Send from '@material-ui/icons/Send';
import EventAvailable from '@material-ui/icons/EventAvailable';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
/* Local Imports */
import ClipboardIcon from '../../style/ClipboardIcon';
import TooltipMultiLineText from '../TooltipMultiLineText';
import MeetingByCodeDialog from '../MeetingByCodeDialog';
/* Other Imports */
import { Router } from 'holocom-client/lib/utils';
import { linkify } from 'holocom-client/lib/utils';
/* Local style */
import style from './style';

const messages = defineMessages({
  roomInfoTitlePersonal: { id: 'roomInfoTitlePersonal' },
  roomHowToInvitePersonal: { id: `roomHowToInvitePersonal` },
  roomHowInvitePersonalClipBoardHeader: { id: `roomHowInvitePersonalClipBoardHeader` },
  roomWebAccess: { id: 'roomInfoWebAccess' },
  roomPstnAccess: { id: 'roomInfoPstnAccess' },
  roomPstnNumber: { id: 'roomInfoPstnNumber' },
  joinTooltip: { id: 'joinTooltip' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
  roomHowInvitePersonalClipBoardWeb: { id: `roomHowInvitePersonalClipBoardWeb` },
  joinByCode: { id: "joinByCode" },
  meetingWelcome: { id: 'meetingWelcome' }
});

class MeetingPersonal extends Component {
  constructor(props) {
    super(props);
    this.router = new Router();
    this.tooltipTimeoutHandler = null;
    this.state = {
      linkCopied: false,
      joinByCodeDialogOpen: false
    };
  }

  componentWillUnmount() {
    if (this.tooltipTimeoutHandler) {
      clearTimeout(this.tooltipTimeoutHandler);
    }
  }

  onLinkCopied = () => {
    this.setState({ linkCopied: true });
  }

  joinMeeting = () => {
    const personalRoomPath = this.router.getUrlFor(this.router.MEETING, {
      slug: this.props.username
    });
    this.props.history.push(personalRoomPath);
  }

  getMeetingUrl = (slug) => {
    const router = new Router();
    return router.getUrlFor(router.MEETING, { slug: slug }, null, window.location.origin);
  }

  getInviteText = () => {
    const rawUrl = this.getMeetingUrl(this.props.username);
    const hdr = this.props.intl.formatMessage(messages.roomHowInvitePersonalClipBoardHeader);
    const webAccess = this.props.intl.formatMessage(messages.roomHowInvitePersonalClipBoardWeb);
    const phoneAccess = this.props.intl.formatMessage(messages.roomPstnAccess);
    const phoneDetails = this.props.intl.formatMessage(messages.roomPstnNumber,
      { number: this.props.pstnNumber, pin: this.props.publicRoomPin });

    let text = `${ hdr }\n\n` +
      `${ webAccess }: ${ rawUrl }\n`;

    if (this.props.pstnNumber) {
      text = text + `${ phoneAccess }: ${ phoneDetails }\n`;
    }

    return text;
  }

  handleJoinByCodeClose = () => {
    this.setState({ joinByCodeDialogOpen: false });
  }

  handleJoinByCodeOpen = () => {
    this.setState({ joinByCodeDialogOpen: true });
  }

  handleClipboardTooltipClose = () => {
    // ugly, but avoid re-rendering the normal tooltip text just before closing it
    this.tooltipTimeoutHandler = setTimeout(() => {
      this.setState({ linkCopied: false });
      this.tooltipTimeoutHandler = null;
    },
    500);
  }

  render() {
    const { classes } = this.props;

    const rawUrl = this.getMeetingUrl(this.props.username);
    const meetingUrl = linkify(rawUrl, '_self');

    let cboardTtipText = this.props.intl.formatMessage(messages.copyToClipboard);
    if (this.state.linkCopied) {
      cboardTtipText = this.props.intl.formatMessage(messages.copiedToClipboard);
    }

    return (
      <Paper>

        <div className={classes.welcome}>
          <Typography
            variant="h5"
            gutterBottom
          >
            {this.props.intl.formatMessage(messages.meetingWelcome, { user: this.props.displayName })}
          </Typography>
        </div>

        <List>
          <ListItem button onClick={this.joinMeeting}>
            <ListItemIcon>
              <EventAvailable />
            </ListItemIcon>
            <ListItemText
              primary={this.props.intl.formatMessage(messages.roomInfoTitlePersonal)}
            />
            <ListItemSecondaryAction>
              <TooltipMultiLineText
                placement="top"
                title={this.props.intl.formatMessage(messages.joinTooltip)}>
                <IconButton onClick={this.joinMeeting}>
                  <Send />
                </IconButton>
              </TooltipMultiLineText>
            </ListItemSecondaryAction>
          </ListItem>
        </List>

        <div className={classes.howToInviteContainer}>
          <div className={classes.content}>
            {this.props.intl.formatMessage(messages.roomHowToInvitePersonal)}
          </div>

          <div className={classes.title}>
            {this.props.intl.formatMessage(messages.roomWebAccess)}
          </div>

          <div className={classes.webAccessContainer}>
            <div className={classes.content}>
              {meetingUrl}
            </div>
            <div className={classes.iconContainer}>
              <IconButton className={classes.noIconPad}>
                <TooltipMultiLineText
                  placement="top"
                  title={cboardTtipText}
                  onClose={this.handleClipboardTooltipClose}
                >
                  <CopyToClipboard
                    onCopy={this.onLinkCopied}
                    text={this.getInviteText()}>
                    <ClipboardIcon />
                  </CopyToClipboard>
                </TooltipMultiLineText>
              </IconButton>
            </div>
          </div>

          {this.props.pstnNumber &&
            <div>
              <div className={classes.title}>
                {this.props.intl.formatMessage(messages.roomPstnAccess)}
              </div>
              <div className={classes.content}>
                {this.props.intl.formatMessage(
                  messages.roomPstnNumber,
                  { number: this.props.pstnNumber, pin: this.props.publicRoomPin })}
              </div>
            </div>
          }

        </div>

        <div className={classes.joinByCode}>
          <Button className={classes.joinByCodeButton} size="small" onClick={this.handleJoinByCodeOpen}>
            {this.props.intl.formatMessage(messages.joinByCode)}
          </Button>
        </div>

        <MeetingByCodeDialog
          open={this.state.joinByCodeDialogOpen}
          onClose={this.handleJoinByCodeClose}
          history={this.props.history} />

      </Paper >
    );
  }
}

function mapStateToProps(state) {
  return {
    username: state.session.username,
    displayName: state.session.displayName,
    publicRoomPin: state.session.roomNumber,
    pstnNumber: state.session.pstnNumber
  };
}

MeetingPersonal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  username: PropTypes.string,
  displayName: PropTypes.string,
  pstnNumber: PropTypes.string,
  publicRoomPin: PropTypes.string,
  intl: intlShape.isRequired
};

export default withStyles(style)(injectIntl(connect(mapStateToProps)(MeetingPersonal)));
