const style = theme => ({
  noIconPad: {
    padding: 0
  },
  webAccessContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  howToInviteContainer: {
    padding: theme.spacing.unit * 3,
  },
  welcome: {
    padding: theme.spacing.unit * 3,
  },
  title: {
    paddingTop: theme.spacing.unit,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    fontWeight: '400',
    lineHeight: '1em',
  },
  content: {
    color: 'rgba(0, 0, 0, 0.90)',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '2em',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  joinByCodeButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    textTransform: 'none',
    fontWeight: 'unset'
  },
  joinByCode: {
    paddingRight: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

export default style;
