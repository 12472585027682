"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setRecorder = setRecorder;
exports.fetchSessionDetails = fetchSessionDetails;
exports.SESSION_SETRECORDER = exports.SESSIONDETAILS_CLEAR = exports.SESSIONDETAILS_SUCCESS = exports.SESSIONDETAILS_FAILURE = exports.SESSIONDETAILS_REQUEST = void 0;

var _relayRuntime = require("relay-runtime");

var _sentry = require("../sentry");

var SESSIONDETAILS_REQUEST = 'SESSIONDETAILS_REQUEST';
exports.SESSIONDETAILS_REQUEST = SESSIONDETAILS_REQUEST;
var SESSIONDETAILS_FAILURE = 'SESSIONDETAILS_FAILURE';
exports.SESSIONDETAILS_FAILURE = SESSIONDETAILS_FAILURE;
var SESSIONDETAILS_SUCCESS = 'SESSIONDETAILS_SUCCESS';
exports.SESSIONDETAILS_SUCCESS = SESSIONDETAILS_SUCCESS;
var SESSIONDETAILS_CLEAR = 'SESSIONDETAILS_CLEAR';
exports.SESSIONDETAILS_CLEAR = SESSIONDETAILS_CLEAR;
var SESSION_SETRECORDER = 'SESSION_SETRECORDER';
exports.SESSION_SETRECORDER = SESSION_SETRECORDER;

function requestSessionDetails() {
  return {
    type: SESSIONDETAILS_REQUEST,
    payload: {}
  };
}

function errorSessionDetails(errorMessage) {
  return {
    type: SESSIONDETAILS_FAILURE,
    payload: {
      errorMessage: errorMessage
    },
    error: true
  };
}

function successSessionDetails(sessionDetails) {
  return {
    type: SESSIONDETAILS_SUCCESS,
    payload: {
      session: sessionDetails
    }
  };
}

function traceUser(session) {
  (0, _sentry.setTracedUser)(session.username + "@" + session.realm, session.username);
}

function setRecorder(is_recorder) {
  return {
    type: SESSION_SETRECORDER,
    payload: {
      is_recorder: is_recorder
    }
  };
}

function fetchSessionDetails(relay, session) {
  return function (dispatch) {
    dispatch(requestSessionDetails());
    (0, _relayRuntime.fetchQuery)(relay, session).then(function (res) {
      traceUser(res.session);
      dispatch(successSessionDetails(res.session));
    })["catch"](function (err) {
      return dispatch(errorSessionDetails(err.detail));
    });
  };
}