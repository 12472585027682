const style = theme => ({
  attendeedList: {
    marginTop: '16px',
    marginLeft: '16px'
  },

  root: {
    flexGrow: 1,
    width: '100%',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },

  button: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
  },

  loginLink: {
    textDecoration: 'none',
  },
});

export default style;
