const style = {
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },

  list: {
    overflowY: 'auto',
    MsOverflowStyle: 'none',
    marginTop: '1em',
    marginBottom: '1em',
    flex: '1 1',
    maxHeight: '20vh'
  },

  item: {
    paddingRight: 0,
    paddingLeft: 0,
  },
};


export default style;
