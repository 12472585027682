/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { createRefetchContainer } from 'react-relay';
/* UI Imports */
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import EventAvailable from '@material-ui/icons/EventAvailable';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import Send from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
/* Local Imports */
import Details from './Details';
import Empty from './Empty';
import TooltipMultiLineText from '../TooltipMultiLineText';
import ScheduleMeeting from '../ScheduleMeeting';
/* Other Imports */
import { RELAY_DONE } from 'holocom-client/lib/actions/relay';
import { relayOperation, DELETE_MEETING, SCHEDULE_MEETING } from 'holocom-client/lib/utils/relayOperation';
import { deleteMeeting } from 'holocom-client/lib/api/relay/deleteMeeting';
import { generateRequestId } from 'holocom-client/lib/utils/requestId';
import { myMeetings, myMeetingsFragment } from 'holocom-client/lib/api/relay/myMeetings';
import { Router } from 'holocom-client/lib/utils';
import { getLogger } from 'holocom-client/lib/logger';
import moment from 'moment';
/* Local Style */
import style from './style';


const messages = defineMessages({
  next: { id: 'next' },
  previous: { id: 'previous' },
  meetingStartsAt: { id: 'meetingStartsAt' },
  editTooltip: { id: 'editTooltip' },
  joinTooltip: { id: 'joinTooltip' },
});


class View extends Component {
  // Component callbacks
  constructor(props) {
    super(props);

    this.router = new Router();

    this.logger = getLogger('Meeting View');

    this.state = {
      editMeetingDialog: false,
      editMeetingDetails: null,
      selectedMeeting: null,
      pageCursors: [null]
    };
  }

  componentDidMount = () => {
    window.addEventListener("keydown", this.onKeyBoardEvent, false);
    this.select1stMeeting();
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyBoardEvent, false);
  }

  componentDidUpdate = (prevProps) => {
    this.checkRelayOps(prevProps, this.props);
  }

  // internal methods
  reselectMeeting = () => {
    if (this.state.selectedMeeting && this.isMeetingInData(this.state.selectedMeeting)) {
      return;
    }
    this.select1stMeeting();
  }

  isMeetingInData = (selectedMeeting) => {
    const meetings = this.getData();

    if (meetings.length <= 0) {
      return false;
    }

    for (let idx = 0; idx < meetings.length; idx++) {
      const meeting = meetings[idx];
      if (meeting.node.id === selectedMeeting.id) {
        return true;
      }
    }
    return false;
  }

  select1stMeeting = () => {
    const meetings = this.getData();
    if (meetings.length > 0) {
      this.setState({
        selectedMeeting: meetings[0].node
      });
    }
  }

  goToNextPage = () => {
    const cursor = this.getLastMeetingCursor();
    const newPageCursors = Array.from(this.state.pageCursors);
    newPageCursors.push(cursor);

    this.refetchMeetings(cursor, newPageCursors);
  }

  goToPrevPage = () => {
    const newPageCursors = Array.from(this.state.pageCursors);
    newPageCursors.pop();
    const cursor = newPageCursors[newPageCursors.length - 1];

    this.refetchMeetings(cursor, newPageCursors);
  }

  refetchMeetings = (cursor, newCursors) => {
    const refetchVariables = fragmentVariables => ({
      ...fragmentVariables,
      first: this.props.pageSize,
      after: cursor,
    });

    const callback = (errors) => {
      if (errors) {
        return;
      }
      this.setState({ pageCursors: newCursors });
      this.reselectMeeting();
    };

    this.props.relay.refetch(
      refetchVariables,
      null,
      callback,
      { force: true },
    );
  }

  getLastMeetingCursor = () => {
    const paging = this.props.data.myMeetings.pageInfo || { endCursor: null };
    return paging.endCursor;
  }

  formatDate = (date) => {
    const dateRoom = moment(date);
    const fullDateRoom = this.props.intl.formatDate(dateRoom, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });

    return fullDateRoom;
  }

  handleDeleteMeeting = () => {
    const environment = this.props.relay.environment;
    const reqId = generateRequestId();

    const { id, userId, domainId } = this.state.selectedMeeting;

    relayOperation(
      this.props.dispatch,
      () => deleteMeeting(environment, id, userId, domainId),
      reqId,
      DELETE_MEETING
    );
  }

  getSelectedMeeting = () => { return this.state.selectedMeeting; }

  hasMorePages = () => {
    const pageInfo = this.props.data.myMeetings.pageInfo;
    return pageInfo.hasNextPage;
  }

  hasPreviousPage = () => {
    const pageInfo = this.props.data.myMeetings.pageInfo;
    return pageInfo.hasPreviousPage;
  }

  onItemClick = (meeting) => {
    return () => {
      this.setState({ selectedMeeting: meeting });
    };
  }

  isSelectedMeeting = (meeting) => {
    if (this.state.selectedMeeting) {
      return meeting.id === this.state.selectedMeeting.id;
    }
    return false;
  }

  isFirstElementofPage = () => {
    const meetings = this.getData();
    const firstMeeting = meetings[0].node;
    const selectedMeeting = this.state.selectedMeeting;

    if (firstMeeting && selectedMeeting && selectedMeeting.id === firstMeeting.id) {
      return true;
    }

    return false;
  }

  isLastElementofPage = () => {
    const meetings = this.getData();
    const lastMeeting = meetings[meetings.length - 1].node;
    const selectedMeeting = this.state.selectedMeeting;

    if (lastMeeting && selectedMeeting && selectedMeeting.id === lastMeeting.id) {
      return true;
    }

    return false;
  }

  selectPrevMeeting = () => {
    const meetings = this.getData();

    if (meetings.length > 0 && this.state.selectedMeeting) {
      const selectedMeetingID = this.state.selectedMeeting.id;
      const pos = meetings.map((m) => { return m.node.id; }).indexOf(selectedMeetingID);

      if (pos >= 0) {
        const newSelectedMeeting = meetings[pos - 1];
        if (newSelectedMeeting) {
          this.setState({ selectedMeeting: newSelectedMeeting.node });
        }
      }
    }
  }

  selectNextMeeting = () => {
    const meetings = this.getData();

    if (meetings.length > 0 && this.state.selectedMeeting) {
      const selectedMeetingID = this.state.selectedMeeting.id;
      const pos = meetings.map((m) => { return m.node.id; }).indexOf(selectedMeetingID);

      if (pos >= 0) {
        const newSelectedMeeting = meetings[pos + 1];
        if (newSelectedMeeting) {
          this.setState({ selectedMeeting: newSelectedMeeting.node });
        }
      }
    }
  }

  getData = () => {
    if (this.props.data.myMeetings) {
      return this.props.data.myMeetings.edges.filter(
        ({ node: meeting }, _index) => {
          if (meeting) {
            return true;
          }

          return false;
        }
      );
    } else {
      return [];
    }
  }

  checkRelayOps = (prevProps, props) => {
    const prevRelayOp = prevProps.relayEvents || {};
    const relayOp = props.relayEvents || {};
    const triggerOps = [SCHEDULE_MEETING, DELETE_MEETING];

    if (relayOp.type === RELAY_DONE && prevRelayOp.type !== relayOp.type
      && triggerOps.indexOf(relayOp.operation) >= 0) {
      this.refreshMeetings();
    }
  }

  refreshMeetings = () => {
    const refetchVariables = fragmentVariables => ({
      ...fragmentVariables,
      first: this.props.pageSize,
      after: null,
    });

    this.props.relay.refetch(
      refetchVariables,
      null,
      (errors) => {
        this.setState({ pageCursors: [null] });
        this.reselectMeeting();
        if (errors) {
          this.logger.error("Error while refreshing meetings:", errors);
        }
      });
  }

  onMeetingJoin = (meeting) => {
    const router = this.router;

    return () => {
      const path = router.getUrlFor(this.router.MEETING, { slug: meeting.slug });
      this.props.history.push(path);
    };
  }

  onMeetingEdit = (meeting) => {
    return () => {
      this.openScheduleMeetingDialog(meeting);
    };
  }

  openScheduleMeetingDialog(meeting) {
    const { id, title, dtStart, dtEnd, notes, attendees, userId, domainId } = meeting;
    const users = attendees.map(function(a) {
      return a.email;
    });
    const meeting_details = {
      users: users,
      title: title,
      notes: notes,
      dt_start: dtStart,
      dt_end: dtEnd,
      meeting_id: id,
      userId: userId,
      domainId: domainId
    };
    // this.setState({ meetingDetails: meeting_details });
    // this.setState({ openScheduleMeetingDialog: true });
    this.setState({
      editMeetingDialog: true,
      editMeetingDetails: meeting_details
    });
  }

  onEditMeetingClose = () => {
    this.setState({ editMeetingDialog: false, editMeetingDetails: null });
  }

  onKeyBoardEvent = (e) => {
    const { keyCode } = e;

    if (keyCode === 38) {
      if (this.isFirstElementofPage() && this.hasPreviousPage()) {
        this.goToPrevPage();
      }
      else if (!this.isFirstElementofPage()) {
        this.selectPrevMeeting();
      }
    }
    else if (keyCode === 40) {
      if (this.hasMorePages() && this.isLastElementofPage()) {
        this.goToNextPage();
      }
      else if (!this.isLastElementofPage()) {
        this.selectNextMeeting();
      }
    }
  }

  // render methods
  getMeetings = () => {
    const classes = this.props.classes;

    return this.getData().map(({ node: meeting }, index) => {
      return (
        <Paper key={index}
          className={this.isSelectedMeeting(meeting) ? (classes.highlightedMeeting) : (classes.notHighlightedMeeting)}
          elevation={this.isSelectedMeeting(meeting) ? 6 : 2}
        >
          <ListItem button onClick={this.onItemClick(meeting)}>
            <ListItemIcon>
              <EventAvailable />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listPrimaryText, secondary: classes.listSecondaryText }}
              primary={meeting.title}
              secondary={this.props.intl.formatMessage(messages.meetingStartsAt, {
                dtStart: this.formatDate(meeting.dtStart)
              })}
            />
            <ListItemSecondaryAction>
              <TooltipMultiLineText
                placement="top"
                title={this.props.intl.formatMessage(messages.editTooltip)}>
                <IconButton onClick={this.onMeetingEdit(meeting)} >
                  <Edit />
                </IconButton>
              </TooltipMultiLineText>
              <TooltipMultiLineText
                placement="top"
                title={this.props.intl.formatMessage(messages.joinTooltip)}>
                <IconButton onClick={this.onMeetingJoin(meeting)}>
                  <Send />
                </IconButton>
              </TooltipMultiLineText>
            </ListItemSecondaryAction>
          </ListItem>
        </Paper>
      );
    });
  }

  renderList = () => {
    const classes = this.props.classes;

    return (
      <div>
        <Grid container spacing={24} className={classes.meetingListGridContainer}>

          <Grid item xs={12} sm={6} className={classes.meetingListElements}>
            <div>
              <List className={classes.root} >
                {this.getMeetings()}
              </List>
              {this.hasPreviousPage() &&
                <Button className={classes.prevButton} onClick={this.goToPrevPage}>
                  <NavigateBefore /> {this.props.intl.formatMessage(messages.previous)}
                </Button>
              }
              {this.hasMorePages() &&
                <Button className={classes.nextButton} onClick={this.goToNextPage}>
                  {this.props.intl.formatMessage(messages.next)} <NavigateNext />
                </Button>
              }
            </div>
          </Grid>

          <Grid item xs={12} sm={6} className={classes.meetingListDetails}>
            <Details
              meetingDetails={this.getSelectedMeeting()}
              history={this.props.history}
              formatDate={this.formatDate}
              onDelete={this.handleDeleteMeeting}
            />
          </Grid>
        </Grid>
        {this.state.editMeetingDialog === true &&
          <ScheduleMeeting
            onCloseClick={this.onEditMeetingClose}
            relayEnvironment={this.props.relay.environment}
            meetingDetails={this.state.editMeetingDetails}
          />
        }
      </div>
    );
  }

  renderEmpty = () => {
    return (<Empty relayEnvironment={this.props.relay.environment} />);
  }

  render() {
    if (this.getData().length > 0) {
      return this.renderList();
    } else {
      return this.renderEmpty();
    }

  }
}


function mapStateToProps(state) {
  return {
    relayEvents: state.relay
  };
}


View.propTypes = {
  data: PropTypes.object.isRequired, // from MeetingsBoard QueryRenderer
  relay: PropTypes.object.isRequired, // from createPaginationContainer
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  history: PropTypes.object.isRequired,
  pageSize: PropTypes.number.isRequired,
  relayEvents: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};


export default createRefetchContainer(
  withStyles(style)(injectIntl(connect(mapStateToProps)(View))),
  myMeetingsFragment,
  myMeetings
);
