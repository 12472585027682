class LocalStorage {
  constructor() {
    this.store = localStorage;
    this.tokenKey = 'HoloCom.authToken';
    this.mediaConfigKey = 'HoloCom.mediaConfig';
  }

  saveToken(token) {
    return this.store.setItem(this.tokenKey, token);
  }

  loadToken() {
    return this.store.getItem(this.tokenKey);
  }

  clearToken() {
    return this.store.removeItem(this.tokenKey);
  }

  saveMediaConfig(config) {
    config = JSON.stringify(config);
    return this.store.setItem(this.mediaConfigKey, config);
  }

  loadMediaConfig() {
    const config = this.store.getItem(this.mediaConfigKey);
    if (config) {
      return JSON.parse(config);
    } else {
      return {};
    }
  }
}

export default LocalStorage;
