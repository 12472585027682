import React from 'react';
import PropTypes from 'prop-types';
import ExitToApp from '@material-ui/icons/ExitToApp';


const style = {
  color: '#d21818',
};


function QuitIcon(props) {
  return (
    <ExitToApp  style={style} {...props}/>
  );
}


QuitIcon.propTypes = {
  className: PropTypes.any,
};


export default QuitIcon;
