/**
 * @flow
 * @relayHash 3cf313386265a83ae99d18bdb37118d1
 */

/* eslint-disable */
'use strict';
/*::
import type { ConcreteRequest } from 'relay-runtime';
export type renewMyPinsMutationVariables = {||};
export type renewMyPinsMutationResponse = {|
  +renewMyPins: ?{|
    +pins: ?{|
      +roomNumber: ?string,
      +roomOwnerNumber: ?string,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +key: string,
      +message: ?$ReadOnlyArray<?string>,
      +reason: ?$ReadOnlyArray<?string>,
    |}>,
  |}
|};
export type renewMyPinsMutation = {|
  variables: renewMyPinsMutationVariables,
  response: renewMyPinsMutationResponse,
|};
*/

/*
mutation renewMyPinsMutation {
  renewMyPins {
    pins {
      roomNumber
      roomOwnerNumber
    }
    errors {
      key
      message
      reason
    }
  }
}
*/

var node
/*: ConcreteRequest*/
= function () {
  var v0 = [{
    "kind": "LinkedField",
    "alias": null,
    "name": "renewMyPins",
    "storageKey": null,
    "args": null,
    "concreteType": "RenewMyPinsPayload",
    "plural": false,
    "selections": [{
      "kind": "LinkedField",
      "alias": null,
      "name": "pins",
      "storageKey": null,
      "args": null,
      "concreteType": "RenewedPins",
      "plural": false,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "roomNumber",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "roomOwnerNumber",
        "args": null,
        "storageKey": null
      }]
    }, {
      "kind": "LinkedField",
      "alias": null,
      "name": "errors",
      "storageKey": null,
      "args": null,
      "concreteType": "InputError",
      "plural": true,
      "selections": [{
        "kind": "ScalarField",
        "alias": null,
        "name": "key",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "message",
        "args": null,
        "storageKey": null
      }, {
        "kind": "ScalarField",
        "alias": null,
        "name": "reason",
        "args": null,
        "storageKey": null
      }]
    }]
  }];
  return {
    "kind": "Request",
    "fragment": {
      "kind": "Fragment",
      "name": "renewMyPinsMutation",
      "type": "RootMutationType",
      "metadata": null,
      "argumentDefinitions": [],
      "selections": v0
      /*: any*/

    },
    "operation": {
      "kind": "Operation",
      "name": "renewMyPinsMutation",
      "argumentDefinitions": [],
      "selections": v0
      /*: any*/

    },
    "params": {
      "operationKind": "mutation",
      "name": "renewMyPinsMutation",
      "id": null,
      "text": "mutation renewMyPinsMutation {\n  renewMyPins {\n    pins {\n      roomNumber\n      roomOwnerNumber\n    }\n    errors {\n      key\n      message\n      reason\n    }\n  }\n}\n",
      "metadata": {}
    }
  };
}(); // prettier-ignore


node
/*: any*/
.hash = '14130d3669c2342b436460024398fb71';
module.exports = node;