/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
/* Components imports */
import DialogMultiLineText from '../DialogMultiLineText';
/* Local Style */
import style from './style';

const messages = defineMessages({
  title: {id: 'extensionErrorTitle'},
  content: {id: 'extensionErrorContent'},
  contentList: {id: 'extensionErrorContentList'},
  cancel: {id: 'extensionErrorButton'},
  ok: {id: 'extensionInstallButton'},
  reload: {id: 'extensionReloadButton'},
});


class ExtensionError extends Component {

  render() {
    const classes = this.props.classes;
    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <DialogMultiLineText
            message={this.props.intl.formatMessage(messages.content)}
          />
          <div className={classes.multiLineList}>
            <DialogMultiLineText
              message={this.props.intl.formatMessage(messages.contentList)}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={this.props.onCloseButtonClick}
            variant="contained"
          >
            {this.props.intl.formatMessage(messages.cancel)}
          </Button>
          <Button
            onClick={this.props.onInstallClick}
            variant="contained"
            color="primary"
          >
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
          <Button
            disabled={!this.props.reloadEnabled}
            onClick={this.props.onReloadClick}
            variant={this.props.reloadEnabled ? "contained" : "text"}
            color="primary"
          >
            {this.props.intl.formatMessage(messages.reload)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


ExtensionError.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  open: PropTypes.bool.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  onInstallClick: PropTypes.func.isRequired,
  reloadEnabled: PropTypes.bool.isRequired,
  onReloadClick: PropTypes.func.isRequired
};


export default withStyles(style)(injectIntl(ExtensionError));
