import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const messages = defineMessages({
  standardLayout: { id: 'standardLayout' },
  featuredLayout: { id: 'featuredLayout' },
});

class ChangeLayout extends Component {

  handleSelectedFeaturedLayout = () => {
    const layout_config = { featured_id: this.props.my_uid, featured_type: 'stream' };
    this.props.onSelectedLayout('featured', layout_config);
  }

  handleSelectedDefaultLayout = () => {
    this.props.onSelectedLayout('default');
  }

  render() {
    return (
      <MenuList dense>
        <MenuItem selected={'default' === this.props.selectedLayout}
          onClick={this.handleSelectedDefaultLayout}>
          {this.props.intl.formatMessage(messages.standardLayout)}
        </MenuItem>
        <MenuItem selected={'featured' === this.props.selectedLayout}
          onClick={this.handleSelectedFeaturedLayout}>
          {this.props.intl.formatMessage(messages.featuredLayout)}
        </MenuItem>
      </MenuList>
    );
  }
}

function mapStateToProps(state, _props) {
  return { selectedLayout: state.room.layout, my_uid: state.websocket.uid };
}

ChangeLayout.propTypes = {
  intl: intlShape.isRequired,
  selectedLayout: PropTypes.string,
  my_uid: PropTypes.string,
  onSelectedLayout: PropTypes.func,
};

export default (injectIntl(connect(mapStateToProps)(ChangeLayout)));
