"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultState = exports["default"] = void 0;

var _session = require("../actions/session");

var defaultState = {
  isFetching: false,
  sessionReady: false,
  displayName: null,
  // name and surname if a user, requested display name if guest
  errorCode: null,
  errorMessage: null,
  roles: null,
  stunServers: null,
  username: null,
  realm: null,
  email: null,
  roomNumber: null,
  roomOwnerNumber: null,
  isRecorder: false,
  recordingEnabled: false,
  livestreamingEnabled: false
};
exports.defaultState = defaultState;

function session() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _session.SESSIONDETAILS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        sessionReady: false
      });

    case _session.SESSIONDETAILS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        sessionReady: false,
        errorMessage: action.payload.errorMessage
      });

    case _session.SESSIONDETAILS_SUCCESS:
      {
        var sess = action.payload.session;
        return Object.assign({}, state, {
          isFetching: false,
          sessionReady: true,
          displayName: sess.displayName,
          realm: sess.realm,
          email: sess.email,
          username: sess.username,
          roles: sess.roles,
          stunServers: sess.stunServers,
          roomNumber: sess.roomNumber,
          roomOwnerNumber: sess.roomOwnerNumber,
          pstnNumber: sess.pstnNumber,
          domainId: sess.domainId,
          userId: sess.userId,
          callStatsConfig: sess.callStatsConfig,
          chromeScreenShareConfig: sess.chromeScreenShareConfig,
          recordingEnabled: sess.recordingEnabled,
          livestreamingEnabled: sess.livestreamingEnabled
        });
      }

    case _session.SESSIONDETAILS_CLEAR:
      return Object.assign({}, state, defaultState);

    case _session.SESSION_SETRECORDER:
      return Object.assign({}, state, {
        isRecorder: action.payload.is_recorder
      });

    default:
      return state;
  }
}

var _default = session;
exports["default"] = _default;