const style = theme => ({
  emptyContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 5
  },
  emptyIcon: {
    fontSize: '64px',
    marginBottom: theme.spacing.unit * 2
  },
  scheduleText: {
    cursor: 'pointer',
    margin: 'auto',
    width: 'fit-content'
  }
});

export default style;
