"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.relayRequest = relayRequest;
exports.relayDone = relayDone;
exports.RELAY_DONE = exports.RELAY_REQUEST = void 0;
var RELAY_REQUEST = 'RELAY_REQUEST';
exports.RELAY_REQUEST = RELAY_REQUEST;
var RELAY_DONE = 'RELAY_DONE';
exports.RELAY_DONE = RELAY_DONE;

function relayRequest(reqId, operation, data) {
  return {
    type: RELAY_REQUEST,
    payload: {
      lastRequestId: reqId,
      operation: operation,
      data: data
    }
  };
}

function relayDone(reqId, operation, data, error) {
  return {
    type: RELAY_DONE,
    payload: {
      lastRequestId: reqId,
      operation: operation,
      data: data,
      error: error
    }
  };
}