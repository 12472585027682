const style = theme => ({
  formControl: {
    marginTop: theme.spacing.unit,
    width: '100%'
  },
  listAttendees: {
    wordBreak: "break-all"
  },
  formError: {
    color: '#f44336',
    margin: theme.spacing.unit
  }
});

export default style;
