"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateRequestId = generateRequestId;

var _uuid = _interopRequireDefault(require("uuid"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function generateRequestId() {
  var reqId = _uuid["default"].v4();

  return reqId;
}