/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
/* Other Imports */
import VideoConferenceIcon from '../../style/VideoConferenceIcon';
import { Router } from 'holocom-client/lib/utils';
/* Local Style */
import style from './style';


const messages = defineMessages({
  insertRoomId: { id: 'insertMeetingCode', },
  joinButton: { id: 'joinButton' },
  loginHaveAnAccount: { id: 'loginHaveAnAccount' }
});

class MeetingSelectorGuest extends Component {
  constructor(props) {
    super(props);
    this.router = new Router();
    this.state = {
      meeting_id: '',
      submitEnabled: false,
    };
  }

  handleJoinMeeting() {
    const path = this.router.getUrlFor(this.router.MEETING, { slug: this.state.meeting_id });
    this.props.history.push(path);
  }

  handleMeetingIdChange = (ev) => {
    this.setState({
      meeting_id: ev.target.value,
      submitEnabled: ev.target.value.length > 0,
    });
  }

  handleKeyUp = (ev) => {
    if ((ev.key === 'Enter') && (this.state.submitEnabled)) {
      this.handleJoinMeeting();
    }
  }

  handleJoinButton = () => {
    this.handleJoinMeeting();
  }

  handleLogin = () => {
    this.props.history.push("/login");
  }


  render() {
    const classes = this.props.classes;
    return (
      <Paper className={classes.paper}>

        <VideoConferenceIcon className={classes.videoConferenceIcon} />

        <DialogContent className={classes.dialogContent}>

          <DialogContentText>
            {this.props.intl.formatMessage(messages.insertRoomId)}
          </DialogContentText>

          <TextField
            inputProps={{ autoCapitalize: 'off', className: classes.codeInputField }}
            autoFocus={true}
            onChange={this.handleMeetingIdChange}
            onKeyUp={this.handleKeyUp}
            fullWidth
          />
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            size="large"
            className={classes.joinButton}
            disabled={!this.state.submitEnabled}
            onClick={this.handleJoinButton}
            variant="contained" color="primary">
            {this.props.intl.formatMessage(messages.joinButton)}
          </Button>
        </DialogActions>

        <div className={classes.loginMessage}>
          <Button className={classes.loginButton} size="small" onClick={this.handleLogin}>
            {this.props.intl.formatMessage(messages.loginHaveAnAccount)}
          </Button>
        </div>

      </Paper>
    );
  }
}

MeetingSelectorGuest.propTypes = {
  intl: intlShape.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default withStyles(style)(injectIntl(connect(mapStateToProps)(MeetingSelectorGuest)));
