/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer } from 'react-relay';
/* UI Imports */
import CircularProgress from '@material-ui/core/CircularProgress';
/* Local Imports */
import View from './View';
/* Other Imports */
import { myMeetings } from 'holocom-client/lib/api/relay/myMeetings';
/* Local Style */


class MeetingsBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 4,
      now: new Date().toISOString()
    };
  }

  renderer = ({ error, props, _retry }) => {
    if (error) {
      return null;
    } else if (props) {
      return (
        <View
          data={props}
          pageSize={this.state.pageSize}
          history={this.props.history}
        />
      );
    } else {
      return (
        <CircularProgress />
      );
    }
  }

  render() {
    const environment = this.props.relayEnvironment;

    return (
      <QueryRenderer
        environment={environment}
        query={myMeetings}
        cacheConfig={{ force: true }}
        variables={{
          first: this.state.pageSize,
          sorting: {
            direction: "ASC",
            sort: "dtStart"
          },
          filters: {
            value: new Date().toISOString(),
            key: "dtEnd",
            match: "GT"
          }
        }}
        render={this.renderer}
      />
    );
  }
}


MeetingsBoard.propTypes = {
  relayEnvironment: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};


export default MeetingsBoard;
