"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renewMyPins = renewMyPins;

var _reactRelay = require("react-relay");

var mutation = function mutation() {
  var node = require("./__generated__/renewMyPinsMutation.graphql");

  if (node.hash && node.hash !== "14130d3669c2342b436460024398fb71") {
    console.error("The definition of 'renewMyPinsMutation' appears to have changed. Run `relay-compiler` to update the generated files to receive the expected data.");
  }

  return require("./__generated__/renewMyPinsMutation.graphql");
};

function renewMyPins(environment) {
  return new Promise(function (resolve, reject) {
    (0, _reactRelay.commitMutation)(environment, {
      mutation: mutation,
      onCompleted: function onCompleted(_ref, otherErrors) {
        var _ref$renewMyPins = _ref.renewMyPins,
            errors = _ref$renewMyPins.errors,
            pins = _ref$renewMyPins.pins;

        if (otherErrors) {
          return reject(otherErrors);
        } else if (errors) {
          return reject(errors);
        }

        return resolve(pins);
      },
      onError: function onError(err) {
        reject(err);
      }
    });
  });
}