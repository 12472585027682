/* Libraries Imports */
import React, {
  Component
} from 'react';
import PropTypes from 'prop-types';
/* UI Imports */
/* Components Imports */
import StandardLogin from './StandardLogin';
import OAuthLogin from './OAuthLogin';
/* Actions Imports */
/* Other Imports */


class Login extends Component {

  getStandardLogin() {
    return (<StandardLogin history={this.props.history} localStore={this.props.localStore} />);
  }

  getOAuthLogin() {
    return (
      <OAuthLogin
        oauthConfig={this.props.appConfig.oauth}
        history={this.props.history}
        localStore={this.props.localStore}
      />
    );
  }

  getLoginDialog() {
    const { oauth } = this.props.appConfig;

    if ((oauth || []).length) {
      return this.getOAuthLogin();
    } else {
      return this.getStandardLogin();
    }

  }

  render() {
    return this.getLoginDialog();
  }
}


Login.propTypes = {
  appConfig: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  localStore: PropTypes.object.isRequired,
};


export default Login;
