/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { checkPermissionNotification, sendNotification } from '../../notification';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline';
import Badge from '@material-ui/core/Badge';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export const styles = (theme) => ({
  iconSelected: {
    color: theme.palette.primary.main,
  },
});


class ChatIcon extends Component {
  componentDidMount() {
    checkPermissionNotification(this.props.userAgent.os);
  }

  componentDidUpdate(prevProps) {
    const { chatMessages } = this.props;
    if (!(document.hasFocus()) && chatMessages.length > prevProps.chatMessages.length ) {
      if (chatMessages.length > 0) {
        const lastSender = chatMessages.length - 1;
        if (chatMessages[lastSender].userId !== this.props.myself) {
          const title = chatMessages[lastSender].from;
          const lastMessage = chatMessages[lastSender].message;
          sendNotification(title, lastMessage);
        }
      }
    }
  }

  render() {
    let chatIcon;
    let unreadMessageCount = this.props.unreadChatMessages;
    if (unreadMessageCount > 0 && !this.props.chatPanelOpen) {
      chatIcon = (
        <Badge badgeContent={unreadMessageCount < 100 ? unreadMessageCount : "99+"} color="secondary">
          <ChatBubbleOutline />
        </Badge>
      );
    }
    else {
      chatIcon = <ChatBubbleOutline />;
    }

    let chatClassName;
    if (this.props.chatPanelOpen) {
      chatClassName = this.props.classes.iconSelected;
    }
    return (
      <ListItemIcon className={chatClassName}>
        {chatIcon}
      </ListItemIcon>
    );
  }
}


ChatIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  unreadChatMessages: PropTypes.number.isRequired,
  chatPanelOpen: PropTypes.bool.isRequired,
  myself: PropTypes.string.isRequired,
  chatMessages: PropTypes.array.isRequired,
  userAgent: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    chatMessages: state.room.chatMessages,
    myself: state.websocket.uid,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(ChatIcon));
