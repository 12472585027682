"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultState = exports["default"] = void 0;

var _auth = require("../actions/auth");

var defaultState = {
  isFetching: false,
  // auth api request done, waiting for response
  isAuthenticated: false,
  // correctly authenticated with username and password
  isAuthenticatedAsGuest: false,
  // "authenticated" as guest
  token: null,
  // user token (as platform user or guest)
  displayName: null,
  // name and surname if a user, requested display name if guest
  username: null,
  // username
  errorCode: null,
  // auth api error code
  errorMessage: null // auth api error message

};
exports.defaultState = defaultState;

function auth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  switch (action.type) {
    case _auth.LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        username: action.payload.username,
        errorCode: null,
        errorMessage: null
      });

    case _auth.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        isAuthenticatedAsGuest: false,
        token: action.payload.token,
        username: action.payload.username,
        errorCode: null,
        errorMessage: null
      });

    case _auth.LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        username: null,
        token: null,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      });

    case _auth.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        isAuthenticatedAsGuest: false,
        username: null,
        token: null,
        errorCode: null,
        displayName: null,
        errorMessage: null
      });

    case _auth.GUESTLOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticatedAsGuest: false,
        displayName: action.payload.displayName,
        errorCode: null,
        errorMessage: null
      });

    case _auth.GUESTLOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticatedAsGuest: true,
        token: action.payload.token,
        displayName: action.payload.displayName,
        errorCode: null,
        errorMessage: null
      });

    case _auth.GUESTLOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticatedAsGuest: false,
        displayName: null,
        token: null,
        errorMessage: action.payload.errorMessage,
        errorCode: action.payload.errorCode
      });

    case _auth.SYNC_TOKEN_LOGIN:
      return Object.assign({}, state, {
        token: action.payload.token
      });

    case _auth.SYNC_TOKEN_LOGOUT:
      return state = defaultState;

    default:
      return state;
  }
}

var _default = auth;
exports["default"] = _default;