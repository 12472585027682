/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* UI Imports */
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ScreenShare from '@material-ui/icons/ScreenShare';
import ScreenShareDownloadIcon from './screensharedownloadicon';
import ScreenShareErrorIcon from './screenshareerroricon';

class ScreenShareIcon extends Component {

  render() {
    const { isElectron } = this.props;
    const isInstalled = this.props.extensionIsInstalled;
    const browser = this.props.browser;
    if (isInstalled || (browser === 'firefox') || isElectron) {
      return (
        <ListItemIcon>
          <ScreenShare />
        </ListItemIcon>
      );
    } else {
      return (
        <div>
          {(browser === 'chrome')
            ? <ScreenShareDownloadIcon setTooltipExtensionMessage={this.props.setTooltipExtensionMessage} />
            : <ScreenShareErrorIcon setTooltipExtensionMessage={this.props.setTooltipExtensionMessage} />
          }
        </div>
      );
    }
  }

}


ScreenShareIcon.propTypes = {
  browser: PropTypes.string.isRequired,
  isElectron: PropTypes.bool.isRequired,
  extensionIsInstalled: PropTypes.bool.isRequired,
  setTooltipExtensionMessage: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    extensionIsInstalled: state.room.extensionIsInstalled,
  };
}

export default connect(mapStateToProps)(ScreenShareIcon);
