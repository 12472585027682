/* Libraries Imports */
import React, { Component } from 'react';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
/* Components Imports */
import VideoElement from '../VideoElement';
/* Other Imports */
import prepareWebRtcProvider from '../../rtc';
import { RtcDevices, getVideoQualities } from 'holocom-client/lib/api/rtcDevices';
import { getLogger } from 'holocom-client/lib/logger';
/* Actions Imports */
import {
  toggleDeviceSettings, saveVideoConfig,
  saveAudioInConfig, saveAudioOutConfig, saveVideoQuality,
} from 'holocom-client/lib/actions/settings';
/* Local Style */
import style from './style';

const messages = defineMessages({
  ok: { id: 'ok' },
  dialogTitle: { id: 'deviceSelectorTitle' },
  videoError: { id: 'deviceSelectorVideoError' },
  audioInput: { id: 'deviceSelectorAudioInput' },
  videoInput: { id: 'deviceSelectorVideoInput' },
  audioOutput: { id: 'deviceSelectorAudioOutput' },
  noAudioOutput: { id: 'deviceSelectorNoAudioOutput' },
  videoQuality: { id: 'deviceSelectorVideoQuality' },
  loadingCam: { id: 'deviceSelectorLoadingCam' },
});

class DeviceSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioInDevices: [],
      audioOutDevices: [],
      videoDevices: [],
      quality: getVideoQualities(),
      localVideoStream: null,
      videoLoading: false,
      videoError: false,
    };
    this.close = this.close.bind(this);
    this.onAudioInputDeviceChange = this.onAudioInputDeviceChange.bind(this);
    this.onAudioOutputDeviceChange = this.onAudioOutputDeviceChange.bind(this);
    this.onVideoInputDeviceChange = this.onVideoInputDeviceChange.bind(this);
    this.onVideoQualityChange = this.onVideoQualityChange.bind(this);
  }

  componentDidMount() {
    const rtc = this._newRtcHandler('DeviceSelector Mount');
    rtc.validateDevicesWithState(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.isOpen && prevProps.isOpen === false) {
      this.discoverDevices();
    } else if (prevProps.isOpen && this.props.isOpen === false) {
      this.stopLocalStream();
    }
    if (this.props.isOpen) {
      this.checkDeviceChanges(prevProps);
    }
  }

  checkDeviceChanges(prevProps) {
    let needsRefresh = false;
    if (this.props.audioInDevice !== prevProps.audioInDevice) {
      needsRefresh = true;
    } else if (this.props.videoDevice !== prevProps.videoDevice) {
      needsRefresh = true;
    } else if (this.props.videoQuality !== prevProps.videoQuality) {
      needsRefresh = true;
    }
    if (needsRefresh) {
      this.restartLocalStream();
    }
  }

  close() {
    this.props.dispatch(toggleDeviceSettings());
  }

  discoverDevices() {
    const rtc = this._newRtcHandler('getUserMedia');
    rtc.discoverDevices().then((devices) => {
      this.setDiscoveredDevices(devices);
    }).catch((e) => this.onError(e));
    this.restartLocalStream();
  }

  restartLocalStream() {
    this.stopLocalStream();
    this.setState({ videoLoading: true });
    const rtc = this._newRtcHandler('restartLocalStream');
    const audioId = this.props.audioInDevice ? this.props.audioInDevice.deviceId : undefined;
    const videoId = this.props.videoDevice ? this.props.videoDevice.deviceId : undefined;
    const quality = this.props.videoQuality ? this.props.videoQuality.value : undefined;
    rtc.getLocalStreamByDevices(audioId, videoId, quality).then((stream) => {
      this.setState({
        localVideoStream: stream, videoError: false, videoLoading: false
      });
    }).catch((e) => this.onLocalStreamError(e));
  }

  stopLocalStream() {
    const rtc = this._newRtcHandler('stopLocalStream');
    rtc.stopStream(this.state.localVideoStream);
  }

  hasAudioInDevices() {
    return this.state.audioInDevices.length > 0;
  }

  hasAudioOutDevices() {
    return this.state.audioOutDevices.length > 0;
  }

  hasVideoDevices() {
    return this.state.videoDevices.length > 0;
  }

  onError(e) {
    const logger = getLogger('MediaDevices Error');
    logger.error(e);
  }

  onLocalStreamError(e) {
    const logger = getLogger('MediaDevices LocalStream');
    logger.error(e);
    if (e.name === 'OverconstrainedError') {
      this.setState({ videoError: true });
    } else if (e.name === 'NotReadableError') {
      this.setState({ videoError: true });
    } else if (e.name === 'Error') {
      this.setState({ videoError: true });
    }
  }

  setDiscoveredDevices(devices) {
    const audioIn = devices.filter((device) => {
      return device.kind === 'audioinput';
    });

    const audioOut = devices.filter((device) => {
      return device.kind === 'audiooutput';
    });

    const videoIn = devices.filter((device) => {
      return device.kind === 'videoinput';
    });
    this.setState({
      audioInDevices: audioIn,
      audioOutDevices: audioOut,
      videoDevices: videoIn
    });
  }

  getAudioInputDevice() {
    const device = this.props.audioInDevice;
    return this.getDeviceId(device);
  }

  getAudioOutputDevice() {
    const device = this.props.audioOutDevice;
    return this.getDeviceId(device);
  }

  getVideoInputDevice() {
    const device = this.props.videoDevice;
    return this.getDeviceId(device);
  }

  getVideoQuality() {
    const quality = this.props.videoQuality;
    if (quality) {
      return quality.value;
    }
    return "";
  }

  onAudioInputDeviceChange(event) {
    const device = this.getDeviceById(this.state.audioInDevices, event.target.value);
    this.props.dispatch(saveAudioInConfig(device, this.props.localStore));
  }

  onAudioOutputDeviceChange(event) {
    const device = this.getDeviceById(this.state.audioOutDevices, event.target.value);
    this.props.dispatch(saveAudioOutConfig(device, this.props.localStore));
  }

  onVideoInputDeviceChange(event) {
    const device = this.getDeviceById(this.state.videoDevices, event.target.value);
    this.props.dispatch(saveVideoConfig(device, this.props.localStore));
  }

  onVideoQualityChange(event) {
    const quality = this.getQualityById(this.state.quality, event.target.value);
    this.props.dispatch(saveVideoQuality(quality, this.props.localStore));
  }

  getDeviceId(device) {
    if (device) {
      return device.deviceId;
    }
    return "";
  }

  getDeviceById(devices, id) {
    return devices.find((device) => {
      return device.deviceId === id;
    });
  }

  getQualityById(qualities, id) {
    return qualities.find((quality) => {
      return quality.value === id;
    });
  }

  getAudioInDevicesSelect() {
    const classes = this.props.classes;
    if (this.hasAudioInDevices()) {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="audioInputDevice">
            {this.props.intl.formatMessage(messages.audioInput)}
          </InputLabel>
          <Select
            value={this.getAudioInputDevice()}
            onChange={this.onAudioInputDeviceChange}
            input={<Input id="audioInputDevice" />}
          >
            {this.state.audioInDevices.map((device, idx) => {
              return (
                <MenuItem key={idx} value={device.deviceId}>
                  {device.label}
                </MenuItem>);
            })}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  }

  getAudioOutDevicesSelect() {
    const classes = this.props.classes;
    if (this.hasAudioOutDevices()) {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="audioOutputDevice">
            {this.props.intl.formatMessage(messages.audioOutput)}
          </InputLabel>
          <Select
            value={this.getAudioOutputDevice()}
            onChange={this.onAudioOutputDeviceChange}
            input={<Input id="audioOutputDevice" />}
          >
            {this.state.audioOutDevices.map((device, idx) => {
              return (
                <MenuItem key={idx} value={device.deviceId}>
                  {device.label}
                </MenuItem>);
            })}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="audioOutputDevice">
            {this.props.intl.formatMessage(messages.noAudioOutput)}
          </InputLabel>
        </FormControl>
      );
    }
  }

  getVideoDevicesSelect() {
    const classes = this.props.classes;
    if (this.hasVideoDevices()) {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="videoInputDevice">
            {this.props.intl.formatMessage(messages.videoInput)}
          </InputLabel>
          <Select
            value={this.getVideoInputDevice()}
            onChange={this.onVideoInputDeviceChange}
            input={<Input id="videoInputDevice" />}
          >
            {this.state.videoDevices.map((device, idx) => {
              return (
                <MenuItem key={idx} value={device.deviceId}>
                  {device.label}
                </MenuItem>);
            })}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  }

  getVideoQualitySelect() {
    const classes = this.props.classes;
    if (this.hasVideoDevices()) {
      return (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="videoQuality">
            {this.props.intl.formatMessage(messages.videoQuality)}
          </InputLabel>
          <Select
            value={this.getVideoQuality()}
            onChange={this.onVideoQualityChange}
            input={<Input id="videoQuality" />}
          >
            {this.state.quality.map((entry, idx) => {
              return (
                <MenuItem key={idx} value={entry.value}>
                  {entry.label}
                </MenuItem>);
            })}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  }

  getVideoPreviewBox() {
    if (this.state.videoError) {
      return (
        <DialogContentText>
          {this.props.intl.formatMessage(messages.videoError)}
        </DialogContentText>
      );
    } else if (this.state.videoLoading) {
      return (
        <DialogContentText>
          {this.props.intl.formatMessage(messages.loadingCam)}
        </DialogContentText>
      );
    } else if (this.state.localVideoStream) {
      return (
        <VideoElement muted={false} mirrored={true} fullHeight={false}
          src={this.state.localVideoStream}
          handleAudioOutputChange={true} />
      );
    } else {
      return null;
    }
  }

  _newRtcHandler(logPostfix) {
    const logger = getLogger('MediaDevices ' + logPostfix);
    const webrtc = prepareWebRtcProvider();
    const rtc = new RtcDevices(webrtc, logger);
    return rtc;
  }

  render() {
    const classes = this.props.classes;
    const fullScreen = this.props.fullScreen;
    return (
      <Dialog open={this.props.isOpen}
        disableBackdropClick
        onEscapeKeyDown={this.close}
        fullScreen={fullScreen}
        maxWidth='sm'>
        <DialogTitle>
          {this.props.intl.formatMessage(messages.dialogTitle)}
        </DialogTitle>
        <DialogContent>
          {this.getVideoPreviewBox()}
          <form className={classes.container}>
            {this.getAudioInDevicesSelect()}
            {this.getAudioOutDevicesSelect()}
          </form>
          <form className={classes.container}>
            {this.getVideoDevicesSelect()}
            {this.getVideoQualitySelect()}
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" className={classes.button}
            onClick={this.close}>
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


DeviceSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  localStore: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  audioInDevice: PropTypes.object,
  audioOutDevice: PropTypes.object,
  videoDevice: PropTypes.object,
  videoQuality: PropTypes.object,
  fullScreen: PropTypes.bool.isRequired,
};


function mapStateToProps(state) {
  return {
    isOpen: state.settings.device_settings,
    audioInDevice: state.settings.audioInDevice,
    audioOutDevice: state.settings.audioOutDevice,
    videoDevice: state.settings.videoDevice,
    videoQuality: state.settings.videoQuality,
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(withMobileDialog()(DeviceSelector))));
