const style = theme => ({
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  menuFilterContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '70vw'
    },
    userSelect: 'none',
    maxWidth: '300px',
    height: "80%",
    display: "flex",
    marginTop: "16px",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  menuFilterFormControl: {
    flex: "1 1 calc(50% - 24px)",
    marginRight: "24px",
    marginBottom: "24px",
  },
  menuFilterTextField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    maxWidth: 200,
  },
  filterEnabled: {
    color: 'red',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
    overflowY: 'auto',
    flexShrink: 0,
    userSelect: 'none',
  },
  table_cell_header: {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 1)',
  },
  table_cell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 0,
    textAlign: 'center',
  },
  button: {
    padding: 0,
  },
});

export default style;
