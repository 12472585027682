/* eslint max-len: 0 */
import React from 'react';
import pure from 'recompose/pure';
import SvgIcon from '@material-ui/core/SvgIcon';

let MicAllIcon = props =>
  <SvgIcon {...props}>
    <path d="M2.8,5.1c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3S3.5,5.1,2.8,5.1z M14.8,24c0-0.1,0-0.1,0-0.2l-1.7-4V8.6
        C13,8.1,13.3,8,13.4,8c0.3,0,0.3,0.3,0.3,0.5v8.2c0,0.6,0.5,1,1,1s1-0.5,1-1v-2.2c0-0.5,0.3-0.5,0.4-0.5c0.1,0,0.1,0,0.2,0.1
        c0.1,0.1,0.1,0.2,0.1,0.5v2.8c0,0.6,0.5,1,1,1s1-0.5,1-1v-2.4c0-0.5,0.3-0.5,0.4-0.5c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.5
        v2.8c0,0.6,0.5,1,1,1s1-0.5,1-1v-2.3c0-0.5,0.3-0.5,0.4-0.5c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.1,0.3,0.1,0.5v4.5L21,24c0,0,0,0,0,0.1
        h2.1l1-3.5l0-4.9c0-1-0.4-1.5-0.7-1.9c-0.4-0.4-1-0.7-1.6-0.7c-0.4,0-0.7,0.1-1,0.3c0,0,0-0.1-0.1-0.1c-0.4-0.4-1-0.7-1.6-0.7
        c-0.4,0-0.7,0.1-1.1,0.3c0,0,0,0,0-0.1c-0.4-0.4-1-0.7-1.6-0.7c-0.1,0-0.3,0-0.4,0V8.6c0-1-0.4-1.5-0.7-1.9C14.6,6.3,14,6,13.4,6
        C12.2,6,11,6.9,11,8.6v11.7l1.6,3.8H14.8z M2.1,20.8c0.1-0.1,0.2-0.2,0.2-0.2c0.2,0,0.7,0,1,0c0.6,0.1,1.2,0.1,1.7,0
        c1-0.3,2.4-1.5,2.9-2.4c0.2-0.5,0.2-1.3,0.1-2c0.3-0.3,0.7-0.7,1-1c0.2-0.3,0.3-0.6,0.3-1c-0.1-0.3-0.3-0.7-0.5-1
        c0.5-0.6,0.5-0.9,0.5-1.1c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.3-0.4-0.5-0.6c0.5-0.2,1-0.5,1.4-0.7c0.4-0.2,0.7-0.5,0.8-1
        c0.2-0.7,0.1-1.6-0.4-2.4C9.7,5.5,7.9,2.3,6.8,0H4.6c1.2,2.8,3.6,6.9,4.1,7.8C8.8,8,8.9,8.2,8.9,8.4c-1,0.4-2.3,1.2-2.4,1.2
        l-1.1,0.6l0.8,1c0,0,0.4,0.5,0.9,1c-0.1,0.1-0.3,0.3-0.5,0.4l-1.1,1l1.5,0.8c0,0,0,0,0,0c-0.3,0.3-0.6,0.5-0.7,0.6l-0.4,0.4L6,15.9
        c0.1,0.6,0.1,1.3,0.1,1.4c-0.2,0.4-1.2,1.2-1.6,1.4c-0.1,0-0.6,0-0.9,0c-0.7-0.1-1.4-0.1-1.9,0.1c-0.5,0.2-1.5,1-1.5,1.9
        c0,1.1,0,3.3,0,3.4l2,0C2.1,24,2.1,21.9,2.1,20.8z"/>
  </SvgIcon>;

MicAllIcon = pure(MicAllIcon);
MicAllIcon.muiName = 'SvgIcon';

export default MicAllIcon;
