/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import gridElements from 'holocom-client/lib/grid';
/* Other Imports */
import style from './style';
import * as defaultDimension from './StandardDimensionLayout.js';
import PiP from '../../pip';
/* Component Imports */
import TalkingNotification from '../TalkingNotification';
import OwnMutedVideoElement from '../OwnMutedVideoElement';
import OwnVideoToolbar from '../OwnVideoToolbar';
import VideoElement from '../VideoElement';
import MutedVideoElement from '../MutedVideoElement';
import MenuVideoToolbar from '../MenuVideoToolbar';
import AudioMutedIcon from '../MenuVideoInfoToolbar/AudioMutedIcon';
import FullScreen from '../Fullscreen';
import VideoToolbar from '../VideoToolbar';
import VideoInfoToolbar from '../VideoInfoToolbar';
// import DesktopControlPointer from '../DesktopControlPointer';
import NoVideoElement from '../NoVideoElement';
import OwnScreenShareToolbar from '../OwnScreenShareToolbar';
import { newEvent, INFO } from 'holocom-client/lib/notifications';
import { getMemoizedDeskControlledUser, getDrawingHasStarted } from './reduxSelectors';


/* Other imports */
import elementResizeDetectorMaker from 'element-resize-detector';


class StandardLayout extends Component {

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.updateBottomDimensions = this.updateBottomDimensions.bind(this);
    this.onToggleOwnAudioMute = props.onToggleOwnAudioMute.bind(this);
    this.onToggleAudioMute = props.onToggleAudioMute.bind(this);
    this.onToggleVideoMute = props.onToggleVideoMute.bind(this);

    this.leaveFullScreen = this.leaveFullScreen.bind(this);
    this.startDrawing = this.startDrawing.bind(this);
    this.stopDrawing = this.stopDrawing.bind(this);
    this.exitFullScreenDesktopControl = this.exitFullScreenDesktopControl.bind(this);

    this.resizeDetector = elementResizeDetectorMaker({ strategy: "scroll" });
    this.state = {
      videoToolBar: false,
      videoSmallToolBar: false,
      fullScreenStream: false,
      fullScreenIdx: null,
      gridBoxes: [],
      bottomBoxes: [],
      controlledUserInFullScreen: null,
      // pointersColor: [],
      screenShareToolbar: false,
      pipUser: null, // stream with picture-in-picture enabled
    };
    const os = (props.userAgent.os || '').toLowerCase();
    // const version = (props.userAgent.version || '');
    if (os === 'ios') {// && !version.startsWith("12")) {
      // fullscreen is not supported on ios < 11
      // ios 12 requires some debugging
      this.onExitFullScreen = null;
      this.onEnterFullScreen = null;
    }
    else {
      this.onExitFullScreen = this.onExitFullScreen.bind(this);
      this.onEnterFullScreen = this.onEnterFullScreen.bind(this);
    }

    this.pip = new PiP(this.video);
  }

  _getMyViewPort() {
    return ReactDOM.findDOMNode(this.videoArea);// eslint-disable-line react/no-find-dom-node
  }

  _getMyBottomViewPort() {
    return ReactDOM.findDOMNode(this.videoBottomArea);// eslint-disable-line react/no-find-dom-node
  }

  videoToolbarIsOnFor(user) {
    if (user && this.state.videoToolBar === user) {
      return true;
    }
    return false;
  }

  videoSmallToolbarIsOnFor(user) {
    if (user && this.state.videoSmallToolBar === user) {
      return true;
    }
    return false;
  }

  onPublishVideo = (user, published) => {
    this.disablePip();
    this.props.onPublishVideo(user, published);
  }

  onLeaveRoom = () => {
    this.disablePip();
    this.pip.set(false);
    this.props.onLeaveRoom();
  }

  onKick = (user) => {
    this.props.onKick(user);
  }

  onChangeRole = (user, role) => {
    this.props.onChangeRole(user, role);
  }

  showOwnToolbar = () => {
    this.setState({ videoToolBar: this.props.myUserId });
  }

  hideOwnToolbar = () => {
    this.setState({ videoToolBar: false });
  }

  showOwnScreenToolbar = () => {
    this.setState({ screenShareToolbar: true });
  }

  hideOwnScreenToolbar = () => {
    this.setState({ screenShareToolbar: false });
  }

  onExitFullScreen() {
    this.setState({ controlledUserInFullScreen: null, fullScreenStream: null, fullScreenIdx: null });
    this.removeWindowEventListener();
  }

  onEnterFullScreen(user, idx) {
    if (user.endsWith('_screen')) {
      if (user.replace(/_screen$/, '') === this.props.desktopControlledUser) {
        this.addWindowEventListener();
        this.props.startControllingDesktop();
      }
    }
    this.setState({ fullScreenStream: user, fullScreenIdx: idx });
  }

  fullScreenIsOnFor(user, idx) {
    if (this.state.fullScreenStream === user && this.state.fullScreenIdx === idx) {
      return true;
    }
    return false;
  }

  onFullScreenChangeFor = (newStatus, user, idx) => {
    if (newStatus) {
      return;
    }

    this.removeWindowEventListener();
    this.props.stopControllingDesktop();
    if (this.state.fullScreenStream === user && this.state.fullScreenIdx === idx) {
      this.setState({ controlledUserInFullScreen: null, fullScreenStream: null, fullScreenIdx: null });
    }
  }

  showVideoLocalStream(props) {
    if (props.localVideoStream && !props.isRecorder) {
      return true;
    }
    return false;
  }

  _countStreams(props) {
    let nrOfStreams = Object.keys(props.remoteVideoStreams).length;
    if (this.showVideoLocalStream(props)) {
      nrOfStreams = nrOfStreams + 1;
    }
    if (props.screenStream) {
      nrOfStreams = nrOfStreams + 1;
    }
    return nrOfStreams;
  }

  _countBottomStreams(props) {
    return Object.keys(props.bottomVideoStreams).length;
  }

  shouldHideBottomVideos() {
    return this._countBottomStreams(this.props) === 0;
  }

  updateBottomDimensions() {
    const me = this._getMyBottomViewPort();
    const W = me.clientWidth;
    const H = me.clientHeight;
    const nrOfStreams = this._countBottomStreams(this.props);

    let boxes = [];
    let width = W;

    width = H * 4 / 3;
    const top_calc = Math.floor(H * 5 / 100);
    const total_width = Math.floor(width - (width * 2 / 100));
    let left_center = Math.floor((W - (width * nrOfStreams)) / 2);
    if (total_width * nrOfStreams >= W) {
      left_center = 0;
    }
    for (let stream = 0; stream < nrOfStreams; stream++) {
      const p = {
        top: top_calc,
        left: left_center + (stream * width),
        height: Math.floor(H - top_calc),
        position: 'absolute',
        width: total_width,
        display: 'table-cell',
        //maxWidth: width,
        transition: 'all 1s, transform 1s',
      };
      boxes.push(p);
    }
    this.setState({ bottomBoxes: boxes });
  }

  updateDimensions() {
    const me = this._getMyViewPort();
    const W = me.clientWidth;
    const H = me.clientHeight;
    const nrOfStreams = this._countStreams(this.props);
    const boxes = gridElements(W, H, nrOfStreams);

    if (this._countBottomStreams(this.props) > 0) {
      this.updateBottomDimensions();
    }

    this.setState({ gridBoxes: boxes });
  }

  getLocalScreenElement(classes) {
    const localScreen = this.props.screenStream;
    if (!localScreen) {
      return null;
    }

    return (
      <div
        onMouseEnter={this.showOwnScreenToolbar}
        onMouseLeave={this.hideOwnScreenToolbar}
        onTouchStart={this.showOwnScreenToolbar}
        style={this.state.gridBoxes[1]}>
        {
          this.state.screenShareToolbar &&
          <OwnScreenShareToolbar
            isElectron={this.props.isElectron}
            screenSourceType={this.props.screenSourceType}
            handleScreenShare={this.props.handleScreenShare}
            enableDesktopControl={this.props.enableDesktopControl}
            user={this.props.myUserId}
            someoneHasDesktopControl={Boolean(this.props.desktopControlledUser)}
          />
        }
        <VideoInfoToolbar
          myUserId={this.props.myUserId}
          user={this.props.myUserId}
          displayName={this.props.myDisplayName}
          type='screen'
        />
        <div className={classes.feed}>
          <VideoElement src={this.props.screenStream} />
        </div>
      </div>
    );
  }

  getLocalVideoElement(classes) {
    if (this.props.isRecorder) {
      return null;
    }

    const localVideoStream = this.props.localVideoStream;
    if (!localVideoStream) {
      return null;
    }
    const isPublishing = this.props.localVideoTrack && !this.props.isOwnVideoMuted;
    return (
      <div
        style={this.state.gridBoxes[0]}
        onMouseEnter={this.showOwnToolbar}
        onMouseLeave={this.hideOwnToolbar}
        onTouchStart={this.showOwnToolbar}
      >
        <div>
          <TalkingNotification user={this.props.myUserId} />
        </div>
        {this.videoToolbarIsOnFor(this.props.myUserId) ?
          <OwnVideoToolbar
            user={this.props.myUserId}
            onToggleAudioMute={this.onToggleOwnAudioMute}
            onToggleVideoMute={this.props.localVideoTrack ? this.onPublishVideo : null}
            onHangup={this.onLeaveRoom}
            pipEnabled={this.isPipEnabledFor(this.props.myUserId)}
            pipEnabledOnOther={this.state.pipUser && (this.state.pipUser !== this.props.myUserId)}
            onEnablePip={isPublishing ? this.enablePip : null}
            onDisablePip={isPublishing ? this.disablePip : null}
          />
          :
          null
        }
        <VideoInfoToolbar
          myUserId={this.props.myUserId}
          user={this.props.myUserId}
          displayName={this.props.myDisplayName}
        />
        <div className={classes.feed}>
          {this.props.localVideoTrack ?
            <VideoElement
              user={this.props.myUserId}
              mirrored={true}
              addVideoMutedIconOverlay={true}
              src={this.props.localVideoStream}
              pipEnabled={this.isPipEnabledFor(this.props.myUserId)}
              onPipEnabled={this.onPipEnabled}
              onPipDisabled={this.onPipDisabled}
            />
            :
            <OwnMutedVideoElement displayName={this.props.myDisplayName} uid={this.props.myUserId} />
          }
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.resizeDetector.listenTo(this._getMyViewPort(), this.updateDimensions);
    if (this.props.isElectron) {
      window.ipcRenderer.on('startDrawing', this.startDrawing);
      window.ipcRenderer.on('stopDrawing', this.stopDrawing);
    }
  }

  maybeDisablePip(props) {
    const pipUser = this.state.pipUser;
    if (pipUser === props.myUserId) {
      // no need to check on own stream, this is done when leaving room
      return;
    }
    const users = Object.keys(props.remoteVideoStreams);
    if (pipUser && !users.includes(pipUser)) {
      // user disappeared, disable PiP
      this.disablePip();
      this.pip.set(false);
    }
  }

  componentDidUpdate(prevProps, _prevState) {
    this.maybeDisablePip(this.props, prevProps);
    if (this._countStreams(this.props) !== this._countStreams(prevProps)) {
      this.updateDimensions();
    }
    if (this._countBottomStreams(this.props) !== this._countBottomStreams(prevProps)) {
      this.updateDimensions();
    }

    if (this.state.controlledUserInFullScreen === null) {
      if (this.props.desktopControlledUser) {
        if (prevProps.desktopControlledUser !== this.props.desktopControlledUser
          && Object.keys(this.props.remoteVideoStreams).includes(`${this.props.desktopControlledUser}_screen`)) {
          if (this.props.isElectron) {
            this.setState({ controlledUserInFullScreen: this.props.desktopControlledUser });
            window.goFullScreen(`${this.props.desktopControlledUser}_screen`);
            this.addWindowEventListener();
            this.props.startControllingDesktop();
          }
          else {
            if (this.props.hasStartedDrawing && !prevProps.hasStartedDrawing
              && Object.keys(this.props.remoteVideoStreams).includes(`${this.props.desktopControlledUser}_screen`)) {
              newEvent(INFO, 'startDrawingBrowser', 'startDrawingBrowser',
                'Remote drawing for this room has started. You need to ' +
                'manually start desktop share in fullscreen to use it.');
            } else {
              newEvent(INFO, 'needFullscreenForControl', 'not_used_reason', "needFullscreenForControl");
            }

          }
        }
      }
    }
    else {
      if (!this.props.desktopControlledUser && prevProps.desktopControlledUser) {
        this.leaveFullScreen();
      }
    }
  }

  componentWillUnmount = () => {
    this.resizeDetector.removeAllListeners(this._getMyViewPort());
    this.removeWindowEventListener();
    this.props.stopControllingDesktop();
  }

  leaveFullScreen() {
    if (this.props.isElectron) {
      window.exitFullScreen();
    }
    this.setState({ controlledUserInFullScreen: null, fullScreenStream: null, fullScreenIdx: null });
    this.removeWindowEventListener();

  }

  removeWindowEventListener() {
    if (this.props.isElectron) {
      window.ipcRenderer.removeListener('leaveFullScreen', this.leaveFullScreen);
      window.ipcRenderer.removeListener('startDrawing', this.startDrawing);
      window.ipcRenderer.removeListener('stopDrawing', this.stopDrawing);
    }
    this.props.removeWindowEventListener();
  }

  addWindowEventListener() {
    if (this.props.isElectron) {
      window.ipcRenderer.on('leaveFullScreen', this.leaveFullScreen);
    }
    this.props.addWindowEventListener();

  }

  startDrawing() {
    this.props.startDrawing();
  }
  stopDrawing() {
    this.props.stopDrawing();
  }

  noStreamsVideo(classes, remoteStreams) {
    if (this.getLocalVideoElement(classes) || this.getLocalScreenElement(classes) ||
      Object.keys(remoteStreams).length > 0) {
      return null;
    }
    return (
      <div className={classes.feed}>
        <NoVideoElement />
      </div>
    );
  }

  renderBottomVideos(classes) {
    return (
      <div className={classes.bottomVideo} ref={(ref) => { this.videoBottomArea = ref; }}>
        {(Object.keys(this.props.bottomVideoStreams).length !== 0) ?
          <div>
            {Object.keys(this.props.bottomVideoStreams).map((key, idx) => {
              return (
                <div key={idx} style={this.state.bottomBoxes[idx]}
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseEnter={() => this.setState({ videoSmallToolBar: key })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseLeave={() => this.setState({ videoSmallToolBar: false })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onTouchStart={() => this.setState({ videoSmallToolBar: key })}>
                  <div>
                    <TalkingNotification
                      user={key}
                    />
                  </div>
                  <div className={classes.feed}>
                    {(this.props.bottomVideoStreams[key].stream) ?
                      <VideoElement user={key} src={this.props.bottomVideoStreams[key].stream} />
                      :
                      <MutedVideoElement
                        displayName={this.props.bottomVideoStreams[key].displayName}
                        viaPhone={this.props.bottomVideoStreams[key].viaPhone}
                        text={this.props.bottomVideoStreams[key].displayName}
                        uid={key}
                      />
                    }

                  </div>
                  <div className={classes.audioMutedIcon} >
                    <AudioMutedIcon user={key} />
                  </div>
                  {this.videoSmallToolbarIsOnFor(key) ?
                    <div className={classes.smallToolBar} >
                      <MenuVideoToolbar className={classes.smallToolBar}
                        user={key}
                        myUserId={this.props.myUserId}
                        isFullScreen={false}
                        onToggleAudioMute={this.onToggleAudioMute}
                        onKick={this.onKick}
                        onChangeRole={this.onChangeRole}
                      />
                    </div>
                    : null}
                </div>
              );
            })}
          </div>
          : null
        }
      </div>
    );
  }

  exitFullScreenDesktopControl() {
    this.leaveFullScreen();
  }

  // addNewColor = (newColor) => {
  //   this.setState({ pointersColor: [...this.state.pointersColor, newColor] });
  // }

  render() {
    const classes = this.props.classes;
    const myVideoClass = this.shouldHideBottomVideos() ? classes.myFullVideo : classes.myVideo;

    return (
      <React.Fragment>
        <div className={classes.myFullVideoContainer}>
          <div className={myVideoClass} ref={(ref) => { this.videoArea = ref; }}>
            {Object.keys(this.props.remoteVideoStreams).map((key, idx) => {
              let offset = 0;
              if (this.showVideoLocalStream(this.props)) offset += 1;
              if (this.props.screenStream) offset += 1;
              let onRemoteVideoMute = (this.props.remoteVideoStreams[key].stream
                ? this.onToggleVideoMute
                : null);

              const fullscreen_id = (this.state.controlledUserInFullScreen)
                ? `${this.state.controlledUserInFullScreen}_screen`
                : '';
              const isDesktopControlRunning = key === fullscreen_id;
              return (
                <div key={idx} style={this.state.gridBoxes[idx + offset]}
                  className={isDesktopControlRunning ?
                    classes.fullScreenDivHideCursor : null}
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseEnter={() => this.setState({ videoToolBar: key })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onMouseLeave={() => this.setState({ videoToolBar: false })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onTouchStart={() => this.setState({ videoToolBar: key })}>
                  <FullScreen
                    idx={idx}
                    fullscreen_id={isDesktopControlRunning ? fullscreen_id : ''}
                    user={key}
                    enabled={this.fullScreenIsOnFor(key, idx)}
                    onChange={this.onFullScreenChangeFor}
                  >
                    <div>
                      <TalkingNotification
                        user={key}
                      />
                    </div>
                    <div className={classes.feed}>
                      {(this.props.remoteVideoStreams[key].stream) ?
                        <VideoElement
                          user={key}
                          src={this.props.remoteVideoStreams[key].stream}
                          pipEnabled={this.isPipEnabledFor(key)}
                          onPipEnabled={this.onPipEnabled}
                          onPipDisabled={this.onPipDisabled}
                        />
                        :
                        <MutedVideoElement
                          displayName={this.props.remoteVideoStreams[key].displayName}
                          viaPhone={this.props.remoteVideoStreams[key].viaPhone}
                          uid={key}
                        />
                      }
                    </div>

                    <VideoInfoToolbar myUserId={this.props.myUserId} user={key} />
                    {this.videoToolbarIsOnFor(key) ?
                      <VideoToolbar
                        idx={idx}
                        user={key}
                        isFullScreen={this.fullScreenIsOnFor(key, idx) || isDesktopControlRunning}
                        exitFullScreen={isDesktopControlRunning
                          ? this.exitFullScreenDesktopControl
                          : this.onExitFullScreen}
                        enterFullScreen={this.onEnterFullScreen}
                        pipEnabled={this.isPipEnabledFor(key)}
                        pipEnabledOnOther={this.state.pipUser && (this.state.pipUser !== key)}
                        onEnablePip={this.enablePip}
                        onDisablePip={this.disablePip}
                        onToggleVideoMute={onRemoteVideoMute}
                        onToggleAudioMute={this.onToggleAudioMute}
                        onKick={this.onKick}
                        onChangeRole={this.onChangeRole}
                      />
                      : null}
                  </FullScreen>
                </div>
              );
            })}
            {this.getLocalVideoElement(classes)}
            {this.getLocalScreenElement(classes)}
            {this.noStreamsVideo(classes, this.props.remoteVideoStreams)}
          </div>
          {this.shouldHideBottomVideos() ? null : this.renderBottomVideos(classes)}
        </div>
      </React.Fragment>
    );
  }

  isPipEnabledFor = (user) => {
    return !!this.state.pipUser && (this.state.pipUser === user);
  }

  enablePip = (user) => {
    // request to enable PiP
    this.setState({pipUser: user});
  }

  disablePip = (_user) => {
    // request to disable PiP
    this.setState({pipUser: null});
  }

  onPipEnabled = (user) => {
    // called when PiP is effectively enabled
    this.setState({pipUser: user});
  }

  onPipDisabled = (_user) => {
    // called when PiP is effectively disabled
    this.setState({pipUser: null});
  }
}


function mapStateToProps(state) {
  const myUserId = state.websocket.uid;
  return {
    myUserId: myUserId,
    myDisplayName: state.session.displayName,
    screenStream: state.room.screenStream,
    // mousePointers: state.room.mousePointers,
    desktopControlledUser: getMemoizedDeskControlledUser(state),
    hasStartedDrawing: getDrawingHasStarted(state),
    localVideoStream: state.room.localvideo_stream,
    remoteVideoStreams: defaultDimension.getStreams(state),
    bottomVideoStreams: defaultDimension.getBottomStreams(state),
  };
}

StandardLayout.defaultProps = {
  isOwnVideoMuted: false,
};

StandardLayout.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  myUserId: PropTypes.string,
  myDisplayName: PropTypes.string,
  browser: PropTypes.string,
  screenStream: PropTypes.object,
  localVideoStream: PropTypes.object,
  remoteVideoStreams: PropTypes.object.isRequired,
  bottomVideoStreams: PropTypes.object.isRequired,
  /* pass from parent */
  userAgent: PropTypes.object.isRequired,
  onToggleVideoMute: PropTypes.func.isRequired,
  onToggleAudioMute: PropTypes.func.isRequired,
  handleScreenShare: PropTypes.func.isRequired,
  onKick: PropTypes.func.isRequired,
  onChangeRole: PropTypes.func.isRequired,
  onPublishVideo: PropTypes.func.isRequired,
  localVideoTrack: PropTypes.object,
  isOwnVideoMuted: PropTypes.bool,
  onToggleOwnAudioMute: PropTypes.func.isRequired,
  onLeaveRoom: PropTypes.func.isRequired,
  isRecorder: PropTypes.bool.isRequired,
  isElectron: PropTypes.bool.isRequired,
  screenSourceType: PropTypes.string.isRequired,
  enableDesktopControl: PropTypes.func.isRequired,
  removeWindowEventListener: PropTypes.func.isRequired,
  addWindowEventListener: PropTypes.func.isRequired,
  desktopControlledUser: PropTypes.string,
  hasStartedDrawing: PropTypes.bool,
  startControllingDesktop: PropTypes.func.isRequired,
  stopControllingDesktop: PropTypes.func.isRequired,
  startDrawing: PropTypes.func.isRequired,
  stopDrawing: PropTypes.func.isRequired,
};


export default withStyles(style)(injectIntl(connect(mapStateToProps)(StandardLayout)));
