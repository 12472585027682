/* Libraries Imports */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import URI from 'urijs';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import style from './style';
/* Components Imports */
import BodyLayout from '../../style/bodylayout';
import ExternalRedirect from '../ExternalRedirect';
/* Actions Imports */
/* Other Imports */
import { Router } from 'holocom-client/lib/utils';


const messages = defineMessages({
  genericOAuth: { id: 'genericOAuth' },
});


class OAuthLogin extends Component {
  constructor(props) {
    super(props);
    this.router = new Router();
    this.state = {
      redirectUrl: null
    };
  }

  handleLogin = (oauthConfig) => {
    const redirectUrl = "/auth/oauth/" + oauthConfig.type;
    this.setState({ redirectUrl: redirectUrl });
  }

  curriedHandleLogin = (config) => {
    return () => this.handleLogin(config);
  }

  getButtonText = ({ label }) => {
    if (label) {
      return label;
    } else {
      return this.props.intl.formatMessage(messages.genericOAuth);
    }
  }

  getOAuthButtons = () => {
    const classes = this.props.classes;
    const oauthConfig = this.props.oauthConfig;

    if (oauthConfig.length === 1) {
      const config = oauthConfig[0];
      this.handleLogin(config);
    }

    const buttons = oauthConfig.map((config) =>
      <ListItem key={config.type}>
        <Button
          variant="contained"
          color="primary"
          className={classes.oauthButton}
          onClick={this.curriedHandleLogin(config)}
        >
          {this.getButtonText(config)}
        </Button>
      </ListItem>);

    return (
      <Paper className={classes.holocomDialogForm}>
        <List>
          {buttons}
        </List>
      </Paper>
    );
  }

  getButtonsOrRedirect = () => {
    if (this.state.redirectUrl) {
      return (
        <ExternalRedirect externalUrl={this.state.redirectUrl} />
      );
    } else {
      return this.getOAuthButtons();
    }
  }

  render() {
    if (this.props.isAuthenticated) {
      let redirectTo = this.router.getUrlFor(this.router.HOME);
      const qs = new URI(this.props.history.location.search);
      const goTo = qs.search(true).redirectTo;
      if (goTo) {
        redirectTo = this.router.getUrlFor(this.router.MEETING, { slug: goTo });
      }
      return (
        <Redirect to={{
          pathname: redirectTo
        }} />
      );
    }

    return (
      <BodyLayout fullHeight={true}>
        {this.getButtonsOrRedirect()}
      </BodyLayout>
    );
  }
}


OAuthLogin.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  localStore: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  oauthConfig: PropTypes.array.isRequired
};


function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(OAuthLogin)));
