"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.startTracer = startTracer;
exports.getRaven = getRaven;
exports.setTracedUser = setTracedUser;
exports.unsetTracedUser = unsetTracedUser;
exports.traceWSCloseEvent = traceWSCloseEvent;

var _ravenJs = _interopRequireDefault(require("raven-js"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function startTracer(dsn) {
  _ravenJs["default"].config(dsn).install();
}

function getRaven() {
  return _ravenJs["default"];
}

function setTracedUser(id, username, email, ip_addr) {
  _ravenJs["default"].setUserContext({
    id: id,
    username: username,
    email: email,
    ip_address: ip_addr
  });
}

function unsetTracedUser() {
  _ravenJs["default"].setUserContext({});
}

function traceWSCloseEvent(event) {
  if (event.wasClean === true) {
    return;
  }

  var data = {
    wasClean: event.wasClean,
    type: event.close,
    code: event.code
  };

  _ravenJs["default"].captureMessage("WebSocket Closed", {
    extra: data
  });
}