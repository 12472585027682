import { Environment, Network, RecordSource, Store } from 'relay-runtime';


function fetchQuery(operation, variables, token) {
  let headers = { 'content-type': 'application/json' };
  if (token) {
    headers = Object.assign(headers, { 'Authorization': token, });
  }

  return fetch(
    '/api/graphql',
    {
      method: 'POST',
      headers: headers,
      credentials: 'same-origin',
      body: JSON.stringify({
        query: operation.text, // GraphQL text from input
        variables,
      }),
    },
  ).then(response => response.json()).then(responseJson => {
    if (responseJson.errors) {
      return Promise.reject(responseJson.errors);
    }
    return responseJson;
  });
}

export function getEnvironment(token) {
  const env = new Environment({
    network: Network.create((op, vars) => fetchQuery(op, vars, token)),
    store: new Store(new RecordSource()),
  });
  return env;
}
