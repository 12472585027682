/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
/* Components imports */
import DialogMultiLineText from '../DialogMultiLineText';
/* Local Style */
import style from './style';

const messages = defineMessages({
  title: {id: 'screenSharingUnsupportedTitle'},
  message: {id: 'screenSharingUnsupportedMessage'},
  close: {id: 'close'},
});


class ScreenShareUnsupported extends Component {

  render() {
    const classes = this.props.classes;
    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="md"
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <DialogMultiLineText
            message={this.props.intl.formatMessage(messages.message)}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            variant="contained"
            onClick={this.props.onCloseButtonClick}
          >
            {this.props.intl.formatMessage(messages.close)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


ScreenShareUnsupported.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


export default withStyles(style)(injectIntl(ScreenShareUnsupported));
