"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function calculateGrid(howMany, viewSize, gutterSize) {
  var w = 16 / 9;
  var h = 1;
  var W = viewSize.width;
  var H = viewSize.height;
  var sW = gutterSize.width;
  var sH = gutterSize.height;

  if (howMany < 1) {
    return {
      rows: 0,
      cols: 0,
      ratio: 0,
      all: []
    };
  }

  var sequence = range(1, howMany);
  var lW = sequence.map(function (c) {
    return (W - (c - 1) * sW) / (c * w);
  });
  var lH = sequence.map(function (c) {
    return (H - (c - 1) * sH) / (c * h);
  });
  var l = lW.concat(lH);
  var res = l.filter(function (c) {
    var a = Math.floor((W + sW) / (c * w + sW));
    var b = Math.floor((H + sH) / (c * h + sH));
    return a * b >= howMany;
  }).sort(function (a, b) {
    return b - a;
  });
  var bestRatio = res[0];
  var rows = Math.floor((H + sH) / (bestRatio * h + sH));
  var cols = Math.floor((W + sW) / (bestRatio * w + sW));
  rows = Math.min(rows, Math.ceil(howMany / cols)); // only return filled rows

  return {
    rows: rows,
    cols: cols,
    ratio: bestRatio,
    all: res
  };
}

function range(start, end) {
  return _toConsumableArray(Array(1 + end - start).keys()).map(function (v) {
    return start + v;
  });
}

function gridElements(w, h, streams) {
  var gutters = {
    width: 0,
    height: 0
  };
  var viewSize = {
    width: w,
    height: h
  };
  var grid = calculateGrid(streams, viewSize, gutters);
  var boxes = [];
  var colsInLastRow = streams % grid.cols;

  for (var row = 0; row < grid.rows; row++) {
    for (var col = 0; col < grid.cols; col++) {
      var videoW = Math.floor(grid.ratio * (16 / 9));
      var videoH = Math.floor(grid.ratio);
      /* calculate centering offset */

      var neededWidth = videoW * grid.cols;
      var availableWidth = viewSize.width - neededWidth;

      if (row === grid.rows - 1 && colsInLastRow > 0 && col < colsInLastRow) {
        availableWidth = viewSize.width - videoW * colsInLastRow;
      }

      var left = col * videoW + availableWidth / 2;
      var usedRows = Math.ceil(streams / grid.cols);
      var neededHeight = videoH * usedRows;
      var availableHeight = viewSize.height - neededHeight;
      var top = row * grid.ratio + availableHeight / 2;
      var p = {
        left: left,
        top: top,
        width: videoW,
        height: grid.ratio,
        position: 'absolute',
        transition: 'all 1s, transform 1s'
      };
      boxes.push(p);
    } // end cols

  } // end rows


  return boxes;
}

var _default = gridElements;
exports["default"] = _default;