/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  intlShape, injectIntl, //defineMessages
} from 'react-intl';
import moment from 'moment';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
/* Local Imports */
import MeetingSelectorGuest from '../MeetingSelectorGuest';
import MeetingPersonal from '../MeetingPersonal';
import MeetingsBoard from '../MeetingsBoard';
import Spinner from '../Spinner';
import TabTitle from '../../tabTitle';
/* Local Style */
import style from './style';


class MeetingSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoMeeting: {},
      openSpinner: false
    };
    this.setInfoMeeting = this.setInfoMeeting.bind(this);
    this.getDate = this.getDate.bind(this);
    this.handleOpenSpinner = this.handleOpenSpinner.bind(this);
    this.handleCloseSpinner = this.handleCloseSpinner.bind(this);

    TabTitle.resetTabTitle();
  }

  setInfoMeeting(infoMeeting) {
    this.setState({ infoMeeting: infoMeeting });
  }

  handleOpenSpinner() {
    this.setState({ openSpinner: true });
  }

  handleCloseSpinner() {
    this.setState({ openSpinner: false });
  }

  getDate(date) {
    const dateRoom = moment(date);
    const fullDateRoom = this.props.intl.formatDate(dateRoom, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
    return fullDateRoom;
  }

  authedRender() {
    const classes = this.props.classes;

    return (
      <div className={classes.flexMeetingContainer}>
        <div className={classes.flexMeetingPersonal}>
          <MeetingPersonal history={this.props.history} />
        </div>
        <MeetingsBoard
          relayEnvironment={this.props.relayEnvironment}
          history={this.props.history}
        />
      </div>
    );
  }

  guestRender() {
    const classes = this.props.classes;

    return (
      <div className={classes.guestFlexMeetingSelector}>
        <MeetingSelectorGuest history={this.props.history} />
      </div>
    );
  }

  renderImpl() {
    if (this.props.authToken && this.props.isAuthenticated) {
      return this.authedRender();
    } else {
      return this.guestRender();
    }
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.root}>
        {this.state.openSpinner &&
          <Spinner />
        }
        {this.renderImpl()}
      </div>
    );
  }
}

MeetingSelector.propTypes = {
  history: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  authToken: PropTypes.string,
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  relayEnvironment: PropTypes.object.isRequired,
  displayName: PropTypes.string
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    authToken: state.auth.token,
    displayName: state.session.displayName
  };
}

export default withStyles(style)(injectIntl(connect(mapStateToProps)(MeetingSelector)));
