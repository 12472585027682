/* Libraries Imports */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
/* UI Imports */
import Typography from '@material-ui/core/Typography';
import ClipBoardCheckIcon from '../../style/ClipBoardCheckIcon';
import ScheduleMeeting from '../ScheduleMeeting';
/* Other Imports */
import style from './styleEmpty';


const messages = defineMessages({
  allDone: { id: 'allDone' },
  allDoneSchedule: { id: 'allDoneSchedule' }
});


class Empty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scheduleMeetingDialog: false,
    };
  }

  onScheduleClick = () => {
    this.setState({
      scheduleMeetingDialog: true
    });
  }

  onScheduleDialogClose = () => {
    this.setState({ scheduleMeetingDialog: false });
  }

  render() {
    const classes = this.props.classes;

    return (
      <div className={classes.emptyContainer}>
        <ClipBoardCheckIcon className={classes.emptyIcon} color='primary' />
        <Typography variant="h5" gutterBottom >
          {this.props.intl.formatMessage(messages.allDone)}
        </Typography>
        <Typography gutterBottom onClick={this.onScheduleClick} className={classes.scheduleText}>
          {this.props.intl.formatMessage(messages.allDoneSchedule)}
        </Typography>

        {this.state.scheduleMeetingDialog === true &&
          <ScheduleMeeting
            onCloseClick={this.onScheduleDialogClose}
            relayEnvironment={this.props.relayEnvironment}
          />
        }
      </div>
    );
  }
}


Empty.propTypes = {
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  relayEnvironment: PropTypes.object.isRequired
};


export default withStyles(style)(injectIntl(Empty));
