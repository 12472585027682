/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import MicOff from '@material-ui/icons/MicOff';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Local Style */
import style from './style';


const messages = defineMessages({
  microphoneButtonDisabledTooltip: {id: 'microphoneButtonDisabledTooltip'},
});

class AudioMutedIcon extends Component {

  render() {
    const classes = this.props.classes;
    return (
      <React.Fragment>
        {this.props.isAudioMuted ? (
          <TooltipMultiLineText
            placement="top"
            title={this.props.intl.formatMessage(messages.microphoneButtonDisabledTooltip)}>
            <MicOff className={classes.redIcon} />
          </TooltipMultiLineText>
        ) : null}
      </React.Fragment>
    );
  }
}

AudioMutedIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isAudioMuted: PropTypes.bool,
  user: PropTypes.string,
};


function mapStateToProps(state, props) {
  let user = props.user;
  if (user) {
    user = user.replace(/_screen$/, '');
  }

  return {
    isAudioMuted: (state.room.roster[user] || { isAudioMuted: false }).isAudioMuted,
  };
}

export default withStyles(style)(injectIntl(connect(mapStateToProps)(AudioMutedIcon)));
