// /* global process */
import { getRaven, startTracer } from 'holocom-client/lib/sentry';
import "@babel/polyfill";
import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { rootReducer } from 'holocom-client/lib/reducers';
import { retriveStoredSettings } from 'holocom-client/lib/api/settings';
import { checkBrowserSession } from 'holocom-client/lib/api/session';
import { setApplicationEnvironment } from 'holocom-client/lib/utils/environment';
import { initNotifications } from 'holocom-client/lib/notifications';
import { getLogger, setLoggerTransport } from 'holocom-client/lib/logger';
import 'webrtc-adapter';
import './index.css';
import App from './App';
import LocalStorage from './localStorage';
import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import TabTitle from './tabTitle';

function setupStores(debug, session_token) {
  let token = null;
  const localStore = new LocalStorage();
  if (session_token) {
    token = session_token;
    localStore.saveToken(token);
  } else {
    token = localStore.loadToken();
  }

  const initialState = {
    auth: { isAuthenticated: false, token: token },
    settings: retriveStoredSettings(localStore),
  };
  const store = createStore(rootReducer, initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware)));

  if (debug) {
    window.reduxStore = store;
  }

  return { store, localStore };
}

function startApp(store, localStore, lang, config) {
  getRaven().context(() => {
    // start notification subsystem
    startNotificationsSubsystem(store);

    ReactDOM.render(
      <Provider store={store}>
        <App lang={lang} localStore={localStore} appConfig={config} />
      </Provider>,
      document.getElementById('root'));
  });
}

function startNotificationsSubsystem(store) {
  const logger = getLogger('Notifications');
  initNotifications(store.dispatch, logger);
}

function fetchConfiguration() {
  const fetchConfig = {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
    credentials: 'same-origin'
  };

  return fetch("/_holocom_config.json", fetchConfig).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Cannot fetch configuration.');
  });
}

function runBrowserSession(lang) {
  setLoggerTransport(window.console);

  fetchConfiguration().then((holoConfig) => {
    // set window title
    setWindowTitle(holoConfig);

    // start application
    checkBrowserSession().then((res) => {
      const token = res.token;
      const stores = setupStores(reduxDebugEnabled, token);
      startApp(stores.store, stores.localStore, lang, holoConfig);
    }).catch(() => {
      const stores = setupStores(reduxDebugEnabled);
      startApp(stores.store, stores.localStore, lang, holoConfig);
    });
  });
}

function setWindowTitle(config) {
  const title = config.site_title || "Unconfigured Holocom Server";
  new TabTitle(title);
}

/////////////////////////////////////
// BROWSER APPLICATION ENTRY POINT //
/////////////////////////////////////

// setup debug tools
let reduxDebugEnabled;
if (process.env.NODE_ENV === 'development') {
  setApplicationEnvironment('dev');
  /* trace on devel sentry project */
  startTracer('https://da750a419a014e8cb5887d75fe08552f@sentry.voismart.net/3');
  reduxDebugEnabled = true;
} else {
  /* trace on production sentry project */
  setApplicationEnvironment('prod');
  startTracer('https://ed479faefeeb4f8ab04ee6418fcf8aeb@sentry.voismart.net/2');
  reduxDebugEnabled = true;  // for now also enabled in production mode
}

let composeEnhancers = compose;
if (reduxDebugEnabled) {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }
}

// setup moment locale
let lang = (navigator.language || navigator.userLanguage).toLowerCase().split(/[_-]+/)[0];
if (lang !== 'it') {
  lang = 'en';
}

//setup locale
moment.locale(lang === 'it' ? 'it' : 'en-gb');

// start app
runBrowserSession(lang);
