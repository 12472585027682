"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statsStart = statsStart;
exports.statsAddStream = statsAddStream;
exports.sendConferenceMetadata = sendConferenceMetadata;
exports.sendUserMetadata = sendUserMetadata;
exports.reportError = reportError;
exports.SIGNALINGERROR = exports.GETUSERMEDIA = void 0;

var _rtcstats = require("rtcstats");

var GETUSERMEDIA = 'getUserMedia';
exports.GETUSERMEDIA = GETUSERMEDIA;
var SIGNALINGERROR = 'signalingError';
exports.SIGNALINGERROR = SIGNALINGERROR;
var rtcstats = null;

function statsStart(appID, appSecret, endpoint, parsedUA) {
  if (rtcstats) {
    return;
  }

  rtcstats = new _rtcstats.RtcStats(appID, appSecret, endpoint, parsedUA);
}

function statsAddStream(pc, conferenceID, userID, usage) {
  if (!pc) throw "statsAddStream: Missing PeerConnection!";
  if (!conferenceID) throw "statsAddStream: Missing Conference ID!";
  if (!userID) throw "statsAddStream: Missing User ID!";
  if (!usage) throw "statsAddStream: Missing usage";

  if (!rtcstats) {
    return;
  }

  rtcstats.addStream(pc, conferenceID, userID, usage);
}

function sendConferenceMetadata(conferenceID, metadata) {
  if (!conferenceID) throw "sendConferenceMetadata: Missing Conference ID!";
  if (!metadata) throw "sendConferenceMetadata: Missing conference metadata";

  if (!rtcstats) {
    return;
  }

  rtcstats.sendConferenceMetadata(conferenceID, metadata);
}

function sendUserMetadata(conferenceID, userID, metadata) {
  if (!conferenceID) throw "sendUserMetadata: Missing Conference ID!";
  if (!userID) throw "sendUserMetadata: Missing User ID!";
  if (!metadata) throw "sendUserMetadata: Missing user metadata";

  if (!rtcstats) {
    return;
  }

  rtcstats.sendUserMetadata(conferenceID, userID, metadata);
}

function reportError(conferenceID, userID, failedFunction) {
  if (!conferenceID) throw "reportError: Missing Conference ID!";
  if (!userID) throw "reportError: Missing User ID!";

  if (!rtcstats) {
    return;
  }

  rtcstats.reportError(conferenceID, userID, failedFunction);
}