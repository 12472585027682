import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


const messages = defineMessages({
  ok: {id: 'ok'},
  content: {id: 'meetingLeftDialogContent'},
  kickedContent: {id: 'meetingLeftDialogContentWhenKicked'},
  endedContent: {id: 'meetingLeftDialogContentWhenEnded'},
  iceFailedContent: {id: 'meetingLeftDialogContentWhenIceFailed'},
  title: {id: 'meetingLeftDialogTitle'},
});


class MeetingLeftDialog extends Component {
  getContent() {
    if (this.props.reason === 'kicked') {
      return this.props.intl.formatMessage(messages.kickedContent);
    }
    else if (this.props.reason === 'ended') {
      return this.props.intl.formatMessage(messages.endedContent);
    }
    else if (this.props.reason === 'ice_failed') {
      return this.props.intl.formatMessage(messages.iceFailedContent);
    }
    else {
      return this.props.intl.formatMessage(messages.content);
    }
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.getContent()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onConfirm} color="primary">
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


MeetingLeftDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reason: PropTypes.string,
  intl: intlShape.isRequired,
};


export default injectIntl(MeetingLeftDialog);
