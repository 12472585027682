/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
// import Slide from '@material-ui/core/transitions/Slide';
/* Local Style */
import style from './style';


class NavigationSlide extends Component {
  render() {
    if (!this.props.open) {
      return null;
    }

    const {
      elevation,
      children,
      classes
    } = this.props;
    const drawer = (
      <div className={classes.container}>
        <Paper
          elevation={elevation}
          square
          className={classes.paper}
        >
          {children}
        </Paper>
      </div>
    );

    /*
    return (
      <Slide
        in={this.props.open}
        direction={'left'}
        timeout={{ enter: 1000, exit: 1000 }}
      >
        {drawer}
      </Slide>
    );
    */
    return drawer;
  }
}


NavigationSlide.propTypes = {
  classes: PropTypes.object.isRequired,
  elevation: PropTypes.number,
  children: PropTypes.node,
  open: PropTypes.bool,
};


NavigationSlide.defaultProps = {
  elevation: 16,
  open: false,
};


export default withStyles(style)(NavigationSlide);
