import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


const messages = defineMessages({
  ok: {id: 'ok'},
  cancel: {id: 'cancel'},
  content: {id: 'endMeetingDialogContent'},
  title: {id: 'endMeetingDialogTitle'},
});


class EndMeetingDialog extends Component {
  render() {
    return (
      <Dialog
        open={this.props.open}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
      >
        <DialogTitle>
          {this.props.intl.formatMessage(messages.title)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.props.intl.formatMessage(messages.content)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel}>
            {this.props.intl.formatMessage(messages.cancel)}
          </Button>
          <Button onClick={this.props.onConfirm} variant="contained" color="primary">
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


EndMeetingDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};


export default injectIntl(EndMeetingDialog);
