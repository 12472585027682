import { createDeepEqualitySelector, getRoster, getMyUserId } from './reduxSelectors';


export const getBottomStreams = createDeepEqualitySelector([getRoster, getMyUserId], (roster, myself) => {
  let videos = {};
  if (!myself) {
    return videos;
  }
  let keys = Object.keys(roster);
  for (let user of keys) {
    if (user === myself) {
      continue;
    }
    let remote_v = roster[user].stream;
    if (remote_v) {
      continue;
    }
    else {
      videos[user] = {
        stream: remote_v,
        displayName: roster[user].display,
        viaPhone: roster[user].viaPhone,
      };
    }
  }
  return videos;
});


export const getStreams = createDeepEqualitySelector([getRoster, getMyUserId], (roster, myself) => {
  let videos = {};
  if (!myself) {
    return videos;
  }
  let keys = Object.keys(roster);
  for (let user of keys) {
    if (user === myself) {
      continue;
    }
    let remote_v = roster[user].stream;
    if (remote_v) {
      videos[user] = {
        stream: remote_v,
        displayName: roster[user].display,
        viaPhone: roster[user].viaPhone,
      };
    }
    let v = roster[user].screen;
    if (v) {
      videos[user + '_screen'] = {
        stream: v,
        displayName: roster[user].display,
        viaPhone: false,
      };
    }
  }
  return videos;
});
