/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

/* UI Imports */
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Eject from '@material-ui/icons/Eject';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Other Imports */
import { WithAuthorization } from '../../authorization';
/* Local Style */
import style from './style';

const messages = defineMessages({
  ejectIconButtonTooltip: { id: 'ejectIconButtonTooltip' }
});


class KickIconButton extends Component {
  onKick = () => {
    let user = this.props.user;
    if (user) {
      user = user.replace(/_screen$/, '');
      this.props.onClick(user);
    }
  }

  render() {
    const { classes, className } = this.props;
    if (this.props.userRole === 'room_owner') {
      return null;
    }
    return (
      <TooltipMultiLineText
        placement="top"
        title={this.props.intl.formatMessage(messages.ejectIconButtonTooltip)}>
        <IconButton
          onClick={this.onKick}
          className={classes.button}
        >
          <Eject className={classNames(
            classes.kickButton, className, classes.videoButton)}
          />
        </IconButton>
      </TooltipMultiLineText>
    );
  }
}


KickIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  intl: intlShape.isRequired
};


function mapStateToProps(state, props) {
  const user = state.room.roster[props.user.replace(/_screen$/, '')] || {};
  return {
    userRole: user.role,
  };
}


const roles = ['room_owner', 'room_moderator'];
export default WithAuthorization(roles, withStyles(style)(injectIntl(connect(mapStateToProps)(KickIconButton))));
